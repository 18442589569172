import { useEffect, useState } from "react";
import axios from "axios";
import { useWeb3React } from "@web3-react/core";
import styled from "styled-components";
import useSWR from "swr";
import { t } from "@lingui/macro";

import Tab from "components/Tab/Tab";
import Footer from "components/Footer/Footer";
import { useNaviPrice } from "domain/legacy";
import TooltipComponent from "components/Tooltip/Tooltip";
import ftmIcon from "img/ic_ftm_24.svg";
import opIcon from "img/ic_op_24.svg";
import {
  PLACEHOLDER_ACCOUNT,
  getBalanceAndSupplyData,
  getDepositBalanceData,
  getVestingData,
  getStakingData,
  getProcessedData,
  USD_DECIMALS,
  NLP_DECIMALS,
} from "lib/legacy";

import { StyledWrapperSection, StyledWrapperSectionIpad, StyledVaultInfoSection, StyledVaultInfo } from "./index.style";

import naviIcon from "img/ic_navi_24.svg";
import NAVI_FTM from "img/NAVI-FTM.svg";
import NAVI_ETH from "img/NAVI-ETH.svg";
import tokensIcon from "img/ic_nlp_24.svg";
import iconColor from "img/ic_color.svg";
import iconSnake from "img/ic_snake.svg";
import iconDeFive from "img/ic_defive.svg";

import VaultJson from "abis/Vault.json";
import NewVaultJson from "abis/NewVault.json";
import ReaderV2 from "abis/ReaderV2.json";
import RewardReader from "abis/RewardReader.json";
import Token from "abis/Token.json";
import NlpManager from "abis/NlpManager.json";
import VaultStrategyNAVI from "abis/VaultStrategyNAVI.json";
import VaultStrategyNLP from "abis/VaultStrategyNLP.json";

import { getContract } from "config/contracts";
import { contractFetcher } from "lib/contracts";
import { formatAmount, formatKeyAmount, formatNumber, formatAmountFree, expandDecimals } from "lib/numbers";
import VaultAction from "./VaultAction";
import moment, { now } from "moment";
import VaultTab from "./VaultTab";
import StatsTooltipRow from "components/StatsTooltip/StatsTooltipRow";
import { FANTOM, SONIC_TESTNET } from "config/chains";
import { useMedia } from "react-use";
import { useThemeContext } from "contexts/ThemeProvider";
import { EarnFrame } from "pages/Earn/styles";
import { vaultNaviUrl } from "pages/BuyNAVI/constants";

export const NAVI_VAULT = "NAVI vault";
export const NLP_VAULT = "NLP vault";
export const COLOR = "Color";
export const SKANE = "Skane";
export const DEFIVE = "Defive";
export const NAVI_WFTM_OLD_VAULT = "NAVI_WFTM_OLD vault";
export const NAVI_WFTM_VAULT = "NAVI_WFTM vault";
export const NAVI_WETH_VAULT = "NAVI_WETH vault";

const TAB_ICONS = {
  [NAVI_VAULT]: naviIcon,
  [NLP_VAULT]: tokensIcon,
  [COLOR]: iconColor,
  [SKANE]: iconSnake,
  [DEFIVE]: iconDeFive,
};

const LINK_TABS = {
  [COLOR]: `${vaultNaviUrl}/All/COLOR/`,
  [SKANE]: `${vaultNaviUrl}/All/SKANE/`,
  [DEFIVE]: `${vaultNaviUrl}/All/DEFIVE/`,
};

const StyledTab = styled(Tab)`
  background-color: #12141a !important;
  height: 48px;
  text-align: center;
  font-weight: 700;
  font-size: 14px;
  margin-bottom: 24px;
  border-radius: 12px;
  width: fit-content;
  display: inline-flex;
  .active {
    background: #262933 !important;
    color: #90fb75 !important ;
  }
  .Tab-option {
    color: #828899;
  }
  @media (max-width: 768px) {
    width: 100%;

    display: flex !important;
    flex-wrap: wrap;

    height: fit-content;

    .Tab-option {
      width: 50% !important;

      padding: 8px !important;
    }
  }

  > div,
  a {
    height: 48px;
    /* padding: 12px 16px !important; */
    display: flex;
    align-items: center;
    min-width: 120px !important;
    text-decoration: none;
    white-space: nowrap;
    justify-content: center;
    border-radius: 12px !important;
    /* box-sizing: border-box !important; */

    img {
      filter: none !important;
      width: auto;
      height: 24px;
    }

    /* &:nth-child(2) {
      img {
        width: 36px;
      }
    } */
  }
`;

const NAVI_WFTM_Address = "0xdc935ffe9f9c972b7b304e0b7e61eb774037e394";

export default function Vault(props) {
  // const { chainId } = useChainId();
  const isMobile = useMedia("(max-width: 600px)");
  const { lightThemeClassName, isLightTheme } = useThemeContext();
  const chainId = SONIC_TESTNET;

  const [activeTab, setActiveTab] = useState(NAVI_VAULT);
  const TAB_OPTION_LABELS = {
    [NAVI_VAULT]: `NAVI`,
    [NLP_VAULT]: `NLP`,
    [COLOR]: `Colors Finance`,
    [SKANE]: `Snake`,
    [DEFIVE]: `Defive`,
    // [NAVI_WFTM_OLD_VAULT]: `NAVI-WFTM V1`,
    // [NAVI_WFTM_VAULT]: `NAVI-WFTM`,
    // [NAVI_WETH_VAULT]: `NAVI-WETH`,
  };

  const { connectWallet, setPendingTxns } = props;

  const { active, library, account } = useWeb3React();

  const [value, setValue] = useState("");
  const [statsNAVIWFTM, setStatsNAVIWFTM] = useState({
    price: 0,
    apr: 10,
  });

  const fetchStatsNAVIWFTM = async () => {
    const res = await axios.get(`https://api.navigator.exchange/api/tokens/info?symbols=NAVI/S&chain=SONIC`, {});
    // const resAPR = await axios.get(`https://api.grim.finance/apy/breakdown`, {});

    if (res && res.data) {
      const { price } = res.data["NAVI/FTM"];
      // const apr = resAPR.data["equalizerv2-navi-ftm"]?.vaultApr ? resAPR.data["equalizerv2-navi-ftm"]?.vaultApr * 100 : 0;
      setStatsNAVIWFTM({
        apr: 10,
        price,
      });
    }
  };

  useEffect(() => {
    fetchStatsNAVIWFTM();

    const interval = setInterval(async () => {
      fetchStatsNAVIWFTM();
    }, 30000);
    return () => clearInterval(interval);
  }, []);

  const TAB_OPTIONS = [NAVI_VAULT, NLP_VAULT, COLOR, SKANE, DEFIVE];

  const rewardReaderAddress = getContract(chainId, "RewardReader");
  const readerAddress = getContract(chainId, "Reader");

  const vaultAddress = getContract(chainId, "Vault");
  const vaultNAVIAddress = getContract(chainId, "VaultNAVI");
  const vaultNLPAddress = getContract(chainId, "VaultNLP");

  const currentVaultAddress = activeTab === NAVI_VAULT ? vaultNAVIAddress : vaultNLPAddress;

  const nativeTokenAddress = getContract(chainId, "NATIVE_TOKEN");
  const naviAddress = getContract(chainId, "NAVI");
  const esNaviAddress = getContract(chainId, "ES_NAVI");
  const bnNaviAddress = getContract(chainId, "BN_NAVI");
  const nlpAddress = getContract(chainId, "NLP");
  const usdgAddress = getContract(chainId, "USDN");

  const stakedNaviTrackerAddress = getContract(chainId, "StakedNaviTracker");
  const bonusNaviTrackerAddress = getContract(chainId, "BonusNaviTracker");
  const feeNaviTrackerAddress = getContract(chainId, "FeeNaviTracker");

  const stakedNlpTrackerAddress = getContract(chainId, "StakedNlpTracker");
  const feeNlpTrackerAddress = getContract(chainId, "FeeNlpTracker");

  const nlpManagerAddress = getContract(chainId, "NlpManager");

  const naviVesterAddress = getContract(chainId, "NaviVester");
  const nlpVesterAddress = getContract(chainId, "NlpVester");
  const nslpAddress = getContract(chainId, "NSLP");
  const nslpVesterAddress = getContract(chainId, "NslpVester");
  const feeNslpTrackerAddress = getContract(chainId, "FeeNslpTracker");
  const stakedNslpTrackerAddress = getContract(chainId, "StakedNslpTracker");
  const vesterAddresses = [naviVesterAddress, nlpVesterAddress, nslpVesterAddress];

  const walletTokens = [naviAddress, esNaviAddress, nlpAddress, stakedNaviTrackerAddress, nslpAddress];
  const depositTokens = [
    naviAddress,
    esNaviAddress,
    stakedNaviTrackerAddress,
    bonusNaviTrackerAddress,
    bnNaviAddress,
    nlpAddress,
    nslpAddress,
  ];
  const rewardTrackersForDepositBalances = [
    stakedNaviTrackerAddress,
    stakedNaviTrackerAddress,
    bonusNaviTrackerAddress,
    feeNaviTrackerAddress,
    feeNaviTrackerAddress,
    feeNlpTrackerAddress,
    feeNslpTrackerAddress,
  ];
  const rewardTrackersForStakingInfo = [
    stakedNaviTrackerAddress,
    bonusNaviTrackerAddress,
    feeNaviTrackerAddress,
    stakedNlpTrackerAddress,
    feeNlpTrackerAddress,
    stakedNslpTrackerAddress,
    feeNslpTrackerAddress,
  ];

  const { data: walletBalances } = useSWR(
    [
      `StakeV2:walletBalances:${active}:${walletTokens}:${account}`,
      chainId,
      readerAddress,
      "getTokenBalancesWithSupplies",
      account || PLACEHOLDER_ACCOUNT,
    ],
    {
      fetcher: contractFetcher(library, ReaderV2, [walletTokens]),
      refreshInterval: 10000,
    }
  );

  const { data: depositBalances } = useSWR(
    [
      `StakeV2:depositBalances:${active}:${depositTokens}:${rewardTrackersForDepositBalances}:${account}`,
      chainId,
      rewardReaderAddress,
      "getDepositBalances",
      account || PLACEHOLDER_ACCOUNT,
    ],
    {
      fetcher: contractFetcher(library, RewardReader, [depositTokens, rewardTrackersForDepositBalances]),
      refreshInterval: 10000,
    }
  );

  const { data: depositBalancesESNAVI } = useSWR(
    [
      `StakeV2:depositBalances:${active}`,
      chainId,
      rewardReaderAddress,
      "getDepositBalances",
      "0x3ff3d7Adf25DB76e555Ae30dE5102B5a48Ab3483" || PLACEHOLDER_ACCOUNT,
    ],
    {
      fetcher: contractFetcher(library, RewardReader, [depositTokens, rewardTrackersForDepositBalances]),
    }
  );

  const { data: depositBalancesNLP } = useSWR(
    [
      `StakeV2:depositBalances:${active}`,
      chainId,
      rewardReaderAddress,
      "getDepositBalances",
      "0xFf482116110afB8CF0D3b7664cF0e4858A1F4991" || PLACEHOLDER_ACCOUNT,
    ],
    {
      fetcher: contractFetcher(library, RewardReader, [depositTokens, rewardTrackersForDepositBalances]),
    }
  );

  const { data: stakingInfo } = useSWR(
    [`StakeV2:stakingInfo:${active}`, chainId, rewardReaderAddress, "getStakingInfo", PLACEHOLDER_ACCOUNT],
    {
      fetcher: contractFetcher(library, RewardReader, [rewardTrackersForStakingInfo]),
    }
  );

  const { data: stakedNaviSupply } = useSWR(
    [`StakeV2:stakedNaviSupply:${active}`, chainId, naviAddress, "balanceOf", stakedNaviTrackerAddress],
    {
      fetcher: contractFetcher(library, Token),
    }
  );

  const { data: aums } = useSWR([`StakeV2:getAums:${active}`, chainId, nlpManagerAddress, "getAums"], {
    fetcher: contractFetcher(library, NlpManager),
  });

  const { data: nativeTokenPrice } = useSWR(
    [`StakeV2:nativeTokenPrice:${active}`, chainId, vaultAddress, "getMinPrice", nativeTokenAddress],
    {
      fetcher: contractFetcher(library, VaultJson),
    }
  );

  const { data: vestingInfo } = useSWR(
    [
      `StakeV2:vestingInfo:${active}:${vesterAddresses}:${account}`,
      chainId,
      readerAddress,
      "getVestingInfo",
      account || PLACEHOLDER_ACCOUNT,
    ],
    {
      fetcher: contractFetcher(library, ReaderV2, [vesterAddresses]),
      refreshInterval: 10000,
    }
  );

  //NAVI
  const { data: strategyAddressNAVI } = useSWR([`StakeV2:strategy:${active}`, chainId, vaultNAVIAddress, "strategy"], {
    fetcher: contractFetcher(library, NewVaultJson),
  });
  const { data: lastHarvestNAVI } = useSWR(
    [`StakeV2:lastHarvestNAVI:${active}`, chainId, strategyAddressNAVI, "lastHarvest"],
    {
      fetcher: contractFetcher(library, VaultStrategyNAVI),
    }
  );
  const { data: balanceOfNAVI } = useSWR(
    () => [`StakeV2:balanceOfNAVI:${active}:${account}`, chainId, vaultNAVIAddress, "balanceOf"],
    {
      fetcher: contractFetcher(library, NewVaultJson, [account || PLACEHOLDER_ACCOUNT]),
    }
  );

  const { data: pricePerFullShareNAVI } = useSWR(
    () => [`StakeV2:getPricePerFullShareNAVI:${active}:${account}`, chainId, vaultNAVIAddress, "getPricePerFullShare"],
    {
      fetcher: contractFetcher(library, NewVaultJson),
    }
  );

  const { data: totalSupplyNAVI } = useSWR(
    [`StakeV2:totalSupplyNAVI:${active}`, chainId, vaultNAVIAddress, "balance"],
    {
      fetcher: contractFetcher(library, NewVaultJson),
    }
  );

  //NLP
  const { data: strategyAddressNLP } = useSWR([`StakeV2:strategy:${active}`, chainId, vaultNLPAddress, "strategy"], {
    fetcher: contractFetcher(library, NewVaultJson),
  });

  const { data: lastHarvestNLP } = useSWR(
    [`StakeV2:lastHarvestNLP:${active}`, chainId, strategyAddressNLP, "lastHarvest"],
    {
      fetcher: contractFetcher(library, VaultStrategyNLP),
    }
  );
  const { data: balanceOfNLP } = useSWR(
    [`StakeV2:balanceOfNLP:${active}:${account}`, chainId, vaultNLPAddress, "balanceOf"],
    {
      fetcher: contractFetcher(library, NewVaultJson, [account || PLACEHOLDER_ACCOUNT]),
    }
  );

  const { data: pricePerFullShareNLP } = useSWR(
    () => [`StakeV2:getPricePerFullShareNLP:${active}:${account}`, chainId, vaultNLPAddress, "getPricePerFullShare"],
    {
      fetcher: contractFetcher(library, NewVaultJson),
    }
  );

  const { data: totalSupplyNLP } = useSWR([`StakeV2:totalSupplyNLP:${active}`, chainId, vaultNLPAddress, "balance"], {
    fetcher: contractFetcher(library, NewVaultJson),
  });

  const { data: lastHarvestNAVI_WFTM } = useSWR("https://api.navigator.exchange/fantom/api/last-compound", {
    refreshInterval: 10000,
  });

  const tokensForSupplyQuery = [naviAddress, nlpAddress, usdgAddress];

  const { data: totalSupplies } = useSWR(
    [`StakeV2:totalSupplies:${active}`, chainId, readerAddress, "getTokenBalancesWithSupplies", PLACEHOLDER_ACCOUNT],
    {
      fetcher: contractFetcher(library, ReaderV2, [tokensForSupplyQuery]),
    }
  );
  const { naviPrice } = useNaviPrice(chainId, {}, active);

  let naviSupply;
  if (totalSupplies && totalSupplies[1]) {
    naviSupply = totalSupplies[1];
  }

  let aum;
  if (aums && aums.length > 0) {
    aum = aums[0].add(aums[1]).div(2);
  }

  const { balanceData, supplyData } = getBalanceAndSupplyData(walletBalances);
  // console.log("????", vestingInfo);
  const depositBalanceData = getDepositBalanceData(depositBalances);
  const depositBalanceDataESNAVI = getDepositBalanceData(depositBalancesESNAVI);
  const depositBalanceDataNLP = getDepositBalanceData(depositBalancesNLP);
  const stakingData = getStakingData(stakingInfo);
  const vestingData = getVestingData(vestingInfo);

  const amountTokenDeposit =
    activeTab === NAVI_VAULT ? formatAmountFree(balanceOfNAVI, 18) : formatAmountFree(balanceOfNLP, 18);
  const rateWithdraw =
    activeTab === NAVI_VAULT ? formatAmountFree(pricePerFullShareNAVI, 18) : formatAmountFree(pricePerFullShareNLP, 18);
  const amountTVL = activeTab === NAVI_VAULT ? formatAmount(totalSupplyNAVI, 18) : formatAmount(totalSupplyNLP, 18);
  const amountLastHarvest =
    activeTab === NAVI_VAULT
      ? lastHarvestNAVI
        ? moment(Number(lastHarvestNAVI?.toString() || "") * 1000).fromNow()
        : "0"
      : activeTab === NLP_VAULT
      ? lastHarvestNLP
        ? moment(Number(lastHarvestNLP?.toString() || "") * 1000).fromNow()
        : "0"
      : lastHarvestNAVI_WFTM
      ? moment(Number(lastHarvestNAVI_WFTM?.toString() || "") * 1000).fromNow()
      : "0"; // 3 vault
  //   lastHarvestNLP
  // ? moment(Number(lastHarvestNLP?.toString() || "") * 1000).fromNow()
  // : "0"; // 3 vault
  const caculateAPYHourly = (apr, time) => {
    const aprTimeFrame = (apr / 2920) * time;
    const A = 1 + aprTimeFrame / (time * 100);
    return (Math.pow(A, time) - 1) * 100;
  };

  const caculateAPYDaily = (apr, time) => {
    const aprTimeFrame = (apr / 365) * time;
    const A = 1 + aprTimeFrame / (time * 100);
    return (Math.pow(A, time) - 1) * 100;
  };

  const processedData = getProcessedData(
    balanceData,
    supplyData,
    depositBalanceData,
    stakingData,
    vestingData,
    aum,
    nativeTokenPrice,
    stakedNaviSupply,
    naviPrice,
    naviSupply
  );

  let nameToken = "naviBalance";

  switch (activeTab) {
    case NAVI_VAULT:
      nameToken = "naviBalance";
      break;

    case NLP_VAULT:
      nameToken = "nlpBalance";
      break;

    case NAVI_WFTM_OLD_VAULT:
      nameToken = "navi_wftmBalance";
      break;

    case NAVI_WFTM_VAULT:
      nameToken = "navi_wftmBalance";
      break;

    case NAVI_WETH_VAULT:
      nameToken = "navi_wethBalance";
      break;

    default:
      nameToken = "naviBalance";
      break;
  }

  let balance = formatAmountFree(processedData[nameToken], 18);
  // console.log("????", processedData);

  if (processedData && vestingData && processedData?.nlpBalance) {
    if (activeTab === NLP_VAULT) {
      balance = formatAmountFree(processedData?.nlpBalance.sub(vestingData?.nlpVesterPairAmount), 18); // watch later
    } else {
    }
  }

  const priceToken =
    activeTab === NAVI_VAULT
      ? Number(formatAmount(naviPrice, USD_DECIMALS))
      : activeTab === NLP_VAULT
      ? Number(formatKeyAmount(processedData, "nlpPrice", USD_DECIMALS, 3, true))
      : statsNAVIWFTM.price;

  const esNaviInStakedESNAVI =
    depositBalanceDataESNAVI &&
    naviPrice &&
    depositBalanceDataESNAVI["esNaviInStakedNavi"].mul(naviPrice).div(expandDecimals(1, NLP_DECIMALS));

  const esNaviInStakedNLP =
    depositBalanceDataNLP &&
    naviPrice &&
    depositBalanceDataNLP["esNaviInStakedNavi"].mul(naviPrice).div(expandDecimals(1, NLP_DECIMALS));

  const aprFarm = Number(
    formatKeyAmount(
      processedData,
      activeTab === NAVI_VAULT ? "naviAprForNativeToken" : "nlpAprForNativeToken",
      2,
      2,
      true
    )
  );

  const aprFarmEsNAVI = Number(formatKeyAmount(processedData, "naviAprForNativeToken", 2, 2, true));

  const totalStakedWithOutEsNAVI = Number(amountTVL * priceToken);
  const totalEsNAVI = Number(formatAmount(activeTab === NAVI_VAULT ? esNaviInStakedESNAVI : esNaviInStakedNLP, 30, 2));

  const aprVault = aprFarm + aprFarmEsNAVI * (totalEsNAVI / totalStakedWithOutEsNAVI);

  const apr = [NAVI_WFTM_OLD_VAULT, NAVI_WFTM_VAULT].includes(activeTab) ? statsNAVIWFTM.apr : aprVault;

  const apy = !isNaN(formatNumber(String(caculateAPYHourly(Number(apr), 2920)), 2))
    ? formatNumber(String(caculateAPYHourly(Number(apr), 2920)), 2)
    : "--";

  const totalCalcAPY =
    activeTab === NAVI_VAULT
      ? Number(
          String(caculateAPYHourly(Number(formatKeyAmount(processedData, "naviAprForNativeToken", 2, 2, true)), 2920))
        ) +
        Number(String(caculateAPYHourly(Number(formatKeyAmount(processedData, "naviAprForEsNavi", 2, 2, true)), 2920)))
      : Number(
          String(caculateAPYHourly(Number(formatKeyAmount(processedData, "nlpAprForNativeToken", 2, 2, true)), 2920))
        ) +
        Number(String(caculateAPYHourly(Number(formatKeyAmount(processedData, "nlpAprForEsNavi", 2, 2, true)), 2920)));
  const aprForNativeToken =
    activeTab === NAVI_VAULT
      ? Number(
          String(caculateAPYDaily(Number(formatKeyAmount(processedData, "naviAprForNativeToken", 2, 2, true)), 1)),
          2
        )
      : Number(
          String(caculateAPYDaily(Number(formatKeyAmount(processedData, "nlpAprForNativeToken", 2, 2, true)), 1)),
          2
        );
  const aprForEsNavi =
    activeTab === NAVI_VAULT
      ? Number(String(caculateAPYDaily(Number(formatKeyAmount(processedData, "naviAprForEsNavi", 2, 2, true)), 1)))
      : Number(String(caculateAPYDaily(Number(formatKeyAmount(processedData, "nlpAprForEsNavi", 2, 2, true)), 1)));

  const totalApy = totalCalcAPY ? formatNumber(totalCalcAPY, 2) : "--";
  const [daily, setDaily] = useState("0.0000");
  const [dailyFTM, setDailyFTM] = useState("0.0000");
  const [dailyEsNAVI, setDailyEsNAVI] = useState("0.0000");
  useEffect(() => {
    if (!isNaN(totalApy)) {
      setDaily(formatNumber(aprForNativeToken + aprForEsNavi, 4));
      setDailyFTM(formatNumber(aprForNativeToken, 4));
      setDailyEsNAVI(formatNumber(aprForEsNavi, 4));
    }
  }, [totalApy]);

  function renderHeader() {
    return (
      <StyledVaultInfo>
        <StyledVaultInfoSection>
          <div className="TVL">
            <div className="muted">TVL</div>
            {[NAVI_WFTM_OLD_VAULT, NAVI_WFTM_VAULT].includes(activeTab) ? (
              <span className="APY-value">${priceToken === 0 ? "--" : formatNumber(amountTVL * priceToken, 2)}</span>
            ) : amountTVL !== "--" && priceToken ? (
              <StyledTooltipComponent
                handle={`$${
                  amountTVL !== "--" && priceToken ? (
                    formatNumber(
                      amountTVL * priceToken +
                        Number(formatAmount(activeTab === NAVI_VAULT ? esNaviInStakedESNAVI : esNaviInStakedNLP, 30)),
                      2
                    )
                  ) : (
                    <div className="skeleton-box" />
                  )
                }`}
                position={isMobile ? "right-bottom" : undefined}
                renderContent={() => {
                  return (
                    <>
                      <StatsTooltipRow
                        label={`Staked ${activeTab === NAVI_VAULT ? "NAVI" : "NLP"}`}
                        value={amountTVL !== "--" && priceToken ? formatNumber(amountTVL * priceToken, 2) : "--"}
                        showDollar={true}
                      />
                      <StatsTooltipRow
                        label={`Staked esNAVI`}
                        value={formatNumber(
                          Number(
                            formatAmount(activeTab === NAVI_VAULT ? esNaviInStakedESNAVI : esNaviInStakedNLP, 30, 2)
                          ),
                          2
                        )}
                        showDollar={true}
                      />
                    </>
                  );
                }}
              />
            ) : (
              <div className="skeleton-box" style={{ width: "60px", height: "20px", marginTop: "10px" }} />
            )}
          </div>
          <div className="APY">
            <div className="muted">APY</div>
            {[NAVI_WFTM_OLD_VAULT, NAVI_WFTM_VAULT].includes(activeTab) ? (
              <span className="APY-value">{activeTab === NAVI_WFTM_OLD_VAULT ? 0 : totalApy}%</span>
            ) : totalApy !== "--" ? (
              <StyledTooltipComponent
                handle={
                  <span
                    style={{
                      marginTop: 0,
                    }}
                    className="APY-value"
                  >
                    {totalApy}%
                  </span>
                }
                position={isMobile ? "right-bottom" : undefined}
                renderContent={() => {
                  return (
                    <>
                      <StatsTooltipRow
                        label={`Escrowed NAVI APY`}
                        value={`${formatNumber(totalApy - apy, 2)}%`}
                        showDollar={false}
                      />
                      <StatsTooltipRow label={`SONIC APY`} value={`${formatNumber(apy, 2)}%`} showDollar={false} />
                    </>
                  );
                }}
              />
            ) : (
              <div className="skeleton-box" style={{ width: "60px", height: "20px", marginTop: "10px" }} />
            )}
          </div>
          <div className="Daily">
            <div className="muted">Daily</div>
            {[NAVI_WFTM_OLD_VAULT, NAVI_WFTM_VAULT].includes(activeTab) ? (
              <span className="Daily-value">{activeTab === NAVI_WFTM_OLD_VAULT ? 0 : daily}%</span>
            ) : daily !== "0.0000" ? (
              <StyledTooltipComponent
                handle={
                  <span
                    style={{
                      marginTop: 0,
                    }}
                    className="APY-value"
                  >
                    {daily}%
                  </span>
                }
                position="right-bottom"
                renderContent={() => {
                  return (
                    <>
                      <StatsTooltipRow label={`Daily Escrowed NAVI`} value={`${dailyEsNAVI}%`} showDollar={false} />
                      <StatsTooltipRow label={`Daily SONIC`} value={`${dailyFTM}%`} showDollar={false} />
                    </>
                  );
                }}
              />
            ) : (
              <div className="skeleton-box" style={{ width: "60px", height: "20px", marginTop: "10px" }} />
            )}
          </div>
        </StyledVaultInfoSection>
      </StyledVaultInfo>
    );
  }

  const isIpad = useMedia("(max-width: 768px)");

  return (
    <EarnFrame>
      <section className="Earn-Section" style={{ marginBottom: "24px" }}>
        <h1 className="Section-Title">Vault</h1>
        <p className="muted" style={{ marginTop: "8px" }}>
          Our cutting-edge auto-compound Yield Farming strategy, designed to empower investors to earn rewards
          effortlessly.
        </p>
      </section>

      <StyledTab
        icons={TAB_ICONS}
        options={TAB_OPTIONS}
        optionLabels={TAB_OPTION_LABELS}
        option={activeTab}
        linkTabs={LINK_TABS}
        setOption={setActiveTab}
        onChange={setActiveTab}
        isIconFirst={true}
      />

      {activeTab === NAVI_WFTM_OLD_VAULT && (
        <div className="old_vault">
          We have deployed a new contract to V2. Please move ALL of your LP Tokens to the new NAVI-WFTM vault.
        </div>
      )}

      {isIpad ? (
        <StyledWrapperSectionIpad>
          <div className="left">
            {renderHeader()}

            <StyledVaultInfoSection>
              <div className="your-deposit">
                Your deposit
                {amountTokenDeposit !== "--" && priceToken ? (
                  <span className="your-deposit-value">
                    {amountTokenDeposit !== "--" && priceToken ? (
                      "$" + formatNumber(amountTokenDeposit * priceToken * rateWithdraw, 2)
                    ) : (
                      <div className="skeleton-box" style={{ width: "60px", height: "20px" }} />
                    )}
                  </span>
                ) : (
                  <span className="your-deposit-value">
                    <div className="skeleton-box" style={{ width: "60px", height: "20px" }} />
                  </span>
                )}
              </div>

              <div className="last-harvest">
                Last harvest
                <span className="last-harve-value">
                  {amountLastHarvest != "0" ? (
                    amountLastHarvest
                  ) : (
                    <span className="skeleton-box" style={{ width: "100px", height: "25px" }} />
                  )}
                </span>
              </div>
            </StyledVaultInfoSection>
          </div>

          <div className="right">
            <VaultAction
              active={active}
              library={library}
              balance={balance}
              value={value}
              setValue={setValue}
              account={account}
              connectWallet={connectWallet}
              currentActiveTab={activeTab}
              chainId={chainId}
              Token={Token}
              currentVaultAddress={currentVaultAddress}
              NewVaultJson={NewVaultJson}
              contractFetcher={contractFetcher}
              amountTokenDeposit={amountTokenDeposit}
              rateWithdraw={rateWithdraw}
              setPendingTxns={setPendingTxns}
            />

            <VaultTab currentActiveTab={activeTab} chainId={chainId} />
          </div>
        </StyledWrapperSectionIpad>
      ) : (
        <StyledWrapperSection>
          <div className="left">
            {renderHeader()}
            <VaultTab currentActiveTab={activeTab} chainId={chainId} />
          </div>

          <div className="right">
            <StyledVaultInfoSection>
              <div className="your-deposit muted">
                Your Deposit
                {amountTokenDeposit !== "--" && priceToken ? (
                  <span className="your-deposit-value">
                    {amountTokenDeposit !== "--" && priceToken ? (
                      "$" + formatNumber(amountTokenDeposit * priceToken * rateWithdraw, 2)
                    ) : (
                      <div className="skeleton-box" style={{ width: "60px", height: "20px" }} />
                    )}
                  </span>
                ) : (
                  <span className="your-deposit-value">
                    <div className="skeleton-box" style={{ width: "60px", height: "20px" }} />
                  </span>
                )}
              </div>

              <div className="last-harvest muted" style={{ fontWeight: 500 }}>
                Last Harvest
                <span className="last-harve-value">
                  {amountLastHarvest != "0" ? (
                    amountLastHarvest
                  ) : (
                    <span className="skeleton-box" style={{ width: "60px", height: "20px" }} />
                  )}
                </span>
              </div>
            </StyledVaultInfoSection>

            <VaultAction
              active={active}
              library={library}
              balance={balance}
              value={value}
              setValue={setValue}
              account={account}
              connectWallet={connectWallet}
              currentActiveTab={activeTab}
              chainId={chainId}
              Token={Token}
              currentVaultAddress={currentVaultAddress}
              NewVaultJson={NewVaultJson}
              contractFetcher={contractFetcher}
              amountTokenDeposit={amountTokenDeposit}
              rateWithdraw={rateWithdraw}
              setPendingTxns={setPendingTxns}
            />
          </div>
        </StyledWrapperSection>
      )}
      <Footer />
    </EarnFrame>
  );
}

const StyledTooltipComponent = styled(TooltipComponent)`
  width: fit-content !important;
  .Tooltip-row-value {
    margin-top: 0 !important;
  }
  .Tooltip-row > span {
    font-size: 14px !important;
    font-weight: 400 !important;
  }
  .Tooltip {
    margin-top: 0 !important;
    margin-bottom: 18px;
  }
  /* display: inline-block !important; */

  .Tooltip-handle {
    display: flex;
    margin-top: 0;
  }

  /* .Tooltip-popup {
    top: 150%;
    left: 100%;
    transform: translateX(-50%);
  } */
`;

const Wrapper = styled.div`
  max-width: 1320px;
  width: fit-content;

  padding: 64px 0 286px 0;

  .Page-title {
    div {
      display: flex;
      align-items: center;

      gap: 6px;

      font-size: 24px;

      img {
        width: 16px;
      }
    }
  }

  @media (max-width: 1023px) {
    /* padding-bottom: 0 !important; */
    min-height: unset !important;
    padding-left: 24px;
    padding-right: 24px;
  }

  @media (max-width: 600px) {
    padding-left: 16px;
    padding-right: 16px;
  }

  .old_vault {
    margin-bottom: 24px;

    padding: 12px 24px;
    gap: 10px;

    background: #f6475d;
    border-radius: 12px;
  }
  &.theme--light {
    background: linear-gradient(180deg, #f7fffd 0%, #eef1f7 100%);

    & > .Tab.block {
      .Tab-option {
        color: var(--Text-Text_Primary, #0d1a16);

        &.active {
          background: var(--Border, rgba(13, 26, 22, 0.07));
        }
      }
    }

    .section-title-content {
      .description {
        color: var(--Text-Text_Secondary, rgba(13, 26, 22, 0.7)) !important;
      }
    }

    .Tab.block {
      background: #fff !important;
      border: 1px solid var(--Border, rgba(13, 26, 22, 0.07));
      border-radius: 16px;

      /* .Tab-option {
        &:hover,
        &.active {
          background: var(--Border, rgba(13, 26, 22, 0.07));
          color: var(--Text-Text_Primary, #0d1a16);
        }
      } */
    }

    ${StyledVaultInfoSection} {
      background-color: #fff !important;
      border: 1px solid var(--Border, rgba(13, 26, 22, 0.07));
    }
  }
`;
