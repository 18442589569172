import { useEffect, useState } from "react";
import axios from "axios";

const useVaultInfo = () => {
  const [vaultInfo, setVaultInfo] = useState<any>([]);

  useEffect(() => {
    const fetchVaultInfo = async () => {
      const res = await axios.get(`https://api.navigator.exchange/public/vault/get-vaults`, {
        headers: {},
      });
      if (res && res.data.data) {
        const tokenInfo = res.data.data.filter((d) => d.chainCode === "SONIC");
        setVaultInfo(tokenInfo);
      }
    };

    fetchVaultInfo();
    const interval = setInterval(fetchVaultInfo, 30000);
    return () => clearInterval(interval);
  }, []);

  return vaultInfo;
};

export default useVaultInfo;
