import { Bank } from "hooks/vault/types";
import { useEffect, useState } from "react";
import Token from "abis/Token.json";
import VaultAuto from "abis/VaultAuto.json";

import { Contract } from "ethers";
import { bankDefinitions, BankVaultsNew, listTokens } from "config/vaults";
import { getProvider } from "lib/rpc";
import { useWeb3React } from "@web3-react/core";

const getTokenAddressByChainId = (chainId, name) => {
  const tokensChain = listTokens.find((x) => x.chainId === chainId);
  if (tokensChain) {
    return tokensChain.tokens[name][0];
  }
  return null;
};

const useBanks = () => {
  const [banks, setBanks] = useState<Bank[]>([]);

  const { library } = useWeb3React();

  useEffect(() => {
    (async () => {
      const banks: any = [];
      let i = 0;
      let bankVaultsArray: any = {};
      for (let i = 0; i < BankVaultsNew.length; i++) {
        bankVaultsArray[BankVaultsNew[i].chainId] = {
          chainId: BankVaultsNew[i].chainId,
          vault: new Contract(BankVaultsNew[i].address, VaultAuto.abi, library?.getSigner()),
        };
      }
      for (const bankInfo of Object.values(bankDefinitions)) {
        i++;
        banks.push({
          ...bankInfo,
          // depositToken: ndo.contracts.tokens[bankInfo.depositTokenName],
          depositToken: new Contract(
            getTokenAddressByChainId(bankInfo.chainId, bankInfo.depositTokenName),
            Token.abi,
            getProvider(undefined, bankInfo.chainId)
          ),
          // earnToken: ndo.contracts.Dollar,
          rewardPool: bankVaultsArray[bankInfo?.chainId || 25].vault,
          index: i,
        });
      }

      banks.sort((a, b) => (a.sort > b.sort ? 1 : -1));
      setBanks(banks);
    })();
  }, [library]);

  return banks;
};

export default useBanks;
