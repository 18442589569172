import { useEffect, useState } from "react";
import axios from "axios";
import { getContract } from "config/contracts";
import { useChainIdWithDefault } from "lib/chains";
import { Contract } from "ethers";
import { useWeb3React } from "@web3-react/core";
import { PLACEHOLDER_ACCOUNT } from "lib/legacy";

import Reader from "abis/ReaderV2.json";
import NlpManager from "abis/NlpManager.json";
import { formatUnits } from "ethers/lib/utils";
import { parseValue } from "lib/numbers";
import { getProvider } from "lib/rpc";

const useTokenInfo = () => {
  const [tokenInfo, setTokenInfo] = useState<any>();

  const { account } = useWeb3React();
  const chainId = useChainIdWithDefault();

  useEffect(() => {
    const fetchTokenInfo = async () => {
      const res = await axios.get(`https://api.navigator.exchange/public/token-info?token=ALL&chainCode=SONIC`, {
        headers: {},
      });
      if (res && res.data) {
        const tokenInfo = res.data;
        return tokenInfo;
      }
    };

    const fetchMoreTokenInfo = async () => {
      const provider = getProvider(undefined, chainId);

      const readerAddress = getContract(chainId, "Reader");
      const nlpAddress = getContract(chainId, "NLP");
      const nlpManagerAddress = getContract(chainId, "NlpManager");

      const readerContract = new Contract(readerAddress, Reader.abi, provider);
      const nlpManagerContract = new Contract(nlpManagerAddress, NlpManager.abi, provider);

      try {
        const [nlpBalanceWithSupply, aums] = await Promise.all([
          readerContract.getTokenBalancesWithSupplies(account || PLACEHOLDER_ACCOUNT, [nlpAddress]),
          nlpManagerContract.getAums(),
        ]);

        if (!nlpBalanceWithSupply || !aums) {
          throw new Error("fetch more token info failed!");
        }

        const aum = aums[0].add(aums[1]).div(2);
        const nlpSupply = nlpBalanceWithSupply[1];
        const nlpPrice = Number(formatUnits(aum.mul(parseValue(1, 18)).div(nlpSupply), 30));

        return {
          NLP: {
            symbol: "NLP",
            chainCode: "SONIC",
            displayName: "NLP",
            address: nlpAddress,
            decimal: 18,
            price: nlpPrice,
            totalSupply: null,
            circulatingSupply: null,
            tokenBaseIndex: null,
            holder: null,
            id: null,
          },
        };
      } catch (error) {
        console.error("error", error);
      }
    };

    const totalFetch = async () => {
      const [tokenInfo, moreTokenInfo] = await Promise.all([fetchTokenInfo(), fetchMoreTokenInfo()]);

      setTokenInfo({ ...tokenInfo, ...moreTokenInfo });
    };

    totalFetch();

    const interval = setInterval(totalFetch, 30000);
    return () => clearInterval(interval);
  }, [account, chainId]);

  return tokenInfo;
};

export default useTokenInfo;
