import { useWeb3React } from "@web3-react/core";
import StatsTooltipRow from "components/StatsTooltip/StatsTooltipRow";
import Tooltip from "components/Tooltip/Tooltip";
import icon_vest from "img/icon_vest.svg";
import icon_vestLight from "img/icon_vestLight.svg";
import { bigNumberify, formatAmount, formatAmountFree, formatKeyAmount, parseValue } from "lib/numbers";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import Wrapper from "./index.styled";

import "./VestTheme.scss";

import MigrateVester from "abis/MigrateVester.json";
import RewardReader from "abis/RewardReader.json";
import Vester from "abis/Vester.json";
import SEO from "components/Common/SEO";
import Modal from "components/Modal/Modal";
import { getContract } from "config/contracts";
import { ethers } from "ethers";
import { useChainId } from "lib/chains";
import { callContract, contractFetcher } from "lib/contracts";
import { helperToast } from "lib/helperToast";
import { getPageTitle } from "lib/legacy";
import useSWR from "swr";

import ExternalLink from "components/ExternalLink/ExternalLink";
import { FANTOM, OP } from "config/chains";
import { useMedia } from "react-use";
import { useThemeContext } from "contexts/ThemeProvider";

function VesterMigrateModal(props) {
  const {
    isVisible,
    setIsVisible,
    chainId,
    title,
    maxAmount,
    maxAmountV1,
    value,
    setValue,
    balance,
    vestedAmount,
    vestedAmountV1,
    averageStakedAmount,
    maxVestableAmount,
    library,
    stakeTokenLabel,
    reserveAmount,
    maxReserveAmount,
    vesterAddress,
    setPendingTxns,
    migrateNLPAmountV1,
    migrateNAVIAmountV1,
    migrateNLPAmount,
    migrateNAVIAmount,
  } = props;
  const [isDepositing, setIsDepositing] = useState(false);
  const [isWithdrawing, setIsWithdrawing] = useState(false);
  const [isWithdrawingV1, setIsWithdrawingV1] = useState(false);
  const [isWithdraw, setIsWithdraw] = useState(false);
  const [isWithdrawV1, setIsWithdrawV1] = useState(false);

  useEffect(() => {
    setIsWithdraw(vestedAmount && vestedAmount.gt(0) && vestedAmountV1 && !vestedAmountV1.gt(0));

    setIsWithdrawV1(vestedAmountV1 && vestedAmountV1.gt(0));
  }, [vestedAmount, vestedAmountV1]);

  const migrateVesterAddress = getContract(chainId, "MigrateVester");
  let fromVesterAddresss = vesterAddress;

  switch (vesterAddress) {
    case "0x8eF5634F5C6Ef625302Ab6e4C424Dbe6B59496C0":
      fromVesterAddresss = "0xa1a65D3639A1EFbFB18C82003330a4b1FB620C5a";
      break;
    case "0xDdeaC5Dc3A5352cCa9dD13C3067Cbbdbe2A43700":
      fromVesterAddresss = "0x2A3E489F713ab6F652aF930555b5bb3422711ac1";
      break;
    case "0x4F697DeDF2b3862CA43493c33589F42CFEF66026":
      fromVesterAddresss = "0x2A3E489F713ab6F652aF930555b5bb3422711ac1";
      break;
    case "0xA25d432ae720e57e85778905935A9A0B9284114A":
      fromVesterAddresss = "0xb54AbE3FEC8e1aE64620185A1E111Aa0c3a81542";
      break;
    default:
      break;
  }
  let amount = parseValue(value, 18);

  let nextReserveAmount = reserveAmount;

  let nextDepositAmount = vestedAmount;
  if (amount) {
    nextDepositAmount = vestedAmount.add(amount);
  }

  let additionalReserveAmount = bigNumberify(0);
  if (amount && averageStakedAmount && maxVestableAmount && maxVestableAmount.gt(0)) {
    nextReserveAmount = nextDepositAmount.mul(averageStakedAmount).div(maxVestableAmount);
    if (nextReserveAmount?.gt(reserveAmount)) {
      additionalReserveAmount = nextReserveAmount.sub(reserveAmount);
    }
  }

  const getError = () => {
    if (!amount || amount.eq(0)) {
      return `Enter an amount`;
    }
    if (maxAmount && amount.gt(maxAmount)) {
      return `Max amount exceeded`;
    }
    if (nextReserveAmount?.gt(maxReserveAmount)) {
      return `Insufficient staked tokens`;
    }
  };

  const onClickMigrate = () => {
    setIsDepositing(true);
    const contract = new ethers.Contract(migrateVesterAddress, MigrateVester.abi, library.getSigner());

    callContract(chainId, contract, "migrate", [fromVesterAddresss], {
      sentMsg: `Migrate submitted!`,
      failMsg: `Migrate failed!`,
      successMsg: `Migrated!`,
      setPendingTxns,
    })
      .then(async (res) => {
        setIsVisible(false);
      })
      .finally(() => {
        setIsDepositing(false);
      });
  };

  const onClickWithdraw = () => {
    setIsWithdrawing(true);
    const contract = new ethers.Contract(vesterAddress, Vester.abi, library.getSigner());

    callContract(chainId, contract, "withdraw", [], {
      sentMsg: `Withdraw submitted.`,
      failMsg: `Withdraw failed.`,
      successMsg: `Withdrawn!`,
      setPendingTxns,
    })
      .then(async (res) => {
        setIsWithdraw(false);
      })
      .finally(() => {
        setIsWithdrawing(false);
      });
  };
  const onClickWithdrawV1 = () => {
    setIsWithdrawingV1(true);
    const contract = new ethers.Contract(fromVesterAddresss, Vester.abi, library.getSigner());

    callContract(chainId, contract, "withdraw", [], {
      sentMsg: `Withdraw submitted.`,
      failMsg: `Withdraw failed.`,
      successMsg: `Withdrawn!`,
      setPendingTxns,
    })
      .then(async (res) => {
        setIsWithdrawV1(false);
        setIsWithdraw(vestedAmount && vestedAmount.gt(0));
      })
      .finally(() => {
        setIsWithdrawingV1(false);
      });
  };
  const isPrimaryEnabled = () => {
    const error = getError();
    if (error) {
      return false;
    }
    if (isDepositing) {
      return false;
    }
    return true;
  };

  const getPrimaryText = () => {
    // const error = getError();
    // if (error) {
    //   return error;
    // }
    if (isDepositing) {
      return `Migrating...`;
    }
    return `Migrate`;
  };

  return (
    <SEO title={getPageTitle("Earn")}>
      <div className="StakeModal">
        <Modal isVisible={isVisible} setIsVisible={setIsVisible} label={title} className="non-scrollable">
          <div className="VesterDepositModal-info-rows">
            {!isWithdraw && !isWithdrawV1 ? (
              <>
                <div>Migrated EsNAVI will no longer be available for vesting on v1; it can only be vested on v2.</div>
              </>
            ) : (
              <>
                <div className="Exchange-info-row">
                  <div className="Exchange-info-label">
                    <span>V1 vesting amount</span>
                  </div>
                  <div className="align-right">{formatAmount(vestedAmountV1, 18, 8, true)} EsNAVI</div>
                </div>
                <div className="Exchange-info-row">
                  <div className="Exchange-info-label">
                    <span>V2 vesting amount</span>
                  </div>
                  <div className="align-right">{formatAmount(vestedAmount, 18, 8, true)} EsNAVI</div>
                </div>
                {!(!isWithdraw && !isWithdrawV1) && (
                  <span>
                    <div>
                      <br />
                      This will withdraw and unreserve all tokens as well as pause vesting.
                      <br />
                      <br />
                      EsNAVI tokens that have been converted to NAVI will remain as NAVI tokens.
                      <br />
                      <br />
                      Migrated EsNAVI will no longer be available for vesting on v1; it can only be vested on v2.
                      <br />
                    </div>
                  </span>
                )}
              </>
            )}
          </div>
          <div className="Exchange-swap-button-container">
            {isWithdrawV1 && (
              <button className="App-cta Exchange-swap-button" onClick={onClickWithdrawV1} disabled={isWithdrawingV1}>
                {!isWithdrawingV1 && "Confirm Withdraw V1"}
                {isWithdrawingV1 && "Confirming..."}
              </button>
            )}
            {isWithdraw && (
              <button className="App-cta Exchange-swap-button" onClick={onClickWithdraw} disabled={isWithdrawing}>
                {!isWithdrawing && "Confirm Withdraw V2"}
                {isWithdrawing && "Confirming..."}
              </button>
            )}
            {!isWithdrawV1 && !isWithdraw && (
              <button className="App-cta Exchange-swap-button" onClick={onClickMigrate}>
                {getPrimaryText()}
              </button>
            )}
          </div>
        </Modal>
      </div>
    </SEO>
  );
}

function VesterWithdrawModal(props) {
  const { isVisible, setIsVisible, chainId, title, library, vesterAddress, setPendingTxns } = props;
  const [isWithdrawing, setIsWithdrawing] = useState(false);

  const onClickPrimary = () => {
    setIsWithdrawing(true);
    const contract = new ethers.Contract(vesterAddress, Vester.abi, library.getSigner());

    callContract(chainId, contract, "withdraw", [], {
      sentMsg: `Withdraw submitted.`,
      failMsg: `Withdraw failed.`,
      successMsg: `Withdrawn!`,
      setPendingTxns,
    })
      .then(async (res) => {
        setIsVisible(false);
      })
      .finally(() => {
        setIsWithdrawing(false);
      });
  };

  return (
    <div className="StakeModal">
      <Modal isVisible={isVisible} setIsVisible={setIsVisible} label={title}>
        <span>
          <div>
            This will withdraw and unreserve all tokens as well as pause vesting.
            <br />
            <br />
            EsNAVI tokens that have been converted to NAVI will remain as NAVI tokens.
            <br />
            <br />
            To claim NAVI tokens without withdrawing, use the "Claim" button under the General Rewards section.
            <br />
            <br />
          </div>
        </span>
        <div className="Exchange-swap-button-container">
          <button className="App-cta Exchange-swap-button" onClick={onClickPrimary} disabled={isWithdrawing}>
            {!isWithdrawing && "Confirm Withdraw"}
            {isWithdrawing && "Confirming..."}
          </button>
        </div>
      </Modal>
    </div>
  );
}
function VesterDepositModal(props) {
  const {
    isVisible,
    setIsVisible,
    chainId,
    title,
    maxAmount,
    value,
    setValue,
    balance,
    vestedAmount,
    averageStakedAmount,
    maxVestableAmount,
    library,
    stakeTokenLabel,
    reserveAmount,
    maxReserveAmount,
    vesterAddress,
    setPendingTxns,
  } = props;
  const [isDepositing, setIsDepositing] = useState(false);

  let amount = parseValue(value, 18);

  let nextReserveAmount = reserveAmount;

  let nextDepositAmount = vestedAmount;
  if (amount) {
    nextDepositAmount = vestedAmount.add(amount);
  }

  let additionalReserveAmount = bigNumberify(0);
  if (amount && averageStakedAmount && maxVestableAmount && maxVestableAmount.gt(0)) {
    nextReserveAmount = nextDepositAmount.mul(averageStakedAmount).div(maxVestableAmount);
    if (nextReserveAmount?.gt(reserveAmount)) {
      additionalReserveAmount = nextReserveAmount.sub(reserveAmount);
    }
  }

  const getError = () => {
    if (!amount || amount.eq(0)) {
      return `Enter an amount`;
    }
    if (maxAmount && amount.gt(maxAmount)) {
      return `Max amount exceeded`;
    }
    if (nextReserveAmount?.gt(maxReserveAmount)) {
      return `Insufficient staked tokens`;
    }
  };

  const onClickPrimary = () => {
    setIsDepositing(true);
    const contract = new ethers.Contract(vesterAddress, Vester.abi, library.getSigner());

    callContract(chainId, contract, "deposit", [amount], {
      sentMsg: `Deposit submitted!`,
      failMsg: `Deposit failed!`,
      successMsg: `Deposited!`,
      setPendingTxns,
    })
      .then(async (res) => {
        setIsVisible(false);
      })
      .finally(() => {
        setIsDepositing(false);
      });
  };

  const isPrimaryEnabled = () => {
    const error = getError();
    if (error) {
      return false;
    }
    if (isDepositing) {
      return false;
    }
    return true;
  };

  const getPrimaryText = () => {
    const error = getError();
    if (error) {
      return error;
    }
    if (isDepositing) {
      return `Depositing...`;
    }
    return `Deposit`;
  };

  return (
    <SEO title={getPageTitle("Earn")}>
      <div className="StakeModal">
        <Modal isVisible={isVisible} setIsVisible={setIsVisible} label={title} className="non-scrollable">
          <div className="Exchange-swap-section">
            <div className="Exchange-swap-section-top">
              <div className="muted">
                <div className="Exchange-swap-usd">
                  <span>Deposit</span>
                </div>
              </div>
              <div
                className="muted align-right clickable"
                onClick={() => setValue(formatAmountFree(maxAmount, 18, 18))}
              >
                <span>Max: {formatAmount(maxAmount, 18, 4, true)}</span>
              </div>
            </div>
            <div className="Exchange-swap-section-bottom">
              <div>
                <input
                  type="number"
                  placeholder="0.0"
                  className="Exchange-swap-input"
                  value={value}
                  onChange={(e) => setValue(e.target.value)}
                />
              </div>
              <div className="PositionEditor-token-symbol">EsNAVI</div>
            </div>
          </div>
          <div className="VesterDepositModal-info-rows">
            <div className="Exchange-info-row">
              <div className="Exchange-info-label">
                <span>Wallet</span>
              </div>
              <div className="align-right">{formatAmount(balance, 18, 2, true)} EsNAVI</div>
            </div>
            <div className="Exchange-info-row">
              <div className="Exchange-info-label">
                <span>Vault Capacity</span>
              </div>
              <div className="align-right">
                <Tooltip
                  handle={`${formatAmount(nextDepositAmount, 18, 2, true)} / ${formatAmount(
                    maxVestableAmount,
                    18,
                    2,
                    true
                  )}`}
                  position="right-bottom"
                  renderContent={() => {
                    return (
                      <div>
                        <p className="text-white">
                          <span>Vault Capacity for your Account:</span>
                        </p>
                        <StatsTooltipRow
                          showDollar={false}
                          label={`Deposited`}
                          value={`${formatAmount(vestedAmount, 18, 2, true)} EsNAVI`}
                        />
                        <StatsTooltipRow
                          showDollar={false}
                          label={`Max Capacity`}
                          value={`${formatAmount(maxVestableAmount, 18, 2, true)} EsNAVI`}
                        />
                      </div>
                    );
                  }}
                  className="excep"
                />
              </div>
            </div>
            <div className="Exchange-info-row">
              <div className="Exchange-info-label">
                <span>Reserve Amount</span>
              </div>
              <div className="align-right">
                <Tooltip
                  handle={`${formatAmount(
                    reserveAmount && reserveAmount.gte(additionalReserveAmount)
                      ? reserveAmount
                      : additionalReserveAmount,
                    18,
                    2,
                    true
                  )} / ${formatAmount(maxReserveAmount, 18, 2, true)}`}
                  position={amount && nextReserveAmount?.gt(maxReserveAmount) ? "right-top" : "right-bottom"}
                  renderContent={() => {
                    return (
                      <>
                        <StatsTooltipRow
                          label={`Current Reserved`}
                          value={formatAmount(reserveAmount, 18, 2, true)}
                          showDollar={false}
                        />
                        <StatsTooltipRow
                          label={`Additional Reserve Required`}
                          value={formatAmount(additionalReserveAmount, 18, 2, true)}
                          showDollar={false}
                        />
                        {amount && nextReserveAmount?.gt(maxReserveAmount) && (
                          <>
                            <br />
                            <span>
                              You need a total of at least {formatAmount(nextReserveAmount, 18, 2, true)}{" "}
                              {stakeTokenLabel} to vest {formatAmount(amount, 18, 2, true)} EsNAVI.
                            </span>
                          </>
                        )}
                      </>
                    );
                  }}
                  className="excep"
                />
              </div>
            </div>
          </div>
          <div className="Exchange-swap-button-container">
            <button className="App-cta Exchange-swap-button" onClick={onClickPrimary} disabled={!isPrimaryEnabled()}>
              {getPrimaryText()}
            </button>
          </div>
        </Modal>
      </div>
    </SEO>
  );
}
export default function Vault(props) {
  const { lightThemeClassName, isLightTheme } = useThemeContext();
  const { active, library, account } = useWeb3React();
  const { vestingData, connectWallet, setPendingTxns, processedData, totalRewardTokens } = props;
  const { chainId } = useChainId();
  const [isVesterMigrateModalVisible, setIsVesterMigrateModalVisible] = useState(false);
  const [isVesterDepositModalVisible, setIsVesterDepositModalVisible] = useState(false);

  const [vesterDepositTitle, setVesterDepositTitle] = useState("");
  const [vesterDepositStakeTokenLabel, setVesterDepositStakeTokenLabel] = useState("");
  const [vesterDepositMaxAmount, setVesterDepositMaxAmount] = useState("");
  const [vesterDepositMaxAmountV1, setVesterDepositMaxAmountV1] = useState("");

  const [vesterDepositBalance, setVesterDepositBalance] = useState("");
  const [vesterDepositEscrowedBalance, setVesterDepositEscrowedBalance] = useState("");
  const [vesterDepositVestedAmount, setVesterDepositVestedAmount] = useState("");
  const [vesterDepositAverageStakedAmount, setVesterDepositAverageStakedAmount] = useState("");
  const [vesterDepositMaxVestableAmount, setVesterDepositMaxVestableAmount] = useState("");
  const [vesterDepositValue, setVesterDepositValue] = useState("");
  const [vesterDepositReserveAmount, setVesterDepositReserveAmount] = useState("");
  const [vesterDepositMaxReserveAmount, setVesterDepositMaxReserveAmount] = useState("");
  const [vesterDepositAddress, setVesterDepositAddress] = useState("");

  const [isVesterWithdrawModalVisible, setIsVesterWithdrawModalVisible] = useState(false);
  const [vesterWithdrawTitle, setVesterWithdrawTitle] = useState(false);
  const [vesterWithdrawAddress, setVesterWithdrawAddress] = useState("");
  const [vesterDepositVestedAmountV1, setVesterDepositVestedAmountV1] = useState("");
  const [fromVesterAddresssNLP, setFromVesterAddresssNLP] = useState("");
  const [fromVesterAddresssNAVI, setFromVesterAddresssNAVI] = useState("");
  const [isMigrateNAVIEnabled, setIsMigrateNAVIEnabled] = useState(false);
  const [isMigrateNLPEnabled, setIsMigrateNLPEnabled] = useState(false);

  // const [migrateNLPAmountV1, setMigrateNLPAmountV1] = useState("");
  // const [migrateNAVIAmountV1, setMigrateNAVIAmountV1] = useState("");
  // const [migrateNLPAmount, setMigrateNLPAmount] = useState("");
  // const [migrateNAVIAmount, setMigrateNAVIAmount] = useState("");

  const naviVesterAddress = getContract(chainId, "NaviVester");
  const nlpVesterAddress = getContract(chainId, "NlpVester");

  const { data: NAVIdepositBalances } = useSWR(
    [
      `StakeV2:NAVIdepositBalances:${[active, account]}`,
      OP,
      "0x766257B7cE3a5E3Ad5876200Ab1671D31289BC75",
      "getDepositBalances",
      account,
    ],
    {
      fetcher: contractFetcher(undefined, RewardReader, [
        ["0x47536f17f4ff30e64a96a7555826b8f9e66ec468"],
        ["0x04f23404553fcc388Ec73110A0206Dd2E76a6d95"],
      ]),
      refreshInterval: 10000,
    }
  );
  const migrateVesterAddress = getContract(chainId, "MigrateVester");
  // let fromVesterAddresss = vesterDepositAddress;
  //OP
  // NaviVester: "0x4F697DeDF2b3862CA43493c33589F42CFEF66026",
  //   NlpVester: "0xA25d432ae720e57e85778905935A9A0B9284114A",
  //   NaviVesterV1: "0x2A3E489F713ab6F652aF930555b5bb3422711ac1",
  //   NlpVesterV1: "0xb54AbE3FEC8e1aE64620185A1E111Aa0c3a81542",

  //FANTOM
  // NaviVester: "0x8eF5634F5C6Ef625302Ab6e4C424Dbe6B59496C0",
  // NlpVester: "0xDdeaC5Dc3A5352cCa9dD13C3067Cbbdbe2A43700",
  // NaviVesterV1: "0xa1a65D3639A1EFbFB18C82003330a4b1FB620C5a",
  // NlpVesterV1: "0x2A3E489F713ab6F652aF930555b5bb3422711ac1",
  useEffect(() => {
    switch (naviVesterAddress) {
      case "0x8eF5634F5C6Ef625302Ab6e4C424Dbe6B59496C0":
        setFromVesterAddresssNAVI("0xa1a65D3639A1EFbFB18C82003330a4b1FB620C5a");
        break;
      case "0x4F697DeDF2b3862CA43493c33589F42CFEF66026":
        setFromVesterAddresssNAVI("0x2A3E489F713ab6F652aF930555b5bb3422711ac1");
        break;
      default:
        break;
    }
    switch (nlpVesterAddress) {
      case "0xDdeaC5Dc3A5352cCa9dD13C3067Cbbdbe2A43700":
        setFromVesterAddresssNLP("0x2A3E489F713ab6F652aF930555b5bb3422711ac1");
        break;
      case "0xA25d432ae720e57e85778905935A9A0B9284114A":
        setFromVesterAddresssNLP("0xb54AbE3FEC8e1aE64620185A1E111Aa0c3a81542");
        break;
      default:
        break;
    }
  }, [nlpVesterAddress, naviVesterAddress, setFromVesterAddresssNLP, setFromVesterAddresssNAVI]);

  const { data: hasCalledMigrateNLP } = useSWR(
    [
      `StakeV2:hasCalledMigrateNLP:${[active, fromVesterAddresssNLP, account]}`,
      chainId,
      migrateVesterAddress,
      "hasCalledMigrate",
      account,
      fromVesterAddresssNLP,
    ],
    {
      fetcher: contractFetcher(undefined, MigrateVester),
      refreshInterval: 10000,
    }
  );
  const { data: hasCalledMigrateNAVI } = useSWR(
    [
      `StakeV2:hasCalledMigrateNAVI:${[active, fromVesterAddresssNAVI, account]}`,
      chainId,
      migrateVesterAddress,
      "hasCalledMigrate",
      account,
      fromVesterAddresssNAVI,
    ],
    {
      fetcher: contractFetcher(undefined, MigrateVester),
      refreshInterval: 10000,
    }
  );

  // const { data: vestedNAVIAmountV1 } = useSWR(
  //   [`StakeV2:vestedNAVIAmountV1:${[active, account]}`, chainId, naviVesterAddressV1, "getVestedAmount", account],
  //   {
  //     fetcher: contractFetcher(undefined, Vester),
  //     refreshInterval: 10000,
  //   }
  // );
  // const { data: vestedNLPAmountV1 } = useSWR(
  //   [`StakeV2:vestedNLPAmountV1:${[active, account]}`, chainId, nlpVesterAddressV1, "getVestedAmount", account],
  //   {
  //     fetcher: contractFetcher(undefined, Vester),
  //     refreshInterval: 10000,
  //   }
  // );

  // const { data: vestedNAVIAmountV1Fantom } = useSWR(
  //   [
  //     `StakeV2:vestedNAVIAmountV1Fantom:${[active, account]}`,
  //     chainId,
  //     naviVesterAddressV1,
  //     "getMaxVestableAmount",
  //     account,
  //   ],
  //   {
  //     fetcher: contractFetcher(undefined, Vester),
  //     refreshInterval: 10000,
  //   }
  // );
  // const { data: vestedNLPAmountV1Fantom } = useSWR(
  //   [
  //     `StakeV2:vestedNLPAmountV1Fantom:${[active, account]}`,
  //     chainId,
  //     nlpVesterAddressV1,
  //     "getMaxVestableAmount",
  //     account,
  //   ],
  //   {
  //     fetcher: contractFetcher(undefined, Vester),
  //     refreshInterval: 10000,
  //   }
  // );
  // const { data: vestedNAVIAmountV1OP } = useSWR(
  //   [`StakeV2:vestedNAVIAmountV1OP:${[active, account]}`, chainId, naviVesterAddressV1, "bonusRewards", account],
  //   {
  //     fetcher: contractFetcher(undefined, Vester),
  //     refreshInterval: 10000,
  //   }
  // );

  // const { data: vestedNLPAmountV1OP } = useSWR(
  //   [`StakeV2:vestedNLPAmountV1OP:${[active, account]}`, chainId, nlpVesterAddressV1, "bonusRewards", account],
  //   {
  //     fetcher: contractFetcher(undefined, Vester),
  //     refreshInterval: 10000,
  //   }
  // );

  // useEffect(() => {
  //   if (chainId === FANTOM) {
  //     if (hasCalledMigrateNLP === false && vestedNLPAmountV1Fantom && vestedNLPAmountV1Fantom.gt(0))
  //       setIsMigrateNLPEnabled(true);
  //   }
  //   if (chainId === OP) {
  //     if (hasCalledMigrateNLP === false && vestedNLPAmountV1OP && vestedNLPAmountV1OP.gt(0))
  //       setIsMigrateNLPEnabled(true);
  //   }
  //   if (hasCalledMigrateNLP && hasCalledMigrateNLP === true) {
  //     setIsMigrateNLPEnabled(false);
  //   }
  // }, [
  //   chainId,
  //   hasCalledMigrateNLP,
  //   vestedNLPAmountV1,
  //   vestedNLPAmountV1Fantom,
  //   vestedNLPAmountV1OP,
  //   vestingData?.nlpVester?.vestedAmount,
  // ]);

  // useEffect(() => {
  //   if (chainId === FANTOM) {
  //     if (hasCalledMigrateNAVI === false && vestedNAVIAmountV1Fantom && vestedNAVIAmountV1Fantom.gt(0))
  //       setIsMigrateNAVIEnabled(true);
  //   }
  //   if (chainId === OP) {
  //     if (hasCalledMigrateNAVI === false && vestedNAVIAmountV1OP && vestedNAVIAmountV1OP.gt(0))
  //       setIsMigrateNAVIEnabled(true);
  //   }
  //   if (hasCalledMigrateNAVI === true) {
  //     setIsMigrateNAVIEnabled(false);
  //   }
  // }, [
  //   chainId,
  //   hasCalledMigrateNAVI,
  //   vestedNAVIAmountV1,
  //   vestedNAVIAmountV1Fantom,
  //   vestedNAVIAmountV1OP,
  //   vestingData?.naviVester?.vestedAmount,
  // ]);

  const showNaviVesterDepositModal = () => {
    let remainingVestableAmount = vestingData.naviVester.maxVestableAmount.sub(vestingData.naviVester.vestedAmount);
    if (processedData.esNaviBalance && processedData.esNaviBalance.lt(remainingVestableAmount)) {
      remainingVestableAmount = processedData.esNaviBalance;
    }
    setVesterDepositAddress(naviVesterAddress);
    setVesterDepositTitle(`NAVI Vault`);
    //TODO off setIsMigrateNLPEnabled
    if (Date.now() < 1692180000000 && isMigrateNAVIEnabled) {
      setIsVesterMigrateModalVisible(true);
      // if (chainId === FANTOM) {
      //   setVesterDepositMaxAmountV1(vestedNAVIAmountV1Fantom);
      // }
      // if (chainId === OP) {
      //   setVesterDepositMaxAmountV1(vestedNAVIAmountV1OP);
      // }
      // setVesterDepositVestedAmountV1(vestedNAVIAmountV1);
      setVesterDepositTitle(`Migrate NAVI Vault`);
    } else {
      setIsVesterDepositModalVisible(true);
    }

    setVesterDepositStakeTokenLabel("staked NAVI + EsNAVI + Multiplier Points");
    setVesterDepositMaxAmount(remainingVestableAmount);
    setVesterDepositBalance(processedData.esNaviBalance);
    setVesterDepositEscrowedBalance(vestingData.naviVester.escrowedBalance);
    setVesterDepositVestedAmount(vestingData.naviVester.vestedAmount);
    setVesterDepositMaxVestableAmount(vestingData.naviVester.maxVestableAmount);
    setVesterDepositAverageStakedAmount(vestingData.naviVester.averageStakedAmount);
    setVesterDepositReserveAmount(vestingData.naviVester.pairAmount);
    setVesterDepositMaxReserveAmount(totalRewardTokens);
    setVesterDepositValue("");
  };
  const showNlpVesterDepositModal = () => {
    let remainingVestableAmount = vestingData.nlpVester.maxVestableAmount.sub(vestingData.nlpVester.vestedAmount);
    if (processedData.esNaviBalance && processedData.esNaviBalance.lt(remainingVestableAmount)) {
      remainingVestableAmount = processedData.esNaviBalance;
    }
    setVesterDepositTitle(`NLP Vault`);
    setVesterDepositAddress(nlpVesterAddress);
    if (Date.now() < 1692180000000 && isMigrateNLPEnabled) {
      setIsVesterMigrateModalVisible(true);
      // if (chainId === FANTOM) {
      //   setVesterDepositMaxAmountV1(vestedNLPAmountV1Fantom);
      // }
      // if (chainId === OP) {
      //   setVesterDepositMaxAmountV1(vestedNLPAmountV1OP);
      // }
      // setVesterDepositVestedAmountV1(vestedNLPAmountV1);

      // setMigrateNAVIAmountV1(vestedNAVIAmountV1);
      // setMigrateNAVIAmountV1(vestedNAVIAmountV1);
      // setMigrateNLPAmount(vestingData.naviVester.vestedAmount);
      // setMigrateNAVIAmount(vestingData.nlpVester.vestedAmount);
      setVesterDepositTitle(`Migrate NLP Vault`);
    } else {
      setIsVesterDepositModalVisible(true);
    }
    setVesterDepositStakeTokenLabel("staked NLP");
    setVesterDepositMaxAmount(remainingVestableAmount);
    setVesterDepositBalance(processedData.esNaviBalance);
    setVesterDepositEscrowedBalance(vestingData.nlpVester.escrowedBalance);
    setVesterDepositVestedAmount(vestingData.nlpVester.vestedAmount);
    setVesterDepositMaxVestableAmount(vestingData.nlpVester.maxVestableAmount);
    setVesterDepositAverageStakedAmount(vestingData.nlpVester.averageStakedAmount);
    setVesterDepositReserveAmount(vestingData.nlpVester.pairAmount);
    setVesterDepositMaxReserveAmount(processedData.nlpBalance);
    setVesterDepositValue("");
  };
  const showNaviVesterWithdrawModal = () => {
    if (!vestingData || !vestingData.naviVesterVestedAmount || vestingData.naviVesterVestedAmount.eq(0)) {
      helperToast.error(`You have not deposited any tokens for vesting.`);
      return;
    }

    setIsVesterWithdrawModalVisible(true);
    setVesterWithdrawTitle(`Withdraw from NAVI Vault`);
    setVesterWithdrawAddress(naviVesterAddress);
  };
  const showNlpVesterWithdrawModal = () => {
    if (!vestingData || !vestingData.nlpVesterVestedAmount || vestingData.nlpVesterVestedAmount.eq(0)) {
      helperToast.error(`You have not deposited any tokens for vesting.`);
      return;
    }

    setIsVesterWithdrawModalVisible(true);
    setVesterWithdrawTitle(`Withdraw from NLP Vault`);
    setVesterWithdrawAddress(nlpVesterAddress);
  };
  return (
    <Wrapper className={`Vault default-container page-layout VestPage ${lightThemeClassName}`}>
      <div className="title">Vest</div>

      <div
        style={{
          margin: "8px 0",
        }}
        className="description"
      >
        Convert EsNAVI tokens to NAVI tokens.
      </div>

      <div className="description">
        Prior to using the vaults, please review the vesting{" "}
        <ExternalLink href="https://docs.navigator.exchange/rewards#vesting">details</ExternalLink> carefully.
      </div>
      {Date.now() < 1692180000000 && chainId === OP && (
        <Warning>Please withdraw your EsNAVI from Vault v1 and deposit in Vault v2.</Warning>
      )}

      <div className="content">
        <div className="left">
          <div className="inside">
            <div className="title">NAVI Vault </div>

            <div className="claim-area">
              <div className="top">
                <div className="top-left">Claimable</div>
                <div className="top-right">
                  <Link to="/earn-v2/">Claim page</Link>
                </div>
              </div>
              <div className="bottom">
                {vestingData?.naviVesterClaimable ? (
                  <Tooltip
                    handle={`${formatKeyAmount(vestingData, "naviVesterClaimable", 18, 4, true)} NAVI`}
                    renderContent={() => (
                      <span
                        style={{
                          fontWeight: 400,
                          fontSize: 14,
                          lineHeight: "140%",
                        }}
                      >
                        <span
                          style={{
                            fontWeight: 700,
                            fontSize: 14,
                            lineHeight: "140%",
                          }}
                        >
                          {formatKeyAmount(vestingData, "naviVesterClaimable", 18, 4, true)} NAVI
                        </span>{" "}
                        tokens can be claimed, use the options under the Total Rewards section to claim them.
                      </span>
                    )}
                  />
                ) : (
                  <div className="skeleton-box" style={{ width: "80px", height: "20px" }} />
                )}
              </div>
            </div>

            <div className="info">
              <div className="child">
                <div className="title">Staked Tokens</div>
                <div className="value">
                  {totalRewardTokens ? (
                    <Tooltip
                      handle={formatAmount(totalRewardTokens, 18, 2, true)}
                      position="right-bottom"
                      renderContent={() => {
                        return (
                          <>
                            <StatsTooltipRow
                              showDollar={false}
                              label={`NAVI${chainId === OP ? " (L0)" : ""}`}
                              value={formatAmount(processedData.naviInStakedNavi, 18, 2, true)}
                            />
                            {chainId === OP && NAVIdepositBalances && NAVIdepositBalances[0] && (
                              <StatsTooltipRow
                                showDollar={false}
                                label="NAVI"
                                value={formatAmount(NAVIdepositBalances[0], 18, 2, true)}
                              />
                            )}
                            <StatsTooltipRow
                              showDollar={false}
                              label="EsNAVI"
                              value={formatAmount(processedData.esNaviInStakedNavi, 18, 2, true)}
                            />
                            <StatsTooltipRow
                              showDollar={false}
                              label="Multiplier Points"
                              value={formatAmount(processedData.bnNaviInFeeNavi, 18, 2, true)}
                            />
                          </>
                        );
                      }}
                    />
                  ) : (
                    <div className="skeleton-box" style={{ width: "80px", height: "20px" }} />
                  )}
                </div>
              </div>

              <div className="child">
                <div className="title">Reserved for Vesting</div>
                {vestingData?.naviVesterPairAmount ? (
                  <div className="value">
                    {formatKeyAmount(vestingData, "naviVesterPairAmount", 18, 2, true)} /{" "}
                    {formatAmount(totalRewardTokens, 18, 2, true)}
                  </div>
                ) : (
                  <div className="value">
                    <div className="skeleton-box" style={{ width: "80px", height: "20px" }} />
                  </div>
                )}
              </div>

              <div className="child">
                <div className="title">Vesting Status</div>
                <div className="value">
                  {vestingData?.naviVesterClaimSum ? (
                    <Tooltip
                      handle={`${formatKeyAmount(vestingData, "naviVesterClaimSum", 18, 4, true)} / ${formatKeyAmount(
                        vestingData,
                        "naviVesterVestedAmount",
                        18,
                        4,
                        true
                      )}`}
                      position="right-bottom"
                      renderContent={() => {
                        return (
                          <div>
                            <span
                              style={{
                                fontWeight: 400,
                                fontSize: 14,
                                lineHeight: "140%",
                              }}
                            >
                              <span
                                style={{
                                  fontWeight: 700,
                                  fontSize: 14,
                                  lineHeight: "140%",
                                }}
                              >
                                {formatKeyAmount(vestingData, "naviVesterClaimSum", 18, 4, true)}
                              </span>{" "}
                              tokens have been converted to NAVI from the{" "}
                              <span
                                style={{
                                  fontWeight: 700,
                                  fontSize: 14,
                                  lineHeight: "140%",
                                }}
                              >
                                {formatKeyAmount(vestingData, "naviVesterVestedAmount", 18, 4, true)} EsNAVI
                              </span>{" "}
                              deposited for vesting.
                            </span>
                          </div>
                        );
                      }}
                    />
                  ) : (
                    <div className="skeleton-box" style={{ width: "80px", height: "20px" }} />
                  )}
                </div>
              </div>
            </div>

            <div className="group-btn">
              {!active && (
                <button className="App-button-option App-card-option" onClick={() => connectWallet()}>
                  <span>Connect Wallet</span>
                </button>
              )}
              {active && (
                <button className="App-button-option App-card-option" onClick={() => showNaviVesterDepositModal()}>
                  <span>Deposit</span>
                </button>
              )}
              {active && (
                <button
                  className="App-button-option App-card-option border-btn"
                  onClick={() => showNaviVesterWithdrawModal()}
                >
                  Withdraw
                </button>
              )}
            </div>
          </div>
          {/* {chainId === OP && (
            <ExternalLink
              href="https://legacy.navigator.exchange/#/earn-v2/vest"
              className="vault-link"
              style={{ color: isLightTheme ? "#02b27f " : "#90FB75" }}
            >
              Withdraw in NAVI Vault v1{" "}
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M12.7791 1.66663C12.341 1.66663 11.9854 2.02218 11.9854 2.46028C11.9854 2.89837 12.341 3.25393 12.7791 3.25393H15.6251L9.04334 9.83565C8.73342 10.1456 8.73342 10.648 9.04334 10.9579C9.1985 11.1131 9.4013 11.1904 9.60448 11.1904C9.80765 11.1904 10.0105 11.1131 10.1656 10.9579L16.7473 4.3762V7.22218C16.7473 7.66028 17.1029 8.01583 17.541 8.01583C17.9791 8.01583 18.3346 7.66028 18.3346 7.22218V2.46028C18.3346 2.02218 17.9791 1.66663 17.541 1.66663H12.7791ZM4.04892 2.46028C2.73583 2.46028 1.66797 3.52813 1.66797 4.84123V15.9523C1.66797 17.2654 2.73583 18.3333 4.04892 18.3333H15.16C16.4731 18.3333 17.541 17.2654 17.541 15.9523V11.1904C17.541 10.7523 17.1854 10.3968 16.7473 10.3968C16.3092 10.3968 15.9537 10.7523 15.9537 11.1904V15.9523C15.9537 16.39 15.5977 16.746 15.16 16.746H4.04892C3.61122 16.746 3.25527 16.39 3.25527 15.9523V4.84123C3.25527 4.40353 3.61122 4.04758 4.04892 4.04758H8.81083C9.24892 4.04758 9.60448 3.69202 9.60448 3.25393C9.60448 2.81583 9.24892 2.46028 8.81083 2.46028H4.04892Z"
                  fill={isLightTheme ? "#02b27f " : "#90FB75"}
                />
              </svg>
            </ExternalLink>
          )} */}

          <img src={isLightTheme ? icon_vestLight : icon_vest} alt="icon_vest" />
        </div>

        <div className="right">
          <div className="inside">
            <div className="title">NLP Vault</div>

            <div className="claim-area">
              <div className="top">
                <div className="top-left">Claimable</div>
                <div className="top-right">
                  <Link to="/earn-v2/">Claim page</Link>
                </div>
              </div>
              <div className="bottom">
                {vestingData?.nlpVesterClaimable ? (
                  <Tooltip
                    handle={`${formatKeyAmount(vestingData, "nlpVesterClaimable", 18, 4, true)} NAVI`}
                    renderContent={() => (
                      <span
                        style={{
                          fontWeight: 400,
                          fontSize: 14,
                          lineHeight: "140%",
                        }}
                      >
                        <span
                          style={{
                            fontWeight: 700,
                            fontSize: 14,
                            lineHeight: "140%",
                          }}
                        >
                          {formatKeyAmount(vestingData, "nlpVesterClaimable", 18, 4, true)} NAVI
                        </span>{" "}
                        tokens can be claimed, use the options under the General Rewards section to claim them.
                      </span>
                    )}
                  />
                ) : (
                  <div className="skeleton-box" style={{ width: "80px", height: "20px" }} />
                )}
              </div>
            </div>

            <div className="info">
              <div className="child">
                <div className="title">Staked Tokens</div>
                {processedData.nlpBalance ? (
                  <div className="value">{formatAmount(processedData.nlpBalance, 18, 2, true)} NLP</div>
                ) : (
                  <div className="value">
                    <div className="skeleton-box" style={{ width: "80px", height: "20px" }} />
                  </div>
                )}
              </div>

              <div className="child">
                <div className="title">Reserved for Vesting</div>
                {vestingData?.nlpVesterPairAmount ? (
                  <div className="value">
                    {formatKeyAmount(vestingData, "nlpVesterPairAmount", 18, 2, true)} /{" "}
                    {formatAmount(processedData.nlpBalance, 18, 2, true)}
                  </div>
                ) : (
                  <div className="value">
                    <div className="skeleton-box" style={{ width: "80px", height: "20px" }} />
                  </div>
                )}
              </div>

              <div className="child">
                <div className="title">Vesting Status</div>
                <div className="value">
                  {vestingData?.nlpVesterClaimSum ? (
                    <Tooltip
                      handle={`${formatKeyAmount(vestingData, "nlpVesterClaimSum", 18, 4, true)} / ${formatKeyAmount(
                        vestingData,
                        "nlpVesterVestedAmount",
                        18,
                        4,
                        true
                      )}`}
                      position="right-bottom"
                      renderContent={() => {
                        return (
                          <div>
                            <span
                              style={{
                                fontWeight: 400,
                                fontSize: 14,
                                lineHeight: "140%",
                              }}
                            >
                              <span
                                style={{
                                  fontWeight: 700,
                                  fontSize: 14,
                                  lineHeight: "140%",
                                }}
                              >
                                {formatKeyAmount(vestingData, "nlpVesterClaimSum", 18, 4, true)}
                              </span>{" "}
                              tokens have been converted to NAVI from the{" "}
                              <span
                                style={{
                                  fontWeight: 700,
                                  fontSize: 14,
                                  lineHeight: "140%",
                                }}
                              >
                                {formatKeyAmount(vestingData, "nlpVesterVestedAmount", 18, 4, true)} EsNAVI
                              </span>{" "}
                              deposited for vesting.
                            </span>
                          </div>
                        );
                      }}
                    />
                  ) : (
                    <div className="skeleton-box" style={{ width: "80px", height: "20px" }} />
                  )}
                </div>
              </div>
            </div>

            <div className="group-btn">
              {!active && (
                <button className="App-button-option App-card-option" onClick={() => connectWallet()}>
                  <span>Connect Wallet</span>
                </button>
              )}
              {active && (
                <button className="App-button-option App-card-option" onClick={() => showNlpVesterDepositModal()}>
                  <span>Deposit</span>
                </button>
              )}
              {active && (
                <button
                  className="App-button-option App-card-option border-btn"
                  onClick={() => showNlpVesterWithdrawModal()}
                >
                  Withdraw
                </button>
              )}
            </div>
          </div>
          {/* {chainId === OP && (
            <ExternalLink
              href="https://legacy.navigator.exchange/#/earn-v2/vest"
              className="vault-link"
              style={{ color: isLightTheme ? "#02b27f " : "#90FB75" }}
            >
              Withdraw in NLP Vault v1{" "}
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M12.7791 1.66663C12.341 1.66663 11.9854 2.02218 11.9854 2.46028C11.9854 2.89837 12.341 3.25393 12.7791 3.25393H15.6251L9.04334 9.83565C8.73342 10.1456 8.73342 10.648 9.04334 10.9579C9.1985 11.1131 9.4013 11.1904 9.60448 11.1904C9.80765 11.1904 10.0105 11.1131 10.1656 10.9579L16.7473 4.3762V7.22218C16.7473 7.66028 17.1029 8.01583 17.541 8.01583C17.9791 8.01583 18.3346 7.66028 18.3346 7.22218V2.46028C18.3346 2.02218 17.9791 1.66663 17.541 1.66663H12.7791ZM4.04892 2.46028C2.73583 2.46028 1.66797 3.52813 1.66797 4.84123V15.9523C1.66797 17.2654 2.73583 18.3333 4.04892 18.3333H15.16C16.4731 18.3333 17.541 17.2654 17.541 15.9523V11.1904C17.541 10.7523 17.1854 10.3968 16.7473 10.3968C16.3092 10.3968 15.9537 10.7523 15.9537 11.1904V15.9523C15.9537 16.39 15.5977 16.746 15.16 16.746H4.04892C3.61122 16.746 3.25527 16.39 3.25527 15.9523V4.84123C3.25527 4.40353 3.61122 4.04758 4.04892 4.04758H8.81083C9.24892 4.04758 9.60448 3.69202 9.60448 3.25393C9.60448 2.81583 9.24892 2.46028 8.81083 2.46028H4.04892Z"
                  fill={isLightTheme ? "#02b27f " : "#90FB75"}
                />
              </svg>
            </ExternalLink>
          )} */}

          <img src={isLightTheme ? icon_vestLight : icon_vest} alt="icon_vest" />
        </div>
      </div>
      <VesterMigrateModal
        isVisible={isVesterMigrateModalVisible}
        setIsVisible={setIsVesterMigrateModalVisible}
        chainId={chainId}
        title={vesterDepositTitle}
        stakeTokenLabel={vesterDepositStakeTokenLabel}
        maxAmount={vesterDepositMaxAmount}
        balance={vesterDepositBalance}
        escrowedBalance={vesterDepositEscrowedBalance}
        vestedAmount={vesterDepositVestedAmount}
        vestedAmountV1={vesterDepositVestedAmountV1}
        averageStakedAmount={vesterDepositAverageStakedAmount}
        maxVestableAmount={vesterDepositMaxVestableAmount}
        maxVestableAmountV1={vesterDepositMaxAmountV1}
        reserveAmount={vesterDepositReserveAmount}
        maxReserveAmount={vesterDepositMaxReserveAmount}
        value={vesterDepositValue}
        setValue={setVesterDepositValue}
        library={library}
        vesterAddress={vesterDepositAddress}
        setPendingTxns={setPendingTxns}
        // migrateNLPAmountV1={migrateNLPAmountV1}
        // migrateNAVIAmountV1={migrateNAVIAmountV1}
        // migrateNLPAmount={migrateNLPAmount}
        // migrateNAVIAmount={migrateNAVIAmount}
      />
      <VesterDepositModal
        isVisible={isVesterDepositModalVisible}
        setIsVisible={setIsVesterDepositModalVisible}
        chainId={chainId}
        title={vesterDepositTitle}
        stakeTokenLabel={vesterDepositStakeTokenLabel}
        maxAmount={vesterDepositMaxAmount}
        balance={vesterDepositBalance}
        escrowedBalance={vesterDepositEscrowedBalance}
        vestedAmount={vesterDepositVestedAmount}
        averageStakedAmount={vesterDepositAverageStakedAmount}
        maxVestableAmount={vesterDepositMaxVestableAmount}
        reserveAmount={vesterDepositReserveAmount}
        maxReserveAmount={vesterDepositMaxReserveAmount}
        value={vesterDepositValue}
        setValue={setVesterDepositValue}
        library={library}
        vesterAddress={vesterDepositAddress}
        setPendingTxns={setPendingTxns}
      />
      <VesterWithdrawModal
        isVisible={isVesterWithdrawModalVisible}
        setIsVisible={setIsVesterWithdrawModalVisible}
        vesterAddress={vesterWithdrawAddress}
        chainId={chainId}
        title={vesterWithdrawTitle}
        library={library}
        setPendingTxns={setPendingTxns}
      />
    </Wrapper>
  );
}
const Warning = styled.div`
  border-radius: 12px;
  background: var(--main-red, #f6475d);
  padding: 12px 24px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  margin-top: 24px;
  @media screen and (max-width: 700px) {
    padding: 16px;
  }
`;
