const SvgIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none" viewBox="0 0 20 20">
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M8.518 3.333a.74.74 0 1 0 0 1.482h5.62L3.55 15.402a.74.74 0 0 0 1.048 1.048L15.185 5.862v4.879a.74.74 0 1 0 1.482 0V3.333z"
      clipRule="evenodd"
    ></path>
  </svg>
);

export default SvgIcon;
