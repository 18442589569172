import useSWR from "swr";
import { BASE, DEFAULT_CHAIN_ID, FANTOM, OP } from "config/chains";
import { fetcher } from "lib/fetcher";
import { InitDataAggregator } from "pages/SpotThroughArregator/config";
import { useCallback, useEffect, useRef, useState } from "react";
import { getServerBaseUrl, getServerKyberUrl, getServerUrl, MICROSWAP_API_URL } from "config/backend";
import axios from "axios";
import { helperToast } from "lib/helperToast";
import { ADDRESS_ZERO } from "@uniswap/v3-sdk";
import { DEFAULT_FROM_V2_TOKEN } from "config/tokens";
export type Result = Record<string, { value: string; type: string }>;
export type AggregatorParamType = {
  from?: string;
  to?: string;
  dest?: string;
  amount?: string;
  slippage?: string;
  source?: string;
  ref?: string;
  receiver?: string;
};

export const apiKeyMicroswap = "navigatorfinance";

function useMultiTokenAggregator(
  chainId: number = DEFAULT_CHAIN_ID,
  params: AggregatorParamType,
  onRefresh: boolean,
  savedSelectedDexes: string
) {
  const [dataRes, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const interval = useRef<any>(null);
  let listTokenInfoValid = [] as any;

  let paramsValue = { ...params };
  paramsValue.from =
    params.from === ADDRESS_ZERO || params.from === "ether"
      ? "0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee"
      : params.from?.toLowerCase();
  paramsValue.to =
    params.to === ADDRESS_ZERO || params.to === "ether"
      ? "0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee"
      : params.to?.toLowerCase();
  paramsValue.amount = params.amount?.toString();
  paramsValue.slippage = params.slippage?.toString() || "0.005";
  paramsValue.source = "microswap";
  paramsValue.ref = "0xae4049fb7721E683302459ee0F382EcA389736f3";

  const apiUrl = `${MICROSWAP_API_URL}/sonic/v2/route?tokenDetail=1&dexes=${savedSelectedDexes?.replaceAll(" ", "") || ""}`;

  const fetchIds = useCallback(async () => {
    try {
      setIsLoading(true);

      const res = await fetcher(
        `${apiUrl}&${Object.entries({
          amount: paramsValue?.amount || 0,
          receiver: paramsValue?.receiver || "",
          from: paramsValue?.from || "",
          to: paramsValue?.to || "",
          slippage: paramsValue?.slippage || 1,
          source: paramsValue?.source || "",
        })
          .map(([k, v]) => `${k}=${v}`)
          .join("&")}`,
        {}
      );
      if (res) {
        const data = res || {};

        setIsLoading(false);
        setData(data);
      } else {
        // helperToast.error("error ");
      }
    } catch (e) {
      setIsLoading(true);
      // helperToast.error(e);
      setData([]);
    }
  }, [
    apiUrl,
    paramsValue?.amount,
    paramsValue?.from,
    paramsValue?.receiver,
    paramsValue?.slippage,
    paramsValue?.source,
    paramsValue?.to,
  ]);

  useEffect(() => {
    if (params.amount && Number(params.amount) && !DEFAULT_FROM_V2_TOKEN?.[chainId]?.includes(params.from)) {
      fetchIds();
      if (interval?.current) {
        clearInterval(interval.current);
      }
      interval.current = setInterval(fetchIds, 15000);
      return () => clearInterval(interval.current);
    } else {
      setData([]);
      return () => clearInterval(interval.current);
    }
  }, [params, savedSelectedDexes]);
  useEffect(() => {
    if (params.amount && onRefresh) {
      fetchIds();
    }
  }, [onRefresh]);

  return {
    data: dataRes,
    isLoading: false,
  };
}

export default useMultiTokenAggregator;

const getCurrentChain = (chainId) => {
  return chainId === FANTOM ? "fantom" : chainId === OP ? "optimism" : chainId === BASE ? "base" : "arbitrum";
};
export const GetInfoTokenValid = ({ chainId, contractTokenId }) => {
  const [dataRes, setData] = useState({});

  const fetchIds = useCallback(async () => {
    const apiUrl = `https://api.coingecko.com/api/v3/coins/${getCurrentChain(chainId)}/contract/${contractTokenId}`;
    try {
      const res = await axios.get(apiUrl, {
        headers: {
          // Authorization: `${apiKey}`,
          "Content-Type": "application/json",
        },
      });

      if (res && res.data) {
        const data = res.data || {};

        setData(data);
      }
    } catch (e) {
      console.error(e);
      setData({});
    }
  }, [chainId]);

  useEffect(() => {
    if (contractTokenId) {
      fetchIds();
    }
  }, [chainId, contractTokenId]);

  return {
    data: dataRes,
  };
};
