import { Divider } from "components/ExchangeV2/AddTPSL";
import ExchangeInfoRow from "components/ExchangeV2/ExchangeInfoRow";
import GreenArraowIcon from "components/Icon/GreenArraowIcon";
import { getConstant } from "config/chains";
import { getContract } from "config/contracts";
import { SLIPPAGE_BPS_KEY } from "config/localStorage";
import { getNativeToken, getToken, getWrappedToken } from "config/tokens";
import { TRIGGER_PREFIX_ABOVE, TRIGGER_PREFIX_BELOW } from "config/ui";
import { cancelDecreaseOrder, handleCancelOrder } from "domain/legacy";
import { helperToast } from "lib/helperToast";
import {
  BASIS_POINTS_DIVISOR,
  DECREASE,
  DEFAULT_HIGHER_SLIPPAGE_AMOUNT,
  DEFAULT_SLIPPAGE_AMOUNT,
  INCREASE,
  LIMIT,
  MIN_PROFIT_TIME,
  PRECISION,
  USD_DECIMALS,
  calculatePositionDelta,
  getExchangeRate,
  getExchangeRateDisplay,
  getOrderError,
  importImage,
} from "lib/legacy";
import { useLocalStorageSerializeKey } from "lib/localStorage";
import { expandDecimals, formatAmount, getDisplayDecimalByAmount, parseValue } from "lib/numbers";
import { useCallback, useMemo, useRef, useState } from "react";
import styled from "styled-components";
import Checkbox from "../Checkbox/Checkbox";
import Modal from "../Modal/Modal";
import StatsTooltipRow from "../StatsTooltip/StatsTooltipRow";
import Tooltip from "../Tooltip/Tooltip";
import "./ConfirmationBox.css";
import MessageComponent from "./MessageComponent";
import TokenTopInfo from "./TokenTopInfo";
import { getTokenInfo } from "domain/tokens";
import { ethers } from "ethers";
const { AddressZero } = ethers.constants;
const HIGH_SPREAD_THRESHOLD = expandDecimals(1, USD_DECIMALS).div(100); // 1%;

export function getSpread(fromTokenInfo, toTokenInfo, isLong, nativeTokenAddress) {
  if (fromTokenInfo && fromTokenInfo.maxPrice && toTokenInfo && toTokenInfo.minPrice) {
    const fromDiff = fromTokenInfo.maxPrice.sub(fromTokenInfo.minPrice).div(2);
    const fromSpread = fromDiff.mul(PRECISION).div(fromTokenInfo.maxPrice.add(fromTokenInfo.minPrice).div(2));
    const toDiff = toTokenInfo.maxPrice.sub(toTokenInfo.minPrice).div(2);
    const toSpread = toDiff.mul(PRECISION).div(toTokenInfo.maxPrice.add(toTokenInfo.minPrice).div(2));

    let value = fromSpread.add(toSpread);

    const fromTokenAddress = fromTokenInfo.isNative ? nativeTokenAddress : fromTokenInfo.address;
    const toTokenAddress = toTokenInfo.isNative ? nativeTokenAddress : toTokenInfo.address;

    if (isLong && fromTokenAddress === toTokenAddress) {
      value = fromSpread;
    }

    return {
      value,
      isHigh: value.gt(HIGH_SPREAD_THRESHOLD),
    };
  }
}

export default function ConfirmationIncreaseBox(props) {
  const {
    account,
    fromToken,
    fromTokenInfo,
    toToken,
    toTokenInfo,
    isSwap,
    isLong,
    isMarketOrder,
    orderOption,
    isShort,
    toAmount,
    fromAmount,
    isHigherSlippageAllowed,
    setIsHigherSlippageAllowed,
    onConfirmationClick,
    setIsConfirming,
    shortCollateralAddress,
    hasExistingPosition,
    leverage,
    existingPosition,
    existingLiquidationPrice,
    displayLiquidationPrice,
    shortCollateralToken,
    isPendingConfirmation,
    triggerPriceUsd,
    triggerRatio,
    fees,
    feesUsd,
    isSubmitting,
    fromUsdMin,
    toUsdMax,
    nextAveragePrice,
    collateralTokenAddress,
    feeBps,
    chainId,
    orders,
    library,
    setPendingTxns,
    pendingTxns,
    minExecutionFee,
    minExecutionFeeUSD,
    minExecutionFeeErrorMessage,
    leverageOption,
    setSavedSlippageAmount,
    entryMarkPrice,
    swapFees,
    collateralToken,
    positionFee,
    fromValue,
    infoTokens,
    positions,
  } = props;

  const nativeTokenSymbol = getConstant(chainId, "nativeTokenSymbol");
  const nativeTokenInfo = getTokenInfo(infoTokens, AddressZero);
  const [savedSlippageAmount] = useLocalStorageSerializeKey([chainId, SLIPPAGE_BPS_KEY], DEFAULT_SLIPPAGE_AMOUNT);
  const [isProfitWarningAccepted, setIsProfitWarningAccepted] = useState(false);
  const [isTriggerWarningAccepted, setIsTriggerWarningAccepted] = useState(false);
  const [isLimitOrdersVisible, setIsLimitOrdersVisible] = useState(false);

  const slippage = parseInt(savedSlippageAmount);
  const ref = useRef(null);
  const [slippageAmount, setSlippageAmount] = useState((slippage / BASIS_POINTS_DIVISOR) * 100);
  const handleClick = () => {
    ref.current.focus();
  };
  const onCancelOrderClick = useCallback(
    (order) => {
      handleCancelOrder(chainId, library, order, { pendingTxns, setPendingTxns });
    },
    [library, pendingTxns, setPendingTxns, chainId]
  );

  let minOut;
  let fromTokenUsd;
  let toTokenUsd;

  let collateralAfterFees = fromUsdMin;
  if (feesUsd) {
    collateralAfterFees = fromUsdMin.sub(feesUsd);
  }

  if (isSwap) {
    minOut = toAmount.mul(BASIS_POINTS_DIVISOR - savedSlippageAmount).div(BASIS_POINTS_DIVISOR);

    fromTokenUsd = fromTokenInfo ? formatAmount(fromTokenInfo.minPrice, USD_DECIMALS, 2, true) : 0;
    toTokenUsd = toTokenInfo ? formatAmount(toTokenInfo.maxPrice, USD_DECIMALS, 2, true) : 0;
  }

  const getTitle = () => {
    if (!isMarketOrder) {
      return `Confirm Limit Order`;
    }
    if (isSwap) {
      return `Confirm Swap`;
    }
    return isLong ? `Confirm Long` : `Confirm Short`;
  };
  const title = getTitle();

  const existingOrder = useMemo(() => {
    const wrappedToken = getWrappedToken(chainId);
    for (const order of orders) {
      if (order.type !== INCREASE) continue;
      const sameToken =
        order.indexToken === wrappedToken.address ? toToken.isNative : order.indexToken === toToken.address;
      if (order.isLong === isLong && sameToken) {
        return order;
      }
    }
  }, [orders, chainId, isLong, toToken.address, toToken.isNative]);

  const existingOrders = useMemo(() => {
    const wrappedToken = getWrappedToken(chainId);
    return orders.filter((order) => {
      if (order.type !== INCREASE) return false;
      const sameToken =
        order.indexToken === wrappedToken.address ? toToken.isNative : order.indexToken === toToken.address;
      return order.isLong === isLong && sameToken;
    });
  }, [orders, chainId, isLong, toToken.address, toToken.isNative]);

  const existingTriggerOrders = useMemo(() => {
    const wrappedToken = getWrappedToken(chainId);
    return orders.filter((order) => {
      if (order.type !== DECREASE) return false;
      const sameToken =
        order.indexToken === wrappedToken.address ? toToken.isNative : order.indexToken === toToken.address;
      return order.isLong === isLong && sameToken;
    });
  }, [orders, chainId, isLong, toToken.address, toToken.isNative]);

  const decreaseOrdersThatWillBeExecuted = useMemo(() => {
    if (isSwap) return [];
    return existingTriggerOrders.filter((order) => {
      if (order.triggerAboveThreshold) {
        return existingPosition?.markPrice.gte(order.triggerPrice);
      } else {
        return existingPosition?.markPrice.lte(order.triggerPrice);
      }
    });
  }, [existingPosition, existingTriggerOrders, isSwap]);
  const currentPosition = positions.find((x) => x.key === existingPosition.key);
  const getError = () => {
    if (!isSwap && hasExistingPosition && !isMarketOrder) {
      const { delta, hasProfit } = calculatePositionDelta(triggerPriceUsd, existingPosition);
      if (hasProfit && delta.eq(0)) {
        return `Invalid price, see warning`;
      }
    }
    if (isMarketOrder && hasPendingProfit && !isProfitWarningAccepted) {
      return `Forfeit profit not checked`;
    }
    return false;
  };

  const getPrimaryText = () => {
    // if (decreaseOrdersThatWillBeExecuted.length > 0 && !isTriggerWarningAccepted) {
    //   return `Accept confirmation of trigger orders`;
    // }

    if (!isPendingConfirmation) {
      const error = getError();
      if (error) {
        return error;
      }
      return "Increase Position";
    }
    return `Increasing...`;
  };

  const isPrimaryEnabled = () => {
    if (getError()) {
      return false;
    }
    // if (decreaseOrdersThatWillBeExecuted.length > 0 && !isTriggerWarningAccepted) {
    //   return false;
    // }
    return !isPendingConfirmation && !isSubmitting;
  };

  const nativeTokenAddress = getContract(chainId, "NATIVE_TOKEN");
  const spread = getSpread(fromTokenInfo, toTokenInfo, isLong, nativeTokenAddress);
  // it's meaningless for limit/stop orders to show spread based on current prices
  const showSpread = isMarketOrder && !!spread;

  let allowedSlippage = savedSlippageAmount;
  if (isHigherSlippageAllowed) {
    allowedSlippage = DEFAULT_HIGHER_SLIPPAGE_AMOUNT;
  }

  const renderSpreadWarning = useCallback(() => {
    if (!isMarketOrder) {
      return null;
    }

    if (spread && spread.isHigh) {
      return (
        <MessageComponent type="warning">
          <div className="Confirmation-box-warning">
            <span>The spread is {`>`} 1%, please ensure the trade details are acceptable before comfirming</span>
          </div>
        </MessageComponent>
      );
    }
  }, [isMarketOrder, spread]);

  const renderFeeWarning = useCallback(() => {
    if (orderOption === LIMIT || !feeBps || feeBps <= 60) {
      return null;
    }

    if (isSwap) {
      return (
        <MessageComponent type="warning">
          {/* <div className="Confirmation-box-warning"> */}
          <span>
            Fees are high to swap from {fromToken.symbol} to {toToken.symbol}.
          </span>
          {/* </div> */}
        </MessageComponent>
      );
    }

    if (!collateralTokenAddress) {
      return null;
    }

    const collateralToken = getToken(chainId, collateralTokenAddress);
    return (
      <MessageComponent type="warning">
        {/* <div className="Confirmation-box-warning"> */}
        <span>
          Fees are high to swap from {fromToken.symbol} to {collateralToken.symbol}. <br />
          {collateralToken.symbol} is needed for collateral.
        </span>
        {/* </div> */}
      </MessageComponent>
    );
  }, [feeBps, isSwap, collateralTokenAddress, chainId, fromToken.symbol, toToken.symbol, orderOption]);

  const hasPendingProfit =
    MIN_PROFIT_TIME > 0 && existingPosition && existingPosition.delta.eq(0) && existingPosition.pendingDelta.gt(0);

  const renderExistingOrderWarning = useCallback(() => {
    if (isSwap || !existingOrder) {
      return;
    }
    const indexToken = getToken(chainId, existingOrder.indexToken);
    const sizeInToken = formatAmount(
      existingOrder.sizeDelta.mul(PRECISION).div(existingOrder.triggerPrice),
      USD_DECIMALS,
      4,
      true
    );
    const longOrShortText = existingOrder.isLong ? `Long` : `Short`;
    if (existingOrders?.length > 1) {
      return (
        <MessageComponent type="error">
          {/* <div className="Confirmation-box-info"> */}
          <span>
            <span>
              You have multiple existing Increase {longOrShortText} {indexToken.symbol} limit orders{" "}
            </span>
          </span>
          <span onClick={() => setIsLimitOrdersVisible((p) => !p)} className="view-orders">
            ({isLimitOrdersVisible ? `hide` : `view`})
          </span>
          {/* </div> */}
          {isLimitOrdersVisible && (
            <ul className="order-list">
              {existingOrders.map((order) => {
                const { account, index, type, triggerAboveThreshold, triggerPrice } = order;
                const id = `${account}-${index}`;
                const triggerPricePrefix = triggerAboveThreshold ? TRIGGER_PREFIX_ABOVE : TRIGGER_PREFIX_BELOW;
                const indexToken = getToken(chainId, order.indexToken);

                return (
                  <li key={id} className="font-sm">
                    <p>
                      {type === INCREASE ? `Increase` : `Decrease`} {indexToken.symbol} {isLong ? `Long` : `Short`}{" "}
                      &nbsp;{triggerPricePrefix} ${formatAmount(triggerPrice, USD_DECIMALS, 2, true)}
                    </p>
                    <button onClick={() => onCancelOrderClick(order)}>
                      <span>Cancel</span>
                    </button>
                  </li>
                );
              })}
            </ul>
          )}
        </MessageComponent>
      );
    }
    return (
      <MessageComponent type="error">
        {/* <div className="Confirmation-box-info"> */}
        <span>
          You have an active Limit Order to Increase {longOrShortText} {sizeInToken} {indexToken.symbol} ($
          {formatAmount(existingOrder.sizeDelta, USD_DECIMALS, 2, true)}) at price $
          {formatAmount(existingOrder.triggerPrice, USD_DECIMALS, 2, true)}
        </span>
        {/* </div> */}
      </MessageComponent>
    );
  }, [existingOrder, isSwap, chainId, existingOrders, isLong, isLimitOrdersVisible, onCancelOrderClick]);

  const renderExistingTriggerErrors = useCallback(() => {
    if (isSwap || decreaseOrdersThatWillBeExecuted?.length < 1) {
      return;
    }
    const existingTriggerOrderLength = decreaseOrdersThatWillBeExecuted.length;
    return (
      <MessageComponent type="error">
        <div className="Confirmation-box-warning">
          {existingTriggerOrderLength === 1
            ? "You have an active trigger order that might execute immediately after you open this position. Please cancel the order or accept the confirmation to continue."
            : `You have ${existingTriggerOrderLength} active trigger orders that might execute immediately after you open this position. Please cancel the orders or accept the confirmation to continue.`}
          {/* <Plural
            value={existingTriggerOrderLength}
            one="You have an active trigger order that might execute immediately after you open this position. Please cancel the order or accept the confirmation to continue."
            other="You have # active trigger orders that might execute immediately after you open this position. Please cancel the orders or accept the confirmation to continue."
          /> */}
        </div>
        <ul className="order-list">
          {decreaseOrdersThatWillBeExecuted.map((order) => {
            const { account, index, type, triggerAboveThreshold, triggerPrice } = order;
            const id = `${account}-${index}`;
            const triggerPricePrefix = triggerAboveThreshold ? TRIGGER_PREFIX_ABOVE : TRIGGER_PREFIX_BELOW;
            const indexToken = getToken(chainId, order.indexToken);
            return (
              <li key={id} className="font-sm">
                <p>
                  {type === INCREASE ? `Increase` : `Decrease`} {indexToken.symbol} {isLong ? `Long` : `Short`}
                  &nbsp;{triggerPricePrefix} ${formatAmount(triggerPrice, USD_DECIMALS, 2, true)}
                </p>
                <button
                  onClick={() =>
                    cancelDecreaseOrder(chainId, library, index, {
                      successMsg: `Order cancelled`,
                      failMsg: `Cancel failed`,
                      sentMsg: `Cancel submitted`,
                      pendingTxns,
                      setPendingTxns,
                    })
                  }
                >
                  <span>Cancel</span>
                </button>
              </li>
            );
          })}
        </ul>
      </MessageComponent>
    );
  }, [decreaseOrdersThatWillBeExecuted, isSwap, chainId, library, pendingTxns, setPendingTxns, isLong]);

  const renderExistingTriggerWarning = useCallback(() => {
    if (
      isSwap ||
      existingTriggerOrders.length < 1 ||
      decreaseOrdersThatWillBeExecuted.length > 0 ||
      renderExistingOrderWarning()
    ) {
      return;
    }
    const existingTriggerOrderLength = existingTriggerOrders.length;
    return (
      <MessageComponent type="error">
        {/* <div className="Confirmation-box-info"> */}
        {existingTriggerOrderLength === 1
          ? "You have an active trigger order that could impact this position."
          : `You have ${existingTriggerOrderLength} active trigger orders that could impact this position.`}
        {/* <Plural
          value={existingTriggerOrderLength}
          one="You have an active trigger order that could impact this position."
          other="You have # active trigger orders that could impact this position."
        /> */}
        {/* </div> */}
      </MessageComponent>
    );
  }, [existingTriggerOrders, isSwap, decreaseOrdersThatWillBeExecuted, renderExistingOrderWarning]);

  // TODO handle unaprproved order plugin (very unlikely case)
  const renderMain = useCallback(() => {
    if (isSwap) {
      return (
        <div className="Confirmation-box-main">
          <div>
            <span>Pay</span>&nbsp;{formatAmount(fromAmount, fromToken.decimals, 4, true)} {fromToken.symbol} ($
            {formatAmount(fromUsdMin, USD_DECIMALS, 2, true)})
          </div>
          <div className="Confirmation-box-main-icon"></div>
          <div>
            <span>Receive</span>&nbsp;{formatAmount(toAmount, toToken.decimals, 4, true)} {toToken.symbol} ($
            {formatAmount(toUsdMax, USD_DECIMALS, 2, true)})
          </div>
        </div>
      );
    }

    return (
      <div className="Confirmation-box-main">
        <span>
          <span>Pay</span>&nbsp;{formatAmount(fromAmount, fromToken.decimals, 4, true)} {fromToken.symbol} ($
          {formatAmount(fromUsdMin, USD_DECIMALS, 2, true)})
        </span>
        <div className="Confirmation-box-main-icon"></div>
        <div>
          {isLong ? `Long` : `Short`}&nbsp;
          {formatAmount(toAmount, toToken.decimals, 4, true)} {toToken.symbol} ($
          {formatAmount(toUsdMax, USD_DECIMALS, 2, true)})
        </div>
      </div>
    );
  }, [isSwap, fromAmount, fromToken, toToken, fromUsdMin, toUsdMax, isLong, toAmount]);

  const SWAP_ORDER_EXECUTION_GAS_FEE = getConstant(chainId, "SWAP_ORDER_EXECUTION_GAS_FEE");
  const INCREASE_ORDER_EXECUTION_GAS_FEE = getConstant(chainId, "INCREASE_ORDER_EXECUTION_GAS_FEE");
  const executionFee = isSwap ? SWAP_ORDER_EXECUTION_GAS_FEE : INCREASE_ORDER_EXECUTION_GAS_FEE;

  const renderExecutionFee = useCallback(() => {
    if (isMarketOrder) {
      return null;
    }
    return (
      <ExchangeInfoRow label={`Execution Fee`}>
        {formatAmount(executionFee, 18, 4)} {getNativeToken(chainId).symbol}
      </ExchangeInfoRow>
    );
  }, [isMarketOrder, executionFee, chainId]);

  const renderAvailableLiquidity = useCallback(() => {
    let availableLiquidity;
    const riskThresholdBps = 5000;
    let isLiquidityRisk;
    const token = isSwap || isLong ? toTokenInfo : shortCollateralToken;

    if (!token || !token.poolAmount || !token.availableAmount) {
      return null;
    }

    if (isSwap) {
      const poolWithoutBuffer = token.poolAmount.sub(token.bufferAmount);
      availableLiquidity = token.availableAmount.gt(poolWithoutBuffer) ? poolWithoutBuffer : token.availableAmount;
      isLiquidityRisk = availableLiquidity.mul(riskThresholdBps).div(BASIS_POINTS_DIVISOR).lt(toAmount);
    } else {
      if (isShort) {
        availableLiquidity = token.availableAmount;

        let adjustedMaxGlobalShortSize;

        if (toTokenInfo.maxAvailableShort && toTokenInfo.maxAvailableShort.gt(0)) {
          adjustedMaxGlobalShortSize = toTokenInfo.maxAvailableShort
            .mul(expandDecimals(1, token.decimals))
            .div(expandDecimals(1, USD_DECIMALS));
        }

        if (adjustedMaxGlobalShortSize && adjustedMaxGlobalShortSize.lt(token.availableAmount)) {
          availableLiquidity = adjustedMaxGlobalShortSize;
        }

        const sizeTokens = toUsdMax.mul(expandDecimals(1, token.decimals)).div(token.minPrice);
        isLiquidityRisk = availableLiquidity.mul(riskThresholdBps).div(BASIS_POINTS_DIVISOR).lt(sizeTokens);
      } else {
        availableLiquidity = token.availableAmount;
        isLiquidityRisk = availableLiquidity.mul(riskThresholdBps).div(BASIS_POINTS_DIVISOR).lt(toAmount);
      }
    }

    if (!availableLiquidity) {
      return null;
    }

    return (
      <ExchangeInfoRow label={`Available Liquidity`}>
        <Tooltip
          position="right-bottom"
          handleClassName={isLiquidityRisk ? "negative" : null}
          handle={
            <>
              {formatAmount(availableLiquidity, token.decimals, token.isStable ? 0 : 2, true)} {token.symbol}
            </>
          }
          renderContent={() =>
            isLiquidityRisk
              ? `There may not be sufficient liquidity to execute your order when the price conditions are met`
              : `The order will only execute if the price conditions are met and there is sufficient liquidity`
          }
        />
      </ExchangeInfoRow>
    );
  }, [toTokenInfo, shortCollateralToken, isShort, isLong, isSwap, toAmount, toUsdMax]);
  const nextCollateral =
    fromValue && collateralAfterFees
      ? collateralAfterFees.add(existingPosition.collateral)
      : existingPosition.collateral;
  const posQty = existingPosition?.averagePrice
    ? existingPosition.size.mul(parseValue(1, USD_DECIMALS)).div(existingPosition?.averagePrice)
    : 0;
  const nextSize = fromValue ? existingPosition.size.add(toUsdMax) : existingPosition.size;
  const nextQty = fromValue
    ? nextSize.mul(parseValue(1, USD_DECIMALS)).div(nextAveragePrice)
    : existingPosition.averagePrice;

  const renderMarginSection = useCallback(() => {
    return (
      <>
        <div>
          {/* {renderMain()} */}

          {minExecutionFeeErrorMessage && <div className="Confirmation-box-warning">{minExecutionFeeErrorMessage}</div>}
          {hasPendingProfit && isMarketOrder && (
            <div className="PositionEditor-accept-profit-warning">
              <Checkbox isChecked={isProfitWarningAccepted} setIsChecked={setIsProfitWarningAccepted}>
                <span className="muted">
                  <span>Forfeit profit</span>
                </span>
              </Checkbox>
            </div>
          )}
          {/* <TagBox>
            <Token>
              <img className="token" src={importImage("ic_" + toToken?.symbol.toLowerCase() + "_24.svg")} alt="" />
              <div className="title">{toToken?.symbol}</div>
            </Token>
            <SideTag className={`${isLong ? "long" : "short"}`}>{isLong ? "Long" : "Short"}</SideTag>
            <SideTag>{isMarketOrder ? "MARKET" : "LIMIT"}</SideTag>
            <LeverateTag>
              {leverage && !leverage.eq(existingPosition.leverage)
                ? formatAmount(leverage, 4, 2, false)
                : formatAmount(existingPosition.leverage, 4, 2, false)}
              x
            </LeverateTag>
          </TagBox> */}
          <TokenTopInfo
            type="small"
            symbol={toToken?.symbol}
            isLong={isLong}
            orderOption={isMarketOrder ? "MARKET" : "LIMIT"}
            posId={currentPosition?.posId}
            leverageOption={
              leverage && !leverage.eq(existingPosition.leverage)
                ? formatAmount(leverage, 4, 2, false)
                : formatAmount(existingPosition.leverage, 4, 2, false)
            }
          />
          <StyledBox>
            <div>
              <div className="Exchange-info-label">Amount</div>
              <div className="Exchange-info-amount"> ${formatAmount(fromUsdMin, USD_DECIMALS, 2, true)}</div>
            </div>
            {!isMarketOrder && (
              <div>
                <div className="Exchange-info-label">Limit price</div>
                <div className="Exchange-info-amount">
                  {" "}
                  ${triggerPriceUsd ? formatAmount(triggerPriceUsd, 30, 2, true) : "0"}
                </div>
              </div>
            )}
          </StyledBox>
          <ExchangeInfoRow label={`Mark Price`}>${formatAmount(entryMarkPrice, 30, 2, true)}</ExchangeInfoRow>
          {isShort && (
            <ExchangeInfoRow label={`Collateral In`}>
              {getToken(chainId, shortCollateralAddress).symbol}
            </ExchangeInfoRow>
          )}
          {isLong && <ExchangeInfoRow label={`Collateral In`} value={toTokenInfo.symbol} />}
          <ExchangeInfoRow label={`Fees`}>
            <Tooltip
              handle={`$${formatAmount(
                feesUsd.add(
                  isMarketOrder
                    ? minExecutionFeeUSD
                    : executionFee.mul(nativeTokenInfo?.minPrice || 1).div(parseValue(1, 21))
                ),
                USD_DECIMALS,
                getDisplayDecimalByAmount(feesUsd, USD_DECIMALS),
                true
              )}`}
              position="right-bottom"
              renderContent={() => {
                return (
                  <div>
                    {swapFees && (
                      <div>
                        <span>{collateralToken.symbol} is required for collateral.</span> <br />
                        <br />
                        <StatsTooltipRow
                          label={`Swap ${fromToken.symbol} to ${collateralToken.symbol} Fee`}
                          value={formatAmount(
                            swapFees,
                            USD_DECIMALS,
                            getDisplayDecimalByAmount(swapFees, USD_DECIMALS),
                            true
                          )}
                        />
                        <br />
                      </div>
                    )}
                    <div>
                      <StatsTooltipRow
                        label={`Network Fee`}
                        showDollar={false}
                        value={`${formatAmount(
                          isMarketOrder ? minExecutionFee : executionFee,
                          18,
                          getDisplayDecimalByAmount(isMarketOrder ? minExecutionFee : executionFee, USD_DECIMALS),
                          true
                        )} ${nativeTokenSymbol} ($${formatAmount(
                          isMarketOrder
                            ? minExecutionFeeUSD
                            : executionFee.mul(nativeTokenInfo?.minPrice || 1).div(parseValue(1, 21)),
                          USD_DECIMALS,
                          getDisplayDecimalByAmount(minExecutionFee, USD_DECIMALS),
                          true
                        )})`}
                      />
                    </div>
                    <br />
                    <div>
                      <StatsTooltipRow
                        label={`Position Fee (0.15% of position size)`}
                        value={formatAmount(
                          positionFee,
                          USD_DECIMALS,
                          getDisplayDecimalByAmount(positionFee, USD_DECIMALS),
                          true
                        )}
                      />
                    </div>
                  </div>
                );
              }}
            />
          </ExchangeInfoRow>
          {orderOption === LIMIT && renderAvailableLiquidity()}
          {/* <ExchangeInfoRow label={`Collateral`}>
            <Tooltip
              handle={`$${formatAmount(collateralAfterFees, USD_DECIMALS, 2, true)}`}
              position="right-bottom"
              renderContent={() => {
                return (
                  <>
                    <span>Your position's collateral after deducting fees.</span>
                    <br />
                    <br />
                    <StatsTooltipRow label={`Pay Amount`} value={formatAmount(fromUsdMin, USD_DECIMALS, 2, true)} />
                    <StatsTooltipRow label={`Fees`} value={formatAmount(feesUsd, USD_DECIMALS, 2, true)} />
                    <div className="Tooltip-divider" />
                    <StatsTooltipRow
                      label={`Collateral`}
                      value={formatAmount(collateralAfterFees, USD_DECIMALS, 2, true)}
                    />
                  </>
                );
              }}
            />
          </ExchangeInfoRow> */}
          <DividerStyled />
          <ExchangeInfoRow label={`Leverage`}>
            {leverage && !leverage.eq(existingPosition.leverage) ? (
              <span>
                <span style={{ textDecoration: "line-through", color: "#828899" }}>
                  {formatAmount(existingPosition.leverage, 4, 2, true)}x
                </span>{" "}
                <span style={{ position: "relative", top: "2px" }}>
                  {" "}
                  <GreenArraowIcon fill={"#828899"} />
                </span>{" "}
                {formatAmount(leverage, 4, 2, true)}x
                {/* <span
                  className={cx("Exchange-list-info-label", {
                    positive: leverage.sub(existingPosition.leverage).gt(0),
                    negative: leverage.sub(existingPosition.leverage).lt(0),
                  })}
                  style={{ marginLeft: "4px", fontSize: "14px" }}
                >
                  ({leverage.sub(existingPosition.leverage).gte(0) ? "+" : "-"}
                  {formatAmount(leverage.sub(existingPosition.leverage), 4, 2, false).replace("-", "")}x)
                </span> */}
              </span>
            ) : (
              `$${formatAmount(existingPosition.leverage, 30, 2)}`
            )}
          </ExchangeInfoRow>
          <ExchangeInfoRow label={`Collateral`}>
            {nextCollateral && !nextCollateral.eq(existingPosition.collateral) ? (
              <span>
                <span style={{ textDecoration: "line-through", color: "#828899" }}>
                  ${formatAmount(existingPosition.collateral, USD_DECIMALS, 2, true)}
                </span>{" "}
                <span style={{ position: "relative", top: "2px" }}>
                  {" "}
                  <GreenArraowIcon fill={"#828899"} />
                </span>{" "}
                ${formatAmount(nextCollateral, USD_DECIMALS, 2, true)}
                {/* <span
                  className={cx("Exchange-list-info-label", {
                    positive: nextCollateral.sub(existingPosition.collateral).gt(0),
                    negative: nextCollateral.sub(existingPosition.collateral).lt(0),
                  })}
                  style={{ marginLeft: "4px", fontSize: "14px" }}
                >
                  ({nextCollateral.sub(existingPosition.collateral).gte(0) ? "+" : "-"}$
                  {formatAmount(nextCollateral.sub(existingPosition.collateral), 30, 2, false).replace("-", "")})
                </span> */}
              </span>
            ) : (
              `$${formatAmount(existingPosition.collateral, 30, 2)}`
            )}
          </ExchangeInfoRow>
          <ExchangeInfoRow label={`Pos. Size`}>
            {nextAveragePrice && !nextAveragePrice.eq(existingPosition.averagePrice) ? (
              <span>
                <span style={{ textDecoration: "line-through", color: "#828899" }}>
                  ${formatAmount(existingPosition.size, USD_DECIMALS, 2, true)}
                </span>{" "}
                <span style={{ position: "relative", top: "2px" }}>
                  {" "}
                  <GreenArraowIcon fill={"#828899"} />
                </span>{" "}
                ${formatAmount(existingPosition.size.add(toUsdMax), USD_DECIMALS, 2, true)}
                {/* <span
                  className={cx("Exchange-list-info-label", {
                    positive: existingPosition.size.add(toUsdMax).sub(existingPosition.size).gt(0),
                    negative: existingPosition.size.add(toUsdMax).sub(existingPosition.size).lt(0),
                  })}
                  style={{ marginLeft: "4px", fontSize: "14px" }}
                >
                  ({existingPosition.size.add(toUsdMax).sub(existingPosition.size).gte(0) ? "+" : "-"}$
                  {formatAmount(existingPosition.size.add(toUsdMax).sub(existingPosition.size), 30, 2, false).replace(
                    "-",
                    ""
                  )}
                  )
                </span> */}
              </span>
            ) : (
              `$${formatAmount(existingPosition.size, 30, 2)}`
            )}
          </ExchangeInfoRow>
          <ExchangeInfoRow label={`Pos. Qty (${existingPosition.indexToken.symbol})`}>
            {nextAveragePrice && !nextAveragePrice.eq(existingPosition.averagePrice) ? (
              <span>
                <span style={{ textDecoration: "line-through", color: "#828899" }}>
                  {formatAmount(posQty, 30, 4, true)}
                </span>{" "}
                <span style={{ position: "relative", top: "2px" }}>
                  {" "}
                  <GreenArraowIcon fill={"#828899"} />
                </span>{" "}
                {formatAmount(nextQty, 30, 4, true)}
                {/* <span
                  className={cx("Exchange-list-info-label", {
                    positive: true,
                  })}
                  style={{ marginLeft: "4px", fontSize: "14px" }}
                >
                  (+{formatAmount(nextQty.sub(posQty), 30, 4, true)})
                </span> */}
              </span>
            ) : (
              `${formatAmount(posQty, 30, 4, true)}`
            )}
          </ExchangeInfoRow>
          <ExchangeInfoRow label={`Entry Price`}>
            {nextAveragePrice && !nextAveragePrice.eq(existingPosition.averagePrice) ? (
              <span>
                <span style={{ textDecoration: "line-through", color: "#828899" }}>
                  ${formatAmount(existingPosition.averagePrice, USD_DECIMALS, 2, true)}
                </span>{" "}
                <span style={{ position: "relative", top: "2px" }}>
                  {" "}
                  <GreenArraowIcon fill={"#828899"} />
                </span>{" "}
                ${formatAmount(nextAveragePrice, USD_DECIMALS, 2, true)}
                {/* <span
                  className={cx("Exchange-list-info-label", {
                    positive: nextAveragePrice.sub(existingPosition.averagePrice).gt(0),
                    negative: nextAveragePrice.sub(existingPosition.averagePrice).lt(0),
                  })}
                  style={{ marginLeft: "4px", fontSize: "14px" }}
                >
                  ({nextAveragePrice.sub(existingPosition.averagePrice).gte(0) ? "+" : "-"}$
                  {formatAmount(nextAveragePrice.sub(existingPosition.averagePrice), 30, 2, false).replace("-", "")})
                </span> */}
              </span>
            ) : (
              `$${formatAmount(existingPosition.averagePrice, 30, 2)}`
            )}
          </ExchangeInfoRow>
          <ExchangeInfoRow label={`Liq. Price`} className="no-margin Exchange-info-row">
            {displayLiquidationPrice && !displayLiquidationPrice.eq(existingLiquidationPrice) ? (
              <span>
                <span style={{ textDecoration: "line-through", color: "#828899" }}>
                  ${formatAmount(existingLiquidationPrice, USD_DECIMALS, 2, true)}
                </span>{" "}
                <span style={{ position: "relative", top: "2px" }}>
                  {" "}
                  <GreenArraowIcon fill={"#828899"} />
                </span>{" "}
                ${formatAmount(displayLiquidationPrice, USD_DECIMALS, 2, true)}
                {/* <span
                  className={cx("Exchange-list-info-label", {
                    positive: displayLiquidationPrice.sub(existingLiquidationPrice).gt(0),
                    negative: displayLiquidationPrice.sub(existingLiquidationPrice).lt(0),
                  })}
                  style={{ marginLeft: "4px", fontSize: "14px" }}
                >
                  ({displayLiquidationPrice.sub(existingLiquidationPrice).gte(0) ? "+" : "-"}$
                  {formatAmount(displayLiquidationPrice.sub(existingLiquidationPrice), 30, 2, false).replace("-", "")})
                </span> */}
              </span>
            ) : (
              `$${formatAmount(existingLiquidationPrice, 30, 2)}`
            )}
          </ExchangeInfoRow>

          {/* {showSpread && (
            <ExchangeInfoRow label={`Spread`} isWarning={spread.isHigh}>
              {formatAmount(spread.value.mul(100), USD_DECIMALS, 2, true)}%
            </ExchangeInfoRow>
          )} */}
          {/* <DividerStyled /> */}

          {/* {!isMarketOrder && (
            <ExchangeInfoRow label={`Limit Price`} isTop={true}>
              $
              {formatAmount(
                triggerPriceUsd,
                USD_DECIMALS,
                getDisplayDecimalByAmount(triggerPriceUsd, USD_DECIMALS),
                true
              )}
            </ExchangeInfoRow>
          )}
          <ExchangeInfoRow label={`Borrow Fee`}>
            {isLong && toTokenInfo && formatAmount(toTokenInfo.fundingRate, 4, 4)}
            {isShort && shortCollateralToken && formatAmount(shortCollateralToken.fundingRate, 4, 4)}
            {((isLong && toTokenInfo && toTokenInfo.fundingRate) ||
              (isShort && shortCollateralToken && shortCollateralToken.fundingRate)) &&
              "% / 1h"}
          </ExchangeInfoRow> */}
          {/* {isMarketOrder && (
            <div className="PositionEditor-allow-higher-slippage">
              <ExchangeInfoRow label={`Execution Fee`}>
                <Tooltip
                  handle={`${formatAmountFree(minExecutionFee, 18, 5)} ${nativeTokenSymbol}`}
                  position="right-top"
                  renderContent={() => {
                    return (
                      <>
                        <StatsTooltipRow
                          label={`Network fee`}
                          showDollar={false}
                          value={`${formatAmountFree(minExecutionFee, 18, 5)} ${nativeTokenSymbol} ($${formatAmount(
                            minExecutionFeeUSD,
                            USD_DECIMALS,
                            2
                          )})`}
                        />
                        <br />
                        <span>
                          This is the network cost required to execute the postion.{" "}
                          <ExternalLink href="https://docs.navigator.exchange/trading#execution-fee">More Info</ExternalLink>
                        </span>
                      </>
                    );
                  }}
                />
              </ExchangeInfoRow>
            </div>
          )} */}
          <div style={{ marginTop: "8px" }} />
          {/* <ExchangeInfoRow label={`Allowed Slippage`}>
            <Tooltip
              handle={`${formatAmount(allowedSlippage, 2, 2)}%`}
              position="right-top"
              renderContent={() => {
                return (
                  <span>
                    You can change this in the settings menu on the top right of the page.
                    <br />
                    <br />
                    Note that a low allowed slippage, e.g. less than{" "}
                    <span style={{ color: "white", fontWeight: 700 }}>0.5%</span>, may result in failed orders if prices
                    are volatile.
                  </span>
                );
              }}
            />
          </ExchangeInfoRow>
          {isMarketOrder && (
            <div className="PositionEditor-allow-higher-slippage">
              <Checkbox isChecked={isHigherSlippageAllowed} setIsChecked={setIsHigherSlippageAllowed}>
                <span className="muted font-sm">
                  <span>Allow up to 1% slippage</span>
                </span>
              </Checkbox>
            </div>
          )} */}
          {/* <DividerStyled /> */}
          {/* {
            <div className="allow-slippaged-line">
              <ExchangeInfoRow label={`Slippage`}>
                <div className="allow-slippage-container">
                  <input
                    ref={ref}
                    type="number"
                    className="App-slippage-tolerance-input"
                    min="0"
                    value={slippageAmount}
                    onChange={(e) => setSlippageAmount(e.target.value)}
                  />
                  <div>%</div>
                  <img alt="icon-edit" onClick={handleClick} src={iconEdit} className="icon-edit" />
                </div>
              </ExchangeInfoRow>
            </div>
          }
          {decreaseOrdersThatWillBeExecuted.length > 0 && (
            <div className="PositionEditor-allow-higher-slippage">
              <Checkbox isChecked={isTriggerWarningAccepted} setIsChecked={setIsTriggerWarningAccepted}>
                <span className="muted font-sm">
                  <span>I am aware of the trigger orders</span>
                </span>
              </Checkbox>
            </div>
          )}
          {renderExecutionFee()} */}
          {/* {renderFeeWarning()}
          {renderExistingOrderWarning()}
          {renderExistingTriggerErrors()}
          {renderExistingTriggerWarning()} */}
        </div>
      </>
    );
  }, [
    renderMain,
    shortCollateralAddress,
    isShort,
    isLong,
    toTokenInfo,
    nextAveragePrice,
    toAmount,
    hasExistingPosition,
    existingPosition,
    isMarketOrder,
    triggerPriceUsd,
    showSpread,
    spread,
    displayLiquidationPrice,
    existingLiquidationPrice,
    feesUsd,
    leverage,
    renderExecutionFee,
    shortCollateralToken,
    chainId,
    renderFeeWarning,
    hasPendingProfit,
    isProfitWarningAccepted,
    renderAvailableLiquidity,
    orderOption,
    fromUsdMin,
    collateralAfterFees,
    renderExistingOrderWarning,
    renderExistingTriggerWarning,
    renderExistingTriggerErrors,
    isHigherSlippageAllowed,
    setIsHigherSlippageAllowed,
    allowedSlippage,
    isTriggerWarningAccepted,
    decreaseOrdersThatWillBeExecuted,
    minExecutionFee,
    nativeTokenSymbol,
    minExecutionFeeUSD,
    minExecutionFeeErrorMessage,
  ]);

  const renderSwapSection = useCallback(() => {
    return (
      <>
        <div>
          {/* {renderMain()} */}
          {/* {renderFeeWarning()} */}

          {orderOption === LIMIT && renderAvailableLiquidity()}
          <ExchangeInfoRow label={`Min. Receive`}>
            {formatAmount(minOut, toTokenInfo.decimals, 4, true)} {toTokenInfo.symbol}
          </ExchangeInfoRow>
          <ExchangeInfoRow label={`Price`}>
            {getExchangeRateDisplay(getExchangeRate(fromTokenInfo, toTokenInfo), fromTokenInfo, toTokenInfo)}
          </ExchangeInfoRow>
          {!isMarketOrder && (
            <div className="Exchange-info-row">
              <div className="Exchange-info-label">
                <span>Limit Price</span>
              </div>
              <div className="align-right">{getExchangeRateDisplay(triggerRatio, fromTokenInfo, toTokenInfo)}</div>
            </div>
          )}
          {showSpread && (
            <ExchangeInfoRow label={`Spread`} isWarning={spread.isHigh}>
              {formatAmount(spread.value.mul(100), USD_DECIMALS, 2, true)}%
            </ExchangeInfoRow>
          )}
          <div className="Exchange-info-row">
            <div className="Exchange-info-label">
              <span>Fees</span>
            </div>
            <div className="align-right">
              {formatAmount(feeBps, 2, 2, true)}% ({formatAmount(fees, fromTokenInfo.decimals, 4, true)}{" "}
              {fromTokenInfo.symbol}: ${formatAmount(feesUsd, USD_DECIMALS, 2, true)})
            </div>
          </div>
          {/* {renderExecutionFee()} */}
          {fromTokenUsd && (
            <div className="Exchange-info-row">
              <div className="Exchange-info-label">
                <span>{fromTokenInfo.symbol} Price</span>
              </div>
              <div className="align-right">{fromTokenUsd} USD</div>
            </div>
          )}
          {toTokenUsd && (
            <div className="Exchange-info-row">
              <div className="Exchange-info-label">
                <span>{toTokenInfo.symbol} Price</span>
              </div>
              <div className="align-right">{toTokenUsd} USD</div>
            </div>
          )}
        </div>
      </>
    );
  }, [
    renderMain,
    renderSpreadWarning,
    fromTokenInfo,
    toTokenInfo,
    orderOption,
    showSpread,
    spread,
    feesUsd,
    feeBps,
    renderExecutionFee,
    fromTokenUsd,
    toTokenUsd,
    triggerRatio,
    fees,
    isMarketOrder,
    minOut,
    renderFeeWarning,
    renderAvailableLiquidity,
  ]);
  const handleConfirmClick = async () => {
    const slippage = parseFloat(slippageAmount);
    if (isNaN(slippage)) {
      helperToast.error(`Invalid slippage value`);
      return;
    }
    if (slippage > 5) {
      helperToast.error(`Slippage should be less than 5%`);
      return;
    }
    const basisPoints = (slippage * BASIS_POINTS_DIVISOR) / 100;
    if (parseInt(basisPoints) !== parseFloat(basisPoints)) {
      helperToast.error(`Max slippage precision is 0.01%`);
      return;
    }
    await setSavedSlippageAmount(basisPoints);
    // onConfirmationClick && onConfirmationClick();
  };
  const handleClickBtn = async () => {
    handleConfirmClick();
    onConfirmationClick();
  };
  const renderLinkedOrders = (orders, isError) => {
    if (!orders || orders.length === 0) return null;
    return (
      <>
        {orders.map((order) => {
          const orderError = getOrderError(account, order, undefined, {
            ...existingPosition,
            size: toUsdMax.add(existingPosition?.size || 0),
            collateral: nextCollateral,
            leverage,
          });
          return (
            <div key={`${order.isLong}-${order.type}-${order.index}`} className="linked-list-order">
              <div>
                <span className={`${orderError ? "muted" : ""}`} style={{ fontSize: "14px" }}>
                  {["Swap", "Increase"].includes(order.type)
                    ? "Limit"
                    : existingPosition.isLong
                    ? order.triggerAboveThreshold
                      ? "Take Profit"
                      : "Stop Loss"
                    : order.triggerAboveThreshold
                    ? "Stop Loss"
                    : "Take Profit"}
                  : {order.triggerAboveThreshold ? "≥" : "≤"} $
                  {formatAmount(order.triggerPrice, 30, existingPosition?.indexToken?.displayDecimals || 2, true)} |
                  {order.type === INCREASE ? " +" : " -"}${formatAmount(order.sizeDelta, 30, 2, true)}
                </span>
              </div>
              {orderError && (
                <div>
                  <span className="negative">{orderError}</span>
                </div>
              )}

              <button
                className="Exchange-list-action"
                onClick={(e) => {
                  e.stopPropagation();
                  onCancelOrderClick(order);
                }}
                style={{ margin: 0, width: "fit-content" }}
              >
                <span>Cancel</span>
              </button>
              <div className="divider" style={{ display: "flex", margin: "8px 0 0 0" }} />
            </div>
          );
        })}
      </>
    );
  };
  const renderExistingOrders = () => {
    // const posOrders = [...existingOrders, ...decreaseOrdersThatWillBeExecuted, ...existingTriggerOrders];
    const posOrdersData = [...existingOrders, ...existingTriggerOrders];
    const posOrders = posOrdersData
      .filter((order) => order.type !== "Swap")
      .filter(
        (x) =>
          (x.type === INCREASE &&
            (x.isLong ||
              (!x.isLong &&
                !existingPosition.isLong &&
                existingPosition.indexToken.address === x.indexToken &&
                existingPosition.collateralToken.address === x.collateralToken))) ||
          (x.isLong) ||
          (!x.isLong &&
            !existingPosition.isLong &&
            existingPosition.indexToken.address === x.indexToken &&
            existingPosition.collateralToken.address === x.collateralToken)
      );

    if (!posOrders || posOrders.length === 0) return null;

    const validOrders = posOrders.filter((order) => {
      const orderError = getOrderError(account, order, undefined, {
        ...existingPosition,
        size: toUsdMax.add(existingPosition?.size || 0),
        collateral: nextCollateral,
        leverage,
      });
      return !orderError;
    });
    const inValidOrders = posOrders.filter((order) => {
      const orderError = getOrderError(account, order, undefined, {
        ...existingPosition,
        size: toUsdMax.add(existingPosition?.size || 0),
        collateral: nextCollateral,
        leverage,
      });
      return orderError;
    });
    return (
      <>
        {posOrders.length > 0 && <Divider className="medium" />}

        {posOrders.length > 0 && (
          <div className="linked-order-container">
            <div>
              <div className="linked-title">
                <span style={{ fontWeight: 700, color: "white", fontSize: "14px" }}>Linked Orders</span>
              </div>
              <div className="muted" style={{ fontSize: "14px", marginTop: "4px" }}>
                You have existing order(s)
              </div>
            </div>
            <div className="linked-order-content">
              {renderLinkedOrders(validOrders, false)}
              {renderLinkedOrders(inValidOrders, true)}
            </div>
          </div>
        )}
      </>
    );
  };
  return (
    <Wrapper className="Confirmation-box Modal PositionSeller-modal confirmation-modal details-position-modal">
      <Modal
        isVisible={true}
        setIsVisible={() => setIsConfirming(false)}
        label={"Increase Position"}
        allowContentTouchMove
      >
        <div className="content-container" style={{ paddingTop: "24px" }}>
          {!isSwap && renderMarginSection()}
          <MessageBox>
            {renderFeeWarning()}
            {renderSpreadWarning()}

            {/* {renderExistingOrderWarning()}
          {renderExistingTriggerErrors()}
          {renderExistingTriggerWarning()} */}
            {renderExistingOrders()}
          </MessageBox>
        </div>

        <div className="Exchange-swap-button-container">
          <button
            style={{ width: "100%" }}
            onClick={handleClickBtn}
            className={`App-cta  ${isLong || isSwap ? "long-btn-default" : "short-btn-default"}`}
            disabled={!isPrimaryEnabled()}
          >
            {getPrimaryText()}
          </button>
        </div>
      </Modal>
    </Wrapper>
  );
}

const StyledBox = styled.div`
  border-radius: 16px;
  background: var(--Nature-1, #12141a);
  display: flex;
  padding: 12px 16px;
  align-items: center;
  display: flex;
  margin: 16px 0;
  margin-top: 0;
  > div {
    width: 50%;
    display: flex;
    flex-direction: column;
    .Exchange-info-label {
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 140%;
    }
    .Exchange-info-amount {
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 140%;
      color: #fff;
    }
  }
`;

const DividerStyled = styled.div`
  background: var(--Border, rgba(55, 63, 92, 0.5));
  height: 1px;
  width: 100%;
  margin: 8px 0 12px 0;
`;
const MessageBox = styled.div`
  margin-top: 8px;
`;

const Wrapper = styled.div`
  .Exchange-info-row {
    margin-bottom: 0;
  }
  /* .content-container {
    @media screen and (max-width: 700px) {
      padding-left: 16px;
      padding-right: 16px;
    }
  } */
`;
