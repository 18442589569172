import { BigNumber, ethers } from "ethers";
import { useState, useEffect } from "react";

import { getContract } from "config/contracts";

import Multicall from "abis/Multicall.json";
import Token from "abis/Token.json";
import { getProvider } from "lib/rpc";
import multicall from "domain/multicall";
import { useWeb3React } from "@web3-react/core";
import { WHITELISTED_MICROSWAP_TOKENS, WHITELISTED_V2_TOKENS } from "config/tokens";
import { MICROSWAP_SUPPORTED_CHAIN_IDS, SONIC_TESTNET } from "config/chains";
import { MANUAL_TOKENS } from "config/localStorage";

const convertTokenToArray = (chainId) => {
  const { tokens } = WHITELISTED_MICROSWAP_TOKENS[chainId || SONIC_TESTNET];
  const result: any = [];
  for (const [key, value] of Object.entries(tokens)) {
    result.push({
      ...tokens[key],
      balance: BigNumber.from(0),
    });
  }
  return result;
};

export default function useTokenBalances(currentChainId: number) {
  const chainId = MICROSWAP_SUPPORTED_CHAIN_IDS.includes(currentChainId)
    ? currentChainId
    : MICROSWAP_SUPPORTED_CHAIN_IDS[0];

  const { account, library } = useWeb3React();
  const initTokens = convertTokenToArray(SONIC_TESTNET);
  const [data, setData] = useState(initTokens);
  const multicallAddress = getContract(chainId, "Multicall");

  useEffect(() => {
    const fetch = async () => {
      try {
        const provider = library || getProvider(undefined, chainId);
        if (!provider) return;
        const nativeBalance = await provider.getBalance(account);

        const multicallContract = new ethers.Contract(multicallAddress, Multicall.abi, provider);
        const { tokens: _tokens } = WHITELISTED_MICROSWAP_TOKENS[chainId || SONIC_TESTNET];
        const manualTokensStorage = JSON.parse(localStorage.getItem(MANUAL_TOKENS) || "[]");

        const tokens = manualTokensStorage.reduce((acc, cur) => {
          return {
            ...acc,
            [cur.address]: {
              ...cur,
            },
          };
        }, _tokens);

        const calls: any = [];
        for (const [key, value] of Object.entries(tokens)) {
          if (key !== "ether") {
            calls.push({
              address: key,
              name: "balanceOf",
              params: [account],
            });
          }
        }
        const response = await multicall(
          multicallContract,
          Token.abi,
          calls.map(({ address, name, params }) => ({
            address,
            name,
            params,
          }))
        );

        const result: any = [];
        let index = 0;
        for (const [key, value] of Object.entries(tokens)) {
          if (key !== "ether") {
            result.push({
              ...tokens[key],
              balance: response[index][0],
            });
            index++;
          } else {
            result.push({
              ...tokens[key],
              balance: nativeBalance,
            });
          }
        }
        setData(result);
      } catch (error) {
        console.error("[ERROR]: multicall", error);
      }
    };
    if (chainId && account) {
      fetch();
      const timerId = setInterval(fetch, 8000);
      return () => clearInterval(timerId);
    } else {
      const { tokens: _tokens } = WHITELISTED_MICROSWAP_TOKENS[chainId || SONIC_TESTNET];
      const manualTokensStorage = JSON.parse(localStorage.getItem(MANUAL_TOKENS) || "[]");

      const tokens = manualTokensStorage.reduce((acc, cur) => {
        return {
          ...acc,
          [cur.address]: {
            ...cur,
          },
        };
      }, _tokens);

      const result: any = [];
      for (const [key, value] of Object.entries(tokens)) {
        result.push({
          ...tokens[key],
          balance: BigNumber.from(0),
        });
      }
      setData(result);
    }
  }, [account, chainId, library, multicallAddress]);

  return data;
}
