import useTokenInfo from "hooks/vault/useTokenInfo";
import useTVL from "hooks/vault/useTVL";
import useVaultInfo from "hooks/vault/useVaultInfo";
import { createContext, useContext } from "react";

interface VaultType {
  tokenInfo: any;
  vaultInfo: any;
  tvlInfo: any;
}

const VaultContext = createContext<VaultType>({
  tokenInfo: undefined,
  vaultInfo: undefined,
  tvlInfo: undefined,
});

const VaultProvider = ({ children }) => {
  const tokenInfo = useTokenInfo();
  const vaultInfo = useVaultInfo();
  const tvlInfo = useTVL(vaultInfo, tokenInfo);

  return (
    <VaultContext.Provider
      value={{
        tokenInfo,
        vaultInfo,
        tvlInfo,
      }}
    >
      {children}
    </VaultContext.Provider>
  );
};

export const useVaultContext = () => {
  const value = useContext(VaultContext);

  if (value === undefined) {
    throw new Error("Vault Provider not found!");
  }

  return value;
};

export default VaultProvider;
