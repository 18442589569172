import { useWeb3React } from "@web3-react/core";
import { getContract } from "config/contracts";
import { Contract } from "ethers";
import { formatEther } from "ethers/lib/utils";
import { useChainIdWithDefault } from "lib/chains";
import { useEffect, useState } from "react";

import { getProvider } from "lib/rpc";

import Multicall from "abis/Multicall.json";
import NewVault from "abis/NewVault.json";
import { nativeBankDefinitions } from "config/vaults";
import multicall from "domain/multicall";

interface TVL {
  NAVI: {
    supply: number;
    staked: number;
    total: number;
  };
  NLP: {
    supply: number;
    staked: number;
    total: number;
  };
  total: number;
}

const useTVL = (vaultInfo: any, tokenInfo: any) => {
  const { account } = useWeb3React();
  const chainId = useChainIdWithDefault();
  const [tvl, setTvl] = useState<TVL>();

  const totalTvl = vaultInfo
    ? vaultInfo.reduce((acc, cur) => {
        return acc + cur.tvl;
      }, 0)
    : undefined;

  useEffect(() => {
    const provider = getProvider(undefined, chainId);
    const rewardReaderAddress = getContract(chainId, "RewardReader");
    const esNaviAddress = getContract(chainId, "ES_NAVI");
    const stakedNaviTrackerAddress = getContract(chainId, "StakedNaviTracker");

    const multicallAddress = getContract(chainId, "Multicall");

    const fetch = async () => {
      const multicallContract = new Contract(multicallAddress, Multicall.abi, provider);

      const calls: any[] = [];

      for (const nativeBank of Object.values(nativeBankDefinitions)) {
        calls.push(
          {
            address: rewardReaderAddress,
            name: "getDepositBalances",
            params: [nativeBank.strategyAddress, [esNaviAddress], [stakedNaviTrackerAddress]],
          },
          {
            address: nativeBank.vaultAddress,
            name: "balance",
            params: [],
          }
        );
      }

      const response = await multicall(
        multicallContract,
        [
          ...NewVault.abi,
          {
            inputs: [
              {
                internalType: "address",
                name: "_account",
                type: "address",
              },
              {
                internalType: "address[]",
                name: "_depositTokens",
                type: "address[]",
              },
              {
                internalType: "address[]",
                name: "_rewardTrackers",
                type: "address[]",
              },
            ],
            name: "getDepositBalances",
            outputs: [
              {
                internalType: "uint256[]",
                name: "",
                type: "uint256[]",
              },
            ],
            stateMutability: "view",
            type: "function",
          },
        ],
        calls
      );

      const length = calls.length / Object.keys(nativeBankDefinitions).length;

      const result = {};

      Object.keys(nativeBankDefinitions).forEach((key, index) => {
        const depositBalances = response[index * length][0];
        const vaultSupply = response[index * length + 1][0];

        const tokenPrice = tokenInfo?.[key]?.price || 1;
        const totalSupplyUsd = parseFloat(formatEther(vaultSupply)) * tokenPrice;
        const tokenInStakedUsd = parseFloat(formatEther(depositBalances[0])) * tokenPrice;
        const tvl = totalSupplyUsd + tokenInStakedUsd;

        result[key.toLowerCase()] = {
          supply: totalSupplyUsd,
          staked: tokenInStakedUsd,
          total: tvl,
        };
      });

      return result;
    };

    const compute = async () => {
      try {
        if (!tokenInfo) return;

        const { navi, nlp } = (await fetch()) as any;

        setTvl({
          NAVI: navi,
          NLP: nlp,
          total: navi.total + nlp.total + totalTvl,
        });
      } catch (error) {
        console.error(error);
      }
    };

    compute();

    const timer = setInterval(compute, 30_000);

    return () => clearInterval(timer);
  }, [account, tokenInfo, totalTvl, chainId]);

  return tvl;
};

export default useTVL;
