import { ARBITRUM, AVALANCHE, BASE, FANTOM, OP } from "config/chains";
import { getContract } from "config/contracts";

const FANTOM_NAVI = getContract(FANTOM, "NAVI");
const OP_NAVI = getContract(OP, "NAVI");

const ARBITRUM_NAVI = getContract(ARBITRUM, "NAVI");
const AVALANCHE_NAVI = getContract(AVALANCHE, "NAVI");

export const vaultNaviUrl = "http://vaults.navigator.exchange";

type Exchange = {
  name: string;
  icon: string;
  iconLight?: string;
  networks: number[];
  link?: string;
  links?: { [ARBITRUM]: string; [AVALANCHE]: string; [FANTOM]: string; [OP]: string };
};

export const EXTERNAL_LINKS = {
  [OP]: {
    bungee: `https://multitx.bungee.exchange/?toChainId=43114&toTokenAddress=${OP_NAVI}`,
    banxa: "https://navi.banxa.com/?coinType=AVAX&fiatType=USD&fiatAmount=500&blockchain=avalanche",
    networkWebsite: "https://www.avax.network/",
    buyNavi: {
      velodrome: `https://app.velodrome.finance/swap`,
      uniswap: `https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=${OP_NAVI}`,
      equalizer: "https://equalizer.exchange/swap",
      banxa: "https://navi.banxa.com/?coinType=NAVI&fiatType=USD&fiatAmount=500&blockchain=avalanche",
      traderjoe: `https://traderjoexyz.com/trade?outputCurrency=${OP_NAVI}`,
      spooky: `https://spooky.fi/#/swap?inputCurrency=0x04068DA6C83AFCFA0e13ba15A6696662335D5B75&outputCurrency=0x01e77288b38b416F972428d562454fb329350bAc`,
    },
  },
  [FANTOM]: {
    bungee: `https://multitx.bungee.exchange/?toChainId=43114&toTokenAddress=${OP_NAVI}`,
    banxa: "https://navi.banxa.com/?coinType=AVAX&fiatType=USD&fiatAmount=500&blockchain=avalanche",
    networkWebsite: "https://www.avax.network/",
    buyNavi: {
      equalizer: "https://equalizer.exchange/swap",
      banxa: "https://navi.banxa.com/?coinType=NAVI&fiatType=USD&fiatAmount=500&blockchain=avalanche",
      traderjoe: `https://traderjoexyz.com/trade?outputCurrency=${FANTOM_NAVI}`,
      skeleton: `https://www.skeleton.finance/swap?outputCurrency=0x01e77288b38b416F972428d562454fb329350bAc`,
      microswap: `https://app.microswap.org/swap?outputCurrency=0x01e77288b38b416F972428d562454fb329350bAc`,
      spooky: `https://spooky.fi/#/swap?inputCurrency=0x04068DA6C83AFCFA0e13ba15A6696662335D5B75&outputCurrency=0x01e77288b38b416F972428d562454fb329350bAc`,
    },
  },
  [ARBITRUM]: {
    bungee: `https://multitx.bungee.exchange/?toChainId=42161&toTokenAddress=${ARBITRUM_NAVI}`,
    banxa: "https://navi.banxa.com/?coinType=ETH&fiatType=USD&fiatAmount=500&blockchain=arbitrum",
    o3: "https://o3swap.com/",
    networkWebsite: "https://arbitrum.io/",
    buyNavi: {
      banxa: "https://navi.banxa.com/?coinType=NAVI&fiatType=USD&fiatAmount=500&blockchain=arbitrum",
      uniswap: `https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=${ARBITRUM_NAVI}`,
    },
  },
  [AVALANCHE]: {
    bungee: `https://multitx.bungee.exchange/?toChainId=43114&toTokenAddress=${AVALANCHE_NAVI}`,
    banxa: "https://navi.banxa.com/?coinType=AVAX&fiatType=USD&fiatAmount=500&blockchain=avalanche",
    networkWebsite: "https://www.avax.network/",
    buyNavi: {
      banxa: "https://navi.banxa.com/?coinType=NAVI&fiatType=USD&fiatAmount=500&blockchain=avalanche",
      traderjoe: `https://traderjoexyz.com/trade?outputCurrency=${AVALANCHE_NAVI}`,
    },
  },
};

export const TRANSFER_EXCHANGES: Exchange[] = [
  {
    name: "O3 Swap",
    icon: "ic_o3.png",
    networks: [FANTOM],
    link: "https://o3swap.com/swap?dst_chain=250&dst_token_hash=0x01e77288b38b416F972428d562454fb329350bAc",
  },
  {
    name: "OpenOcean",
    icon: "ic_openocean.svg",
    iconLight: "ic_openoceanLight.svg",
    link: "https://app.openocean.finance/cross",
    networks: [FANTOM, OP],
  },
  {
    name: "Binance",
    icon: "ic_binance.svg",
    networks: [ARBITRUM, AVALANCHE],
    link: "https://www.binance.com/en/trade/",
  },
  {
    name: "Synapse",
    icon: "ic_synapse.svg",
    networks: [ARBITRUM, AVALANCHE],
    link: "https://synapseprotocol.com/",
  },
  {
    name: "Arbitrum",
    icon: "ic_arbitrum_24.svg",
    networks: [ARBITRUM],
    link: "https://bridge.arbitrum.io/",
  },
  {
    name: "Avalanche",
    icon: "ic_avax_30.svg",
    networks: [AVALANCHE],
    link: "https://bridge.avax.network/",
  },
  {
    name: "Hop",
    icon: "ic_hop.svg",
    networks: [ARBITRUM],
    link: "https://app.hop.exchange/send?token=ETH&sourceNetwork=ethereum&destNetwork=arbitrum",
  },
  {
    name: "Bungee",
    icon: "ic_bungee.png",
    networks: [ARBITRUM, AVALANCHE],
    link: "https://multitx.bungee.exchange",
  },
  {
    name: "Multiswap",
    icon: "ic_multiswap.svg",
    networks: [ARBITRUM, AVALANCHE],
    link: "https://app.multichain.org/#/router",
  },
  {
    name: "Across",
    icon: "ic_across.svg",
    networks: [ARBITRUM],
    link: "https://across.to/",
  },
];

export const CENTRALISED_EXCHANGES: Exchange[] = [
  {
    name: "MEXC Global",
    icon: "ic_mexc.png",
    link: "https://www.mexc.com/exchange/NAVI_USDT",
    networks: [ARBITRUM, FANTOM, OP, BASE],
  },
  // {
  //   name: "Binance",
  //   icon: "ic_binance.svg",
  //   link: "https://www.binance.com/en/trade/NAVI_USDT?_from=markets",
  //   networks: [ARBITRUM, AVALANCHE],
  // },
  // {
  //   name: "Bybit",
  //   icon: "ic_bybit.svg",
  //   link: "https://www.bybit.com/en-US/trade/spot/NAVI/USDT",
  //   networks: [ARBITRUM, AVALANCHE],
  // },
  // {
  //   name: "Kucoin",
  //   icon: "ic_kucoin.svg",
  //   link: "https://www.kucoin.com/trade/NAVI-USDT",
  //   networks: [ARBITRUM, AVALANCHE],
  // },
  // {
  //   name: "Huobi",
  //   icon: "ic_huobi.svg",
  //   link: "https://www.huobi.com/en-us/exchange/navi_usdt/",
  //   networks: [ARBITRUM, AVALANCHE],
  // },
];

export const DECENTRALISED_AGGRIGATORS: Exchange[] = [
  {
    name: "1inch",
    icon: "ic_1inch.svg",
    link: "https://app.1inch.io/#/250/simple/swap/USDC/NAVI",
    networks: [FANTOM],
  },
  // {
  //   name: "1inch",
  //   icon: "ic_1inch.svg",
  //   link: "https://app.1inch.io/#/10/simple/swap/USDC/NAVI",
  //   networks: [OP],
  // },

  // {
  //   name: "Equalizer",
  //   icon: "EQUAL.png",
  //   link: "https://equalizer.exchange/swap",
  //   networks: [FANTOM],
  // },

  // {
  //   name: "Firebird",
  //   icon: "ic_firebird.png",
  //   link: "https://app.firebird.finance/swap?inputCurrency=ETH&outputCurrency=0x385719545Ef34d457A88e723504544A53F0Ad9BC",
  //   networks: [OP],
  // },
  {
    name: "Firebird",
    icon: "ic_firebird.png",
    link: "https://app.firebird.finance/swap?inputCurrency=ETH&outputCurrency=0x01e77288b38b416F972428d562454fb329350bAc",
    networks: [FANTOM],
  },
  {
    name: "OpenOcean",
    icon: "ic_openocean.svg",
    iconLight: "ic_openoceanLight.svg",
    link: "https://app.openocean.finance/CLASSIC#/FANTOM/USDC/NAVI",
    networks: [FANTOM],
  },
  // {
  //   name: "OpenOcean",
  //   icon: "ic_openocean.svg",
  //   link: "https://app.openocean.finance/CLASSIC#/OPTIMISM/ETH/NAVI",
  //   networks: [OP],
  // },

  {
    name: "KyberSwap",
    icon: "ic_kyber.svg",
    link: "https://kyberswap.com/swap/fantom/usdc-to-navi",
    networks: [FANTOM],
  },

  // {
  //   name: "KyberSwap",
  //   icon: "ic_kyber.svg",
  //   link: "https://kyberswap.com/swap/optimism/eth-to-navi",
  //   networks: [OP],
  // },

  // {
  //   name: "Odos",
  //   icon: "ic_odos.png",
  //   link: "https://app.odos.xyz/",
  //   networks: [ARBITRUM],
  // },
  // {
  //   name: "Slingshot",
  //   icon: "ic_slingshot.svg",
  //   link: "https://app.slingshot.finance/swap/ETH",
  //   networks: [ARBITRUM],
  // },
  {
    name: "Yieldyak",
    icon: "ic_yield_yak.png",
    link: "https://yieldyak.com/swap",
    networks: [AVALANCHE],
  },
];
export const MAX_LERVERAGES = {
  [FANTOM]: {
    BTC: 100,
    ETH: 100,
    axlBTC: 100,
    axlETH: 100,
    FTM: 100,
    WFTM: 100,
    BNB: 100,
    LINK: 100,
  },
  [OP]: {
    BTC: 100,
    ETH: 100,
    OP: 100,
    SNX: 100,
    LINK: 100,
  },
  [ARBITRUM]: {
    BTC: 100,
    ETH: 100,
    SNX: 100,
    UNI: 100,
    LINK: 100,
    RDNT: 100,
    ARB: 100,
    NAVI: 100,
  },
  [BASE]: {
    tBTC: 100,
    cbETH: 100,
    ETH: 100,
    UNI: 100,
    LINK: 100,
    RDNT: 100,
    ARB: 100,
    NAVI: 100,
  },
};
