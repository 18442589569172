import { useMemo } from "react";
import { getContract } from "config/contracts";
import usePoolTokenBalance from "hooks/usePoolTokenBalance";
import { BigNumber } from "ethers";
import { formatAmount, parseValue } from "lib/numbers";
import { getTokenInfo, useInfoTokens } from "domain/tokens";
import { useWeb3React } from "@web3-react/core";
import useSWR from "swr";
import { importImage, PLACEHOLDER_ACCOUNT } from "lib/legacy";
import { DEFAULT_FROM_V2_TOKEN, getTokens } from "config/tokens";
import ReaderV2 from "abis/ReaderV2.json";
import styled from "styled-components";
import { contractFetcher } from "lib/contracts";
import { DataTable, TableRow } from "components/Table";
import AssetDropdown from "pages/Dashboard/AssetDropdown";
import { useChainIdWithDefault } from "lib/chains";
import { SUPPORTED_CHAIN_ID_NSLP } from "config/chains";
import VaultNavi from "abis/VaultNavi.json";

const PoolCompositionNslp = () => {
  const { account, active, library } = useWeb3React();
  const chainId = useChainIdWithDefault({
    chains: SUPPORTED_CHAIN_ID_NSLP,
    isUseDefaultChain: false,
  });

  const vaultNaviAddress = getContract(chainId, "VaultNslp");
  const readerAddress = getContract(chainId, "Reader");

  const tokens = getTokens(chainId);
  const tokenAddresses = tokens.map((token) => token.address);

  const { data: tokenBalances } = useSWR(
    [`NslpSwap:getTokenBalances:${active}`, chainId, readerAddress, "getTokenBalances", account || PLACEHOLDER_ACCOUNT],
    {
      fetcher: contractFetcher(library, ReaderV2, [tokenAddresses]),
      refreshInterval: 5000,
    }
  );
  // const { data: vaultUSDBalance } = useSWR(
  //   [`NslpSwap:getVaultUSDBalance:${active}`, chainId, vaultNaviAddress, "getVaultUSDBalance"],
  //   {
  //     fetcher: contractFetcher(library, VaultNavi),
  //     refreshInterval: 5000,
  //   }
  // );

  const poolTokenBalances = usePoolTokenBalance(chainId, vaultNaviAddress);
  const { infoTokens } = useInfoTokens(library, chainId, active, tokenBalances, undefined);
  // const curUSDCInfo = getTokenInfo(infoTokens, "0x29219dd400f2Bf60E5a23d13Be72B486D4038894");
  // const amountUSDCBalance = curUSDCInfo?.minPrice && curUSDCInfo?.maxPrice && vaultUSDBalance
  //             ? vaultUSDBalance.mul(parseValue(1, 6)).div(curUSDCInfo?.minPrice.add(curUSDCInfo?.maxPrice).div(2) || parseValue(1, 30))
  //             : vaultUSDBalance;
  // const amountscUSDBalance = vaultUSDBalance
  //             ? vaultUSDBalance.mul(parseValue(1, 6)).div(curUSDCInfo?.minPrice.add(curUSDCInfo?.maxPrice).div(2) || parseValue(1, 30))
  //             : vaultUSDBalance;
  const mapPoolComposition = useMemo(() => {
    //   const poolTokenBalances = [
    //     {
    //         "name": "Bridged USDC",
    //         "symbol": "USDC.e",
    //         "decimals": 6,
    //         "address": "0x29219dd400f2Bf60E5a23d13Be72B486D4038894",
    //         "isStable": true,
    //         "imageUrl": "https://optimistic.etherscan.io/token/images/usdc_32.png",
    //         "balance": amountUSDCBalance,
    //     },
    //     {
    //       "name": "Sonic USD",
    //       "symbol": "scUSD",
    //       "decimals": 6,
    //       "address": "0xd3DCe716f3eF535C5Ff8d041c1A41C3bd89b97aE",
    //       "isStable": true,
    //       "imageUrl": "https://optimistic.etherscan.io/token/images/usdc_32.png",
    //       "balance": amountscUSDBalance,
    //   }
    // ]
    const origin = [...poolTokenBalances];
    if (poolTokenBalances && poolTokenBalances.length > 0) {
      let total = BigNumber.from(0);
      for (let i = 0; i < poolTokenBalances.length; i++) {
        if (poolTokenBalances[i].balance && poolTokenBalances[i].decimals) {
          total = total.add(
            poolTokenBalances[i].balance.div(
              poolTokenBalances[i].decimals !== 6 ? parseValue(1, poolTokenBalances[i].decimals - 6) : 1
            )
          );
        }
      }
      for (let i = 0; i < origin.length; i++) {
        const curTokenInfo =
          origin[i].address === DEFAULT_FROM_V2_TOKEN[chainId][0]
            ? getTokenInfo(infoTokens, origin[i].address)
            : {
                minPrice: parseValue(1, 30),
                maxPrice: parseValue(1, 30),
              };
        const amountBalance =
          poolTokenBalances[i]?.balance &&
          poolTokenBalances[i]?.balance.div(
            poolTokenBalances[i].decimals !== 6 ? parseValue(1, poolTokenBalances[i].decimals - 6) : 1
          );
        origin[i] = {
          ...poolTokenBalances[i],
          amountPool: amountBalance,
          amountPoolUSD:
            curTokenInfo?.minPrice && curTokenInfo?.maxPrice && amountBalance
              ? amountBalance.mul(curTokenInfo?.minPrice.add(curTokenInfo?.maxPrice).div(2) || parseValue(1, 30))
              : amountBalance.mul(parseValue(1, 30)),
          percentPool: total.gt(0) ? amountBalance.mul(100).mul(parseValue(1, 6)).div(total) : 0,
        };
      }
    }
    return origin;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [poolTokenBalances]);
  return (
    <Wrapper className="Pool-Paper Pool-Composition">
      <h3>Pool Composition</h3>
      <DataTable $noIndex $rowHeightSkeletonMobile="146.38px">
        <div className="head">
          <div className="col-name">Token</div>
          <div className="col-name">Amount</div>
          <div className="col-name">Value</div>
          <div className="col-name">Weight</div>
        </div>
        <div className="body">
          {mapPoolComposition.map((token, index) => {
            const tokenImage = importImage("ic_" + token.symbol.toLowerCase() + "_40.svg");

            return (
              <TableRow key={index} noScaleOnHover>
                <div d-label="Token" className="col-body">
                  <div className="token-symbol-wrapper">
                    <div className="App-card-title-info-icon">
                      <img src={tokenImage} alt="USDC.e" width="40px" />
                    </div>
                    <div className="App-card-title-info-text">
                      <div className="App-card-info-subtitle">
                        {token.symbol}{" "}
                        <div className="dropdown-clickable">
                          <AssetDropdown assetSymbol={token.symbol} assetInfo={token} />
                        </div>
                      </div>
                      <div className="App-card-info-title">{token.name}</div>
                    </div>
                  </div>
                </div>
                <div d-label="Amount" className="col-body">
                  {token?.amountPool ? formatAmount(token?.amountPool, 6, 2, true) : "--"}
                </div>
                <div d-label="Value" className="col-body">
                  ${token?.amountPool ? formatAmount(token?.amountPoolUSD, 36, 2, true) : "--"}
                </div>
                <div d-label="Weight" className="col-body">
                  {token?.percentPool ? formatAmount(token?.percentPool, 6, 2, true) : "--"}%
                </div>
              </TableRow>
            );
          })}
        </div>
      </DataTable>
    </Wrapper>
  );
};
const Wrapper = styled.div`
  @media screen and (max-width: 700px) {
    .body {
      gap: 12px;
      .body-row {
        border-radius: 16px;
      }
    }
  }
`;
export default PoolCompositionNslp;
