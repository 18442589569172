import { getServerUrl } from "config/backend";
import { switchNetwork } from "lib/wallets";
import { useCallback } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import useSWR from "swr";

import { DEFAULT_CHAIN_ID, SONIC_TESTNET } from "config/chains";
import { getWhitelistedTokens } from "config/tokens";
import { useNaviPrice, useTotalNaviStaked, useTotalNaviSupplyAll } from "domain/legacy";
import { useInfoTokens } from "domain/tokens";
import { BASIS_POINTS_DIVISOR, NAVI_DECIMALS, NLP_DECIMALS, PLACEHOLDER_ACCOUNT, USD_DECIMALS } from "lib/legacy";
import { bigNumberify, expandDecimals, formatAmount, formatKeyAmount, formatNumber, parseValue } from "lib/numbers";

import Tooltip from "components/Tooltip/Tooltip";
import { useThemeContext } from "contexts/ThemeProvider";
import useNslpContract from "hooks/contracts/useNslpContract";
import useInfoNslp from "hooks/useInfoNslp";
import useNslpPriceMultiChain from "hooks/useNslpPriceMultiChain";
import useProcessedData from "hooks/useProcessedData";
import navi40Icon from "img/dashboard/img1.svg";
import nlp40Icon from "img/dashboard/img2.svg";
import nslp40Icon from "img/dashboard/img3.svg";
import icToken from "img/ic_s_24.svg";
import icSonic from "img/icons/icon-sonic.svg";
import { caculateAPYHourly2 } from "lib/helper";

const Tokens = ({ chainId, active, library, apy }) => {
  // const processedData = useAprContext();

  const processedData = useProcessedData(chainId);

  const { lightThemeClassName, isLightTheme } = useThemeContext();

  const whitelistedTokens = getWhitelistedTokens(chainId);
  const tokenList = whitelistedTokens.filter((t) => !t.isWrapped);

  const { data: nlpInfo } = useSWR(getServerUrl(chainId, "/nlp-info"), {
    refreshInterval: 30000,
  });
  const { data: nlpInfoSONIC } = useSWR(getServerUrl(SONIC_TESTNET, "/nlp-info"), {
    refreshInterval: 30000,
  });

  let { sonic: totalNaviSupplySONIC, total: totalNaviSupply } = useTotalNaviSupplyAll();
  const { infoTokens } = useInfoTokens(library, chainId, active, undefined, undefined);
  const { naviPriceFromSONIC, naviPriceFromOP, naviPriceFromArbitrum, naviPriceFromBase, naviPrice } = useNaviPrice(
    chainId,
    undefined,
    active
  );

  let { sonic: sonicStakedNavi } = useTotalNaviStaked();

  let sonicStakedNaviUsd;
  if (sonicStakedNavi && naviPriceFromArbitrum) {
    sonicStakedNaviUsd = sonicStakedNavi.mul(naviPriceFromArbitrum).div(expandDecimals(1, NAVI_DECIMALS));
  }

  let stakedNaviSupplyUsd;
  if (naviPriceFromSONIC && sonicStakedNavi) {
    stakedNaviSupplyUsd = sonicStakedNavi.mul(naviPriceFromSONIC).div(expandDecimals(1, NAVI_DECIMALS));
  }

  let nlpPrice;
  let nlpSupply;
  let nlpMarketCap;
  // let nlpPriceFantomLegacy;
  let nlpPriceSONIC;
  let nlpSupplySONIC;
  // let nlpMarketCapFantomLegacy;
  let nlpMarketCapSONIC;
  let totalNlpSupply;
  if (nlpInfo) {
    nlpSupply = bigNumberify(nlpInfo?.totalSupply || "0");

    nlpPrice = bigNumberify(nlpInfo?.price || "0");
    nlpMarketCap = nlpPrice.mul(nlpSupply).div(expandDecimals(1, NLP_DECIMALS));
  }
  if (nlpInfoSONIC) {
    nlpSupplySONIC = bigNumberify(nlpInfoSONIC?.totalSupply || "0");

    nlpPriceSONIC = bigNumberify(nlpInfoSONIC?.price || "0");
    nlpMarketCapSONIC = nlpPriceSONIC.mul(nlpSupplySONIC).div(expandDecimals(1, NLP_DECIMALS));
  }

  if (nlpSupplySONIC) {
    totalNlpSupply = nlpSupplySONIC;
  }
  if (nlpMarketCapSONIC) {
    nlpMarketCap = nlpMarketCapSONIC;
    // .add(nlpMarketCapSONICLegacy)
    // .add(nlpMarketCapBase);
  }
  let adjustedUsdgSupply = bigNumberify(0);

  for (let i = 0; i < tokenList.length; i++) {
    const token = tokenList[i];
    const tokenInfo = infoTokens[token.address];
    if (tokenInfo && tokenInfo.usdgAmount) {
      adjustedUsdgSupply = adjustedUsdgSupply.add(tokenInfo.usdgAmount);
    }
  }

  let stableNlp = 0;
  let totalNlp = 0;

  tokenList.forEach((token) => {
    const tokenInfo = infoTokens[token.address];
    if (tokenInfo.usdgAmount && adjustedUsdgSupply && adjustedUsdgSupply.gt(0)) {
      const currentWeightBps = tokenInfo.usdgAmount.mul(BASIS_POINTS_DIVISOR).div(adjustedUsdgSupply);
      if (tokenInfo.isStable) {
        stableNlp += parseFloat(`${formatAmount(currentWeightBps, 2, 2, false)}`);
      }
      totalNlp += parseFloat(`${formatAmount(currentWeightBps, 2, 2, false)}`);
    }
  });

  let stablePercentage = totalNlp > 0 ? ((stableNlp * 100) / totalNlp).toFixed(2) : "0.0";

  const changeNetwork = useCallback(
    (network) => {
      if (network === chainId) {
        return;
      }
      if (!active) {
        setTimeout(() => {
          return switchNetwork(network, active);
        }, 500);
      } else {
        return switchNetwork(network, active);
      }
    },
    [chainId, active]
  );

  const BuyLink = ({ className, to, children, network }) => {
    return (
      <Link to={to} className={className} onClick={() => changeNetwork(network)}>
        {children}
      </Link>
    );
  };

  const nslpPrices = useNslpPriceMultiChain();
  const nslpPrice = nslpPrices[chainId] || nslpPrices[DEFAULT_CHAIN_ID];

  const { totalSupply: totalSupplyNslpSONIC, decimals: nslpDecimalSONIC } = useNslpContract(
    SONIC_TESTNET,
    active,
    PLACEHOLDER_ACCOUNT
  );

  // const { totalSupply: totalSupplyNslpOp, decimals: nslpDecimalOp } = useNslpContract(OP, active, PLACEHOLDER_ACCOUNT);
  // const totalSupplyNslp = totalSupplyNslpSONIC && totalSupplyNslpOp ? totalSupplyNslpSONIC.add(totalSupplyNslpOp) : undefined;
  const { totalSupply: totalSupplyNslp } = useInfoNslp(chainId);

  // const {
  //   nlpPoolInfo: [totalStakedSONIC],
  //   poolRewardsPerSec: poolRewardsPerSecSONIC,
  // } = useNAVIFarmContractMulticall(SONIC_TESTNET, active, PLACEHOLDER_ACCOUNT, library);

  // const {
  //   nlpPoolInfo: [totalStakedOp],
  //   poolRewardsPerSec: poolRewardsPerSecOp,
  // } = useNAVIFarmContractMulticall(OP, active, PLACEHOLDER_ACCOUNT, library);

  // const totalStaked = {
  //   [SONIC_TESTNET]: useUsdPrice(totalStakedSONIC, nslpPrice, 30),
  //   // [OP]: useUsdPrice(totalStakedOp, nslpPrice, 30),
  // };
  // const totalStakedAll = totalStaked[SONIC_TESTNET] ? totalStaked[SONIC_TESTNET] : undefined;

  // const rewardsPriceSONIC = useTokensPrice({
  //   naviPrice: naviPriceFromSONIC,
  //   SONIC_TESTNET,
  // });

  // const aprSONIC = useNslpApr({
  //   nslpPrice: nslpPrices[SONIC_TESTNET],
  //   rewardsPrice: rewardsPriceSONIC,
  //   totalStaked: totalStakedSONIC,
  //   poolRewardsPerSec: poolRewardsPerSecSONIC,
  // });

  //NAVI
  const naviAprSONIC = parseFloat(formatAmount(processedData[SONIC_TESTNET]?.naviAprTotal, 2)) / 100;
  const naviApySONIC = caculateAPYHourly2(naviAprSONIC) * 100;

  //NLP
  const nlpAprSONIC = parseFloat(formatAmount(processedData[SONIC_TESTNET]?.nlpAprTotal, 2)) / 100;
  const nlpApySONIC = caculateAPYHourly2(nlpAprSONIC) * 100;

  let { total: totalNaviStaked } = useTotalNaviStaked();

  const totalNaviStake = naviPrice ? naviPrice.mul(totalNaviStaked || 0).div(parseValue(1, USD_DECIMALS)) : 0;
  return (
    <Wrapper className={lightThemeClassName}>
      <Title>
        <span>Earns</span>
      </Title>
      <TokenCardContainer>
        <TokenCard>
          <CardTitle>
            <TokenImg>
              <img src={navi40Icon} alt="NAVI Token Icon" />
            </TokenImg>
            <TokenInfo>
              <div>
                <div className="token-symbol">Crypto Pool - NLP</div>
                <div className="token-desc">Crypto index pool (S, WS, ETH, USDC.e...)</div>
              </div>
            </TokenInfo>
          </CardTitle>
          <TokenAPR>
            <div className="apr-wrap">
              <img src={icToken} alt="token-icon" />

              <div className="apr-wrap">
                {" "}
                <div className="label"> APR </div>
                {processedData?.nlpAprTotal ? (
                  <span>
                  {formatKeyAmount(processedData, "nlpAprTotal", 2, 2, true)}%{" "}
                  {/* <img style={{ position: "relative", top: "-1px" }} src={icSonic} alt="sonic" /> */}
                </span>
                  // <Tooltip
                  //   position={`${"center-bottom"}`}
                  //   className="tooltip-apr"
                  //   handle={
                  //     <span>
                  //       {formatKeyAmount(processedData, "nlpAprTotal", 2, 2, true)}%{" "}
                  //       <img style={{ position: "relative", top: "-1px" }} src={icSonic} alt="sonic" />
                  //     </span>
                  //   }
                  //   renderContent={() => {
                  //     return (
                  //       <TooltipWrap>
                  //         <div>
                  //           <span className="muted">You Get Additional:</span>
                  //           <b>
                  //             <img src={icSonic} alt="sonic" /> 5x Sonic <span className="muted">Points</span>{" "}
                  //           </b>
                  //         </div>
                  //         <a
                  //           href="https://blog.soniclabs.com/sonic-points-simplified-how-to-qualify-for-200-million-s-airdrop/"
                  //           target="_blank"
                  //           rel="noreferrer"
                  //         >
                  //           Sonic Details{" "}
                  //           <svg
                  //             width="20"
                  //             height="20"
                  //             viewBox="0 0 20 20"
                  //             fill="none"
                  //             xmlns="http://www.w3.org/2000/svg"
                  //           >
                  //             <path
                  //               fill-rule="evenodd"
                  //               clip-rule="evenodd"
                  //               d="M8.33333 2.5C7.8731 2.5 7.5 2.8731 7.5 3.33333C7.5 3.79357 7.8731 4.16667 8.33333 4.16667H14.6548L2.74408 16.0774C2.41864 16.4028 2.41864 16.9305 2.74408 17.2559C3.06951 17.5814 3.59715 17.5814 3.92259 17.2559L15.8333 5.34518V10.8333C15.8333 11.2936 16.2064 11.6667 16.6667 11.6667C17.1269 11.6667 17.5 11.2936 17.5 10.8333V2.5H8.33333Z"
                  //               fill="#90FB75"
                  //             />
                  //           </svg>
                  //         </a>
                  //       </TooltipWrap>
                  //     );
                  //   }}
                  // />
                ) : (
                  <div className="skeleton-box" style={{ width: "80px", height: "19.6px" }} />
                )}
              </div>
            </div>{" "}
            {/* <div className="apr-wrap">
              <img src={icToken} alt="token-icon" />

              <div className="apr-wrap">
                {" "}
                <div className="label"> APR </div>
                {processedData?.naviAprTotalWithBoost ? (
                  <span>{formatKeyAmount(processedData, "naviAprTotalWithBoost", 2, 2, true)}%</span>
                ) : (
                  <div className="skeleton-box" style={{ width: "60px" }} />
                )}
              </div>
            </div> */}
          </TokenAPR>{" "}
          <Cards>
            <TokenCardInfo>
              <div className="label-card">
                <span>Price</span>
              </div>
              <div className={`value`}>{"$" + formatAmount(nlpPrice || 0, USD_DECIMALS, 2, true)}</div>
            </TokenCardInfo>
            <TokenCardInfo>
              <div className="label-card">
                <span>Total Supply</span>
              </div>
              <div className={`value`}>
                {processedData?.nlpSupply && processedData?.nlpSupplyUsd ? (
                  <>
                    {formatAmount(processedData?.nlpSupply, NLP_DECIMALS, 0, true)} NLP
                    <div className="value-dollar">
                      ${formatNumber(formatKeyAmount(processedData, "nlpSupplyUsd", USD_DECIMALS, 2), 2)}
                    </div>
                  </>
                ) : (
                  <div
                    className="skeleton-box"
                    style={{
                      width: 48,
                    }}
                  />
                )}
              </div>
            </TokenCardInfo>
            <TokenCardInfo>
              <div className="label-card">
                <span>Total Staked</span>
              </div>
              <div className="value">
                {processedData?.nlpSupply && processedData?.nlpSupplyUsd ? (
                  <>
                    {formatKeyAmount(processedData, "nlpSupply", 18, 0, true)} NLP
                    <div className="value-dollar">
                      ${formatNumber(formatKeyAmount(processedData, "nlpSupplyUsd", USD_DECIMALS, 2), 2)}
                    </div>
                  </>
                ) : (
                  <div
                    className="skeleton-box"
                    style={{
                      width: 48,
                    }}
                  />
                )}
              </div>
            </TokenCardInfo>
            <TokenCardInfo>
              <div className="label-card">
                <span>Your Staked</span>
              </div>
              <div className="value">
                {processedData.nlpBalance ? (
                  <>
                    {formatKeyAmount(processedData, "nlpBalance", NLP_DECIMALS, 2, true)} NLP
                    <div className="value-dollar">
                      ${formatNumber(formatKeyAmount(processedData, "nlpBalanceUsd", USD_DECIMALS, 2))}
                    </div>
                  </>
                ) : (
                  <>
                    <div className="skeleton-box" style={{ width: "65px", height: "20px" }} />
                    <span className="skeleton-box" style={{ width: "65px", height: "20px" }} />
                  </>
                )}
              </div>
            </TokenCardInfo>
          </Cards>
          <BuyLink to="/earn/nlp" className="btn-token default-btn" network={chainId}>
            Deposit
          </BuyLink>
        </TokenCard>
        <TokenCard>
          <CardTitle>
            <TokenImg>
              <img src={nlp40Icon} alt="nlp40Icon" />
            </TokenImg>
            <TokenInfo>
              <div>
                <div className="token-symbol">
                  Stable Pool - NSLP{" "}
                  {/* <ExternalLink href="/#">
                    <ArrowIcon isLightTheme={isLightTheme} />
                  </ExternalLink> */}
                </div>
                <div className="token-desc">Stable index pool (USDC.e, scUSD, USDT)</div>
              </div>{" "}
            </TokenInfo>
          </CardTitle>

          <TokenAPR>
            <div>
              <img src={icToken} alt="token-icon" />

              <div className="apr-wrap">
                {" "}
                <div className="label"> APR</div>{" "}
                {/* {processedData?.nslpAprTotal ? (
                  <span>{formatKeyAmount(processedData, "nslpAprTotal", 2, 2, true)}%</span>
                ) : (
                  <div className="skeleton-box" style={{ width: "60px" }} />
                )} */}
                {processedData?.nslpAprTotal ? (
                   <span>
                   {formatKeyAmount(processedData, "nslpAprTotal", 2, 2, true)}%{" "}
                   {/* <img style={{ position: "relative", top: "-1px" }} src={icSonic} alt="sonic" /> */}
                 </span>
                  // <Tooltip
                  //   position={`${"center-bottom"}`}
                  //   className="tooltip-apr"
                  //   handle={
                  //     <span>
                  //       {formatKeyAmount(processedData, "nslpAprTotal", 2, 2, true)}%{" "}
                  //       <img style={{ position: "relative", top: "-1px" }} src={icSonic} alt="sonic" />
                  //     </span>
                  //   }
                  //   renderContent={() => {
                  //     return (
                  //       <TooltipWrap>
                  //         <div>
                  //           <span className="muted">You Get Additional:</span>
                  //           <b>
                  //             <img src={icSonic} alt="sonic" /> 5x Sonic <span className="muted">Points</span>{" "}
                  //           </b>
                  //         </div>
                  //         <a
                  //           href="https://blog.soniclabs.com/sonic-points-simplified-how-to-qualify-for-200-million-s-airdrop/"
                  //           target="_blank"
                  //           rel="noreferrer"
                  //         >
                  //           Sonic Details{" "}
                  //           <svg
                  //             width="20"
                  //             height="20"
                  //             viewBox="0 0 20 20"
                  //             fill="none"
                  //             xmlns="http://www.w3.org/2000/svg"
                  //           >
                  //             <path
                  //               fill-rule="evenodd"
                  //               clip-rule="evenodd"
                  //               d="M8.33333 2.5C7.8731 2.5 7.5 2.8731 7.5 3.33333C7.5 3.79357 7.8731 4.16667 8.33333 4.16667H14.6548L2.74408 16.0774C2.41864 16.4028 2.41864 16.9305 2.74408 17.2559C3.06951 17.5814 3.59715 17.5814 3.92259 17.2559L15.8333 5.34518V10.8333C15.8333 11.2936 16.2064 11.6667 16.6667 11.6667C17.1269 11.6667 17.5 11.2936 17.5 10.8333V2.5H8.33333Z"
                  //               fill="#90FB75"
                  //             />
                  //           </svg>
                  //         </a>
                  //       </TooltipWrap>
                  //     );
                  //   }}
                  // />
                ) : (
                  <div className="skeleton-box" style={{ width: "80px", height: "19.6px" }} />
                )}
              </div>
            </div>
            {/* <div>
              <img src={icToken} alt="token-icon" />

              <div className="apr-wrap">
                {" "}
                <div className="label"> APR</div>{" "}
                {processedData?.nlpAprTotal ? (
                  <span>{formatKeyAmount(processedData, "nlpAprTotal", 2, 2, true)}%</span>
                ) : (
                  <div className="skeleton-box" style={{ width: "60px" }} />
                )}
              </div>
            </div> */}
          </TokenAPR>
          <Cards>
            <TokenCardInfo>
              <div className="label-card">
                <span>Price</span>
              </div>
              <div className={`value`}>{"$" + formatAmount(nslpPrice || 0, USD_DECIMALS, 2, true)}</div>
            </TokenCardInfo>
            <TokenCardInfo>
              <div className="label-card">
                <span>Total Supply</span>
              </div>
              <div className={`value`}>
                {totalSupplyNslp && processedData?.nslpSupplyUsd ? (
                  <>
                    {formatAmount(totalSupplyNslp, nslpDecimalSONIC, 0, true)} NSLP
                    <div className="value-dollar">
                      ${formatNumber(formatKeyAmount(processedData, "nslpSupplyUsd", USD_DECIMALS, 2), 2)}
                    </div>
                  </>
                ) : (
                  <div
                    className="skeleton-box"
                    style={{
                      width: 48,
                    }}
                  />
                )}
              </div>
            </TokenCardInfo>
            <TokenCardInfo>
              <div className="label-card">
                <span>Total Staked</span>
              </div>
              <div className="value">
                {processedData?.nlpSupply && processedData?.nlpSupplyUsd ? (
                  <>
                    {formatKeyAmount(processedData, "nslpSupply", 18, 0, true)} NSLP
                    <div className="value-dollar">
                      ${formatNumber(formatKeyAmount(processedData, "nslpSupplyUsd", USD_DECIMALS, 2), 2)}
                    </div>
                  </>
                ) : (
                  <div
                    className="skeleton-box"
                    style={{
                      width: 48,
                    }}
                  />
                )}
              </div>
            </TokenCardInfo>
            <TokenCardInfo>
              <div className="label-card">
                <span>Your Staked</span>
              </div>
              <div className="value">
                {processedData.nslpBalance ? (
                  <>
                    {formatKeyAmount(processedData, "nslpBalance", NLP_DECIMALS, 2, true)} NSLP
                    <div className="value-dollar">
                      ${formatNumber(formatKeyAmount(processedData, "nslpBalanceUsd", USD_DECIMALS, USD_DECIMALS), 2)}
                    </div>
                  </>
                ) : (
                  <>
                    <div className="skeleton-box" style={{ width: "65px", height: "20px" }} />
                    <span className="skeleton-box" style={{ width: "65px", height: "20px" }} />
                  </>
                )}
              </div>
            </TokenCardInfo>
          </Cards>

          <BuyLink to="/earn/nslp" className="btn-token default-btn" network={chainId}>
            Deposit
          </BuyLink>
        </TokenCard>
        <TokenCard>
          <CardTitle>
            <TokenImg className="golden">
              <img src={nslp40Icon} alt="nslp40Icon" />
            </TokenImg>
            <TokenInfo>
              <div>
                <div className="token-symbol">NAVI </div>
                <div className="token-desc">Navigator exchange governance token</div>

                {/* <div className="token-price">
                  Price:{" "}
                  {!nslpPrice && (
                    <div
                      className="skeleton-box"
                      style={{
                        width: 64,
                        height: 19.59,
                      }}
                    />
                  )}
                  {nslpPrice && (
                    <TooltipComponent
                      position="left-bottom"
                      className="nowrap excep"
                      handle={"$" + formatAmount(nslpPrice, 30, 2)}
                      renderContent={() => (
                        <div
                          className="customTooltip"
                          style={{
                            minWidth: 220,
                          }}
                        >
                          <StatsTooltipRow
                            label={`Price on SONIC`}
                            value={formatAmount(nslpPrices[FANTOM], 30, 2)}
                            showDollar={true}
                          />
                          <StatsTooltipRow
                            label={`Price on Optimism`}
                            value={formatAmount(nslpPrices[OP], 30, 2)}
                            showDollar={true}
                          />
                        </div>
                      )}
                    />
                  )}
                </div> */}
              </div>{" "}
            </TokenInfo>
          </CardTitle>
          <TokenAPR>
            <div>
              <img src={icToken} alt="token-icon" />
              <div className="apr-wrap">
                {" "}
                <div className="label">APR </div>
                {/* <span>
                  {processedData?.naviAprTotalWithBoost ? (
                    <span>{formatKeyAmount(processedData, "naviAprTotalWithBoost", 2, 2, true)}%</span>
                  ) : (
                    <div className="skeleton-box" style={{ width: "60px" }} />
                  )}
                </span> */}
                {processedData?.naviAprTotalWithBoost ? (
                   <span>
                   {formatKeyAmount(processedData, "naviAprTotalWithBoost", 2, 2, true)}%{" "}
                   {/* <img style={{ position: "relative", top: "-1px" }} src={icSonic} alt="sonic" /> */}
                 </span>
                  // <Tooltip
                  //   position={`${"center-bottom"}`}
                  //   className="tooltip-apr"
                  //   handle={
                  //     <span>
                  //       {formatKeyAmount(processedData, "naviAprTotalWithBoost", 2, 2, true)}%{" "}
                  //       <img style={{ position: "relative", top: "-1px" }} src={icSonic} alt="sonic" />
                  //     </span>
                  //   }
                  //   renderContent={() => {
                  //     return (
                  //       <TooltipWrap>
                  //         <div>
                  //           <span className="muted">You Get Additional:</span>
                  //           <b>
                  //             <img src={icSonic} alt="sonic" /> 5x Sonic <span className="muted">Points</span>{" "}
                  //           </b>
                  //         </div>
                  //         <a
                  //           href="https://blog.soniclabs.com/sonic-points-simplified-how-to-qualify-for-200-million-s-airdrop/"
                  //           target="_blank"
                  //           rel="noreferrer"
                  //         >
                  //           Sonic Details{" "}
                  //           <svg
                  //             width="20"
                  //             height="20"
                  //             viewBox="0 0 20 20"
                  //             fill="none"
                  //             xmlns="http://www.w3.org/2000/svg"
                  //           >
                  //             <path
                  //               fill-rule="evenodd"
                  //               clip-rule="evenodd"
                  //               d="M8.33333 2.5C7.8731 2.5 7.5 2.8731 7.5 3.33333C7.5 3.79357 7.8731 4.16667 8.33333 4.16667H14.6548L2.74408 16.0774C2.41864 16.4028 2.41864 16.9305 2.74408 17.2559C3.06951 17.5814 3.59715 17.5814 3.92259 17.2559L15.8333 5.34518V10.8333C15.8333 11.2936 16.2064 11.6667 16.6667 11.6667C17.1269 11.6667 17.5 11.2936 17.5 10.8333V2.5H8.33333Z"
                  //               fill="#90FB75"
                  //             />
                  //           </svg>
                  //         </a>
                  //       </TooltipWrap>
                  //     );
                  //   }}
                  // />
                ) : (
                  <div className="skeleton-box" style={{ width: "80px", height: "19.6px" }} />
                )}
              </div>
            </div>
            {/* <div>
              <img src={icToken} alt="token-icon" />
              <div className="apr-wrap">
                {" "}
                <div className="label">APR </div>
                <span>
                  {processedData?.nslpAprTotal ? (
                    <>{formatKeyAmount(processedData, "nslpAprTotal", 2, 2, true)}%</>
                  ) : (
                    <div className="skeleton-box" style={{ width: "60px" }} />
                  )}
                </span>
              </div>
            </div> */}
          </TokenAPR>
          <Cards>
            <TokenCardInfo>
              <div className="label-card">
                <span>Price</span>
              </div>
              <div className={`value`}>{"$" + formatAmount(naviPrice || 0, USD_DECIMALS, 2, true)}</div>
            </TokenCardInfo>
            <TokenCardInfo>
              <div className="label-card">
                <span>Total Supply</span>
              </div>
              <div className={`value `}>
                {totalNaviSupply && processedData?.naviSupplyUsd ? (
                  <>
                    {formatAmount(totalNaviSupply, NAVI_DECIMALS, 0, true) + " NAVI"}
                    <div className="value-dollar">
                      ${formatNumber(formatKeyAmount(processedData, "naviSupplyUsd", USD_DECIMALS, 2), 2)}
                    </div>
                  </>
                ) : (
                  <div
                    className="skeleton-box"
                    style={{
                      width: 48,
                    }}
                  />
                )}
              </div>
            </TokenCardInfo>
            <TokenCardInfo>
              <div className="label-card">
                <span>Total Staked</span>
              </div>
              <div className="value">
                {totalNaviStaked && totalNaviStake ? (
                  <>
                    {formatAmount(totalNaviStaked, 18, 0, true)} NAVI
                    <div className="value-dollar">${formatNumber(formatAmount(totalNaviStake, 18, 2), 2)}</div>
                  </>
                ) : (
                  <div
                    className="skeleton-box"
                    style={{
                      width: 48,
                    }}
                  />
                )}
              </div>
            </TokenCardInfo>
            <TokenCardInfo>
              <div className="label-card">
                <span>Your Staked</span>
              </div>
              <div className="value">
                {processedData.naviInStakedNavi ? (
                  <>
                    {formatKeyAmount(processedData, "naviInStakedNavi", 18, 2, true)} NAVI
                    <div className="value-dollar">
                      ${formatNumber(formatKeyAmount(processedData, "naviInStakedNaviUsd", USD_DECIMALS, USD_DECIMALS))}
                    </div>
                  </>
                ) : (
                  <>
                    <div className="skeleton-box" style={{ width: "65px", height: "20px" }} />
                    <span className="skeleton-box" style={{ width: "65px", height: "20px" }} />
                  </>
                )}
              </div>
            </TokenCardInfo>
          </Cards>

          {/* <TokenCardInfo>
            <div className="label">
              <span>Market Cap</span>
            </div>
            <div className="value">
              {nslpMarketCap ? (
                <TooltipComponent
                  position="right-bottom"
                  className="nowrap"
                  handle={`$${formatAmount(nslpMarketCap, NLP_DECIMALS, 0, true)}`}
                  renderContent={() => (
                    <>
                      <StatsTooltipRow
                        label={t`Marketcap on SONIC`}
                        value={formatAmount(nslpMarketCapSONIC, NLP_DECIMALS, 0, true)}
                        showDollar={true}
                      />
                      <StatsTooltipRow
                        label={t`Marketcap on Optimism`}
                        value={formatAmount(nslpMarketCapOp, NLP_DECIMALS, 0, true)}
                        showDollar={true}
                      />
                      <div className="divider"></div>
                      <StatsTooltipRow
                        label={t`Total`}
                        value={formatAmount(nslpMarketCap, NLP_DECIMALS, 0, true)}
                        showDollar={true}
                      />
                    </>
                  )}
                />
              ) : (
                <div
                  className="skeleton-box"
                  style={{
                    width: 48,
                  }}
                />
              )}
            </div>
          </TokenCardInfo> */}
          <BuyLink to="/earn" className="btn-token default-btn" network={chainId}>
            Deposit
          </BuyLink>
        </TokenCard>
      </TokenCardContainer>
    </Wrapper>
  );
};

const TokenAPR = styled.div`
  border-radius: 16px;
  background: var(--Nature-2, #1a1d26);
  display: grid;
  grid-template-columns: repeat(1fr);
  > div {
    display: flex;
    padding: 12px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: var(--Base-Unit-XS-8, 8px);
    width: 100%;
    /* &:first-child {
      border-right: 1px solid var(--Border, rgba(255, 255, 255, 0.1));
    } */
  }
  .label {
    margin-bottom: 0;
    color: #828899;
    font-weight: 700;
  }
  .apr-wrap {
    display: flex;

    color: var(--Text-Text_Primary, #fff);
    font-size: 14px;
    font-weight: 700;
    line-height: 140%; /* 19.6px */
    gap: 8px;
    .label {
      margin-bottom: 0;
      font-weight: 700;
    }

    & > span {
      color: var(--main-primary, #90fb75);
      font-size: 14px;
      font-weight: 700;
      line-height: 140%; /* 19.6px */
      display: flex;
    }

    /* @media screen and (max-width: 767px) {
      flex-direction: row;
      gap: 4px;
    } */
  }

  > .row {
    width: 100%;

    gap: 8px;

    .title {
      font-weight: 400;
      font-size: 14px;
      line-height: 140%;
      color: #ffffff99;
      display: flex;
      flex-direction: column;

      gap: 8px;

      > span {
        font-weight: 500;
        font-size: 14px;
        line-height: 140%;
        color: #90fb75;
      }

      @media screen and (max-width: 767px) {
        flex-direction: row;
      }
    }

    .btn-buy {
      display: flex;
      height: 40px;
      padding: 8px 16px;
      justify-content: center;
      align-items: center;
      gap: 4px;
      align-self: stretch;
      border-radius: 8px;
      border: 1px solid var(--stroke-10, rgba(255, 255, 255, 0.1));
      background-color: transparent;
      /* color: #080715; */
      text-decoration: none;

      color: var(--white-100, #fff);
      font-size: 14px;
      font-weight: 500;
      line-height: 140%; /* 19.6px */

      &:hover {
        color: #90fb75 !important;

        box-shadow: none !important;
        border: 1px solid #90fb75 !important;
      }
    }

    @media (max-width: 767px) {
      flex-direction: row;

      .btn-buy {
        width: fit-content;
      }
    }
  }

  @media (max-width: 1023px) {
    display: flex;
    justify-content: space-between;
  }
`;

const TokenCardInfo = styled.div`
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .label-card {
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    color: #828899;
    margin-bottom: 0;
  }

  .value {
    font-weight: 700;
    font-size: 14px;
    line-height: 140%;
    align-items: center;
    display: flex;
    gap: 4px;
    .value-dollar {
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 140%;
      color: var(--Text-Text_Secondary, #828899);
    }
  }
  @media (max-width: 1023px) {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  @media (max-width: 767px) {
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
  }
`;

const TokenInfo = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: center;
  padding-left: 12px;

  & > div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
  }

  .token-symbol {
    color: var(--Text-Text_Primary, #fff);
    font-size: 16px;
    font-weight: 700;
    line-height: 140%; /* 22.4px */

    display: flex;
    align-items: center;
    gap: var(--Border_radius, 4px);

    & > a {
      &:hover {
        svg {
          g {
            opacity: 1;
          }
          path {
            fill: #90fb75;
          }
        }
      }
    }
  }
  .token-desc {
    color: #828899;
    font-size: 14px;
    font-weight: 500;
    line-height: 140%; /* 19.6px */
  }
  .token-price {
    color: #828899;
    font-size: 14px;
    font-weight: 400;
    line-height: 140%; /* 19.6px */

    .Tooltip-handle {
      color: var(--Primary, #90fb75);
      font-size: 14px;
      font-weight: 700;
      line-height: 140%; /* 19.6px */
    }
  }
  /* .btn-readmore {
    height: 40px;
    display: flex;
    text-decoration: none;
    align-items: center;
    padding: 8px 16px;
    border: 1px solid rgba(55, 63, 92, 0.50);
    border-radius: 8px;
    color: #fff;
  } */
`;

const TokenImg = styled.div`
  img {
    width: 40px;
    height: 40px;
  }
`;

const CardTitle = styled.div`
  /* width: 340px; */
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media screen and (max-width: 1024px) {
    width: 250px;
  }
  @media screen and (max-width: 700px) {
    width: 100%;
  }
  /* position: absolute;
  top: 0;
  left: 24px;
  right: 24px; */

  /* @media (max-width: 767px) {
    left: 16px;
    right: 16px;
  } */
`;

const TokenCard = styled.div`
  padding: 24px;
  background-color: #12141a;
  border-radius: 16px;
  gap: 24px;
  position: relative;
  display: flex;
  flex-direction: column;

  @media (max-width: 1023px) {
    height: fit-content;
    flex-direction: column;
  }
  @media (max-width: 700px) {
    gap: 12px;
    padding: 16px;
  }
`;

const TokenCardContainer = styled.div`
  margin-top: 16px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 24px;
  .label {
    font-weight: 400;
    font-size: 14px;
    margin-bottom: 8px;
    @media (max-width: 1023px) {
      margin-bottom: 0;
    }
  }

  @media (max-width: 1023px) {
    grid-template-columns: repeat(1, 1fr);
  }

  @media (max-width: 767px) {
    gap: 16px;
  }
`;

const Title = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--Base-Unit-XS-8, 8px);

  &:not(:first-child) {
    margin-top: 48px;

    @media screen and (max-width: 767px) {
      margin-top: 48px;
    }
  }

  span {
    font-weight: 700;
    font-size: 24px;
    line-height: 140%;
    margin-bottom: 4px;
  }

  div {
    color: #828899;
    font-size: 14px;
    font-weight: 400;
    line-height: 140%; /* 19.6px */
  }
`;

const Wrapper = styled.div`
  .tooltip-apr {
    .Tooltip-popup {
      @media screen and (max-width: 700px) {
        width: 300px !important;
      }
    }
  }
  .btn-token {
    border-radius: 100px;

    display: flex;

    height: 40px;
    padding: 8px 16px;
    justify-content: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
    align-items: center;

    text-decoration: none !important;

    @media screen and (max-width: 700px) {
      width: 100%;
      margin-top: 8px;
    }
  }
  margin: 0 0 48px;

  .lp {
    margin-top: 16px;
    display: flex;
    padding: 24px;
    align-items: center;
    gap: 24px;
    align-self: stretch;
    border-radius: 16px;
    border: 1px solid transparent;
    background: linear-gradient(180deg, #191b2e 0%, #191b2e 100%);
    position: relative;
    overflow: hidden;

    & > img {
      position: absolute;
      top: 0;
      left: 0;
    }

    & > div.lp-col {
      &.tablet {
        display: none;
      }

      .default-btn {
        color: #000;
        font-weight: 700;
      }

      &:has(.lp-symbol) {
        display: flex;
        align-items: center;
        gap: 24px;

        .lp-info {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;
          gap: var(--Border_radius, 4px);
          width: 178px;
        }

        .lp-symbol {
          display: flex;
          align-items: center;
          gap: var(--Border_radius, 4px);
          color: var(--Text-Text_Primary, #fff);
          font-size: 16px;
          font-weight: 700;
          line-height: 120%; /* 22.4px */
        }

        .lp-price {
          color: #828899;
          font-size: 14px;
          font-weight: 400;
          line-height: 140%; /* 19.6px */

          span {
            color: var(--Primary, #90fb75);
            font-size: 14px;
            font-weight: 700;
            line-height: 140%; /* 19.6px */
          }
        }
      }

      &:not(:has(.default-btn, img)) {
        flex: 1;

        &:has(.lp-sumbol) {
        }

        .lp-col-key {
          color: #828899;
          font-size: 14px;
          font-weight: 400;
          line-height: 140%; /* 19.6px */
        }

        .lp-col-value {
          color: var(--Text-Text_Primary, #fff);
          font-size: 14px;
          font-weight: 700;
          line-height: 140%; /* 19.6px */

          &.green {
            color: var(--Primary, #90fb75);
          }

          &:has(span) {
            display: flex;
            align-items: center;
            gap: var(--Border_radius, 4px);
            align-self: stretch;
          }

          span {
            color: #828899;
            font-size: 14px;
            font-weight: 400;
            line-height: 140%; /* 19.6px */
          }
        }
      }
    }

    @media screen and (max-width: 1023px) {
      display: grid;
      grid-template-columns: repeat(4, 1fr);

      & > div.lp-col {
        &.tablet {
          display: block;
          grid-column: 3/5;
          margin-left: auto;
        }

        &:has(.lp-symbol) {
          grid-column: 1/3;
        }

        &:not(.tablet):has(.default-btn) {
          display: none;
        }
      }
    }

    @media screen and (max-width: 767px) {
      grid-template-columns: 1fr;
      gap: 12px;
      padding: 16px;

      & > div.lp-col {
        grid-column: unset !important;

        &:has(.lp-symbol) {
          margin-bottom: 12px;
        }

        &.tablet {
          display: none;
        }

        &:not(.tablet):has(.default-btn) {
          display: block;

          .default-btn {
            width: 100%;
          }
        }

        &:has(.lp-col-key, .lp-col-value) {
          display: flex;
          align-items: center;
          justify-content: space-between;
        }
      }
    }
  }
  @media (max-width: 768px) {
    margin-bottom: 40px;
  }
  @media (max-width: 767px) {
    margin: 0;
    margin-bottom: 40px;

    div.Tooltip-popup:has(.customTooltip) {
      transform: translateX(-100px);
    }
  }

  &.theme--light {
    ${TokenCard} {
      border: 1px solid var(--Border, rgba(13, 26, 22, 0.07));
      background: var(--Nature-1, #fff);

      ${TokenImg} {
        width: 40px;
        height: 40px;
      }

      ${TokenInfo} {
        .token-symbol {
          color: var(--Text-Text_Primary, #0d1a16);

          & > a {
            &:hover {
              svg {
                g {
                  opacity: 1;
                }
                path {
                  fill: #02b27f;
                }
              }
            }
          }
        }

        .token-desc,
        .token-price {
          color: #828899 !important;
        }
      }

      ${TokenAPR} {
        border-color: var(--Border, rgba(13, 26, 22, 0.07)) !important;

        .btn-buy.border-btn {
          color: var(--Text-Text_Primary, #0d1a16) !important;
          border: 1px solid var(--Border, rgba(13, 26, 22, 0.07)) !important;

          &:hover {
            color: #02b27f !important;
            border-color: #02b27f !important;
          }
        }
      }

      ${TokenCardInfo} {
        .label span {
          color: var(--Text-Text_Secondary, rgba(13, 26, 22, 0.7)) !important;
        }
      }
    }

    .lp {
      border: 1px solid var(--Border, rgba(13, 26, 22, 0.07));
      background: var(--Nature-1, #fff);

      .lp-info {
        .lp-symbol {
          color: var(--Text-Text_Primary, #0d1a16) !important;
        }

        .lp-price {
          color: var(--Text-Text_Secondary, rgba(13, 26, 22, 0.7)) !important;

          span {
            color: var(--Primary, #02b27f) !important;
          }
        }
      }

      .lp-col-key {
        color: var(--Text-Text_Secondary, rgba(13, 26, 22, 0.7)) !important;
      }

      .lp-col-value {
        color: var(--Text-Text_Primary, #0d1a16) !important;

        &.green {
          color: var(--Primary, #02b27f) !important;
        }

        span {
          color: var(--Text-Text_Secondary, rgba(13, 26, 22, 0.7)) !important;
        }
      }
    }
  }
`;

const StyleButton = styled(Link)`
  border-radius: var(--Border-Radius-Medium, 12px);
  background: var(--Button-Background, #90fb75);
  display: flex;
  width: 100px;
  height: 40px;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;

  color: #fff;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
`;

const Cards = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  @media (min-width: 768px) and (max-width: 1023px) {
    flex-direction: row;
    > div {
      flex-direction: column;
      align-items: flex-start;
    }
  }
`;
const TooltipWrap = styled.div`
  font-style: normal;
  font-weight: 500;
  line-height: 140%;

  > div:first-child {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 12px;
  }
  b {
    color: #fff;
    display: flex;
    gap: 4px;
    align-items: center;
    .muted {
      font-weight: 700;
    }
    font-weight: 700 !important;
  }
  a {
    font-style: normal;
    font-weight: 700 !important;
    line-height: 140%;
    text-decoration: none;
    display: flex;
    align-items: center;
    color: #90fb75;
  }
`;
export default Tokens;
