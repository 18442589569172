import cx from "classnames";

import { formatAmount, formatAmountInDollar, parseValue } from "lib/numbers";

import icView from "img/ic-view.svg";
import { renderRisk, renderRiskV1 } from "lib/helper";
import { useThemeContext } from "contexts/ThemeProvider";
import StatsTooltipRow from "components/StatsTooltip/StatsTooltipRow";
import Tooltip from "components/Tooltip/Tooltip";
import { useMedia } from "react-use";
import { DataTable, PositionHead, TableRow } from "../../helper/table";
import { formatOpenPosition } from "../../helper";
import styled from "styled-components";
import { TOKEN_SHOWS_DECIMALS } from "config/tokens";
const OpenPositions = ({ positions, assets, marketTokensInfo, isEmpty }) => {
  const { lightThemeClassName } = useThemeContext();
  const isTablet = useMedia("(max-width: 1023px)");

  const formattedPositions = positions.map((position) => {
    return formatOpenPosition(position, assets, marketTokensInfo);
  });

  return (
    <StyledDataTable $noIndex $autoHeight $noHeading={isEmpty} className={lightThemeClassName}>
      <div className="head">
        <div className="col-name">Position</div>
        <div className="col-name">Net/PnL</div>
        <div className="col-name">Pos. Size</div>
        <div className="col-name">Collateral</div>
        <div className="col-name">Liq. Risk</div>
        <div className="col-name">Liq./Mark Price</div>
        <div className="col-name">Avg. Entry Price</div>
        <div style={{ width: "36px" }}></div>
      </div>
      <div className={`body ${formattedPositions?.length === 1 ? "body-single-row" : ""}`}>
        {formattedPositions.map((position, i) => {
          const asset = position.isV2 ? assets[position.indexToken.id] : undefined;
          const symbol = `${
            (position.symbol || position.tokenSymbol)?.includes("/")
              ? position.symbol || position.tokenSymbol
              : (position.symbol || position.tokenSymbol) + "/USD"
          }`;
          return (
            <TableRow key={position.key || i} to={`/analytics/position/${position.id}`}>
              <div d-label="Position" className="col-body">
                <PositionHead position={position} isV2={position.isV2} asset={asset} noFlag />
              </div>
              <div d-label="Net/PnL" className="col-body">
                <div className="col-body-content">
                  <div>
                    <Tooltip
                      handle={
                        <>
                          {position?.netValue?.lt(0) ? "-" : ""}$
                          {position?.netValue ? formatAmount(position.netValue, 30, 2, true).replace("-", "") : "--"}
                        </>
                      }
                      position={isTablet ? "right-bottom" : "left-bottom"}
                      className="fit-content nowrap"
                      handleClassName={cx("plain", { negative: position.hasLowCollateral })}
                      renderContent={() => {
                        return (
                          <>
                            Unrealized PnL after accrued funding & borrow fees
                            <br />
                            <br />
                            <StatsTooltipRow
                              label={`PnL without fees`}
                              value={formatAmountInDollar(position?.pnlWithoutFee, 30, 2, true)}
                              showDollar={false}
                            />
                            <StatsTooltipRow
                              label={`Accrued funding fee`}
                              value={formatAmountInDollar(position?.accruedFundingFee, 30, 2, true)}
                              showDollar={false}
                            />
                            {!position.isV2 && (
                              <StatsTooltipRow
                                label={`Accrued position fee`}
                                value={formatAmountInDollar(parseValue(position?.accruedPositionFee, 30), 30, 2, true)}
                                showDollar={false}
                              />
                            )}
                            {position.isV2 && (
                              <>
                                <StatsTooltipRow
                                  label={`Accrued borrow fee`}
                                  value={formatAmountInDollar(position?.accruedBorrowFee, 30, 2, true)}
                                  showDollar={false}
                                />
                                <StatsTooltipRow
                                  label={`Accrued position fee`}
                                  value={formatAmountInDollar(
                                    parseValue(position?.accruedPositionFee, 30),
                                    30,
                                    2,
                                    true
                                  )}
                                  showDollar={false}
                                />
                              </>
                            )}
                          </>
                        );
                      }}
                    />
                  </div>
                  {position?.pnlWithoutFee && (
                    <div
                      className={cx("fz-12", {
                        positive: position?.pnlAfterFee?.gt(0),
                        negative: position?.pnlAfterFee.lt(0),
                        muted: position?.pnlAfterFee.eq(0),
                      })}
                    >
                      <span style={{ fontSize: "12px", fontWeight: 700 }}>
                        {formatAmountInDollar(position?.pnlAfterFee, 30, 2, true)}
                      </span>

                      <span
                        style={{ marginLeft: "4px", fontSize: "12px", fontWeight: 700 }}
                        className={cx("fz-12", {
                          positive: position?.pnlAfterFee?.gt(0),
                          negative: position?.pnlAfterFee.lt(0),
                          muted: position?.pnlAfterFee.eq(0),
                        })}
                      >
                        ({position.prefix}
                        {formatAmount(position?.percentPnl, 30, 2, true).replace("-", "")}
                        %)
                      </span>
                    </div>
                  )}
                </div>
              </div>
              <div d-label="Pos. Size" className="col-body">
                <div className="col-body-content">
                  ${formatAmount(parseValue(position?.size, 30), 30, 2, true)}
                  <div className="muted fz-12">
                    {formatAmount(position.posQty, 30, 4, true)} {asset?.tokenSymbol}
                  </div>
                </div>
              </div>
              <div d-label="Collateral" className="col-body">
                ${formatAmount(parseValue(position.collateral, 30), 30, 2, true)}
              </div>
              <div d-label="Liq. Risk" className="col-body">
                {position.type === "V2" ? renderRisk(position.LiqRisk) : renderRiskV1(position.LiqRisk)}
              </div>
              <div d-label="Liq./Mark Price" className="col-body">
                <div className="col-body-content">
                  <div>
                    ${formatAmount(parseValue(position.liquidPrice, 30), 30, TOKEN_SHOWS_DECIMALS[symbol] || 2, true)}
                  </div>
                  <div className="muted fz-12">
                    ${formatAmount(parseValue(position.marketPrice, 30), 30, TOKEN_SHOWS_DECIMALS[symbol] || 2, true)}
                  </div>
                </div>
              </div>
              <div d-label="Avg. Entry Price" className="col-body">
                ${formatAmount(parseValue(position.averagePrice, 30), 30, TOKEN_SHOWS_DECIMALS[symbol] || 2, true)}
              </div>
              {/* <div d-label="Created" className="col-body">
                <div className="col-body-content">
                  <DateAndTime unix={position.openTime} />
                </div>
              </div> */}
              <div className="icon-view">
                <img src={icView} alt="view_ic1" />
              </div>
            </TableRow>
          );
        })}
      </div>
    </StyledDataTable>
  );
};

const StyledDataTable = styled(DataTable)`
  .muted.fz-12 {
    font-size: 12px;
    font-weight: 700 !important;
  }
  .body {
    @media screen and (max-width: 1023px) {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      margin-top: 8px;
      gap: 24px;
      &.body-single-row {
        grid-template-columns: 1fr;
      }
      .col-body-content {
        flex-direction: row !important;
        gap: 4px;
      }
    }
    @media screen and (max-width: 700px) {
      grid-template-columns: 1fr;
      margin-top: 0;
      gap: 16px;
    }
  }
  .body .body-row {
    padding: 10px 16px;
    background: #1a1d26;
    @media screen and (max-width: 991px) {
      border-radius: 16px;
    }
    .position-head img {
      width: 32px;
      height: 32px;
    }
  }
  .col-body {
    font-weight: 500;
  }

  .icon-view {
    width: 36px;
    height: 36px;
    border-radius: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    &:hover {
      background: #262933;
    }
    @media screen and (max-width: 991px) {
      display: none;
    }
  }
`;
export default OpenPositions;
