import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useWeb3React } from "@web3-react/core";
import useSWR from "swr";
import { ethers } from "ethers";
import { getContract } from "config/contracts";
import {
  getBuyNlpToAmount,
  getBuyNlpFromAmount,
  getSellNlpFromAmount,
  getSellNlpToAmount,
  adjustForDecimals,
  NLP_DECIMALS,
  USD_DECIMALS,
  BASIS_POINTS_DIVISOR,
  NLP_COOLDOWN_DURATION,
  USDN_DECIMALS,
  PLACEHOLDER_ACCOUNT,
} from "lib/legacy";
import styled from "styled-components";
import ReaderV2 from "abis/ReaderV2.json";
import NlpManager from "abis/NlpManager.json";
import RewardTracker from "abis/RewardTracker.json";
import Vester from "abis/Vester.json";
import RewardRouter from "abis/RewardRouter.json";
import Token from "abis/Token.json";
import infoIcon from "img/icons/info.svg";
import nlp24Icon from "img/nlp.svg";
import arrowIcon from "img/ic_arrow.svg";

import { getChainName, IS_NETWORK_DISABLED, BASE } from "config/chains";
import { callContract, contractFetcher } from "lib/contracts";
import { approveTokens, useInfoTokens } from "domain/tokens";
import { useLocalStorageByChainId } from "lib/localStorage";
import { getTokenInfo, getUsd } from "domain/tokens/utils";
import { bigNumberify, expandDecimals, formatAmount, formatAmountFree, parseValue } from "lib/numbers";
import { getToken, getTokens, getWhitelistedTokens } from "config/tokens";
import { useChainId } from "lib/chains";
import Tooltip from "components/Tooltip/Tooltip";
import Tab from "components/Tab/Tab";
import BuyInputSection from "components/BuyInputSection/BuyInputSection";
import TokenSelector from "components/Exchange/TokenSelector";
import { helperToast } from "lib/helperToast";
import Countdown from "react-countdown";
import { set } from "date-fns";

const { AddressZero } = ethers.constants;

export default function BuySellNlp({
  savedSlippageAmount,
  isBuying,
  setPendingTxns,
  connectWallet,
  setIsBuying,
  savedShouldDisableValidationForTesting,
  nlpValue,
  setNlpValue,
  nlpAmount,
  nlpPrice,
  usdgSupply,
  totalTokenWeights,
}) {
  const history = useHistory();
  const swapLabel = isBuying ? "Buy NLP" : "Sell NLP";
  const tabLabel = isBuying ? `Buy NLP` : `Sell NLP`;
  const { active, library, account } = useWeb3React();
  const { chainId } = useChainId();
  // const chainName = getChainName(chainId)
  const tokens = getTokens(chainId);
  const whitelistedTokens = getWhitelistedTokens(chainId);
  const tokenList = whitelistedTokens.filter((t) => !t.isWrapped);
  const [swapValue, setSwapValue] = useState("");
  // const [nlpValue, setNlpValue] = useState("");
  const [swapTokenAddress, setSwapTokenAddress] = useLocalStorageByChainId(
    chainId,
    `${swapLabel}-swap-token-address2`,
    AddressZero
  );
  const [isApproving, setIsApproving] = useState(false);
  const [isWaitingForApproval, setIsWaitingForApproval] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [anchorOnSwapAmount, setAnchorOnSwapAmount] = useState(true);
  const [feeBasisPoints, setFeeBasisPoints] = useState("");

  const readerAddress = getContract(chainId, "Reader");
  const nativeTokenAddress = getContract(chainId, "NATIVE_TOKEN");
  const feeNlpTrackerAddress = getContract(chainId, "FeeNlpTracker");
  const nlpManagerAddress = getContract(chainId, "NlpManager");
  const rewardRouterAddress = getContract(chainId, "RewardRouter");

  const tokenAddresses = tokens.map((token) => token.address);
  const { data: tokenBalances } = useSWR(
    [
      `BuySellNlp:getTokenBalances:${active}`,
      chainId,
      readerAddress,
      "getTokenBalances",
      account || PLACEHOLDER_ACCOUNT,
    ],
    {
      fetcher: contractFetcher(library, ReaderV2, [tokenAddresses]),
      refreshInterval: 30000,
    }
  );

  const tokenAllowanceAddress = swapTokenAddress === AddressZero ? nativeTokenAddress : swapTokenAddress;
  const { data: tokenAllowance } = useSWR(
    [active, chainId, tokenAllowanceAddress, "allowance", account || PLACEHOLDER_ACCOUNT, nlpManagerAddress],
    {
      fetcher: contractFetcher(library, Token),
      refreshInterval: 30000,
    }
  );

  const { data: lastPurchaseTime } = useSWR(
    [
      `BuySellNlp:lastPurchaseTime:${active}`,
      chainId,
      nlpManagerAddress,
      "lastAddedAt",
      account || PLACEHOLDER_ACCOUNT,
    ],
    {
      fetcher: contractFetcher(library, NlpManager),
      refreshInterval: 30000,
    }
  );

  const { data: nlpBalance } = useSWR(
    [`BuySellNlp:nlpBalance:${active}`, chainId, feeNlpTrackerAddress, "stakedAmounts", account || PLACEHOLDER_ACCOUNT],
    {
      fetcher: contractFetcher(library, RewardTracker),
      refreshInterval: 30000,
    }
  );

  const nlpVesterAddress = getContract(chainId, "NlpVester");
  const { data: reservedAmount } = useSWR(
    [`BuySellNlp:reservedAmount:${active}`, chainId, nlpVesterAddress, "pairAmounts", account || PLACEHOLDER_ACCOUNT],
    {
      fetcher: contractFetcher(library, Vester),
      refreshInterval: 30000,
    }
  );

  // const nlpVesterV1Address = getContract(chainId, "NlpVesterV1");
  // const { data: reservedAmountV1 } = useSWR(
  //   chainId !== BASE && [
  //     `BuySellNlp:reservedAmountV1:${active}`,
  //     chainId,
  //     nlpVesterV1Address,
  //     "pairAmounts",
  //     account || PLACEHOLDER_ACCOUNT,
  //   ],
  //   {
  //     fetcher: contractFetcher(library, Vester),
  //     refreshInterval: 30000,
  //   }
  // );
  const { data: nlpCooldown } = useSWR([`StakeV2:nlpCooldown`, chainId, nlpManagerAddress, "cooldownDuration"], {
    fetcher: contractFetcher(library, NlpManager),
    refreshInterval: 10000,
  });
  const coundowHour = nlpCooldown % 3600 === 0 ? nlpCooldown / 3600 : parseInt(nlpCooldown / 3600) + 1;
  const redemptionTime = lastPurchaseTime ? lastPurchaseTime.add(NLP_COOLDOWN_DURATION) : undefined;
  const inCooldownWindow = redemptionTime && parseInt(Date.now() / 1000) < redemptionTime;

  var maxSellAmount = nlpBalance;
  if (nlpBalance && reservedAmount) {
    maxSellAmount = maxSellAmount.sub(reservedAmount);
  }
  // if (nlpBalance && reservedAmountV1) {
  //   maxSellAmount = maxSellAmount.sub(reservedAmountV1);
  // }
  const { infoTokens } = useInfoTokens(library, chainId, active, tokenBalances, undefined);
  const swapToken = getToken(chainId, swapTokenAddress);
  const swapTokenInfo = getTokenInfo(infoTokens, swapTokenAddress);

  const swapTokenBalance = swapTokenInfo && swapTokenInfo.balance ? swapTokenInfo.balance : bigNumberify(0);

  const swapAmount = parseValue(swapValue, swapToken && swapToken.decimals);

  const needApproval =
    isBuying && swapTokenAddress !== AddressZero && tokenAllowance && swapAmount && swapAmount.gt(tokenAllowance);

  const swapUsdMin = getUsd(swapAmount, swapTokenAddress, false, infoTokens);
  const nlpUsdMax = nlpAmount && nlpPrice ? nlpAmount.mul(nlpPrice).div(expandDecimals(1, NLP_DECIMALS)) : undefined;

  let isSwapTokenCapReached;
  if (swapTokenInfo.managedUsd && swapTokenInfo.maxUsdgAmount) {
    isSwapTokenCapReached = swapTokenInfo.managedUsd.gt(
      adjustForDecimals(swapTokenInfo.maxUsdgAmount, USDN_DECIMALS, USD_DECIMALS)
    );
  }

  const onSwapValueChange = (e) => {
    setAnchorOnSwapAmount(true);
    setSwapValue(e.target.value);
  };

  const onNlpValueChange = (e) => {
    setAnchorOnSwapAmount(false);
    setNlpValue(e.target.value);
  };

  const onSelectSwapToken = (token) => {
    setSwapTokenAddress(token.address);
    setIsWaitingForApproval(false);
  };

  useEffect(() => {
    const updateSwapAmounts = () => {
      if (anchorOnSwapAmount) {
        if (!swapAmount) {
          setNlpValue("");
          setFeeBasisPoints("");
          return;
        }

        if (isBuying) {
          const { amount: nextAmount, feeBasisPoints: feeBps } = getBuyNlpToAmount(
            swapAmount,
            swapTokenAddress,
            infoTokens,
            nlpPrice,
            usdgSupply,
            totalTokenWeights
          );
          const nextValue = formatAmountFree(nextAmount, NLP_DECIMALS, NLP_DECIMALS);

          setNlpValue(nextValue);
          setFeeBasisPoints(feeBps);
        } else {
          const { amount: nextAmount, feeBasisPoints: feeBps } = getSellNlpFromAmount(
            swapAmount,
            swapTokenAddress,
            infoTokens,
            nlpPrice,
            usdgSupply,
            totalTokenWeights
          );
          const nextValue = formatAmountFree(nextAmount, NLP_DECIMALS, NLP_DECIMALS);
          setNlpValue(nextValue);
          setFeeBasisPoints(feeBps);
        }

        return;
      }

      if (!nlpAmount) {
        setSwapValue("");
        setFeeBasisPoints("");
        return;
      }

      if (swapToken) {
        if (isBuying) {
          const { amount: nextAmount, feeBasisPoints: feeBps } = getBuyNlpFromAmount(
            nlpAmount,
            swapTokenAddress,
            infoTokens,
            nlpPrice,
            usdgSupply,
            totalTokenWeights
          );
          const nextValue = formatAmountFree(nextAmount, swapToken.decimals, swapToken.decimals);
          setSwapValue(nextValue);
          setFeeBasisPoints(feeBps);
        } else {
          const { amount: nextAmount, feeBasisPoints: feeBps } = getSellNlpToAmount(
            nlpAmount,
            swapTokenAddress,
            infoTokens,
            nlpPrice,
            usdgSupply,
            totalTokenWeights,
            true
          );

          const nextValue = formatAmountFree(nextAmount, swapToken.decimals, swapToken.decimals);
          setSwapValue(nextValue);
          setFeeBasisPoints(feeBps);
        }
      }
    };

    updateSwapAmounts();
  }, [
    isBuying,
    anchorOnSwapAmount,
    swapAmount,
    nlpAmount,
    swapToken,
    swapTokenAddress,
    infoTokens,
    nlpPrice,
    usdgSupply,
    totalTokenWeights,
  ]);

  const switchSwapOption = (hash = "") => {
    history.push(`${history.location.pathname}#${hash}`);
    setIsBuying(hash === "redeem" ? false : true);
  };

  const fillMaxAmount = () => {
    if (isBuying) {
      setAnchorOnSwapAmount(true);
      setSwapValue(formatAmountFree(swapTokenBalance, swapToken.decimals, swapToken.decimals));
      return;
    }

    setAnchorOnSwapAmount(false);
    setNlpValue(formatAmountFree(maxSellAmount, NLP_DECIMALS, NLP_DECIMALS));
  };

  const getError = () => {
    if (IS_NETWORK_DISABLED[chainId]) {
      if (isBuying) return [`NLP buy disabled, pending ${getChainName(chainId)} upgrade`];
      return [`NLP sell disabled, pending ${getChainName(chainId)} upgrade`];
    }

    if (!isBuying && inCooldownWindow) {
      return [`Redemption time not yet reached`];
    }

    if (!swapAmount || swapAmount.eq(0)) {
      return [`Enter an amount`];
    }
    if (!nlpAmount || nlpAmount.eq(0)) {
      return [`Enter an amount`];
    }

    if (isBuying) {
      const swapTokenInfo = getTokenInfo(infoTokens, swapTokenAddress);
      if (
        !savedShouldDisableValidationForTesting &&
        swapTokenInfo &&
        swapTokenInfo.balance &&
        swapAmount &&
        swapAmount.gt(swapTokenInfo.balance)
      ) {
        return [`Insufficient ${swapTokenInfo.symbol} balance`];
      }

      if (swapTokenInfo.maxUsdgAmount && swapTokenInfo.usdgAmount && swapUsdMin) {
        const usdgFromAmount = adjustForDecimals(swapUsdMin, USD_DECIMALS, USDN_DECIMALS);
        const nextUsdgAmount = swapTokenInfo.usdgAmount.add(usdgFromAmount);
        if (swapTokenInfo.maxUsdgAmount.gt(0) && nextUsdgAmount.gt(swapTokenInfo.maxUsdgAmount)) {
          return [`${swapTokenInfo.symbol} pool exceeded, try different token`, true];
        }
      }
    }

    if (!isBuying) {
      if (maxSellAmount && nlpAmount && nlpAmount.gt(maxSellAmount)) {
        return [`Insufficient NLP balance`];
      }

      const swapTokenInfo = getTokenInfo(infoTokens, swapTokenAddress);
      if (
        swapTokenInfo &&
        swapTokenInfo.availableAmount &&
        swapAmount &&
        swapAmount.gt(swapTokenInfo.availableAmount)
      ) {
        return [`Insufficient liquidity`];
      }
    }

    return [false];
  };

  const isPrimaryEnabled = () => {
    if (IS_NETWORK_DISABLED[chainId]) {
      return false;
    }
    if (!active) {
      return true;
    }
    const [error, modal] = getError();
    if (error && !modal) {
      return false;
    }
    if ((needApproval && isWaitingForApproval) || isApproving) {
      return false;
    }
    if (isApproving) {
      return false;
    }
    if (isSubmitting) {
      return false;
    }
    if (isBuying && isSwapTokenCapReached) {
      return false;
    }

    if (!isBuying && inCooldownWindow) {
      return false;
    }

    return true;
  };

  const getPrimaryText = () => {
    if (!active) {
      return `Connect Wallet`;
    }
    const [error, modal] = getError();
    if (error && !modal) {
      return error;
    }
    if (isBuying && isSwapTokenCapReached) {
      return `Max Capacity for ${swapToken.symbol} Reached`;
    }

    if (needApproval && isWaitingForApproval) {
      return `Waiting for Approval`;
    }
    if (isApproving) {
      return `Approving ${swapToken.symbol}...`;
    }
    if (needApproval) {
      return `Approve ${swapToken.symbol}`;
    }

    if (isSubmitting) {
      return isBuying ? `Buying...` : `Selling...`;
    }

    if (!isBuying && inCooldownWindow) {
      return "Cooldown to sell NLP";
    }

    return isBuying ? `Buy NLP` : `Sell NLP`;
  };

  const approveFromToken = () => {
    approveTokens({
      setIsApproving,
      library,
      tokenAddress: swapToken.address,
      spender: nlpManagerAddress,
      chainId: chainId,
      onApproveSubmitted: () => {
        setIsWaitingForApproval(true);
      },
      infoTokens,
      getTokenInfo,
    });
  };

  const buyNlp = () => {
    setIsSubmitting(true);

    const minNlp = nlpAmount.mul(BASIS_POINTS_DIVISOR - savedSlippageAmount).div(BASIS_POINTS_DIVISOR);

    const contract = new ethers.Contract(rewardRouterAddress, RewardRouter.abi, library.getSigner());
    const method = swapTokenAddress === AddressZero ? "mintAndStakeNlpETH" : "mintAndStakeNlp";
    const params = swapTokenAddress === AddressZero ? [0, minNlp] : [swapTokenAddress, swapAmount, 0, minNlp];
    const value = swapTokenAddress === AddressZero ? swapAmount : 0;

    callContract(chainId, contract, method, params, {
      value,
      sentMsg: `Buy submitted.`,
      failMsg: `Buy failed.`,
      successMsg: `${formatAmount(nlpAmount, 18, 4, true)} NLP bought with ${formatAmount(
        swapAmount,
        swapTokenInfo.decimals,
        4,
        true
      )} ${swapTokenInfo.symbol}!`,
      setPendingTxns,
    })
      .then(async () => {})
      .finally(() => {
        setIsSubmitting(false);
        setSwapValue("");
      });
  };

  const sellNlp = () => {
    setIsSubmitting(true);

    const minOut = swapAmount.mul(BASIS_POINTS_DIVISOR - savedSlippageAmount).div(BASIS_POINTS_DIVISOR);

    const contract = new ethers.Contract(rewardRouterAddress, RewardRouter.abi, library.getSigner());
    const method = swapTokenAddress === AddressZero ? "unstakeAndRedeemNlpETH" : "unstakeAndRedeemNlp";
    const params =
      swapTokenAddress === AddressZero ? [nlpAmount, minOut, account] : [swapTokenAddress, nlpAmount, minOut, account];

    callContract(chainId, contract, method, params, {
      sentMsg: `Sell submitted!`,
      failMsg: `Sell failed.`,
      successMsg: `${formatAmount(nlpAmount, 18, 4, true)} NLP sold for ${formatAmount(
        swapAmount,
        swapTokenInfo.decimals,
        4,
        true
      )} ${swapTokenInfo.symbol}!`,
      setPendingTxns,
    })
      .then(async () => {})
      .finally(() => {
        setIsSubmitting(false);
        setSwapValue("");
        setNlpValue("");
      });
  };

  const onClickPrimary = () => {
    if (!active) {
      connectWallet();
      return;
    }

    if (needApproval) {
      approveFromToken();
      return;
    }

    const [err, modal] = getError();

    if (modal) {
      helperToast.error(err);
      return;
    }

    if (isBuying) {
      buyNlp();
    } else {
      sellNlp();
    }
  };

  let payLabel = `Pay`;
  let receiveLabel = `Receive`;
  let payBalance = "$0.00";
  let receiveBalance = "$0.00";
  if (isBuying) {
    if (swapUsdMin) {
      payBalance = `$${formatAmount(swapUsdMin, USD_DECIMALS, 2, true)}`;
    }
    if (nlpUsdMax) {
      receiveBalance = `$${formatAmount(nlpUsdMax, USD_DECIMALS, 2, true)}`;
    }
  } else {
    if (nlpUsdMax) {
      payBalance = `$${formatAmount(nlpUsdMax, USD_DECIMALS, 2, true)}`;
    }
    if (swapUsdMin) {
      receiveBalance = `$${formatAmount(swapUsdMin, USD_DECIMALS, 2, true)}`;
    }
  }

  let feePercentageText = formatAmount(feeBasisPoints, 2, 2, true, "-");
  if (feeBasisPoints !== undefined && feeBasisPoints.toString().length > 0) {
    feePercentageText += "%";
  }

  const onSwapOptionChange = (opt) => {
    if (opt === `Sell NLP`) {
      switchSwapOption("redeem");
    } else {
      switchSwapOption();
    }
  };

  let adjustedUsdgSupply = bigNumberify(0);

  for (let i = 0; i < tokenList.length; i++) {
    const token = tokenList[i];
    const tokenInfo = infoTokens[token.address];
    if (tokenInfo && tokenInfo.usdgAmount) {
      adjustedUsdgSupply = adjustedUsdgSupply.add(tokenInfo.usdgAmount);
    }
  }

  return (
    <Wrapper>
      <Tab
        className="tab-new-style-fullwidth"
        options={[`Buy NLP`, `Sell NLP`]}
        option={tabLabel}
        onChange={onSwapOptionChange}
      />
      <div className="Pool-Form-container">
        {isBuying && (
          <BuyInputSection
            topLeftLabel={payLabel}
            topRightLabel={`Balance:`}
            tokenBalance={`${formatAmount(swapTokenBalance, swapToken.decimals, 4, true)}`}
            inputValue={swapValue}
            onInputValueChange={onSwapValueChange}
            showMaxButton={swapValue !== formatAmountFree(swapTokenBalance, swapToken.decimals, swapToken.decimals)}
            onClickTopRightLabel={fillMaxAmount}
            onClickMax={fillMaxAmount}
            selectedToken={swapToken}
            balance={payBalance}
            onlyNumber
          >
            <TokenSelector
              label={`Pay`}
              chainId={chainId}
              tokenAddress={swapTokenAddress}
              onSelectToken={onSelectSwapToken}
              tokens={whitelistedTokens}
              infoTokens={infoTokens}
              showSymbolImage={true}
              showTokenImgInDropdown={true}
            />
          </BuyInputSection>
        )}

        {!isBuying && (
          <BuyInputSection
            topLeftLabel={payLabel}
            topRightLabel={`Available:`}
            tokenBalance={`${formatAmount(maxSellAmount, NLP_DECIMALS, 4, true)}`}
            inputValue={nlpValue}
            onInputValueChange={onNlpValueChange}
            showMaxButton={nlpValue !== formatAmountFree(maxSellAmount, NLP_DECIMALS, NLP_DECIMALS)}
            onClickTopRightLabel={fillMaxAmount}
            onClickMax={fillMaxAmount}
            balance={payBalance}
            defaultTokenName={"NLP"}
            onlyNumber
          >
            <div className="selected-token">
              <img src={nlp24Icon} alt="nlp24Icon" />
              NLP
            </div>
          </BuyInputSection>
        )}

        <div className="AppOrder-ball-container">
          <div className="AppOrder-ball">
            <img
              src={arrowIcon}
              alt="arrowIcon"
              onClick={() => {
                setIsBuying(!isBuying);
                switchSwapOption(isBuying ? "redeem" : "");
              }}
            />
          </div>
        </div>
        <StyledWrap>
          {isBuying && (
            <BuyInputSection
              topLeftLabel={receiveLabel}
              topRightLabel={`Balance:`}
              tokenBalance={`${formatAmount(nlpBalance, NLP_DECIMALS, 4, true)}`}
              inputValue={nlpValue}
              onInputValueChange={onNlpValueChange}
              balance={receiveBalance}
              defaultTokenName={"NLP"}
              onlyNumber
            >
              <div className="selected-token">
                <img src={nlp24Icon} alt="nlp24Icon" />
                NLP
              </div>
            </BuyInputSection>
          )}

          {!isBuying && (
            <BuyInputSection
              topLeftLabel={receiveLabel}
              topRightLabel={`Balance:`}
              tokenBalance={`${formatAmount(swapTokenBalance, swapToken.decimals, 4, true)}`}
              inputValue={swapValue}
              onInputValueChange={onSwapValueChange}
              balance={receiveBalance}
              selectedToken={swapToken}
              onlyNumber
            >
              <TokenSelector
                label={`Receive`}
                chainId={chainId}
                tokenAddress={swapTokenAddress}
                onSelectToken={onSelectSwapToken}
                tokens={whitelistedTokens}
                infoTokens={infoTokens}
                className="BuySellNlp-from-token"
                showSymbolImage={true}
                showTokenImgInDropdown={true}
              />
            </BuyInputSection>
          )}
        </StyledWrap>
        <div>
          <div className="Exchange-info-row">
            <div className="Exchange-info-label">NLP Price</div>
            <div className="align-right fee-block">
              {nlpPrice > 0 && <div className="text-white">{formatAmount(nlpPrice, USD_DECIMALS, 2, true)} USD</div>}
            </div>
          </div>
          <div className="Exchange-info-row">
            <div className="Exchange-info-label">{feeBasisPoints > 50 ? `WARNING: High Fees` : `Fees`}</div>
            <div className="align-right fee-block">
              {isBuying && (
                <Tooltip
                  handle={isBuying && isSwapTokenCapReached ? "NA" : feePercentageText}
                  position="right-bottom"
                  renderContent={() => {
                    if (!feeBasisPoints) {
                      return (
                        <div className="text-white">
                          <span>Fees will be shown once you have entered an amount in the order form.</span>
                        </div>
                      );
                    }
                    return (
                      <div className="text-white">
                        {feeBasisPoints > 50 && <span>To reduce fees, select a different asset to pay with.</span>}
                        <span style={{ marginLeft: "4px" }}>
                          Check the "Save on Fees" section below to get the lowest fee percentages.
                        </span>
                      </div>
                    );
                  }}
                />
              )}
              {!isBuying && (
                <Tooltip
                  handle={feePercentageText}
                  position="right-bottom"
                  renderContent={() => {
                    if (!feeBasisPoints) {
                      return (
                        <div className="text-white">
                          <span>Fees will be shown once you have entered an amount in the order form.</span>
                        </div>
                      );
                    }
                    return (
                      <div className="text-white">
                        {feeBasisPoints > 50 && <span>To reduce fees, select a different asset to receive.</span>}
                        <span style={{ marginLeft: "4px" }}>
                          Check the "Save on Fees" section below to get the lowest fee percentages.
                        </span>
                      </div>
                    );
                  }}
                />
              )}
            </div>
          </div>
          {!isBuying && (
            <div className="Exchange-info-row">
              <div className="Exchange-info-label">
                <Tooltip
                  handle="Cooldown Period"
                  renderContent={() => {
                    return `There is a ${coundowHour}-hour cooldown period after each stake.`;
                  }}
                />
              </div>

              <div className="align-right fee-block">
                <Countdown
                  key={redemptionTime}
                  date={new Date(Number(redemptionTime) * 1000)}
                  renderer={countdownRenderer}
                  daysInHours
                />
              </div>
            </div>
          )}
          {isBuying && (
            <Stylednoti>
              <div>
                <img src={infoIcon} alt="info" />
              </div>
              <div>NLP will automatically be staked after purchase.</div>
            </Stylednoti>
          )}
        </div>
        <div className="BuySellNlp-cta Exchange-swap-button-container">
          <button className="App-cta Exchange-swap-button" onClick={onClickPrimary} disabled={!isPrimaryEnabled()}>
            {getPrimaryText()}
          </button>
        </div>
      </div>
    </Wrapper>
  );
}

const countdownRenderer = (countdownProps) => {
  const {
    formatted: { hours, minutes, seconds },
  } = countdownProps;
  return <>{`${hours}:${minutes}:${seconds}`}</>;
};

const StyledWrap = styled.div`
  margin-top: -34px;
`;
const Wrapper = styled.div`
  @media screen and (max-width: 700px) {
    .AppOrder-ball {
      img {
        width: 24px !important;
        height: 24px !important;
        position: relative;
        top: 5px;
      }
    }

    .body .body-row {
      border-radius: 16px;
    }
  }
`;
const Stylednoti = styled.div`
  border-radius: 100px;
  background: var(--Nature-2, #1a1d26);
  display: flex;
  padding: var(--Base-Unit-XS-8, 8px);
  align-items: center;
  gap: var(--Base-Unit-XS-8, 8px);
  margin-top: 4px;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
  @media screen and (max-width: 700px) {
    img {
      width: 16px;
      height: 16px;
    }
  }
`;
