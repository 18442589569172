import { useWeb3React } from "@web3-react/core";
import { BigNumber, ethers } from "ethers";
import { createContext, useEffect, useMemo, useState } from "react";
import useSWR from "swr";
import moment from "moment";
import NewVaultJson from "abis/NewVault.json";
import { getServerUrl, getServerUrlV2 } from "config/backend";
import { ARBITRUM, BASE, SONIC_TESTNET, FANTOM, FANTOM_LEGACY, OP } from "config/chains";
import { getContract } from "config/contracts";
import { getWhitelistedTokens } from "config/tokens";
import { useNAVIInfo, useNaviPrice, useTotalNaviStaked } from "domain/legacy";
import { useInfoTokens } from "domain/tokens";
import { useChainId } from "lib/chains";
import { contractFetcher } from "lib/contracts";
import {
  ACTIVE_CHAIN_IDS,
  ACTIVE_CHAIN_IDS_V2,
  NLP_DECIMALS,
  NAVI_DECIMALS,
  USD_DECIMALS,
  arrayURLFetcher,
  getStatsInfo,
  getStatsInfoV2,
} from "lib/legacy";
import { bigNumberify, expandDecimals, parseValue } from "lib/numbers";
import { gql } from "@apollo/client";
import NlpManager from "abis/NlpManager.json";
import ReaderV2 from "abis/ReaderV2.json";
import Token from "abis/Token.json";
import { isEmpty } from "lodash";
import { getNaviGraphClient } from "lib/subgraph";
const { AddressZero } = ethers.constants;

export const StatsContext = createContext();

const StatsProvider = ({ children }) => {
  const { chainId } = useChainId();
  const { active, library } = useWeb3React();

  const { data: dataStatsAll } = useSWR(
    ACTIVE_CHAIN_IDS.map((chainId) => getServerUrl(chainId, "/app-stats")),
    {
      fetcher: arrayURLFetcher,
      refreshInterval: 30000,
    }
  );

  const { data: dataStatsAllV2 } = useSWR(
    ACTIVE_CHAIN_IDS_V2.map((chainId) => getServerUrlV2(chainId, "/public/app-stats")),
    {
      fetcher: arrayURLFetcher,
      refreshInterval: 30000,
    }
  );


  const { data: vault } = useSWR(
    "https://api.navigator.exchange/public/vault/stats",
    {
      refreshInterval: 30000,
    }
  );

  const { naviPrice } = useNaviPrice(
    chainId,
    { arbitrum: chainId === ARBITRUM ? library : undefined, op: chainId === OP ? library : undefined },
    active
  );
  const currentStats = getStatsInfo(dataStatsAll);
  const currentStatsV2 = getStatsInfoV2(dataStatsAllV2);
  const whitelistedTokens = getWhitelistedTokens(chainId);
  const tokenList = whitelistedTokens.filter((t) => !t.isWrapped);

  const nlpManagerAddress = getContract(chainId, "NlpManager");
  // const nlpManagerAddressFANTOM = getContract(FANTOM, "NlpManager");
  // const nlpManagerAddressOP = getContract(OP, "NlpManager");
  // const nlpManagerAddressARB = getContract(ARBITRUM, "NlpManager");
  // const nlpManagerAddressBase = getContract(BASE, "NlpManager");
  const nlpManagerAddressSONIC = getContract(SONIC_TESTNET, "NlpManager");

  const readerAddress = getContract(chainId, "Reader");
  const naviAddress = getContract(chainId, "NAVI");
  const nlpAddress = getContract(chainId, "NLP");
  const usdgAddress = getContract(chainId, "USDN");
  const nlpVesterAddress = getContract(chainId, "NlpVester");
  const naviVesterAddress = getContract(chainId, "NaviVester");
  const tokensForSupplyQuery = [naviAddress, nlpAddress, usdgAddress];

  const { data: aums } = useSWR([`Dashboard:getAums`, chainId, nlpManagerAddress, "getAums"], {
    fetcher: contractFetcher(library, NlpManager),
  });
  const utcToday = moment().utc().startOf("day").unix();
  const { data: DailyStatsAll } = useSWR(
    ACTIVE_CHAIN_IDS.map((chainId) => `${getServerUrl(chainId, "/daily-stats")}?from=${utcToday}&to=${utcToday}`),
    {
      fetcher: arrayURLFetcher,
      refreshInterval: 30000,
    }
  );
  const { data: dailyStatsUsers } = useSWR(
    `https://api.navigator.exchange/stats/sonic/daily-stats?from=${utcToday}&to=${utcToday}`,
    {
      fetcher: arrayURLFetcher,
      refreshInterval: 30000,
    }
  );

  const [aumsAllChain, setAumsAllChain] = useState({});

  useEffect(() => {
    const query = gql(` {
      nlpStats(where: {id_gte: "${utcToday}"}) {
        aumInUsdn
      }
    }`);

    (async () => {
      const notIncludeLegacy = ACTIVE_CHAIN_IDS.filter((chainId) => chainId !== FANTOM_LEGACY);

      const data = await Promise.all(notIncludeLegacy.map((chainId) => getNaviGraphClient(chainId)?.query({ query })));
      // console.log("????+++", data);

      const result = {};

      notIncludeLegacy.forEach((chainId, index) => {
        result[chainId] = data[index].data.nlpStats.length ? data[index].data.nlpStats[0].aumInUsdn : undefined;
      });

      setAumsAllChain(result);
    })();
  }, []);

  const { infoTokens } = useInfoTokens(library, chainId, active, undefined, undefined);

  let aum;
  if (aums && aums.length > 0) {
    aum = aums[0].add(aums[1]).div(2);
  }

  let adjustedUsdgSupply = bigNumberify(0);

  for (let i = 0; i < tokenList.length; i++) {
    const token = tokenList[i];
    const tokenInfo = infoTokens[token.address];
    if (tokenInfo && tokenInfo.usdgAmount) {
      adjustedUsdgSupply = adjustedUsdgSupply.add(tokenInfo.usdgAmount);
    }
  }

  const { data: totalSupplies } = useSWR(
    [`Dashboard:totalSupplies:${active}`, chainId, readerAddress, "getTokenBalancesWithSupplies", AddressZero],
    {
      fetcher: contractFetcher(library, ReaderV2, [tokensForSupplyQuery]),
      refreshInterval: 30000,
    }
  );

  const { data: naviSupplyOfNaviVester } = useSWR(
    [`Dashboard:naviSupplyOfNaviVester:${active}`, chainId, naviAddress, "balanceOf", naviVesterAddress],
    {
      fetcher: contractFetcher(library, Token),
      refreshInterval: 30000,
    }
  );

  const { data: naviSupplyOfNlpVester } = useSWR(
    [`Dashboard:naviSupplyOfNlpVester:${active}`, chainId, naviAddress, "balanceOf", nlpVesterAddress],
    {
      fetcher: contractFetcher(library, Token),
      refreshInterval: 30000,
    }
  );
  
  const {
    total: totalNaviStaked,
    // fantom: fantomNaviStaked,
    // fantomlegacy: fantomNaviStakedLegacy,
    // op: opNaviStaked,
    // oplegacy: opNaviStakedLegacy,
    // arbitrum: arbitrumStakedNavi,
    // base: baseStakedNavi,
    sonic: sonicNaviStaked,
  } = useTotalNaviStaked();
  let currentChainNaviStaked = sonicNaviStaked;
  // if (chainId === OP) currentChainNaviStaked = opNaviStaked;
  // if (chainId === ARBITRUM) currentChainNaviStaked = arbitrumStakedNavi;
  // if (chainId === BASE) currentChainNaviStaked = baseStakedNavi;
  if (chainId === SONIC_TESTNET) currentChainNaviStaked = sonicNaviStaked;

  const { data: aumsSONIC } = useSWR([`Dashboard:getAumsSONIC`, SONIC_TESTNET, nlpManagerAddressSONIC, "getAums"], {
    fetcher: contractFetcher(library, NlpManager),
    refreshInterval: 30000,
  });

  // const { data: aumsOP } = useSWR([`Dashboard:getAumsOP`, OP, nlpManagerAddressOP, "getAums"], {
  //   fetcher: contractFetcher(library, NlpManager),
  //   refreshInterval: 30000,
  // });

  // const { data: aumsFANTOM } = useSWR([`Dashboard:getAumsFANTOM`, FANTOM, nlpManagerAddressFANTOM, "getAums"], {
  //   fetcher: contractFetcher(library, NlpManager),
  //   refreshInterval: 30000,
  // });
  // const { data: aumsFANTOMlegacy } = useSWR(
  //   [`Dashboard:getAumsFANTOMlegacy`, FANTOM, "0x304951d7172bCAdA54ccAC1E4674862b3d5b3d5b", "getAums"],
  //   {
  //     fetcher: contractFetcher(library, NlpManager),
  //     refreshInterval: 30000,
  //   }
  // );

  // const { data: aumsArb } = useSWR([`Dashboard:getAumsARB`, ARBITRUM, nlpManagerAddressARB, "getAums"], {
  //   fetcher: contractFetcher(library, NlpManager),
  //   refreshInterval: 30000,
  // });

  // const { data: aumsBase } = useSWR([`Dashboard:getAumsBase`, BASE, nlpManagerAddressBase, "getAums"], {
  //   fetcher: contractFetcher(library, NlpManager),
  //   refreshInterval: 30000,
  // });

  let aumSONIC;
  if (aumsSONIC && aumsSONIC.length > 0) {
    aumSONIC = aumsSONIC[0].add(aumsSONIC[1]).div(2);
  }

  let nlpPrice;
  let nlpSupply;
  let nlpMarketCap;
  if (aum && totalSupplies && totalSupplies[3]) {
    nlpSupply = totalSupplies[3];
    if (naviSupplyOfNaviVester && naviSupplyOfNlpVester) {
      nlpSupply = nlpSupply.sub(naviSupplyOfNaviVester).sub(naviSupplyOfNlpVester);
    }

    nlpPrice =
      aum && aum.gt(0) && nlpSupply.gt(0)
        ? aum.mul(expandDecimals(1, NLP_DECIMALS)).div(nlpSupply)
        : expandDecimals(1, USD_DECIMALS);
    nlpMarketCap = nlpPrice.mul(nlpSupply).div(expandDecimals(1, NLP_DECIMALS));
  }

  let tvl;
  if (currentChainNaviStaked && aum && naviPrice) {
    tvl = naviPrice.mul(currentChainNaviStaked).div(expandDecimals(1, NAVI_DECIMALS)).add(aum);
  }

  const [sonicTvl, v2sonicTvl] = useMemo(() => {
    let sonicLogValue;
    if (sonicNaviStaked && naviPrice && aumSONIC) {
      // console.log(
      //   "?????1",
      //   DailyStatsAll,
      //   parseValue(DailyStatsAll?.[0]?.[0]?.naviStakedValue || 0, 30),
      //   parseValue(aumsAllChain[SONIC_TESTNET], 12)
      // );

      // sonicLogValue = naviPrice.mul(sonicNaviStaked).div(expandDecimals(1, NAVI_DECIMALS)).add(aumSONIC);
      sonicLogValue = parseValue(DailyStatsAll?.[0]?.[0]?.naviStakedValue || 0, 30).add(
        parseValue(aumsAllChain[SONIC_TESTNET], 12) || BigNumber.from(0)
      );
    }

    let v2sonicTvl = BigNumber.from(0);

    if (currentStatsV2?.[SONIC_TESTNET]?.nslpTvl) {
      v2sonicTvl = parseValue(currentStatsV2?.[SONIC_TESTNET]?.nslpTvl || 0, USD_DECIMALS);
    }

    return [
      // fantomLogValue,
      // fantomLegacyLogValue,
      // opLogValue,
      // arbLogValue,
      // baseLogValue,
      sonicLogValue,
      v2sonicTvl,
    ];
  }, [sonicNaviStaked, naviPrice, currentStatsV2, aumSONIC]);

  const totalTvl = useMemo(() => {
    let totalLogVal;
    if (sonicTvl) {
      totalLogVal = sonicTvl;
    }
    return totalLogVal;
  }, [sonicTvl]);

  const totalTvlAll = useMemo(() => {
    let totalLogValAll;
    if (totalTvl && v2sonicTvl) {
      totalLogValAll = totalTvl.add(v2sonicTvl);
    }

    if(totalLogValAll && vault && vault.data && vault.data.tvl) {
      totalLogValAll = totalLogValAll.add(parseValue(vault.data.tvl,30))
    }
    
    return totalLogValAll;
  }, [totalTvl, v2sonicTvl, vault]);

  // let totalTvl;
  // if (fantomTvl && fantomLegacyTvl && opTvl && arbTvl && baseTvl) {
  //   totalTvl = fantomTvl.add(opTvl).add(arbTvl).add(baseTvl);
  // }

  const totalAllVersion = useMemo(() => {
    if (!isEmpty(currentStats) && !isEmpty(currentStatsV2)) {
      return {
        totalTradingVolume: currentStats?.totalVolume?.add(parseValue(currentStatsV2?.volume || 0, USD_DECIMALS)),
        totalFees: currentStats?.totalFees?.add(parseValue(currentStatsV2?.fees || 0, USD_DECIMALS)),
        totalFeeSince: currentStats?.feeSinceToNow?.add(parseValue(currentStatsV2?.feeSince || 0, USD_DECIMALS)),
        longOpenInterest: currentStats?.longOpenInterest?.add(
          parseValue(currentStatsV2?.longAmountInterest || 0, USD_DECIMALS)
        ),
        shortOpenInterest: currentStats?.shortOpenInterest?.add(
          parseValue(currentStatsV2?.shortAmountInterest || 0, USD_DECIMALS)
        ),
        totalUsers: BigNumber.from(dailyStatsUsers?.[0]?.data?.[0]?.cumulativeAll || 0),
      };
    }
    return {};
  }, [currentStats, currentStatsV2]);
  // console.log("v2sonicTvl", v2sonicTvl, sonicTvl);
  const valueProvider = useMemo(() => {
    return {
      nlpPrice,
      naviPrice,
      totalValueLocked: {
        total: totalTvl,
        totalAll: totalTvlAll,
        totalV2: v2sonicTvl,
        [SONIC_TESTNET]: sonicTvl,
        v2: {
          [SONIC_TESTNET]: v2sonicTvl,
        },
      },
      totalFees: {
        total: currentStats.totalFees,
        // [FANTOM_LEGACY]: currentStats[FANTOM_LEGACY]?.totalFees,
        // [FANTOM]: currentStats[FANTOM]?.totalFees,
        // [OP]: currentStats[OP]?.totalFees,
        // [ARBITRUM]: currentStats[ARBITRUM]?.totalFees,
        [SONIC_TESTNET]: currentStats[SONIC_TESTNET]?.totalFees,
      },
      totalUsers: {
        total: currentStats.totalUser,
        // [FANTOM]: currentStats[FANTOM]?.totalUser,
        // [FANTOM_LEGACY]: currentStats[FANTOM_LEGACY]?.totalUser,
        // [OP]: currentStats[OP]?.totalUser,
        // [ARBITRUM]: currentStats[ARBITRUM]?.totalUser,
        [SONIC_TESTNET]: currentStats[SONIC_TESTNET]?.totalUser,
      },
      totalTradingVolume: {
        total: currentStats.totalVolume,
        // [FANTOM]: currentStats[FANTOM]?.totalVolume,
        // [FANTOM_LEGACY]: currentStats[FANTOM_LEGACY]?.totalVolume,
        // [OP]: currentStats[OP]?.totalVolume,
        // [ARBITRUM]: currentStats[ARBITRUM]?.totalVolume,
        [SONIC_TESTNET]: currentStats[SONIC_TESTNET]?.totalVolume,
      },
      longOpenInterest: {
        total: currentStats.longOpenInterest,
        // [FANTOM]: currentStats[FANTOM]?.longOpenInterest,
        // [FANTOM_LEGACY]: currentStats[FANTOM_LEGACY]?.longOpenInterest,
        // [OP]: currentStats[OP]?.longOpenInterest,
        // [ARBITRUM]: currentStats[ARBITRUM]?.longOpenInterest,
        [SONIC_TESTNET]: currentStats[SONIC_TESTNET]?.longOpenInterest,
      },
      shortOpenInterest: {
        total: currentStats.shortOpenInterest,
        [SONIC_TESTNET]: currentStats[SONIC_TESTNET]?.shortOpenInterest,
        // [FANTOM_LEGACY]: currentStats[FANTOM_LEGACY]?.shortOpenInterest,
        // [OP]: currentStats[OP]?.shortOpenInterest,
        // [ARBITRUM]: currentStats[ARBITRUM]?.shortOpenInterest,
        // [BASE]: currentStats[BASE]?.shortOpenInterest,
      },
      feeSinceToNow: {
        total: currentStats.feeSinceToNow,
        [SONIC_TESTNET]: {
          value: currentStats[SONIC_TESTNET]?.feeSinceToNow,
          timestamps: currentStats[SONIC_TESTNET]?.feeSinceTimestamp,
        },
        // [FANTOM_LEGACY]: {
        //   value: currentStats[FANTOM_LEGACY]?.feeSinceToNow,
        //   timestamps: currentStats[FANTOM_LEGACY]?.feeSinceTimestamp,
        // },
        // [OP]: {
        //   value: currentStats[OP]?.feeSinceToNow, //TODO
        //   timestamps: currentStats[OP]?.feeSinceTimestamp,
        // },
        // [ARBITRUM]: {
        //   value: currentStats[ARBITRUM]?.feeSinceToNow,
        //   timestamps: currentStats[ARBITRUM]?.feeSinceTimestamp,
        // },
        // [BASE]: {
        //   value: currentStats[BASE]?.feeSinceToNow,
        //   timestamps: currentStats[BASE]?.feeSinceTimestamp,
        // },
      },
      v2: currentStatsV2,
      totalAllVersion,
    };
  }, [nlpPrice, naviPrice, totalTvl, totalTvlAll, v2sonicTvl, sonicTvl, currentStats, currentStatsV2, totalAllVersion]);

  return <StatsContext.Provider value={valueProvider}>{children}</StatsContext.Provider>;
};

export default StatsProvider;
