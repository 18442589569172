/* eslint-disable jsx-a11y/anchor-is-valid */
import { useContext, useState } from "react";
import styled from "styled-components";

import { USD_DECIMALS } from "lib/legacy";
import { formatAmount, parseValue } from "lib/numbers";
import arrow from "img/trade/arrow-down-green.svg";
import cx from "classnames";
import { ARBITRUM, BASE, SONIC_TESTNET, FANTOM, OP, getChainName, FANTOM_TESTNET } from "config/chains";
import iconLong from "img/dashboard/longPosition.svg";
import iconShort from "img/dashboard/shortPosition.svg";
import iconSince from "img/dashboard/since.svg";
import iconFee from "img/dashboard/tfee.svg";
import iconTvl from "img/dashboard/tvolumn.svg";
import iconVolumn from "img/dashboard/tvl.svg";
import { default as arb24Icon, default as arbitrumIcon } from "img/ic_arbitrum_24.svg";
import baseIcon from "img/ic_base_24.svg";
import baseImg from "img/ic_base_40.svg";
import sonicIcon from "img/ic_bera_24.png";
import { default as ftm24Icon, default as ftmIcon } from "img/ic_ftm_24.svg";
import opIcon from "img/ic_op_24.svg";
import opImg from "img/ic_op_40.svg";
import iconToken from "img/ic_s_16.svg";
import { formatDate } from "lib/dates";
import { useMediaQuery } from "react-responsive";
import VolumeChart from "./VolumeChart";

import { Dropdown } from "antd";
import { StatsContext } from "contexts/StatsProvider";
import { useThemeContext } from "contexts/ThemeProvider";
import { BigNumber } from "ethers";
import Tooltip from "components/Tooltip/Tooltip";

const STATS_CHAINS = [
  {
    chainId: FANTOM,
    img: ftm24Icon,
  },
  {
    chainId: OP,
    img: opImg,
  },
  {
    chainId: ARBITRUM,
    img: arb24Icon,
  },
  {
    chainId: BASE,
    img: baseImg,
  },
];

function NavIcons({ selectorLabel }) {
  return (
    <>
      <button className="btn-token-select">
        <img
          width={36}
          height={36}
          className="network-dropdown-icon"
          src={
            selectorLabel === "Fantom"
              ? ftmIcon
              : selectorLabel === "Optimism"
              ? opIcon
              : selectorLabel === "Optimism"
              ? sonicIcon
              : selectorLabel === "Base"
              ? baseIcon
              : arbitrumIcon
          }
          alt={selectorLabel}
        />
      </button>
    </>
  );
}

const Stats = ({ chainId, active }) => {
  const { lightThemeClassName, isLightTheme } = useThemeContext();
  const isTablet = useMediaQuery({ query: "(max-width: 1023px) and (min-width: 767px)" });
  const totalStatsStartDate = {
    [FANTOM]: `05 Dec 2022`,
    [FANTOM_TESTNET]: `05 Dec 2024`,
    [OP]: `03 Mar 2023`,
    [ARBITRUM]: `29 Jun 2023`,
    [BASE]: `16 Oct 2023`,
    [SONIC_TESTNET]: `17 Dec 2024`,
  };
  const chainName = getChainName(chainId);

  const {
    v2,
    totalAllVersion,
    totalValueLocked,
    totalFees,
    totalTradingVolume,
    longOpenInterest,
    shortOpenInterest,
    feeSinceToNow,
  } = useContext(StatsContext);
  const [selectedNetwork, setSelectedNetwork] = useState("Fantom");
  const handleSelect = () => {};
  const items = [
    {
      label: (
        <div
          onClick={() => {
            setSelectedNetwork("Fantom");
          }}
          className={cx({
            selected: selectedNetwork === "Fantom",
          })}
        >
          <img src={ftmIcon} alt="img" />
          Fantom Opera
        </div>
      ),
    },
    {
      label: (
        <div
          className={cx({
            selected: selectedNetwork === "Optimism",
          })}
          onClick={() => {
            setSelectedNetwork("Optimism");
          }}
        >
          <img src={opIcon} alt="img" />
          Optimism
        </div>
      ),
    },
    {
      label: (
        <div
          onClick={() => {
            setSelectedNetwork("Base");
          }}
          className={cx({
            selected: selectedNetwork === "Base",
          })}
        >
          <img src={baseIcon} alt="img" />
          Base
        </div>
      ),
    },
  ];

  return (
    <Wrapper className={lightThemeClassName}>
      <Title>
        <span>
          Total Stats <img alt="s" src={iconToken} />
        </span>
        {/* <DropdownStyled placement="bottomRight" overlayStyle={{ width: "242px" }} menu={{ items }}>
          <a onClick={(e) => e.preventDefault()}>
            {<NavIcons selectorLabel={selectedNetwork} />} <img className="img-arr" src={arrow} alt="arrow" />
          </a>
        </DropdownStyled> */}
      </Title>
      <Description>
        {/* {chainName}  */}
        Total stats start from {totalStatsStartDate[chainId]}
        {/* . For detailed stats:{" "} */}.
      </Description>

      <StatsContainer>
        <StatBlock>
          <div className="img-block">
            <img src={iconTvl} alt="icon-fee" />
          </div>
          <div className="stats-info total-value">
            <div className="label" style={{ display: "flex" }}>
              Total Value Locked{" "}
              <Tooltip
                handle={``}
                position="left-bottom"
                className="tooltip-total-lock"
                renderContent={() => {
                  return (
                    <div className="lock-tooltip-container">
                      <div>Total value locked takes into account:</div>
                      <ul>
                        <li>NAVI & esNAVI staked</li>
                        <li>Stable pool (NSLP)</li>
                        <li>Crypto pool (NLP)</li>
                        <li>Navigator vaults</li>
                      </ul>
                    </div>
                  );
                }}
              />
            </div>
            <div className="stats-value">
              {/**/}
              {totalValueLocked?.totalAll ? (
                <div>{"$" + formatAmount(totalValueLocked?.totalAll, USD_DECIMALS, 0, true)}</div>
              ) : (
                <div>$--</div>
                // <div className="skeleton-box" />
              )}
            </div>
          </div>
        </StatBlock>
        <StatBlock>
          <div className="img-block">
            <img src={iconVolumn} alt="icon-fee" />
          </div>

          <div className="stats-info total-volumn ">
            <div className="label">Total Volume</div>
            <div className="stats-value">
              {totalTradingVolume?.[chainId] ? (
                "$" +
                formatAmount(
                  BigNumber.from(totalTradingVolume?.[chainId]).add(
                    parseValue(v2?.[chainId]?.["volume"] || 0, USD_DECIMALS)
                  ),
                  USD_DECIMALS,
                  0,
                  true
                )
              ) : (
                <div className="skeleton-box" />
              )}
            </div>
          </div>
        </StatBlock>
        <StatBlock>
          <div className="img-block">
            <img src={iconFee} alt="icon-fee" />
          </div>
          <div className="stats-info total-fee">
            <div className="label">Total Fees</div>
            <div className="stats-value">
              {totalFees[chainId] ? (
                "$" +
                formatAmount(
                  BigNumber.from(totalFees[chainId]).add(parseValue(v2?.[chainId]?.["fees"] || 0, USD_DECIMALS)),
                  USD_DECIMALS,
                  0,
                  true
                )
              ) : (
                <div className="skeleton-box" />
              )}
            </div>
          </div>
        </StatBlock>
        <StatBlock>
          <div className="img-block">
            <img src={iconLong} alt="icon-fee" />
          </div>
          <div className="stats-info long-position">
            <div className="label">Long Positions</div>
            <div className="stats-value">
              {longOpenInterest[chainId] ? (
                "$" +
                formatAmount(
                  BigNumber.from(longOpenInterest[chainId]).add(
                    parseValue(v2?.[chainId]?.["longAmountInterest"] || 0, USD_DECIMALS)
                  ),
                  USD_DECIMALS,
                  0,
                  true
                )
              ) : (
                <div className="skeleton-box" />
              )}
            </div>
          </div>
        </StatBlock>
        <StatBlock>
          <div className="img-block">
            <img src={iconShort} alt="icon-fee" />
          </div>
          <div className="stats-info short-position">
            <div className="label">Short Positions</div>
            <div className="stats-value">
              {shortOpenInterest[chainId] ? (
                "$" +
                formatAmount(
                  BigNumber.from(shortOpenInterest[chainId]).add(
                    parseValue(v2?.[chainId]?.["shortAmountInterest"] || 0, USD_DECIMALS)
                  ),
                  USD_DECIMALS,
                  0,
                  true
                )
              ) : (
                <div className="skeleton-box" />
              )}
            </div>
          </div>
        </StatBlock>
        <StatBlock>
          <div className="img-block">
            <img src={iconSince} alt="icon-fee" />
          </div>
          <div className="stats-info fee-since">
            <div className="label">
              Fees Since {feeSinceToNow[chainId]?.timestamps ? formatDate(feeSinceToNow[chainId]?.timestamps) : ""}
            </div>
            <div className="stats-value">
              {feeSinceToNow?.[chainId]?.timestamps && feeSinceToNow?.[chainId]?.value ? (
                "$" +
                formatAmount(
                  BigNumber.from(feeSinceToNow?.[chainId]?.value)?.add(
                    parseValue(v2?.[chainId]?.["feeSince"] || 0, USD_DECIMALS)
                  ),
                  USD_DECIMALS,
                  0,
                  true
                )
              ) : (
                <div className="skeleton-box" />
              )}
            </div>
          </div>
        </StatBlock>
      </StatsContainer>
      <VolumeChart chainId={chainId} />
    </Wrapper>
  );
};

const StatBlock = styled.div`
  display: flex;
  align-items: center;

  gap: 16px;
  position: relative;
  .img-block {
    border-radius: 100px;
    background: var(--Nature-2, #1a1d26);
    padding: 12px;
  }
  img {
  }

  .stats-info {
    width: 100%;

    position: relative;
  }

  .label {
    margin-bottom: 8px;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    white-space: nowrap;
    line-height: 140%;
    color: #828899;
  }
  .stats-value {
    font-weight: 700;
    font-size: 24px;
    line-height: 140%;

    @media (max-width: 1023px) {
      font-size: 16px;
    }
  }

  @media screen and (max-width: 600px) {
    .stats-info.total-volumn .Tooltip-popup,
    .stats-info.long-position .Tooltip-popup,
    .stats-info.fee-since .Tooltip-popup {
      left: -200px;
      right: 0;
      width: 85vw !important;
    }
  }
`;

const StatsContainer = styled.div`
  margin: 36px 0;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 24px;

  @media screen and (max-width: 700px) {
    grid-template-columns: repeat(1, 1fr);
    /* display: flex;
    flex-wrap: wrap;
    justify-content: center; */
  }
  @media screen and (max-width: 930px) {
  }

  @media (max-width: 767px) {
  }
`;

const Description = styled.div`
  color: #828899;
  font-size: 14px;

  font-weight: 500;
  line-height: 140%;
`;

const Title = styled.div`
  color: #fff;
  font-weight: 700;
  font-size: 36px;
  line-height: 140%;
  margin-bottom: 8px;

  img {
    width: 36px;
    height: 36px;
  }
  @media screen and (max-width: 700px) {
    font-size: 24px;
    img {
      width: 24px;
      height: 24px;
    }
  }
`;

const CustomContent = styled.div`
  width: 296px;

  .k-v-box {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: var(--base-unit-xs-8, 8px);

    .k-v-label {
      color: var(--white-60, #828899);
      font-size: 14px;
      font-weight: 400;
      line-height: 140%; /* 19.6px */

      &:has(.ver) {
        display: flex;
        align-items: center;
        gap: var(--Border_radius, 4px);

        .ver {
          display: flex;
          padding: 0px var(--base-unit-xs-8, 8px);
          flex-direction: column;
          align-items: flex-start;
          gap: var(--base-unit-xs-8, 8px);
          border-radius: var(--Border_radius, 4px);
          border: 2px solid var(--main-primary, #90fb75);
          height: 20px;

          color: var(--main-primary, #90fb75);
          font-size: 14px;
          font-weight: 400;
          line-height: 140%; /* 19.6px */

          &.v2 {
            color: var(--Warning, #ffdf76);
            border-radius: var(--Border_radius, 4px);
            border: 2px solid var(--Warning, #ffdf76);
          }
        }
      }
    }

    & > .k-v-value {
      color: #fff !important;
      font-size: 16px;
      font-weight: 700 !important;
      line-height: 140%; /* 22.4px */
    }
  }

  .divider {
    margin: 12px 0;
  }

  .tooltip-row {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    align-self: stretch;
  }

  .chains {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: var(--base-unit-xs-8, 8px);
  }

  .chain {
    display: flex;
    align-items: center;
    gap: var(--base-unit-xs-8, 8px);

    .chain-img {
      display: flex;
      width: 16px;
      height: 16px;
      justify-content: center;
      align-items: center;
    }

    .chain-value {
      color: var(--white-100, #fff);
      font-size: 14px;
      font-weight: 400;
      line-height: 140%; /* 19.6px */
    }
  }
`;

const Wrapper = styled.div`
  position: relative;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  > *:not(.background-chain) {
    position: relative;
    z-index: 2;
  }

  .btn-token-select {
    background-color: transparent;
    border: none;
    outline: none;
    padding: 0 8px;
  }
  @media (max-width: 767px) {
    .Tooltip-popup {
      min-width: 240px !important;
      width: unset !important;
    }
  }

  &.theme--light {
    border-radius: 24px;
    border: 1px solid var(--Border, rgba(13, 26, 22, 0.07));

    .recharts-layer text {
      fill: #0d1a16b2 !important;
    }

    /* .background-chain {
      background: radial-gradient(50% 285.34% at 100% 0%, #a5e8ff 0%, #fff 100%);

      &.op-content {
        background: radial-gradient(50% 285.34% at 100% 0%, #ffa5af 0%, #fff 100%);
      }

      &.arb-content {
        background: radial-gradient(50% 285.34% at 100% 0%, #a5d3ff 0%, #fff 100%);
      }

      &.base-content {
        background: radial-gradient(50% 285.34% at 100% 0%, #aac5ff 0%, #fff 100%);
      }
    } */

    ${Description} {
      * {
        color: var(--Text-Text_Secondary, rgba(13, 26, 22, 0.7));
      }
    }

    .stats-img {
      position: absolute;
    }
  }
`;
const DropdownStyled = styled(Dropdown)`
  .img-arr {
    width: 24px;
    height: 24px;
    margin-left: -12px;
  }
  cursor: pointer;
`;
export default Stats;
