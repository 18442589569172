import {
  DECREASE,
  FUNDING_RATE_PRECISION,
  getLiqRisk,
  getLiquidationPrice,
  getOrderError,
  importImage,
  INCREASE,
  USD_DECIMALS,
} from "lib/legacy";
import { Spin } from "antd";
import { bigNumberify, formatAmount, parseValue } from "lib/numbers";
import cx from "classnames";
import Tooltip from "components/Tooltip/Tooltip";
import StatsTooltipRow from "components/StatsTooltip/StatsTooltipRow";
import { renderRisk, renderRiskV1 } from "lib/helper";

import { LoadingOutlined } from "@ant-design/icons";
import PositionDropdown from "../Exchange/PositionDropdown";
import moment from "moment";
import editIcon from "img/trade/edit.svg";
import ic_ArrowDown from "img/trade/arrow-red.svg";
import ic_ArrowUp from "img/trade/arrow-up-green.svg";
import ic_chain from "img/trade/chains.svg";
import ic_chain_error from "img/trade/chains_error.svg";
import ic_chainLight from "img/trade/chains_light.svg";
import styled from "styled-components";
import { useThemeContext } from "contexts/ThemeProvider";
const dateFormat = "YYYY-MM-DD";
const timeFormat = "HH:mm:ss";

const getOrdersForPosition = (account, position, orders, nativeTokenAddress) => {
  if (!orders || orders.length === 0) {
    return [];
  }
  /* eslint-disable array-callback-return */
  return orders
    .filter((order) => {
      const hasMatchingIndexToken =
        order.indexToken === nativeTokenAddress
          ? position.indexToken.isNative
          : order.indexToken === position.indexToken.address;
      const hasMatchingCollateralToken =
        order.collateralToken === nativeTokenAddress
          ? position.collateralToken.isNative
          : order.collateralToken === position.collateralToken.address;
      if (order.isLong === position.isLong && hasMatchingIndexToken && hasMatchingCollateralToken) {
        return true;
      }
    })
    .map((order) => {
      order.error = getOrderError(account, order, undefined, position);
      if (order.type === DECREASE && order.sizeDelta.gt(position.size)) {
        order.error = `Order size is bigger than position, will only be executable if position increases`;
      }
      return order;
    });
};

const PositionItemMobileV1 = (props) => {
  const {
    position,
    handleAddTPSL,
    setIsPositionShareModalOpen,
    setPositionToShare,
    sellPosition,
    editPosition,
    account,
    setListSection,
    onPositionClick,
    orders,
    nativeTokenAddress,
    showPnlAfterFees,
    handleAddToPosition,
    myOrders,
    setPosOrders,
  } = props;

  const theme = useThemeContext();
  const liquidationPrice = getLiquidationPrice(position) || bigNumberify(0);
  const positionOrders = getOrdersForPosition(account, position, orders, nativeTokenAddress);
  const hasOrderError = !!positionOrders.find((order) => order.error);
  const hasPositionProfit = position[showPnlAfterFees ? "hasProfitAfterFees" : "hasProfit"];
  const positionDelta = position[showPnlAfterFees ? "pendingDeltaAfterFees" : "pendingDelta"] || bigNumberify(0);
  let borrowFeeUSD;
  if (position.collateralToken && position.collateralToken.fundingRate) {
    const borrowFeeRate = position.collateralToken.fundingRate.mul(position.size).mul(24).div(FUNDING_RATE_PRECISION);
    borrowFeeUSD = formatAmount(borrowFeeRate, USD_DECIMALS, 2);
  }
  const posQty =
    position?.averagePrice && position?.averagePrice.gt(0)
      ? position.size.mul(parseValue(1, USD_DECIMALS)).div(position?.averagePrice)
      : 0;
  const isLong = position.isLong;
  const pnlWithoutFee_LiqPrice =
    position.markPrice && position.averagePrice && position.averagePrice.gt(0) && position.markPrice.gt(0)
      ? isLong
        ? liquidationPrice.sub(position.averagePrice).mul(position.size).div(position.averagePrice)
        : position.averagePrice.sub(liquidationPrice).mul(position.size).div(position.averagePrice)
      : bigNumberify(0);

  const pnlWithoutFee_MarkPrice =
    position.markPrice && position.averagePrice && position.averagePrice.gt(0) && position.markPrice.gt(0)
      ? isLong
        ? position.markPrice.sub(position.averagePrice).mul(position.size).div(position.averagePrice)
        : position.averagePrice.sub(position.markPrice).mul(position.size).div(position.averagePrice)
      : bigNumberify(0);

  const LiqRisk =
    position.markPrice &&
    position.averagePrice &&
    pnlWithoutFee_LiqPrice.lt(0) &&
    position.averagePrice.gt(0) &&
    position.markPrice.gt(0)
      ? pnlWithoutFee_MarkPrice.mul(parseValue(100, USD_DECIMALS)).div(pnlWithoutFee_LiqPrice)
      : 0;
  const posOrders =
    myOrders
      ?.filter(
        (x) =>
          (x.isLong && position.isLong && position.indexToken.address === x.indexToken) ||
          (!x.isLong &&
            !position.isLong &&
            position.indexToken.address === x.indexToken &&
            position.collateralToken.address === x.collateralToken)
      )
      .map((i) => ({ ...i, isV1: true })) || [];
  const isInvalid = posOrders.find((x) => getOrderError(account, x, undefined, position) && x.type !== "Increase");
  const myErrorOrders = posOrders.filter((x) => getOrderError(account, x, undefined, position));
  const myValidOrders = posOrders.filter((x) => !getOrderError(account, x, undefined, position));
  return (
    <div key={position.key} className="App-card">
      <div className="App-card-title">
        <span className="Exchange-list-title">
          <img src={importImage("ic_" + position.indexToken?.symbol?.toLowerCase() + "_24.svg")} alt="" />{" "}
          {position?.indexToken?.symbol}/USD
          <span className="muted">#{position.posId}</span>
        </span>
      </div>
      <div className="App-card-divider" />
      <div className="App-card-content app-card-position">
        <div className="leverage-pad">
          <div>
            <span
              className={cx({ positive: position.isLong, negative: !position.isLong })}
              style={{ color: position.isLong ? "#90FB75" : "#F6475D" }}
            >
              {position.isLong ? `LONG` : `SHORT`}
            </span>
          </div>
          {position.leverage && (
            <div
              className=""
              style={{
                color: "#FFC700",
                backgroundColor: "rgba(255, 199, 0, 0.20)",
                height: 16,
                display: "inline-block",
                opacity: 1,
                borderRadius: "100px",
                padding: "0 4px",
                fontSize: "12px",
                lineHeight: "16px",
                fontWeight: 400,
                marginLeft: "4px",
              }}
            >
              {formatAmount(position.leverage, 4, 2, true)}X
            </div>
          )}
        </div>
        <div
          className="App-card-row"
          //
        >
          <div className="label">
            <span>Net</span>
          </div>
          <div>
            <div className="contain-tooltip">
              {!position.netValue && `Opening...`}
              {position.netValue && (
                <div>{`${position.netValue?.lt(0) ? "-" : ""}$${formatAmount(
                  position.netValue,
                  USD_DECIMALS,
                  2,
                  true
                ).replace("-", "")}`}</div>
              )}
            </div>
          </div>
        </div>
        <div
          className="App-card-row"
          //
        >
          <div className="label">
            <span>PnL</span>
          </div>
          <div>
            {position.deltaStr && (
              <div
                style={{ marginTop: 0, gap: 0 }}
                className={cx({
                  positive: position.deltaAfterFeesStr.includes("+"),
                  negative: position.deltaAfterFeesStr.includes("-"),
                  muted: !position.deltaAfterFeesStr.includes("+") && !position.deltaAfterFeesStr.includes("-"),
                })}
              >
                <Tooltip
                  handle={
                    <>
                      {!position.deltaAfterFeesStr.includes("+") && !position.deltaAfterFeesStr.includes("-") ? null : (
                        <img
                          src={position.deltaAfterFeesStr.includes("-") ? ic_ArrowDown : ic_ArrowUp}
                          alt="tk"
                          style={{ width: "16px", height: "16px" }}
                        />
                      )}
                      {position.deltaAfterFeesStr?.replace("+", "")?.replace("-", "")} (
                      {position.deltaAfterFeesPercentageStr?.replace("+", "")?.replace("-", "")})
                    </>
                  }
                  position="right-top"
                  handleClassName="plain"
                  renderContent={() => {
                    return (
                      <>
                        <StatsTooltipRow
                          label={`PnL without fees`}
                          value={`${position.deltaBeforeFeesStr}`}
                          showDollar={false}
                        />
                        <StatsTooltipRow
                          label={`Accrued fees`}
                          value={formatAmount(position.closingFee.add(position.fundingFee), USD_DECIMALS, 2, true)}
                        />
                      </>
                    );
                  }}
                />
              </div>
            )}
          </div>
        </div>
        <div className="App-card-row">
          <div className="label">
            <span>Pos.Size</span>
          </div>
          <div className="">
            <div>${formatAmount(position.size, USD_DECIMALS, 2, true)}</div>
            <div className="muted fz-12 bold">
              {formatAmount(posQty, 30, 4, true)} {position.indexToken.symbol}
            </div>
          </div>
        </div>
        <div className="App-card-row" style={{ opacity: position.liquidationPrice ? 1 : 0.6 }}>
          <div>
            <span>Collateral</span>
          </div>
          <div style={{ display: "flex" }}>
            {`$${formatAmount(position.collateral, USD_DECIMALS, 2, true)}`}
            {position.netValue && (
              <StyledButton
                // disabled={position && !position.liquidationPrice}
                style={{ position: "relative", marginLeft: "2px" }}
                onClick={(e) => {
                  e.stopPropagation();
                  editPosition(position);
                }}
              >
                <img src={editIcon} alt="icon" style={{ width: "16px", height: "16px", maxWidth: "16px" }} />
              </StyledButton>
            )}
          </div>
        </div>

        <div className="App-card-row">
          <div className="label">
            <span>Liq.Risk</span>
          </div>
          <div>
            <div>{renderRiskV1(LiqRisk, false)}</div>
            {/* <div className="position-list-risk">
                          {formatAmount(LiqRisk, 30, LiqRisk.eq(0) ? 0 : 2, true).replace("-", "")}%
                        </div> */}
          </div>
        </div>
        <div className="App-card-row">
          <div className="label">
            <span>Liq.Price</span>
          </div>
          <div>${formatAmount(liquidationPrice, USD_DECIMALS, position?.indexToken?.displayDecimals || 2, true)}</div>
        </div>
        <div className="App-card-row">
          <div className="label">
            <span>Mark Price</span>
          </div>
          <div>
            {position.markPrice ? (
              "$" + formatAmount(position.markPrice, USD_DECIMALS, position?.indexToken?.displayDecimals || 2, true)
            ) : (
              <div className="skeleton-box" style={{ width: "70px" }} />
            )}
          </div>
        </div>
        <div className="App-card-row">
          <div className="label">
            <span>Avg. Entry Price</span>
          </div>
          <div>
            ${formatAmount(position.averagePrice, USD_DECIMALS, position?.indexToken?.displayDecimals || 2, true)}
          </div>
        </div>
        {posOrders.length > 0 && (
          <div className="App-card-row">
            <div className="label">
              <span>Linked Orders</span>
            </div>
            {posOrders.length > 0 && (
              <Tooltip
                position="right-bottom"
                hideInfoImg={true}
                handle={
                  <div
                    className={`chains-btn ${isInvalid ? "chains-btn-invalid" : ""}`}
                    onClick={(e) => {
                      e.stopPropagation();
                      setListSection("Orders");
                      setPosOrders(posOrders);
                    }}
                  >
                    <span>{posOrders.length}</span>
                    <img
                      src={theme?.isLightTheme ? ic_chainLight : isInvalid ? ic_chain_error : ic_chain}
                      alt=""
                      style={{ marginLeft: "0px" }}
                    />
                  </div>
                }
                renderContent={() => {
                  return (
                    <>
                      {myValidOrders && myValidOrders.length > 0 && (
                        <>
                          <strong>
                            <span style={{ fontWeight: 700, color: "white" }}>Active Orders</span>
                          </strong>
                          {myValidOrders.map((order) => {
                            return (
                              <div key={`${order.isLong}-${order.type}-${order.index}`} className="Position-list-order">
                                <div>
                                  <span style={{ color: "white" }}>
                                    {["Swap", "Increase"].includes(order.type)
                                      ? "Limit"
                                      : position.isLong
                                      ? order.triggerAboveThreshold
                                        ? "Take Profit"
                                        : "Stop Loss"
                                      : order.triggerAboveThreshold
                                      ? "Stop Loss"
                                      : "Take Profit"}
                                    : {order.triggerAboveThreshold ? "≥" : "≤"}{" "}
                                    {formatAmount(
                                      order.triggerPrice,
                                      30,
                                      position?.indexToken?.displayDecimals || 2,
                                      true
                                    )}{" "}
                                    | {order.type === INCREASE ? " +" : " -"}$
                                    {formatAmount(order.sizeDelta, 30, 2, true)}
                                  </span>
                                </div>
                              </div>
                            );
                          })}
                        </>
                      )}
                      {myErrorOrders && myErrorOrders.length > 0 && myValidOrders && myValidOrders.length > 0 && (
                        <div className="divider" />
                      )}
                      {myErrorOrders && myErrorOrders.length > 0 && (
                        <>
                          {myErrorOrders.length > 1 && <div className="divider" />}
                          <strong>
                            <span style={{ fontWeight: 700, color: "white" }}>Inactive Orders</span>
                          </strong>
                          {myErrorOrders.map((order) => {
                            return (
                              <div key={`${order.isLong}-${order.type}-${order.index}`} className="Position-list-order">
                                <div>
                                  <span>
                                    {["Swap", "Increase"].includes(order.type)
                                      ? "Limit"
                                      : position.isLong
                                      ? order.triggerAboveThreshold
                                        ? "Take profit"
                                        : "Stop loss"
                                      : order.triggerAboveThreshold
                                      ? "Stop loss"
                                      : "Take profit"}
                                    : {order.triggerAboveThreshold ? "≥" : "≤"}{" "}
                                    {formatAmount(
                                      order.triggerPrice,
                                      30,
                                      position?.indexToken?.displayDecimals || 2,
                                      true
                                    )}{" "}
                                    | {order.type === INCREASE ? " +" : " -"}$
                                    {formatAmount(order.sizeDelta, 30, 2, true)}
                                  </span>
                                </div>
                                {order.error && (
                                  <div>
                                    <span className="negative" style={{ whiteSpace: "pre-wrap" }}>
                                      {order.error}
                                    </span>
                                  </div>
                                )}
                              </div>
                            );
                          })}
                        </>
                      )}
                    </>
                  );
                }}
              />
            )}
          </div>
        )}

        {/* {posOrders.length > 0 && (
                      <div className="App-card-row">
                        <div className="label">
                          <span>Linked Orders</span>
                        </div>
                        <div
                          className="chains-btn"
                          onClick={(e) => {
                            e.stopPropagation();
                            setListSection("Orders");
                            setPosOrders(posOrders);
                          }}
                        >
                          <span>{posOrders.length}</span>
                          <img src={theme?.isLightTheme ? ic_chainLight : ic_chain} alt="" />
                        </div>
                      </div>
                    )} */}
      </div>
      <div style={{ marginTop: "24px" }} />
      <div className="App-card-options">
        <button
          className="App-button-option App-card-option"
          onClick={() => {
            onPositionClick(position);
          }}
          // disabled={position.size == 0}
        >
          <span>View</span>
        </button>
        <button
          className="App-button-option App-card-option border-btn border-btn-small-square "
          onClick={(e) => {
            e.stopPropagation();
            sellPosition(position);
          }}
          disabled={position.size.eq(0)}
        >
          <span>Close</span>
        </button>
        <PositionDropdown
          handleEditCollateral={() => {
            editPosition(position);
          }}
          handleShare={() => {
            setPositionToShare(position);
            setIsPositionShareModalOpen(true);
          }}
          handleMarketSelect={() => {
            onPositionClick(position);
          }}
          handleAddTPSL={() => {
            handleAddTPSL(position);
          }}
          handleAddToPosition={() => {
            handleAddToPosition && handleAddToPosition(position);
          }}
        />
      </div>
    </div>
  );
};
export default PositionItemMobileV1;
const StyledButton = styled.div`
  background: transparent;
  outline: none;
  border: none;
  position: relative;
  top: -2px;
`;
