import { useState, useEffect, useMemo } from "react";
import { useHistory } from "react-router-dom";
import { useWeb3React } from "@web3-react/core";
import useSWR from "swr";
import { BigNumber, ethers } from "ethers";
import { getContract } from "config/contracts";
import SettingsManager from "abis/SettingsManager.json";
import _ from "lodash";
import RouterAggregator from "abis/RouterAggregatorMicroSwap.json";
import {
  adjustForDecimals,
  NLP_DECIMALS,
  USD_DECIMALS,
  NLP_COOLDOWN_DURATION,
  USDN_DECIMALS,
  PLACEHOLDER_ACCOUNT,
  DUST_BNB,
  NSLP_COOLDOWN_DURATION,
} from "lib/legacy";

import styled from "styled-components";
import ReaderV2 from "abis/ReaderV2.json";
import VaultNslp from "abis/VaultNslp.json";
import RewardTracker from "abis/RewardTracker.json";
import Vester from "abis/Vester.json";
import RewardRouter from "abis/RewardRouter.json";
import Token from "abis/Token.json";
import infoIcon from "img/icons/info.svg";
import nlp24Icon from "img/nslp.svg";
import usdcIcon from "img/ic_usdc_24.svg";
import arrowIcon from "img/ic_arrow.svg";

import {
  getChainName,
  IS_NETWORK_DISABLED,
  BASE,
  MICROSWAP_SUPPORTED_CHAIN_IDS,
  SONIC_TESTNET,
  SUPPORTED_V2_CHAINS,
} from "config/chains";
import { callContract, contractFetcher } from "lib/contracts";
import { approveTokens, useInfoTokens } from "domain/tokens";
import { useLocalStorageByChainId } from "lib/localStorage";
import { getTokenInfo, getUsd } from "domain/tokens/utils";
import {
  bigNumberify,
  convertSmallENumber,
  expandDecimals,
  formatAmount,
  formatAmountFree,
  formatNumber,
  parseValue,
} from "lib/numbers";
import {
  DEFAULT_FROM_V2_TOKEN,
  getMicroSwapToken,
  getToken,
  getTokens,
  getWhitelistedTokens,
  getWrappedToken,
} from "config/tokens";
import { useChainId } from "lib/chains";
import Tooltip from "components/Tooltip/Tooltip";
import Tab from "components/Tab/Tab";
import BuyInputSection from "components/BuyInputSection/BuyInputSection";
import useSettingsManagerContract from "hooks/contracts/useSettingsManagerContract";
import useInfoNslp from "hooks/useInfoNslp";
import useMintNslp from "hooks/useMintNslp";
import useVaultNaviContract from "hooks/contracts/useVaultNaviContract";
import useRedeemNslp from "hooks/useRedeemNslp";
import { helperToast } from "lib/helperToast";
import Countdown from "react-countdown";
import useAggregator from "hooks/useAggregator";
import { InitQueriesParam } from "pages/SpotThroughArregator/config";
import useTokenBalances from "hooks/aggregator/useTokenBalances";
import TokenSelectorV3 from "components/Exchange/TokenSelectorV3";
import TokenSelector from "components/Exchange/TokenSelector";
import useMultiTokenAggregator from "hooks/useMultiTokenAggregator";
import TokenSelectorUnderLying from "components/Exchange/TokenSelectorUnderLying";
import useUnderlyingTokenBalances from "hooks/aggregator/useUnderlyingTokenBalances";
import usePoolTokenBalance from "hooks/usePoolTokenBalance";
import { MICROSWAP_API_URL } from "config/backend";

const { AddressZero } = ethers.constants;
const ROUTER_API = `${MICROSWAP_API_URL}/sonic/v2/swap`;
export default function BuySellNslp({
  isBuying,
  setPendingTxns,
  connectWallet,
  setIsBuying,
  savedShouldDisableValidationForTesting,
  savedSelectedDexes,
}) {
  const history = useHistory();
  const swapLabel = isBuying ? "Buy NSLP" : "Sell NSLP";
  const tabLabel = isBuying ? `Buy NSLP` : `Sell NSLP`;
  const { active, library, account } = useWeb3React();
  const { chainId } = useChainId();
  const tokens = getTokens(chainId);
  const usdcAddress = getContract(chainId, "USDC.e");
  const [onRefresh, setOnRefresh] = useState();
  const [destMintAddress, setDestMinAddress] = useLocalStorageByChainId(
    chainId,
    `mint-mslp-default-dest-token-address`,
    DEFAULT_FROM_V2_TOKEN[chainId]?.[0] || usdcAddress
  );

  const [paramsAggregator, setParamsAggregator] = useState(InitQueriesParam);
  const { data: dataAggregator, isLoading: isLoadingPathSwaps } = useMultiTokenAggregator(
    chainId,
    paramsAggregator,
    onRefresh,
    savedSelectedDexes
  );

  // console.log("?????", dataAggregator);

  const onHandleReloadData = () => {
    setOnRefresh(!onRefresh);
  };
  const onSelectMintDestToken = (token) => {
    setDestMinAddress(token.address);
    // setIsWaitingForApproval(false);
  };
  const { stakingFee, unstakingFee, maxTotalNslp } = useSettingsManagerContract(chainId, active, account);
  const { price: nslpPrice, totalSupply: totalSupplyNslp } = useInfoNslp(chainId);

  const nslpAvailableToMint = useMemo(() => {
    if (maxTotalNslp && totalSupplyNslp) {
      return maxTotalNslp.sub(totalSupplyNslp);
    }
  }, [maxTotalNslp, totalSupplyNslp]);
  const whitelistedTokens = getWhitelistedTokens(chainId).filter((t) =>
    DEFAULT_FROM_V2_TOKEN?.[chainId]?.includes(t.address)
  );
  const tokenList = whitelistedTokens.filter((t) => !t.isWrapped);
  const [swapValue, setSwapValue] = useState("");
  const [nslpValue, setNslpValue] = useState("");
  const [swapTokenAddress, setSwapTokenAddress] = useLocalStorageByChainId(
    chainId,
    `${swapLabel}-swap-token-address2`,
    usdcAddress
  );
  const [isApproving, setIsApproving] = useState(false);
  const [isWaitingForApproval, setIsWaitingForApproval] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const stakeHelperAddress = getContract(chainId, "StakeHelper");
  const readerAddress = getContract(chainId, "Reader");
  const nativeTokenAddress = getContract(chainId, "NATIVE_TOKEN");
  const feeNslpTrackerAddress = getContract(chainId, "FeeNslpTracker");
  const nslpManagerAddress = getContract(chainId, "VaultNslp");
  const nslpAddress = getContract(chainId, "NSLP");
  const rewardRouterAddress = getContract(chainId, "RewardRouter");

  const tokenAddresses = tokens.map((token) => token.address);
  const { data: tokenBalances } = useSWR(
    [
      `BuySellNslp:getTokenBalances:${active}`,
      chainId,
      readerAddress,
      "getTokenBalances",
      account || PLACEHOLDER_ACCOUNT,
    ],
    {
      fetcher: contractFetcher(library, ReaderV2, [tokenAddresses]),
      refreshInterval: 30000,
    }
  );

  const { data: lastPurchaseTime } = useSWR(
    [
      `BuySellNslp:lastPurchaseTime:${active}`,
      chainId,
      nslpManagerAddress,
      "lastStakedAt",
      account || PLACEHOLDER_ACCOUNT,
    ],
    {
      fetcher: contractFetcher(library, VaultNslp),
      refreshInterval: 30000,
    }
  );

  const { data: nslpBalance } = useSWR(
    [
      `BuySellNslp:nslpBalance:${active}`,
      chainId,
      feeNslpTrackerAddress,
      "stakedAmounts",
      account || PLACEHOLDER_ACCOUNT,
    ],
    {
      fetcher: contractFetcher(library, RewardTracker),
      refreshInterval: 30000,
    }
  );

  const nslpVesterAddress = getContract(chainId, "NslpVester");
  const { data: reservedAmount } = useSWR(
    [`BuySellNslp:reservedAmount:${active}`, chainId, nslpVesterAddress, "pairAmounts", account || PLACEHOLDER_ACCOUNT],
    {
      fetcher: contractFetcher(library, Vester),
      refreshInterval: 30000,
    }
  );

  // const nlpVesterV1Address = getContract(chainId, "NlpVesterV1");
  // const { data: reservedAmountV1 } = useSWR(
  //   chainId !== BASE && [
  //     `BuySellNslp:reservedAmountV1:${active}`,
  //     chainId,
  //     nlpVesterV1Address,
  //     "pairAmounts",
  //     account || PLACEHOLDER_ACCOUNT,
  //   ],
  //   {
  //     fetcher: contractFetcher(library, Vester),
  //     refreshInterval: 30000,
  //   }
  // );

  const SettingsManagerAddress = getContract(chainId, "SettingsManager");
  const { data: nslpCooldown } = useSWR([`StakeV2:nslpCooldown`, chainId, SettingsManagerAddress, "cooldownDuration"], {
    fetcher: contractFetcher(library, SettingsManager),
    refreshInterval: 10000,
  });
  const coundowHour = nslpCooldown % 3600 === 0 ? nslpCooldown / 3600 : parseInt(nslpCooldown / 3600) + 1;
  const redemptionTime = lastPurchaseTime ? lastPurchaseTime.add(NSLP_COOLDOWN_DURATION) : undefined;
  const inCooldownWindow = redemptionTime && parseInt(Date.now() / 1000) < redemptionTime;
  // const inCooldownWindow = false;
  // const nslpSupply = balancesAndSupplies ? balancesAndSupplies[1] : bigNumberify(0);
  // const usdgSupply = balancesAndSupplies ? balancesAndSupplies[3] : bigNumberify(0);
  // let aum;
  // if (aums && aums.length > 0) {
  //   aum = isBuying ? aums[0] : aums[1];
  // }
  // const nslpPrice =
  //   aum && aum.gt(0) && nslpSupply.gt(0)
  //     ? aum.mul(expandDecimals(1, NLP_DECIMALS)).div(nslpSupply)
  //     : expandDecimals(1, USD_DECIMALS);

  var maxSellAmount = nslpBalance;
  if (nslpBalance && reservedAmount) {
    maxSellAmount = maxSellAmount.sub(reservedAmount);
  }
  // if (nslpBalance && reservedAmountV1) {
  //   maxSellAmount = maxSellAmount.sub(reservedAmountV1);
  // }
  const { infoTokens } = useInfoTokens(library, chainId, active, tokenBalances, undefined);
  const swapToken = getToken(chainId, swapTokenAddress);
  const swapTokenInfo = getTokenInfo(infoTokens, swapTokenAddress);

  const swapTokenBalance = swapTokenInfo && swapTokenInfo.balance ? swapTokenInfo.balance : bigNumberify(0);

  const swapAmount = parseValue(swapValue, swapToken && swapToken.decimals);

  const nslpAmount = parseValue(nslpValue, 18);
  const microSwapTokens = useTokenBalances(chainId);
  const underlyingTokens = useUnderlyingTokenBalances(chainId);

  const defaultTokenSelection = useMemo(
    () => ({
      from: MICROSWAP_SUPPORTED_CHAIN_IDS?.includes(chainId) ? usdcAddress : AddressZero,
      to: MICROSWAP_SUPPORTED_CHAIN_IDS?.includes(chainId) ? usdcAddress : AddressZero,
    }),
    [chainId, microSwapTokens]
  );
  const [tokenSelection, setTokenSelection] = useLocalStorageByChainId(
    chainId,
    "swap-nslp-aggregator-token",
    defaultTokenSelection
  );
  const [redeemDestAddress, setRedeemDestAddress] = useLocalStorageByChainId(
    chainId,
    "redeem-nslp-desst-token",
    usdcAddress
  );
  const destTokens = SUPPORTED_V2_CHAINS.includes(chainId)
    ? tokens.filter((x) => DEFAULT_FROM_V2_TOKEN?.[chainId]?.includes(x.address))
    : [usdcAddress];
  const [fromValue, setFromValue] = useState("");
  const [fromRedeemValue, setFromRedeemValue] = useState("");
  const redeemNslpAmount = parseValue(fromRedeemValue || 0, 18);
  // console.log("?????", redeemNslpAmount);

  const [toValue, setToValue] = useState("");

  const fromTokenAddress = tokenSelection.from;
  const toTokenAddress = tokenSelection.to;

  const fromToken = getMicroSwapToken(microSwapTokens, fromTokenAddress);
  const toToken = getMicroSwapToken(microSwapTokens, toTokenAddress);
  const redeemDestToken = getMicroSwapToken(microSwapTokens, redeemDestAddress);
  // console.log("redeemDestToken", redeemDestToken);
  const destMintToken = getMicroSwapToken(microSwapTokens, destMintAddress);
  const fromAmount = parseValue(fromValue, fromToken.decimals);
  const toAmount = parseValue(toValue, toToken.decimals);

  const tokenAllowanceAddress = fromTokenAddress === AddressZero ? nativeTokenAddress : fromTokenAddress;
  const { data: tokenAllowance } = useSWR(
    [
      active,
      chainId,
      tokenAllowanceAddress,
      "allowance",
      account || PLACEHOLDER_ACCOUNT,
      DEFAULT_FROM_V2_TOKEN?.[chainId]?.includes(fromTokenAddress) ? rewardRouterAddress : stakeHelperAddress,
    ],
    {
      fetcher: contractFetcher(library, Token),
      refreshInterval: 30000,
    }
  );
  useEffect(() => {
    if (fromTokenAddress) {
      if (fromValue && Number(fromValue) && !DEFAULT_FROM_V2_TOKEN?.[chainId]?.includes(fromTokenAddress)) {
        setParamsAggregator({
          ...paramsAggregator,
          amount: parseValue(fromValue, fromToken.decimals),
          from: fromTokenAddress,
          to: destMintAddress,
          receiver: account || AddressZero,
        });
      } else {
        setParamsAggregator({
          ...paramsAggregator,
          amount: 0,
          from: fromTokenAddress,
          to: destMintAddress,
          receiver: account || AddressZero,
        });
      }
    }
  }, [fromTokenAddress, fromValue]);
  const fromUsd = useMemo(() => {
    if (dataAggregator && !_.isEmpty(dataAggregator) && fromAmount) {
      if (!fromToken.isNative) {
        const { tokens } = dataAggregator;
        const token =
          fromTokenAddress === AddressZero
            ? tokens["0x039e2fb66102314ce7b64ce5ce3e5183bc94ad38"]
            : tokens[fromTokenAddress?.toLowerCase()];
        const tokenPrice = token?.price?.toString()?.includes("e-")
          ? convertSmallENumber(token?.price, fromToken.decimals)
          : token?.price;

        return fromAmount.mul(parseValue(tokenPrice || 0, fromToken.decimals)).div(parseValue(1, fromToken.decimals));
      } else {
        const wrappedToken = getWrappedToken(chainId || SONIC_TESTNET);
        const { tokens } = dataAggregator;
        const token = tokens[wrappedToken?.address?.toLowerCase()];
        const tokenPrice = token?.price?.toString()?.includes("e-")
          ? convertSmallENumber(token?.price, fromToken.decimals)
          : token?.price;

        return fromAmount.mul(parseValue(tokenPrice || 0, fromToken.decimals)).div(parseValue(1, fromToken.decimals));
      }
    } else {
      return fromAmount || 0;
    }

    // return parseValue(0, fromToken.decimals);
  }, [dataAggregator, fromAmount]);
  function handleDecimalsOnValue(value) {
    const regex = /([0-9]*[\.|\,]{0,1}[0-9]{0,8})/s;
    return value.match(regex)[0];
  }
  function setFromValueToMaximumAvailable() {
    if (!fromToken || !fromToken?.balance) {
      return;
    }

    const maxAvailableAmount = fromToken?.isNative
      ? fromToken.balance.sub(bigNumberify(DUST_BNB).mul(2))
      : fromToken?.balance;
    if (maxAvailableAmount.gt(0)) {
      setFromValue(formatAmount(maxAvailableAmount, fromToken.decimals, fromToken.decimals, false));
    } else setFromValue("0");
  }
  const toUsd = useMemo(() => {
    if (!DEFAULT_FROM_V2_TOKEN?.[chainId]?.includes(fromTokenAddress)) {
      if (dataAggregator?.totalTo && !_.isEmpty(dataAggregator)) {
        return BigNumber.from(dataAggregator?.totalTo);
      }
    }

    return fromAmount || "";
  }, [dataAggregator, fromToken.decimals]);
  useEffect(() => {
    if (isBuying) {
      setFromValue("");
    }
  }, [fromTokenAddress]);
  const toUsdMin = useMemo(() => {
    if (!DEFAULT_FROM_V2_TOKEN?.[chainId]?.includes(fromTokenAddress)) {
      if (dataAggregator?.minAmountOut && !_.isEmpty(dataAggregator)) {
        return BigNumber.from(dataAggregator?.minAmountOut);
      }
    }

    return fromAmount || "";
  }, [dataAggregator, fromToken.decimals]);

  const onFromValueChange = (e) => {
    const value = handleDecimalsOnValue(e.target.value);

    // setAnchorOnFromAmount(true);
    setFromValue(value);
  };
  function shouldShowMaxButton() {
    if (!fromToken || !fromToken?.balance) {
      return false;
    }
    const maxAvailableAmount = fromToken.isNative
      ? fromToken.balance.sub(bigNumberify(DUST_BNB).mul(2))
      : fromToken.balance;
    return fromValue !== formatAmountFree(maxAvailableAmount, fromToken.decimals, fromToken.decimals);
  }
  const onSelectFromToken = (token) => {
    const address = token.address;
    const newTokenSelection = JSON.parse(JSON.stringify(tokenSelection));
    newTokenSelection.from = address;
    setTokenSelection(newTokenSelection);
  };
  const onSelectReddemDestToken = (token) => {
    const address = token.address;
    setRedeemDestAddress(address);
  };

  const needApproval =
    isBuying && fromTokenAddress !== AddressZero && tokenAllowance && fromAmount && fromAmount.gt(tokenAllowance);

  const swapUsdMin = getUsd(toUsd || "", swapTokenAddress, false, infoTokens);
  const nslpUsdMax =
    redeemNslpAmount && nslpPrice ? redeemNslpAmount.mul(nslpPrice).div(expandDecimals(1, NLP_DECIMALS)) : undefined;

  let isSwapTokenCapReached;
  if (swapTokenInfo.managedUsd && swapTokenInfo.maxUsdgAmount) {
    isSwapTokenCapReached = swapTokenInfo.managedUsd.gt(
      adjustForDecimals(swapTokenInfo.maxUsdgAmount, USDN_DECIMALS, USD_DECIMALS)
    );
  }
  const { data: totalSupplyBlp } = useSWR([`Blp:getTotalSupply`, chainId, nslpAddress, "totalSupply"], {
    fetcher: contractFetcher(undefined, Token),
    refreshInterval: 10000,
  });
  const { totalUSD } = useVaultNaviContract(chainId);

  const { outputMint } = useMintNslp({
    chainId,
    fromValue: fromValue ? (DEFAULT_FROM_V2_TOKEN?.[chainId]?.includes(fromTokenAddress) ? fromAmount : toUsd) : 0,
    nslpPrice: nslpPrice,
    stakingFee,
    isMint: isBuying,
    totalUSD,
    totalSupplyBlp,
    fromAddress: fromTokenAddress,
  });
  const { outputMint: outputMintMinecieved } = useMintNslp({
    chainId,
    fromValue: fromValue ? (DEFAULT_FROM_V2_TOKEN?.[chainId]?.includes(fromTokenAddress) ? fromAmount : toUsdMin) : 0,
    nslpPrice: nslpPrice,
    stakingFee,
    isMint: isBuying,
    totalUSD,
    totalSupplyBlp,
    fromAddress: fromTokenAddress,
  });
  const vaultNaviAddress = getContract(chainId, "VaultNslp");
  const poolTokenBalances = usePoolTokenBalance(chainId, vaultNaviAddress);
  // console.log("?????", { redeemDestToken, poolTokenBalances });
  const { outputRedeem } = useRedeemNslp({
    chainId,
    fromValue: fromRedeemValue ? redeemNslpAmount : 0,
    nslpPrice: nslpPrice,
    unstakingFee,
    isRedeem: !isBuying,
    totalUSD,
    totalSupplyBlp,
    destToken: redeemDestToken?.symbol,
    destAddress: redeemDestToken.address,
    decimals: redeemDestToken.decimals,
  });
  // console.log("?????", outputRedeem);

  const onSwapValueChange = (e) => {
    // setAnchorOnSwapAmount(true);
    setSwapValue(e.target.value);
  };

  const onNslpValueChange = (e) => {
    // setAnchorOnSwapAmount(false);
    setFromRedeemValue(e.target.value);
  };

  // const onSelectSwapToken = (token) => {
  //   setSwapTokenAddress(token.address);
  //   setIsWaitingForApproval(false);
  // };

  useEffect(() => {
    //THIS CALCULATE OUTPUT AMOUNT
    if (isBuying && outputMint !== null) {
      setNslpValue(formatAmount(outputMint, 30, 18));
    }
  }, [outputMint, isBuying]);

  useEffect(() => {
    if (!isBuying && outputRedeem !== null) {
      setSwapValue(formatAmount(outputRedeem, 6, 6));
    }
  }, [isBuying, outputRedeem, redeemNslpAmount]);
  const switchSwapOption = (hash = "") => {
    history.push(`${history.location.pathname}#${hash}`);
    setIsBuying(hash === "redeem" ? false : true);
  };

  const fillMaxAmount = () => {
    if (isBuying) {
      // setAnchorOnSwapAmount(true);
      setSwapValue(formatAmountFree(swapTokenBalance, swapToken.decimals, swapToken.decimals));
      return;
    }

    // setAnchorOnSwapAmount(false);
    setFromRedeemValue(formatAmountFree(maxSellAmount, NLP_DECIMALS, NLP_DECIMALS));
  };

  const getError = () => {
    if (IS_NETWORK_DISABLED[chainId]) {
      if (isBuying) return [`NSLP buy disabled, pending ${getChainName(chainId)} upgrade`];
      return [`NSLP sell disabled, pending ${getChainName(chainId)} upgrade`];
    }

    if (!isBuying && inCooldownWindow) {
      return [`Redemption time not yet reached`];
    }

    if ((!nslpAmount || nslpAmount.eq(0)) && (!swapAmount || swapAmount.eq(0))) {
      return [`Enter an amount`];
    }

    if (isBuying) {
      if (
        !savedShouldDisableValidationForTesting &&
        fromToken &&
        fromToken.balance &&
        fromAmount &&
        fromAmount.gt(fromToken.balance)
      ) {
        return [`Insufficient ${fromToken.symbol} balance`];
      }
      if (swapTokenInfo.maxUsdgAmount && swapTokenInfo.usdgAmount && swapUsdMin) {
        const usdgFromAmount = adjustForDecimals(swapUsdMin, USD_DECIMALS, USDN_DECIMALS);
        const nextUsdgAmount = swapTokenInfo.usdgAmount.add(usdgFromAmount);
        if (swapTokenInfo.maxUsdgAmount.gt(0) && nextUsdgAmount.gt(swapTokenInfo.maxUsdgAmount)) {
          return [`${swapTokenInfo.symbol} pool exceeded, try different token`, true];
        }
      }
    }

    if (!isBuying) {
      if (maxSellAmount && redeemNslpAmount && redeemNslpAmount.gt(maxSellAmount)) {
        return [`Insufficient NSLP balance`];
      }

      // const swapTokenInfo = getTokenInfo(infoTokens, swapTokenAddress);
      // if (
      //   swapTokenInfo &&
      //   swapTokenInfo.availableAmount &&
      //   swapAmount &&
      //   swapAmount.gt(swapTokenInfo.availableAmount)
      // ) {
      //   return [`Insufficient liquidity`];
      // }
      const swapTokenInfo = poolTokenBalances?.find((x) => x.symbol === redeemDestToken.symbol);
      if (swapTokenInfo) {
        if (swapAmount.gt(swapTokenInfo.balance)) return [`Insufficient ${redeemDestToken.symbol} in vault`];
      }
    }

    return [false];
  };

  const isPrimaryEnabled = () => {
    if (IS_NETWORK_DISABLED[chainId]) {
      return false;
    }
    if (!active) {
      return true;
    }
    const [error, modal] = getError();
    if (error && !modal) {
      return false;
    }
    if ((needApproval && isWaitingForApproval) || isApproving) {
      return false;
    }
    if (isApproving) {
      return false;
    }
    if (isSubmitting) {
      return false;
    }
    // if (isBuying && isSwapTokenCapReached) {
    //   return false;
    // }

    if (!isBuying && inCooldownWindow) {
      return false;
    }

    return true;
  };

  const getPrimaryText = () => {
    if (!active) {
      return `Connect Wallet`;
    }
    const [error, modal] = getError();
    if (error && !modal) {
      return error;
    }
    // if (isBuying && isSwapTokenCapReached) {
    //   return `Max Capacity for ${swapToken.symbol} Reached`;
    // }

    if (needApproval && isWaitingForApproval) {
      return `Waiting for Approval`;
    }
    if (isApproving) {
      return `Approving ${fromToken.symbol}...`;
    }
    if (needApproval) {
      return `Approve ${fromToken.symbol}`;
    }

    if (isSubmitting) {
      return isBuying ? `Buying...` : `Selling...`;
    }

    if (!isBuying && inCooldownWindow) {
      return "Cooldown to sell NSLP";
    }

    return isBuying ? `Buy NSLP` : `Sell NSLP`;
  };

  const approveFromToken = () => {
    approveTokens({
      setIsApproving,
      library,
      tokenAddress: fromTokenAddress,
      spender: DEFAULT_FROM_V2_TOKEN?.[chainId]?.includes(fromTokenAddress) ? rewardRouterAddress : stakeHelperAddress,
      chainId: chainId,
      onApproveSubmitted: () => {
        setIsWaitingForApproval(true);
      },
      infoTokens,
      getTokenInfo,
    });
  };
  async function sendTransaction(contract, method, params, value) {
    let customMethod = "swap";
    let funcData;
    try {
      funcData = await contract.interface.decodeFunctionData(customMethod, params.data);
    } catch (e) {
      customMethod = "swapSimpleMode";
      funcData = await contract.interface.decodeFunctionData(customMethod, params.data);
    }
    // console.log("?????", funcData);

    callContract(chainId, contract, "swapAndStake", funcData, {
      value,
      sentMsg: `Mint submitted!`,
      failMsg: `Mint failed.`,
      successMsg: `Minted successfully!`,
      setPendingTxns,
    })
      .then(async (res) => {})
      .finally(() => {
        setIsSubmitting(false);
        setFromValue("");
      });
  }
  const buyNslp = async () => {
    setIsSubmitting(true);
    if (DEFAULT_FROM_V2_TOKEN?.[chainId]?.includes(fromTokenAddress)) {
      const contract = new ethers.Contract(rewardRouterAddress, RewardRouter.abi, library.getSigner());
      const method = "mintAndStakeNslp";
      const params = [fromTokenAddress, fromAmount];
      const value = 0;

      callContract(chainId, contract, method, params, {
        value,
        sentMsg: `Buy submitted.`,
        failMsg: `Buy failed.`,
        successMsg: `${formatAmount(nslpAmount, 18, 4, true)} NSLP bought with ${formatAmount(
          fromAmount,
          fromToken.decimals,
          4,
          true
        )} ${fromToken.symbol}!`,
        setPendingTxns,
      })
        .then(async () => {})
        .finally(() => {
          setSwapValue("");
          setIsSubmitting(false);
        });
    } else {
      let method = "swapAndStake";
      let value;
      const { encodedData } = await fetch(
        `${ROUTER_API}?${Object.entries({
          amount: dataAggregator.totalFrom || 0,
          receiver: stakeHelperAddress,
          from: dataAggregator?.from || "",
          to: dataAggregator?.to || "",
          dexes: savedSelectedDexes.replaceAll(" ", "") || "",
          slippage: dataAggregator?.slippage || 1,
          source: dataAggregator?.source || "",
        })
          .map(([k, v]) => `${k}=${v}`)
          .join("&")}`,
        {}
      ).then((r) => r.json());
      if (encodedData) {
        const tokenIn = dataAggregator.from;
        const currencyAIsEth = tokenIn.toLowerCase() === "0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee";
        const amountIn = dataAggregator.totalFrom;
        value = bigNumberify(0);

        if (fromTokenAddress === AddressZero || fromTokenAddress === AddressZero) {
          value = bigNumberify(amountIn.toString());
        }

        const contract = new ethers.Contract(stakeHelperAddress, RouterAggregator.abi, library.getSigner());
        const tx = {
          from: account,
          to: stakeHelperAddress,
          data: encodedData.data, // encoded contract data
          value: currencyAIsEth ? bigNumberify(amountIn.toString()) : undefined,
        };
        sendTransaction(contract, method, tx, value);
      } else {
        setFromRedeemValue("");
        setIsSubmitting(false);
      }
    }
  };

  const sellNslp = () => {
    setIsSubmitting(true);

    const contract = new ethers.Contract(rewardRouterAddress, RewardRouter.abi, library.getSigner());
    const method = "unstakeAndRedeemNslp";
    const params = [redeemDestToken.address, redeemNslpAmount, outputRedeem];

    callContract(chainId, contract, method, params, {
      sentMsg: `Sell submitted!`,
      failMsg: `Sell failed.`,
      successMsg: `${formatAmount(redeemNslpAmount, 18, 4, true)} NSLP sold for ${formatAmount(
        swapAmount,
        redeemDestToken.decimals,
        4,
        true
      )} ${redeemDestToken.symbol}!`,
      setPendingTxns,
    })
      .then(async () => {})
      .finally(() => {
        setSwapValue("");
        setFromRedeemValue("")
        setIsSubmitting(false);
      });
  };

  const onClickPrimary = () => {
    if (!active) {
      connectWallet();
      return;
    }

    if (needApproval) {
      approveFromToken();
      return;
    }

    const [err, modal] = getError();

    if (modal) {
      helperToast.error(err);
      return;
    }

    if (isBuying) {
      buyNslp();
    } else {
      sellNslp();
    }
  };

  let payLabel = `Pay`;
  let receiveLabel = `Receive`;
  let payBalance = "$0.00";
  let receiveBalance = "$0.00";
  if (isBuying) {
    // if (swapUsdMin) {
    //   payBalance = `$${formatAmount(swapUsdMin, USD_DECIMALS, 2, true)}`;
    // }
    // if (nslpUsdMax) {
    //   receiveBalance = `$${formatAmount(nslpUsdMax, USD_DECIMALS, 2, true)}`;
    // }
    receiveBalance = `$${formatAmount(nslpPrice?.mul(nslpAmount || 0), USD_DECIMALS + 18, 2, true)}`;
  } else {
    // if (nslpUsdMax) {
    //   payBalance = `$${formatAmount(nslpUsdMax, USD_DECIMALS, 2, true)}`;
    // }
    // if (swapUsdMin) {
    //   receiveBalance = `$${formatAmount(swapUsdMin, USD_DECIMALS, 2, true)}`;
    // }
    payBalance = `$${formatAmount(nslpPrice?.mul(parseValue(fromRedeemValue || 0, 18)), USD_DECIMALS + 18, 2, true)}`;
    receiveBalance = `$${formatAmount(swapAmount || 0, redeemDestToken.decimals, 2, true)}`;
  }

  // let feePercentageText = formatAmount(isBuying ? stakingFee : unstakingFee, 2, 2, true, "-");

  const feePercentageText = useMemo(() => {
    // if (!swapValue || Number(swapValue) <= 0) return "--";
    let fee;

    if (isBuying) {
      if (!fromValue || Number(fromValue) <= 0) return "--";
      if (stakingFee && swapUsdMin) {
        fee = Number(formatAmount(swapUsdMin, USD_DECIMALS)) * (stakingFee.toNumber() / 1000);
        return (fee / 100).toFixed(2);
      }
    } else {
      if (!fromRedeemValue || Number(fromRedeemValue) <= 0) return "--";
      if (unstakingFee && nslpUsdMax) {
        fee = Number(formatAmount(nslpUsdMax, USD_DECIMALS)) * (unstakingFee.toNumber() / 1000);
        return (fee / 100).toFixed(2);
      }
    }

    return "--";
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fromValue, fromRedeemValue]);

  const onSwapOptionChange = (opt) => {
    if (opt === `Sell NSLP`) {
      switchSwapOption("redeem");
    } else {
      switchSwapOption();
    }
  };

  let adjustedUsdgSupply = bigNumberify(0);

  for (let i = 0; i < tokenList.length; i++) {
    const token = tokenList[i];
    const tokenInfo = infoTokens[token.address];
    if (tokenInfo && tokenInfo.usdgAmount) {
      adjustedUsdgSupply = adjustedUsdgSupply.add(tokenInfo.usdgAmount);
    }
  }

  return (
    <Wrapper>
      <Tab
        className="tab-new-style-fullwidth "
        options={[`Buy NSLP`, `Sell NSLP`]}
        option={tabLabel}
        onChange={onSwapOptionChange}
      />
      <div className="Pool-Form-container">
        {isBuying && (
          <div className="Exchange-swap-section exchange-section-first">
            <div className="Exchange-swap-section-top">
              <div className="muted">
                {dataAggregator && (
                  <div className="Exchange-swap-usd">
                    <span>
                      From: ${formatNumber(formatAmount(fromUsd, fromToken?.decimals || USD_DECIMALS, 2, false), 2)}{" "}
                    </span>
                  </div>
                )}
                {!dataAggregator && `From`}
              </div>
              {fromToken && (
                <div className="muted align-right clickable" onClick={setFromValueToMaximumAvailable}>
                  <span>Balance: {formatAmount(fromToken?.balance, fromToken.decimals, 4, true)}</span>
                </div>
              )}
            </div>

            <div className="Exchange-swap-section-bottom">
              <div className="Exchange-swap-input-container">
                <input
                  type="number"
                  min="0"
                  placeholder="0.0"
                  className="Exchange-swap-input"
                  value={fromValue}
                  onChange={onFromValueChange}
                />
                {shouldShowMaxButton() && (
                  <div className="Exchange-swap-max" onClick={setFromValueToMaximumAvailable}>
                    <span>MAX</span>
                  </div>
                )}
              </div>
              <div style={{ marginLeft: "8px" }}>
                <TokenSelectorV3
                  label={`From`}
                  chainId={chainId}
                  onSelectToken={onSelectFromToken}
                  tokens={microSwapTokens}
                  showSymbolImage={true}
                  showTokenImgInDropdown={true}
                  selectedToken={fromToken}
                />
              </div>
            </div>
          </div>
        )}

        {!isBuying && (
          <BuyInputSection
            topLeftLabel={payLabel}
            topRightLabel={`Available:`}
            tokenBalance={`${formatAmount(maxSellAmount, NLP_DECIMALS, 4, true)}`}
            inputValue={fromRedeemValue}
            onInputValueChange={onNslpValueChange}
            showMaxButton={fromRedeemValue !== formatAmountFree(maxSellAmount, NLP_DECIMALS, NLP_DECIMALS)}
            onClickTopRightLabel={fillMaxAmount}
            onClickMax={fillMaxAmount}
            balance={payBalance}
            defaultTokenName={"NSLP"}
            onlyNumber
          >
            <div className="selected-token">
              <img src={nlp24Icon} alt="nlp24Icon" />
              NSLP
            </div>
          </BuyInputSection>
        )}

        <div className="AppOrder-ball-container">
          <div className="AppOrder-ball">
            <img
              src={arrowIcon}
              alt="arrowIcon"
              onClick={() => {
                setIsBuying(!isBuying);
                switchSwapOption(isBuying ? "redeem" : "");
              }}
            />
          </div>
        </div>
        {isBuying &&
          MICROSWAP_SUPPORTED_CHAIN_IDS.includes(chainId) &&
          !DEFAULT_FROM_V2_TOKEN?.[chainId]?.includes(fromTokenAddress) && (
            <div className="set-colateral">
              <div className="title">
                <Tooltip
                  handle={`Underlying token`}
                  position="left-top"
                  className="fit-content"
                  renderContent={() => {
                    return (
                      <div className="text-white">
                        <span>
                          We find the best price from the top liquidity sources every time. Fees of 0.05% (non-stable
                          tokens) and 0.005% (stable tokens) are automatically factored into this quote by MicroSwap.
                        </span>
                      </div>
                    );
                  }}
                />
              </div>
              <div className="value">
                {/* <TokenSelector
                  label={`To`}
                  chainId={chainId}
                  tokenAddress={destMintAddress}
                  onSelectToken={onSelectMintDestToken}
                  tokens={destTokens}
                  infoTokens={infoTokens}
                  className="GlpSwap-from-token"
                  showMintingCap={false}
                  showSymbolImage={true}
                  showTokenImgInDropdown={true}
                  showBalances={false}
                /> */}
                <TokenSelectorUnderLying
                  label={`To`}
                  chainId={chainId}
                  onSelectToken={(token) => setDestMinAddress(token.address)}
                  tokens={underlyingTokens}
                  showSymbolImage={true}
                  showTokenImgInDropdown={true}
                  selectedToken={destMintToken}
                />
              </div>
            </div>
          )}
        <Wrap>
          {isBuying && (
            <BuyInputSection
              topLeftLabel={receiveLabel}
              topRightLabel={`Balance:`}
              tokenBalance={`${formatAmount(nslpBalance, NLP_DECIMALS, 4, true)}`}
              inputValue={nslpValue}
              // onInputValueChange={onNslpValueChange}
              balance={receiveBalance}
              defaultTokenName={"NSLP"}
              onlyNumber
            >
              <div className="selected-token">
                <img src={nlp24Icon} alt="nlp24Icon" />
                NSLP
              </div>
            </BuyInputSection>
          )}

          {!isBuying && (
            // <BuyInputSection
            //   topLeftLabel={receiveLabel}
            //   topRightLabel={`Balance:`}
            //   tokenBalance={`${formatAmount(swapTokenBalance, swapToken.decimals, 4, true)}`}
            //   inputValue={swapValue}
            //   // onInputValueChange={onSwapValueChange}
            //   balance={receiveBalance}
            //   selectedToken={swapToken}
            //   onlyNumber
            // >

            //   <TokenSelectorUnderLying
            //     label={`To`}
            //     chainId={chainId}
            //     onSelectToken={onSelectReddemDestToken}
            //     tokens={microSwapTokens}
            //     showSymbolImage={true}
            //     showTokenImgInDropdown={true}
            //     selectedToken={redeemDestToken}
            //   />
            // </BuyInputSection>
            <div className="Exchange-swap-section exchange-section-first">
              <div className="Exchange-swap-section-top">
                <div className="muted">
                  <div className="Exchange-swap-usd">
                    <span>
                      {receiveLabel}: {receiveBalance}
                    </span>
                  </div>
                </div>
                {fromToken && (
                  <div className="muted align-right clickable">
                    <span>Balance: {formatAmount(redeemDestToken?.balance, redeemDestToken.decimals, 4, true)}</span>
                  </div>
                )}
              </div>
              <div className="Exchange-swap-section-bottom">
                <div className="Exchange-swap-input-container">
                  <input
                    type="number"
                    min="0"
                    placeholder="0.0"
                    className="Exchange-swap-input"
                    value={swapValue}
                    // onChange={onFromValueChange}
                  />
                </div>
                <div>
                  <TokenSelectorUnderLying
                    label={`To`}
                    chainId={chainId}
                    onSelectToken={onSelectReddemDestToken}
                    tokens={underlyingTokens}
                    showSymbolImage={true}
                    showTokenImgInDropdown={true}
                    selectedToken={redeemDestToken}
                  />
                </div>
              </div>
            </div>
          )}
        </Wrap>
        <div>
          <div className="Exchange-info-row">
            <div className="Exchange-info-label">NSLP Price</div>
            <div className="align-right fee-block">
              {nslpPrice > 0 && <div className="text-white">{formatAmount(nslpPrice, 30, 4, true)} USD</div>}
            </div>
          </div>
          <div className="Exchange-info-row">
            {/* <div className="Exchange-info-label">{feeBasisPoints > 50 ? `WARNING: High Fees` : `Fees`}</div> */}
            <div className="Exchange-info-label">Fees</div>
            <div className="align-right fee-block">
              {isBuying && (
                <Tooltip
                  handle={feePercentageText + " USD"}
                  position="right-bottom"
                  className="fit-content"
                  renderContent={() => {
                    // if (!feeBasisPoints) {
                    //   return (
                    //     <div className="text-white">
                    //       <span>Fees will be shown once you have entered an amount in the order form.</span>
                    //     </div>
                    //   );
                    // }
                    return (
                      <div className="text-white">
                        {/* {feeBasisPoints > 50 && <span>To reduce fees, select a different asset to pay with.</span>} */}
                        <span>0.05% mint amount.</span>
                      </div>
                    );
                  }}
                />
              )}
              {!isBuying && (
                <Tooltip
                  handle={feePercentageText + " USD"}
                  position="right-bottom"
                  className="fit-content"
                  renderContent={() => {
                    // if (!feeBasisPoints) {
                    //   return (
                    //     <div className="text-white">
                    //       <span>Fees will be shown once you have entered an amount in the order form.</span>
                    //     </div>
                    //   );
                    // }
                    return (
                      <div className="text-white">
                        {/* {feeBasisPoints > 50 && <span>To reduce fees, select a different asset to receive.</span>} */}
                        <span>0.15% redeem amount.</span>
                      </div>
                    );
                  }}
                />
              )}
            </div>
          </div>
          {isBuying &&
            MICROSWAP_SUPPORTED_CHAIN_IDS.includes(chainId) &&
            !DEFAULT_FROM_V2_TOKEN?.[chainId]?.includes(fromTokenAddress) && (
              <div className="Exchange-info-row">
                <div className="Exchange-info-label">Minimum Received</div>
                <div className="align-right fee-block">
                  <div className="text-white">
                    <Tooltip
                      handle={`${formatAmount(outputMintMinecieved, 30, 4, true)} NSLP`}
                      position="right-bottom"
                      className="fit-content"
                      renderContent={() => {
                        return (
                          <div className="text-white">
                            <span>You will receive at least this amount or your transaction will revert</span>
                          </div>
                        );
                      }}
                    />
                  </div>
                </div>
              </div>
            )}
          {isBuying && (
            <div className="Exchange-info-row">
              <div className="Exchange-info-label">Remaining Mintable Amount</div>
              <div className="align-right fee-block">
                {nslpAvailableToMint > 0 && (
                  <div className="text-white">{formatAmount(nslpAvailableToMint, 18, 0, true)} USD</div>
                )}
              </div>
            </div>
          )}
          {!isBuying && (
            <div className="Exchange-info-row">
              <div className="Exchange-info-label">
                <Tooltip
                  handle="Cooldown Period"
                  renderContent={() => {
                    return `There is a ${coundowHour}-hour cooldown period after each stake.`;
                  }}
                />
              </div>

              <div className="align-right fee-block">
                <Countdown
                  key={redemptionTime}
                  date={new Date(Number(redemptionTime) * 1000)}
                  renderer={countdownRenderer}
                  daysInHours
                />
              </div>
            </div>
          )}
          {isBuying && DEFAULT_FROM_V2_TOKEN?.[chainId]?.includes(fromTokenAddress) && (
            <Stylednoti>
              <div>
                <img src={infoIcon} alt="info" />
              </div>
              <div>NSLP will automatically be staked after purchase.</div>
            </Stylednoti>
          )}
          {isBuying && !DEFAULT_FROM_V2_TOKEN?.[chainId]?.includes(fromTokenAddress) && (
            <Stylednoti>
              <div>
                <img src={infoIcon} alt="info" />
              </div>
              <div>
                Your {fromToken.symbol} will be swapped to {destMintToken.symbol} then deposited in to the pool.
              </div>
            </Stylednoti>
          )}
        </div>
        <div className="BuySellNslp-cta Exchange-swap-button-container">
          <button className="App-cta Exchange-swap-button" onClick={onClickPrimary} disabled={!isPrimaryEnabled()}>
            {getPrimaryText()}
          </button>
        </div>
      </div>
    </Wrapper>
  );
}

const countdownRenderer = (countdownProps) => {
  const {
    formatted: { hours, minutes, seconds },
  } = countdownProps;
  return <>{`${hours}:${minutes}:${seconds}`}</>;
};

const Wrap = styled.div`
  margin-top: -34px;
`;
const Wrapper = styled.div`
  @media screen and (max-width: 700px) {
    .AppOrder-ball {
      img {
        width: 24px !important;
        height: 24px !important;
        position: relative;
        top: 5px;
      }
    }

    .body .body-row {
      border-radius: 16px;
    }
  }
`;
const Stylednoti = styled.div`
  border-radius: 100px;
  background: var(--Nature-2, #1a1d26);
  display: flex;
  padding: var(--Base-Unit-XS-8, 8px);
  align-items: center;
  gap: var(--Base-Unit-XS-8, 8px);
  margin-top: 4px;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
  @media screen and (max-width: 700px) {
    img {
      width: 16px;
      height: 16px;
    }
  }
`;
