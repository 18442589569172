import { Trans } from "@lingui/macro";
import cx from "classnames";
import { SUPPORTED_CHAIN_ID_DEPOSIT, getConstant } from "config/chains";
import perps from "img/perps.svg";
import swap_aggregator_ic from "img/swap_aggregator_ic.svg";
import { useChainId } from "lib/chains";
import { useState } from "react";
import { FiX } from "react-icons/fi";
import { Link, useHistory } from "react-router-dom";
import styled from "styled-components";
import "./Header.scss";
import { HeaderLink } from "./HeaderLink";

import arrow from "img/header/arrow.svg";
import arrowLight from "img/header/arrowLight.svg";
import perps_light from "img/header/perps_light.svg";
import swap_aggregator_ic_light from "img/header/swap_aggregator_ic_light.svg";

import { formatAmount } from "lib/numbers";

import { IconLogo, IconVault1, IconVault2, SvgVault1, SvgVault2 } from "assets";
import TransactionModal from "components/DepositModal";
import { useThemeContext } from "contexts/ThemeProvider";
import useProviderBalance from "hooks/useProviderBalance";
import IconArrow from "img/launch/iconArrow";
import { importImage } from "lib/legacy";
import icon1 from "img/header/icon1.svg";
import icon2 from "img/header/icon2.svg";
import icon3 from "img/header/icon3.svg";
import icon4 from "img/header/icon4.svg";
import icon5 from "img/header/icon5.svg";
import icon6 from "img/header/icon6.svg";

// type Props = {
//   small?: boolean;
//   clickCloseIcon?: () => void;
//   openSettings?: () => void;
//   redirectPopupTimestamp: number;
//   showRedirectModal: (to: string) => void;
//   account: string | null | undefined;
//   library: any;
// };

// const arrowIcon = (
//   <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
//     <path d="M7.66536 11.336L11.4181 5.836L3.91259 5.836L7.66536 11.336Z" fill="white" fill-opacity="0.6" />
//   </svg>
// );

export function AppHeaderLinks(props) {
  const { isLightTheme } = useThemeContext();
  const {
    small,
    openSettings,
    clickCloseIcon,
    redirectPopupTimestamp,
    showRedirectModal,
    account,
    library,
    usdcBalance,
    gusdBalance,
    savedSlippageAmount,
  } = props;
  const { chainId } = useChainId();
  const [isDepositModalVisible, setIsDepositModalVisible] = useState(false);

  const [earnDropdownExpanded, setEarnDropdownExpanded] = useState(false);
  const [moreDropdownExpanded, setMoreDropdownExpanded] = useState(false);
  const history = useHistory();

  const hash = window.location.hash;

  // const tradeBtnRef = useRef(null);
  // const moreBtnRef = useRef(null);
  // const analyticBtnRef = useRef(null);
  // const timeoutDuration = 0;
  // let timeout;
  // const onTradeMouseEnter = () => {
  //   clearTimeout(timeout);
  //   return tradeBtnRef.current?.click();
  // };

  // const onTradeMouseLeave = () => {
  //   timeout = setTimeout(() => tradeBtnRef.current?.click(), timeoutDuration);
  // };

  // const onMoreMouseEnter = () => {
  //   clearTimeout(timeout);
  //   return moreBtnRef.current?.click();
  // };

  // const onMoreMouseLeave = () => {
  //   timeout = setTimeout(() => moreBtnRef.current?.click(), timeoutDuration);
  // };

  // const onAnalyticMouseEnter = () => {
  //   clearTimeout(timeout);
  //   return analyticBtnRef.current?.click();
  // };

  // const onAnalyticMouseLeave = () => {
  //   timeout = setTimeout(() => analyticBtnRef.current?.click(), timeoutDuration);
  // };
  const ethBalance = useProviderBalance();
  const nativeTokenSymbol = getConstant(chainId, "nativeTokenSymbol");
  // console.log("hash", hash);
  const renderEarnMenu = () => {
    if (!small)
      return (
        <MenuContainer className="App-header-link-container">
          <div className="App-header-dropdown">
            <span
              className={`${
                [
                  "#/earn",
                  "#/earn/nslp",
                  "#/earn/nlp",
                  "#/earn#stake",
                  "#/earn/nslp",
                  "#/earn#vest",
                  "#/earn/nslp#redeem",
                  "#/earn/nlp#redeem",
                ].includes(hash)
                  ? "menu-selected"
                  : "more-navi"
              }`}
            >
              Earn <IconArrow fill="#828899" class="arrow-icon-tab-header" />
              <div className="show-dropdown-link">
                <div className="menu-items">
                  {" "}
                  <HeaderLink
                    to="/earn"
                    redirectPopupTimestamp={redirectPopupTimestamp}
                    showRedirectModal={showRedirectModal}
                  >
                    <div
                      className={cx("menu-item", {
                        actived: ["#/earn"].includes(hash),
                      })}
                    >
                      <img src={icon1} alt="" />
                      <div className="wrap">
                        <span>Overview</span>
                        <span className="desc">Manage your earning</span>
                      </div>
                    </div>{" "}
                  </HeaderLink>{" "}
                  <HeaderLink
                    to="/earn#stake"
                    redirectPopupTimestamp={redirectPopupTimestamp}
                    showRedirectModal={showRedirectModal}
                  >
                    <div
                      className={cx("menu-item", {
                        actived: [].includes(hash),
                      })}
                    >
                      <img src={icon2} alt="" />
                      <div className="wrap">
                        <span className="inner">Stake NAVI & esNAVI</span>
                        <span className="desc">Stake Navigator governance token</span>
                      </div>
                    </div>{" "}
                  </HeaderLink>{" "}
                  <HeaderLink
                    to="/earn/nlp"
                    redirectPopupTimestamp={redirectPopupTimestamp}
                    showRedirectModal={showRedirectModal}
                  >
                    <div
                      className={cx("menu-item", {
                        actived: [].includes(hash),
                      })}
                    >
                      <img src={icon3} alt="" />
                      <div className="wrap">
                        <span className="inner">Crypto Pool - NLP</span>
                        <span className="desc">Index by crypto asset</span>
                      </div>
                    </div>{" "}
                  </HeaderLink>{" "}
                  <HeaderLink
                    to="/earn/nslp"
                    redirectPopupTimestamp={redirectPopupTimestamp}
                    showRedirectModal={showRedirectModal}
                  >
                    <div
                      className={cx("menu-item", {
                        actived: [].includes(hash),
                      })}
                    >
                      <img src={icon4} alt="" />
                      <div className="wrap">
                        <span className="inner">Stable Pool - NSLP</span>
                        <span className="desc">Index by stable asset</span>
                      </div>
                    </div>{" "}
                  </HeaderLink>
                  {/* <div
                    className={cx("menu-item", {
                      actived: [].includes(hash),
                    })}
                  >
                    <HeaderLink
                      to="/earn#stake"
                      redirectPopupTimestamp={redirectPopupTimestamp}
                      showRedirectModal={showRedirectModal}
                    >
                      <img src={icon5} alt="" />
                      <div className="wrap">
                        <span className="inner">Stake NAVI-S LP</span>
                        <span className="desc">Provide liquidity to earn NAVI and fees.</span>
                      </div>
                    </HeaderLink>
                  </div> */}{" "}
                  <HeaderLink
                    to="/earn#vest"
                    redirectPopupTimestamp={redirectPopupTimestamp}
                    showRedirectModal={showRedirectModal}
                  >
                    <div
                      className={cx("menu-item", {
                        actived: [].includes(hash),
                      })}
                    >
                      <img src={icon6} alt="" />
                      <div className="wrap">
                        <span className="inner">Vest</span>
                        <span className="desc">Convert esNAVI tokens to NAVI tokens</span>
                      </div>
                    </div>{" "}
                  </HeaderLink>
                </div>
              </div>
            </span>
          </div>
        </MenuContainer>
      );
    return (
      <div
        onClick={() => setMoreDropdownExpanded(!moreDropdownExpanded)}
        className={`App-header-link-container-menu ${moreDropdownExpanded ? "selected" : ""}`}
      >
        <div className="App-header-link-row">
          <span>Earn</span>
          <IconArrow fill="#828899" class="arrow-icon-tab-header" />
          {/* <img src={isLightTheme ? arrowLight : arrow} alt="arrow" /> */}
        </div>
        {moreDropdownExpanded && (
          <div className="App-header-link-list">
            <div
              className={cx("App-header-link-container", {
                actived: hash === "#/earn",
              })}
            >
              <Link to="/earn">
                <img src={icon1} alt="" />
                <div className="wrap">
                  <span>Overview</span>
                  <span className="desc">Manage your earning</span>
                </div>
              </Link>
            </div>
            <div
              className={cx("App-header-link-container", {
                actived: hash === "#/earn#stake",
              })}
            >
              <Link to="/earn#stake">
                <img src={icon2} alt="" />
                <div className="wrap">
                  <span className="inner">Stake NAVI & esNAVI</span>
                  <span className="desc">Stake Navigator governance token</span>
                </div>
              </Link>
            </div>
            <div
              className={cx("App-header-link-container", {
                actived: hash === "#/earn/nlp",
              })}
            >
              <Link to="/earn/nlp">
                <img src={icon3} alt="" />
                <div className="wrap">
                  <span className="inner">Crypto Pool - NLP</span>
                  <span className="desc">Index by crypto asset</span>
                </div>
              </Link>
            </div>
            <div
              className={cx("App-header-link-container", {
                actived: hash === "#/earn/nslp",
              })}
            >
              <Link to="/earn/nslp">
                <img src={icon4} alt="" />
                <div className="wrap">
                  <span className="inner">Stable Pool - NSLP</span>
                  <span className="desc">Index by stable asset</span>
                </div>
              </Link>
            </div>
            {/* <div className="App-header-link-container">
              <Link to="/earn#stake">
                <img src={icon5} alt="" />
                <div className="wrap">
                  <span className="inner">Stake NAVI-S LP</span>
                  <span className="desc">Provide liquidity to earn NAVI and fees.</span>
                </div>
              </Link>
            </div> */}
            <div
              className={cx("App-header-link-container", {
                actived: hash === "#/earn#vest",
              })}
            >
              <Link to="/earn#vest">
                <img src={icon6} alt="" />
                <div className="wrap">
                  <span className="inner">Vest</span>
                  <span className="desc">Convert esNAVI tokens to NAVI tokens</span>
                </div>
              </Link>
            </div>
          </div>
        )}
      </div>
    );
  };

  const renderVaultMenu = () => {
    if (!small)
      return (
        <MenuContainer className="App-header-link-container">
          <div className="App-header-dropdown">
            <span className={`${["#/vault"].includes(hash) ? "menu-selected" : "more-navi"}`}>
              Vaults <IconArrow fill="#828899" class="arrow-icon-tab-header" />
              <div className="show-dropdown-link">
                <div className="menu-items">
                  {" "}
                  <a href="https://vaults.navigator.exchange" target="_blank">
                    <div className={cx("menu-item")}>
                      <img src={SvgVault1} alt="" />
                      <div className="wrap">
                        <span>Yield Optimizer </span>
                        <span className="desc">
                          Auto-compound your earnings with advanced strategies for maximum APY
                        </span>
                      </div>
                    </div>{" "}
                  </a>
                  <HeaderLink
                    to="/vault"
                    redirectPopupTimestamp={redirectPopupTimestamp}
                    showRedirectModal={showRedirectModal}
                  >
                    <div
                      className={cx("menu-item", {
                        actived: ["#/vault"].includes(hash),
                      })}
                    >
                      <img src={SvgVault2} alt="" />

                      <div className="wrap">
                        <span>Native Vaults </span>
                        <span className="desc">Designed for sustainable growth and long-term rewards</span>
                      </div>
                    </div>{" "}
                  </HeaderLink>
                </div>
              </div>
            </span>
          </div>
        </MenuContainer>
      );
    return (
      <div
        onClick={() => setMoreDropdownExpanded(!moreDropdownExpanded)}
        className={`App-header-link-container-menu ${moreDropdownExpanded ? "selected" : ""}`}
      >
        <div className="App-header-link-row">
          <span>Earn</span>
          <IconArrow fill="#828899" class="arrow-icon-tab-header" />
          {/* <img src={isLightTheme ? arrowLight : arrow} alt="arrow" /> */}
        </div>
        {moreDropdownExpanded && (
          <div className="App-header-link-list">
            <div
              className={cx("App-header-link-container", {
                actived: hash === "#/earn",
              })}
            >
              <Link to="/earn">
                <img src={icon1} alt="" />
                <div className="wrap">
                  <span>Overview</span>
                  <span className="desc">Manage your earning</span>
                </div>
              </Link>
            </div>
            <div
              className={cx("App-header-link-container", {
                actived: hash === "#/earn#stake",
              })}
            >
              <Link to="/earn#stake">
                <img src={icon2} alt="" />
                <div className="wrap">
                  <span className="inner">Stake NAVI & esNAVI</span>
                  <span className="desc">Stake Navigator governance token</span>
                </div>
              </Link>
            </div>
            <div
              className={cx("App-header-link-container", {
                actived: hash === "#/earn/nlp",
              })}
            >
              <Link to="/earn/nlp">
                <img src={icon3} alt="" />
                <div className="wrap">
                  <span className="inner">Crypto Pool - NLP</span>
                  <span className="desc">Index by crypto asset</span>
                </div>
              </Link>
            </div>
            <div
              className={cx("App-header-link-container", {
                actived: hash === "#/earn/nslp",
              })}
            >
              <Link to="/earn/nslp">
                <img src={icon4} alt="" />
                <div className="wrap">
                  <span className="inner">Stable Pool - NSLP</span>
                  <span className="desc">Index by stable asset</span>
                </div>
              </Link>
            </div>
            {/* <div className="App-header-link-container">
              <Link to="/earn#stake">
                <img src={icon5} alt="" />
                <div className="wrap">
                  <span className="inner">Stake NAVI-S LP</span>
                  <span className="desc">Provide liquidity to earn NAVI and fees.</span>
                </div>
              </Link>
            </div> */}
            <div
              className={cx("App-header-link-container", {
                actived: hash === "#/earn#vest",
              })}
            >
              <Link to="/earn#vest">
                <img src={icon6} alt="" />
                <div className="wrap">
                  <span className="inner">Vest</span>
                  <span className="desc">Convert esNAVI tokens to NAVI tokens</span>
                </div>
              </Link>
            </div>
          </div>
        )}
      </div>
    );
  };
  return (
    <div className="App-header-links">
      {small && (
        <div className="App-header-links-header">
          <a className="App-header-link-main" href="https://www.navigator.exchange/">
            {/* <img src={isLightTheme ? logoImgV2Light : logoImg} alt="NAVI Logo" /> */}
            <IconLogo />
          </a>
          <div
            className="App-header-menu-icon-block mobile-cross-menu"
            onClick={() => clickCloseIcon && clickCloseIcon()}
          >
            <FiX className="App-header-menu-icon" />
          </div>
        </div>
      )}

      {account && small && SUPPORTED_CHAIN_ID_DEPOSIT.includes(chainId) && (
        <>
          <TransactionModal
            isVisible={isDepositModalVisible}
            setIsVisible={setIsDepositModalVisible}
            library={library}
            chainId={chainId}
            savedSlippageAmount={savedSlippageAmount}
          ></TransactionModal>
          <div className="App-header-item-container">
            <div className="App-header-balance-container--mobile">
              <div className="App-header-mobile-left">
                {/* <img src={exchangeIcon} alt="icon" style={{ marginRight: "4px" }} /> */}
                <div className="App-header-usd-balance">${formatAmount(gusdBalance || 0, 30, 2, true)}</div>
                <StyledLine />
                <div className="App-header-eth-balance-container">
                  <span className="App-header-eth-icon">
                    <img src={importImage("ic_" + nativeTokenSymbol.toLowerCase() + "_24.svg")} alt="" />
                  </span>
                  <span className="App-header-eth-balance">{formatAmount(ethBalance, 18, 4, true)}</span>
                </div>
              </div>
              <div className="App-header-mobile-right">
                <button className="App-header-add-eth" onClick={() => setIsDepositModalVisible(true)}>
                  <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M7.27344 3.04492V4.52832H0.451172V3.04492H7.27344ZM4.66211 0.255859V7.50195H3.06934V0.255859H4.66211Z"
                      fill={isLightTheme ? "#fff" : "#12141A"}
                    />
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </>
      )}
      <div className="App-header-link-container">
        <HeaderLink
          to="/dashboard"
          redirectPopupTimestamp={redirectPopupTimestamp}
          showRedirectModal={showRedirectModal}
          className="navi-item"
        >
          {/* <IconDashboard />    */}
          <Trans>Dashboard</Trans>
        </HeaderLink>
      </div>
      <div className="App-header-link-container">
        <HeaderLink
          to="/trade"
          redirectPopupTimestamp={redirectPopupTimestamp}
          showRedirectModal={showRedirectModal}
          className={cx("navi-item", {
            active: ["#/trade", "#/v1"].includes(hash),
          })}
        >
          {/* <IconPerp /> */}
          <Trans>Perp</Trans>
        </HeaderLink>
      </div>
      <div className="App-header-link-container">
        <HeaderLink
          to={true ? "/swap-x" : "/swap"}
          redirectPopupTimestamp={redirectPopupTimestamp}
          showRedirectModal={showRedirectModal}
          className={cx("navi-item", {
            active: ["#/swap-x", "#/swap"].includes(hash),
          })}
        >
          {/* <IconSwap /> */}
          <Trans>Swap</Trans>
        </HeaderLink>
      </div>
      {false && small && (
        <div
          onClick={() => setEarnDropdownExpanded(!earnDropdownExpanded)}
          className={`App-header-link-container-menu ${earnDropdownExpanded ? "selected" : ""}`}
        >
          <div className="App-header-link-row">
            <span>Swap</span>

            <img src={isLightTheme ? arrowLight : arrow} alt="arrow" />
          </div>
          {earnDropdownExpanded && (
            <div className="App-header-link-list">
              <div className="App-header-link-container">
                <HeaderLink
                  to="/swap"
                  redirectPopupTimestamp={redirectPopupTimestamp}
                  showRedirectModal={showRedirectModal}
                >
                  <img src={isLightTheme ? perps_light : perps} alt="" />
                  <div className="wrap">
                    <span>Spot Native</span>
                    <span className="desc">Swap tokens in an instant.</span>
                  </div>
                </HeaderLink>
              </div>
              <div className="App-header-link-container">
                <HeaderLink
                  to={true ? "/swap" : "/swap"}
                  redirectPopupTimestamp={redirectPopupTimestamp}
                  showRedirectModal={showRedirectModal}
                >
                  <img src={isLightTheme ? swap_aggregator_ic_light : swap_aggregator_ic} alt="" />
                  <div className="wrap">
                    <span>Spot Through Aggregator</span>
                    <span className="desc">Swap tokens using the best route powered by MicroSwap</span>
                  </div>
                </HeaderLink>
              </div>
            </div>
          )}
        </div>
      )}

      {false && !small && (
        <div className="App-header-link-container">
          {/* <div className="new-dot" /> */}
          <div className="App-header-dropdown">
            <span
              className={cx({
                "menu-selected": ["#/swap", "#/swap"].includes(hash),
              })}
            >
              Swap <IconArrow class="arrow-icon-tab-header" />
              <div className="show-dropdown-link">
                <div className="menu-items">
                  {/* <div
                    className={cx("menu-item", {
                      actived: ["#/trade", "#/v1"].includes(hash),
                    })}
                  >
                    <HeaderLink
                      to="/trade"
                      redirectPopupTimestamp={redirectPopupTimestamp}
                      showRedirectModal={showRedirectModal}
                    >
                      <img src={isLightTheme ? perps_light : perps} alt="" />
                      <div className="wrap">
                        <span>Perp</span>
                        <span className="desc">Trade perpetual contracts</span>
                      </div>
                    </HeaderLink>
                  </div> */}
                  {/* <div
                    className={cx("menu-item", {
                      actived: ["#/swap"].includes(hash),
                    })}
                  >
                    <HeaderLink
                      to="/swap"
                      redirectPopupTimestamp={redirectPopupTimestamp}
                      showRedirectModal={showRedirectModal}
                    >
                      <img src={isLightTheme ? swap_ic_light : swap_ic} alt="" />
                      <div className="wrap">
                        <span>Spot Native</span>
                        <span className="desc">Swap tokens in an instant</span>
                      </div>
                    </HeaderLink>
                  </div> */}
                  {/* <div
                    className={cx("menu-item", {
                      actived: ["#/swap"].includes(hash),
                    })}
                  >
                    <HeaderLink
                      to="/swap"
                      redirectPopupTimestamp={redirectPopupTimestamp}
                      showRedirectModal={showRedirectModal}
                    >
                      <img src={isLightTheme ? swap_aggregator_ic_light : swap_aggregator_ic} alt="" />
                      <div className="wrap">
                        <span>Spot Through Aggregator</span>
                        <span className="desc">Swap tokens using the best route powered by MicroSwap</span>
                      </div>
                    </HeaderLink>
                  </div> */}
                </div>
              </div>
            </span>
          </div>
        </div>
      )}
      {renderEarnMenu()}
      {renderVaultMenu()}
      {/* <div className="App-header-link-container disabled">
        <HeaderLink
          to="/vault/"
          redirectPopupTimestamp={redirectPopupTimestamp}
          showRedirectModal={showRedirectModal}
          className="navi-item"
        >
          <Trans>Vaults</Trans>
        </HeaderLink>
      </div> */}
      <div className="App-header-link-container disabled">
        <HeaderLink
          to="/buy/"
          redirectPopupTimestamp={redirectPopupTimestamp}
          showRedirectModal={showRedirectModal}
          className="navi-item"
        >
          {/* <IconEarn /> */}
          <Trans>Buy</Trans>
        </HeaderLink>
      </div>
      <div className="App-header-link-container disabled">
        <HeaderLink
          to="/referrals/"
          redirectPopupTimestamp={redirectPopupTimestamp}
          showRedirectModal={showRedirectModal}
          className="navi-item"
        >
          <Trans>Referrals</Trans>
        </HeaderLink>
      </div>
      <div className="App-header-link-container ">
        <HeaderLink
          to="/portfolio"
          className="navi-item"
          redirectPopupTimestamp={redirectPopupTimestamp}
          showRedirectModal={showRedirectModal}
        >
          {/* <IconPor /> */}
          <Trans>Portfolio</Trans>
        </HeaderLink>
      </div>
      <Styledbox className="App-header-link-container ">
        <HeaderLink
          to="/analytics"
          className="navi-item"
          redirectPopupTimestamp={redirectPopupTimestamp}
          showRedirectModal={showRedirectModal}
        >
          <Trans>Analytics</Trans>
          <StyleTxt>Contest</StyleTxt>
        </HeaderLink>
      </Styledbox>
      <div className="App-header-link-container ">
        <HeaderLink
          to="/nfts"
          className="navi-item"
          redirectPopupTimestamp={redirectPopupTimestamp}
          showRedirectModal={showRedirectModal}
        >
          <Trans>NFTs</Trans>
        </HeaderLink>
      </div>
      <div className="App-header-link-container ">
        <HeaderLink
          to="/migrate"
          className="navi-item"
          redirectPopupTimestamp={redirectPopupTimestamp}
          showRedirectModal={showRedirectModal}
        >
          <Trans>Migrate</Trans>
        </HeaderLink>
      </div>
      {/* <div className="App-header-link-container disabled">
        <HeaderLink
          to="/faucet/"
          redirectPopupTimestamp={redirectPopupTimestamp}
          showRedirectModal={showRedirectModal}
          className="navi-item"
        >
          <Trans>Faucet</Trans>
        </HeaderLink>
      </div> */}
      {/* <div className="App-header-link-container">
        <HeaderLink
          to="/vault"
          className="navi-item"
          redirectPopupTimestamp={redirectPopupTimestamp}
          showRedirectModal={showRedirectModal}
        >
          <IconVaults />
          <Trans>Vaults</Trans>
        </HeaderLink>
      </div> */}
      {/* <div className="App-header-link-container">
        <HeaderLink
          to="/faucet"
          className="navi-item"
          redirectPopupTimestamp={redirectPopupTimestamp}
          showRedirectModal={showRedirectModal}
        >
          <IconFaucet />
          <Trans>Faucet</Trans>
        </HeaderLink>
      </div> */}
      {/* <div className="App-header-link-container">
        <HeaderLink
          to="/vault"
          redirectPopupTimestamp={redirectPopupTimestamp}
          showRedirectModal={showRedirectModal}
        >
          <span>Vault</span>
        </HeaderLink>
      </div> */}
      {/* <div className="App-header-link-container">
        <HeaderLink
          className={cx("clickable", {
            active: ["/buy", "/buy_navi", "/buy_nlp", "/buy/mint"].includes(
              window.location.hash.slice(1, window.location.hash.length)
            ),
          })}
          to="/buy"
          redirectPopupTimestamp={redirectPopupTimestamp}
          showRedirectModal={showRedirectModal}
        >
          <Trans>Buy</Trans>
        </HeaderLink>
      </div> */}

      {/* {Date.now() < 1692180000000 && (
        <div className="App-header-link-container">
          <HeaderLink
            to="/migrate"
            redirectPopupTimestamp={redirectPopupTimestamp}
            showRedirectModal={showRedirectModal}
          >
            <Trans>Migrate</Trans>
          </HeaderLink>
        </div>
      )}
      <div className="App-header-link-container">
        <ExternalLink href="https://prediction.navigator.exchange/">
          <Trans>Prediction (BETA)</Trans>
        </ExternalLink>
      </div>
      {chainId !== ARBITRUM && (
        <div className="App-header-link-container">
          <HeaderLink to="/nft" redirectPopupTimestamp={redirectPopupTimestamp} showRedirectModal={showRedirectModal}>
            <Trans>NFT Club</Trans>
          </HeaderLink>
        </div>
      )} */}

      {/* {chainId === FANTOM && (
        <div className="App-header-link-container">
          <HeaderLink
            to="/portfolio"
            redirectPopupTimestamp={redirectPopupTimestamp}
            showRedirectModal={showRedirectModal}
          >
            <Trans>Portfolio</Trans>
          </HeaderLink>
        </div>
      )} */}

      {/* {small && (
        <div
          onClick={() => setBridgeDropdownExpanded(!bridgeDropdownExpanded)}
          className={`App-header-link-container-menu ${bridgeDropdownExpanded ? "selected" : ""}`}
        >
          <div className="App-header-link-row">
            <span>Bridge</span>

            <img src={isLightTheme ? arrowLight : arrow} alt="arrow" />
          </div>
          {bridgeDropdownExpanded && (
            <div className="App-header-link-list">
              <div className="App-header-link-container">
                <HeaderLink
                  to="/bridge"
                  redirectPopupTimestamp={redirectPopupTimestamp}
                  showRedirectModal={showRedirectModal}
                >
                  <img src={isLightTheme ? bridge_light : bridge_light} alt="" />
                  <div className="wrap">
                    <span>LayerZero</span>
                    <span className="desc">Bridge NAVI seamlessly via Layer0 technology</span>
                  </div>
                </HeaderLink>
              </div>
              <div className="App-header-link-container">
                <a href="/#/bridge-wormhole" target="_blank">
                  <img src={isLightTheme ? bridge_wormhole_light : bridge_wormhole_light} alt="" />
                  <div className="wrap">
                    <span>Wormhole</span>
                    <span className="desc">Bridge tokens seamlessly via Wormhole technology</span>
                  </div>
                </a>
              </div>
            </div>
          )}
        </div>
      )}

      {small && (
        <div
          onClick={() => setAnalyticDropdownExpanded(!analyticDropdownExpanded)}
          className={`App-header-link-container-menu ${analyticDropdownExpanded ? "selected" : ""}`}
        >
          <div className="App-header-link-row">
            <span>Analytics</span>
            <img src={isLightTheme ? arrowLight : arrow} alt="arrow" />
          </div>
          {analyticDropdownExpanded && (
            <div className="App-header-link-list">
              {!isLanding && (
                <>
                  {" "}
                  <div className="App-header-link-container">
                    <HeaderLink
                      to="/analytics/overview"
                      redirectPopupTimestamp={redirectPopupTimestamp}
                      showRedirectModal={showRedirectModal}
                    >
                      <img src={isLightTheme ? analytic_light : analytic} alt="" />
                      <div className="wrap">
                        <span>Analytics V1</span>
                        <span className="desc">The comprehensive statistics and data visualization of V1</span>
                      </div>
                    </HeaderLink>
                  </div>
                  <div className="App-header-link-container">
                    <HeaderLink
                      to="/analytic-v2/overview"
                      redirectPopupTimestamp={redirectPopupTimestamp}
                      showRedirectModal={showRedirectModal}
                    >
                      <img src={isLightTheme ? analytic_v2_light : analytic_v2} alt="" />
                      <div className="wrap">
                        <span>
                          Analytics <span className="tag-v2">V2</span>
                        </span>
                        <span className="desc">Stake Navigator governance token</span>
                      </div>
                    </HeaderLink>
                  </div>
                </>
              )}
            </div>
          )}
        </div>
      )} */}

      {/* {small && (
        <div
          onClick={() => setMoreDropdownExpanded(!moreDropdownExpanded)}
          className={`App-header-link-container-menu ${moreDropdownExpanded ? "selected" : ""}`}
        >
          <div className="App-header-link-row">
            <span>More</span>
            <img src={isLightTheme ? arrowLight : arrow} alt="arrow" />
          </div>
          {moreDropdownExpanded && (
            <div className="App-header-link-list">
              {!isLanding && (
                <>
                  {" "}
                  <div className="App-header-link-container">
                    <HeaderLink
                      to="/referrals"
                      redirectPopupTimestamp={redirectPopupTimestamp}
                      showRedirectModal={showRedirectModal}
                    >
                      <img src={isLightTheme ? referral_light : referral} alt="" />
                      <div className="wrap">
                        <span>Referrals</span>
                        <span className="desc">Earn great rebates by referring your friends for trading</span>
                      </div>
                    </HeaderLink>
                  </div>
                  <div className="App-header-link-container">
                    <HeaderLink
                      to="/bridge"
                      redirectPopupTimestamp={redirectPopupTimestamp}
                      showRedirectModal={showRedirectModal}
                    >
                      <img src={isLightTheme ? bridge_ic_light : bridge_ic} alt="" />
                      <div className="wrap">
                        <Trans>Bridge</Trans>
                        <span className="desc">Bridge NAVI seamlessly via Layer0 technology</span>
                      </div>
                    </HeaderLink>
                  </div>
                  <div className="App-header-link-container">
                    <HeaderLink
                      to="/news"
                      redirectPopupTimestamp={redirectPopupTimestamp}
                      showRedirectModal={showRedirectModal}
                    >
                      <img src={isLightTheme ? news_light : news} alt="" />
                      <div className="wrap">
                        <span>News</span>
                        <span className="desc">Stay updated with the latest crypto news</span>
                      </div>
                    </HeaderLink>
                  </div>
                  <div className="App-header-link-container">
                    <ExternalLink href="https://docs.navigator.exchange">
                      <img src={isLightTheme ? about_light : about} alt="" />
                      <div className="wrap">
                        <span>About</span>
                        <span className="desc">Navigator Exchange Documentation</span>
                      </div>
                    </ExternalLink>
                  </div>
                </>
              )}

              <div className="App-header-link-container">
                <ExternalLink href="https://skeleton.finance">
                  <img src={isLightTheme ? skeleton_ic_light : skeleton_ic} alt="" />
                  <div className="wrap">
                    <span>Skeleton</span>
                    <span className="desc">V3 DEX powered by Navigator</span>
                  </div>
                </ExternalLink>
              </div>

              {!isLanding && chainId === FANTOM && (
                <div className="App-header-link-container">
                  <HeaderLink
                    to="/proof-of-locked-liquidity"
                    redirectPopupTimestamp={redirectPopupTimestamp}
                    showRedirectModal={showRedirectModal}
                  >
                    <img src={isLightTheme ? poll_light : poll} alt="" />
                    <div className="wrap">
                      <span>PoL</span>
                      <span className="desc">Protocol-Owned NAVI Liquidity</span>
                    </div>
                  </HeaderLink>
                </div>
              )}
            </div>
          )}
        </div>
      )} */}
    </div>
  );
}

const StyledLine = styled.div`
  height: 40px;
  width: 1px;
  background: rgba(55, 63, 92, 0.5);
  margin-left: 4px;
  margin-right: 16px;
`;
const MenuContainer = styled.div`
  .menu-item {
    width: 100%;
    gap: 12px;
  }
`;
const Styledbox = styled.div`
  .navi-item {
    position: relative;
  }
`;
const StyleTxt = styled.div`
  @keyframes background-slide {
    0% {
      background-position: 0 0;
    }

    to {
      background-position: 200% 0;
    }
  }
  position: absolute;
  top: 22px;
  right: 11px;
  @media screen and (max-width: 1460px) {
    top: 32px;
  }
  @media screen and (max-width: 1350px) {
    /*
    top: 27px; */
    position: unset;
    margin-top: 3px;
  }
  font-size: 10px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  animation: background-slide 2.5s linear infinite;
  background-size: 200%;
  background-image: linear-gradient(277deg, #fff, #ff711b 14.3%, #85c5e0 84.72%, #fff);
`;
