import React from "react";

import cx from "classnames";
import externalLink from "img/ic_new_link_v2_16.svg";

import "./Tab.css";
import "./TabTheme.scss";
import { useThemeContext } from "contexts/ThemeProvider";
import { IconNewLink } from "assets";
const LONG = "Long";
const SHORT = "Short";
export default function Tab(props) {
  const { lightThemeClassName, isLightTheme } = useThemeContext();
  const {
    options,
    option,
    setOption,
    onChange,
    type = "block",
    className,
    optionLabels,
    icons,
    isIconFirst,
    isUpperCase,
    isBoldFontWeight,
    linkTabs,
  } = props;
  const onClick = (opt) => {
    if (setOption) {
      setOption(opt);
    }
    if (onChange) {
      onChange(opt);
    }
  };

  return (
    <div className={cx("Tab", type, className, { [lightThemeClassName]: isLightTheme })}>
      {options.map((opt) => {
        const label = optionLabels && optionLabels[opt] ? optionLabels[opt] : opt;
        console.log("label", label);
        if (linkTabs && linkTabs[opt]) {
          return (
            <a
              href={linkTabs[opt]}
              target="_blank"
              className={cx("Tab-option", "", {
                active: opt === option,
                long: opt === LONG,
                short: opt === SHORT,
                longInactive: opt !== option && opt === LONG,
                shortInactive: opt !== option && opt === SHORT,
                tabFontWetght: isBoldFontWeight === true,
              })}
              // onClick={() => onClick(opt)}
              key={opt}
            >
              {isIconFirst && icons && icons[opt] && (
                <img className="Tab-option-icon tab-option-icon-first" src={icons[opt]} alt={option} />
              )}
              {/* {isUpperCase ? label?.toUpperCase() : label} */}
              <span dangerouslySetInnerHTML={{ __html: label }}></span>
              <div className="icon-newtab">
                <IconNewLink />
              </div>

              {!isIconFirst && icons && icons[opt] && (
                <img className="Tab-option-icon tab-option-icon-last" src={icons[opt]} alt={option} />
              )}
            </a>
          );
        } else {
          return (
            <div
              className={cx("Tab-option", "", {
                active: opt === option,
                long: opt === LONG,
                short: opt === SHORT,
                longInactive: opt !== option && opt === LONG,
                shortInactive: opt !== option && opt === SHORT,
                tabFontWetght: isBoldFontWeight === true,
              })}
              onClick={() => onClick(opt)}
              key={opt}
            >
              {isIconFirst && icons && icons[opt] && (
                <img className="Tab-option-icon tab-option-icon-first" src={icons[opt]} alt={option} />
              )}
              {/* {isUpperCase ? label?.toUpperCase() : label} */}
              <span dangerouslySetInnerHTML={{ __html: label }}></span>
              {!isIconFirst && icons && icons[opt] && (
                <img className="Tab-option-icon tab-option-icon-last" src={icons[opt]} alt={option} />
              )}
            </div>
          );
        }
      })}
    </div>
  );
}
