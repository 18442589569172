import React from "react";

function Icon({ ...props }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
    >
      <path
        fill={props.fill ? props.fill : "#FFFFFF99"}
        fillRule="evenodd"
        d="M8 13.333A5.333 5.333 0 108 2.667a5.333 5.333 0 000 10.666zm0 1.334A6.667 6.667 0 108 1.333a6.667 6.667 0 000 13.334z"
        clipRule="evenodd"
      ></path>
      <path
        fill={props.fill ? props.fill : "#FFFFFF99"}
        d="M8.349 4.667c.7 0 1.26.166 1.68.498.425.332.638.801.638 1.406 0 .558-.203.994-.61 1.309-.399.308-.93.466-1.59.472l-.05.667H6.945l-.049-1.655h.59c.504 0 .887-.057 1.149-.17.268-.112.402-.317.402-.614 0-.207-.062-.37-.186-.49-.125-.118-.298-.177-.52-.177-.237 0-.42.062-.55.187-.132.118-.197.282-.197.49H6a1.723 1.723 0 01.246-.98c.183-.29.452-.52.805-.685.36-.172.793-.258 1.297-.258zM7.7 11.333c-.295 0-.537-.077-.727-.231a.751.751 0 01-.275-.588.76.76 0 01.275-.596c.19-.16.432-.24.727-.24.288 0 .523.08.707.24a.75.75 0 01.285.597.741.741 0 01-.285.587c-.184.154-.42.231-.707.231z"
      ></path>
    </svg>
  );
}

export default Icon;
