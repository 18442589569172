import cx from "classnames";
import { useAffiliateCodes } from "domain/referrals";
import { toJpeg } from "html-to-image";
import { default as copyIc } from "img/icons/copy-ic.svg";
import { default as copyIcLight } from "img/icons/copy-icLight.svg";
import naviLogo from "img/logo_NAVI.svg";

import tweetIc from "img/trade/tweet.svg";
import tweetIcLight from "img/icons/tweet-icLight.svg";

import icCheck from "img/icons/checkbox-ic.svg";
import icChecked from "img/icons/checkbox-ic1.svg";
import icCheckedLight from "img/icons/checkbox-ic1Light.svg";
import icCheckLight from "img/icons/checkbox-icLight.svg";
import downloadIc from "img/icons/download-ic.svg";
import downloadIcLight from "img/icons/download-icLight.svg";

import { useThemeContext } from "contexts/ThemeProvider";
import shareBgImg from "img/position-share-bg.png";
import { useChainId } from "lib/chains";
import downloadImage from "lib/downloadImage";
import { helperToast } from "lib/helperToast";
import { getHomeUrl, getRootShareApiUrl, getTwitterIntentURL, USD_DECIMALS } from "lib/legacy";
import { formatAmount, limitDecimals, parseValue } from "lib/numbers";
import useLoadImage from "lib/useLoadImage";
import { QRCodeSVG } from "qrcode.react";
import { useEffect, useRef, useState } from "react";
import { useCopyToClipboard, useMedia } from "react-use";
import SpinningLoader from "../Common/SpinningLoader";
import Modal from "../Modal/Modal";
import "./PositionShare.scss";
import { TOKEN_SHOWS_DECIMALS } from "config/tokens";
const ROOT_SHARE_URL = getRootShareApiUrl();
const UPLOAD_URL = ROOT_SHARE_URL + "/api/upload";
const UPLOAD_SHARE = ROOT_SHARE_URL + "/api/s";
const config = { quality: 0.95, canvasWidth: 375, canvasHeight: 375, type: "image/jpeg" };

function getShareURL(imageInfo, ref) {
  if (!imageInfo) return;
  let url = `${UPLOAD_SHARE}?id=${imageInfo.id}`;
  if (ref?.success && ref?.code) {
    url = url + `&ref=${ref.code}`;
  }
  return url;
}

function PositionShareClosedV1({
  setIsPositionShareModalOpen,
  isPositionShareModalOpen,
  positionToShare,
  account,
  chainId,
}) {
  const { isLightTheme } = useThemeContext();
  const userAffiliateCode = useAffiliateCodes(chainId, account);
  // console.log("????", userAffiliateCode);

  const [uploadedImageInfo, setUploadedImageInfo] = useState();
  const [uploadedImageError, setUploadedImageError] = useState();
  const [, copyToClipboard] = useCopyToClipboard();
  const sharePositionBgImg = useLoadImage(shareBgImg);
  const positionRef = useRef();
  const tweetLink = getTwitterIntentURL(
    `Latest $${positionToShare?.indexToken?.symbol} trade on @NaviExSonic`,
    getShareURL(uploadedImageInfo, userAffiliateCode)
  );
  const isMobile = useMedia("(max-width: 800px)");
  useEffect(() => {
    (async function () {
      const element = positionRef.current;
      if (element && userAffiliateCode.success && sharePositionBgImg && positionToShare) {
        // We have to call the toJpeg function multiple times to make sure the canvas renders all the elements like background image
        // @refer https://github.com/tsayen/dom-to-image/issues/343#issuecomment-652831863
        const image = await toJpeg(element, config)
          .then(() => toJpeg(element, config))
          .then(() => toJpeg(element, config));
        try {
          const imageInfo = await fetch(UPLOAD_URL, { method: "POST", body: image }).then((res) => res.json());
          setUploadedImageInfo(imageInfo);
        } catch {
          setUploadedImageInfo(null);
          setUploadedImageError(`Image generation error, please refresh and try again.`);
        }
      }
    })();
  }, [userAffiliateCode, sharePositionBgImg, positionToShare]);

  async function handleDownload() {
    const element = positionRef.current;
    if (!element) return;
    const imgBlob = await toJpeg(element, config)
      .then(() => toJpeg(element, config))
      .then(() => toJpeg(element, config));
    downloadImage(imgBlob, "share.jpeg");
  }

  function handleCopy() {
    if (!uploadedImageInfo) return;
    const url = getShareURL(uploadedImageInfo, userAffiliateCode);
    copyToClipboard(url);
    helperToast.success(`Link copied to clipboard.`);
  }

  const LIST = [
    {
      name: "Leverage",
      checked: true,
      value: "leverage",
    },
    {
      name: "ROI",
      checked: true,
      value: "pnl",
    },
    {
      name: "Price",
      checked: true,
      value: "price",
    },
  ];
  const [checkList, setCheckList] = useState(LIST);

  const handleCheck = (item) => {
    const newList = [...checkList];
    newList.map((checkbox) => {
      if (checkbox.value === item.value) {
        checkbox.checked = !item.checked;
      }
      return item;
    });

    setCheckList(newList);
  };

  return (
    <Modal
      className="position-share-modal"
      isVisible={isPositionShareModalOpen}
      setIsVisible={setIsPositionShareModalOpen}
      label={`Share Position`}
    >
      <PositionShareCard
        userAffiliateCode={userAffiliateCode}
        positionRef={positionRef}
        position={positionToShare}
        chainId={chainId}
        account={account}
        uploadedImageInfo={uploadedImageInfo}
        uploadedImageError={uploadedImageError}
        sharePositionBgImg={sharePositionBgImg}
        checkList={checkList}
      />
      {uploadedImageError && <span className="error">{uploadedImageError}</span>}
      <div className="checkbox-list">
        {checkList.map((item) => {
          return (
            <div className="checkbox-item">
              {item.checked ? (
                <img
                  className="img-check"
                  alt="img"
                  src={isLightTheme ? icCheckedLight : icChecked}
                  onClick={() => handleCheck(item)}
                />
              ) : (
                <img
                  className="img-check"
                  src={isLightTheme ? icCheckLight : icCheck}
                  alt="img"
                  onClick={() => handleCheck(item)}
                />
              )}
              {item.name}
            </div>
          );
        })}
      </div>

      <div className="actions">
        <button className={cx("App-button-option btn-share", { disabled: !uploadedImageInfo })}>
          <a
            target="_blank"
            className={cx("tweet-link", { disabled: !uploadedImageInfo })}
            rel="noreferrer"
            href={tweetLink}
          >
            <span>Share</span>
          </a>
        </button>
        <button disabled={!uploadedImageInfo} className="App-button-option" onClick={handleCopy}>
          <img src={isLightTheme ? copyIcLight : copyIc} alt="img" className="icon" />
        </button>
        {!isMobile && (
          <button disabled={!uploadedImageInfo} className="App-button-option" onClick={handleDownload}>
            <img src={isLightTheme ? downloadIcLight : downloadIc} alt="img" className="icon" />
          </button>
        )}
      </div>
    </Modal>
  );
}

function PositionShareCard({
  positionRef,
  position,
  userAffiliateCode,
  uploadedImageInfo,
  uploadedImageError,
  sharePositionBgImg,
  checkList,
}) {
  const { chainId } = useChainId();
  const isMobile = useMedia("(max-width: 400px)");
  const { code, success } = userAffiliateCode;

  const { isLong, indexToken, averagePrice, exitPrice, realisedPnl, roi } = position;
  const leverage = parseValue(position.size, 30)
    .mul(parseValue(1, USD_DECIMALS))
    .div(parseValue(position.collateral, 30));
  const homeURL = getHomeUrl();
  return (
    <div className="relative">
      <div className="share-card-container">
        <div ref={positionRef} className="position-share" style={{ backgroundImage: `url(${sharePositionBgImg})` }}>
          <div className="info info-share-container">
            <div className="title">{indexToken?.symbol}/USD</div>
            <div className={`side ${isLong ? "side-long" : "side-short"}`}>{isLong ? "LONG" : "SHORT"}</div>
            {/* {checkList[0].checked && <li>{formatAmount(leverage, 4, 2, true)}x&nbsp;</li>} */}
          </div>
          {checkList[1].checked && (
            <h3 className={`pnl ${realisedPnl.includes("-") ? "negative" : "positive"}`}>
              {roi > 0 ? "+" : ""}
              {limitDecimals(roi, 2)}%
              {/* {checkList[1].checked && <div className="change-dollar">({deltaStr})</div>} */}
            </h3>
          )}

          <div className="prices">
            {checkList[2].checked && (
              <div>
                <p className="muted">Entry price:</p>
                <p className="price">
                  $
                  {formatAmount(
                    parseValue(averagePrice, USD_DECIMALS),
                    USD_DECIMALS,
                    TOKEN_SHOWS_DECIMALS[`${indexToken?.symbol || "ETH"}/USD`],
                    true
                  )}
                </p>
              </div>
            )}
            {checkList[2].checked && (
              <div>
                <p className="muted">Mark price:</p>
                <p className="price">
                  $
                  {formatAmount(
                    parseValue(exitPrice, USD_DECIMALS),
                    USD_DECIMALS,
                    TOKEN_SHOWS_DECIMALS[`${indexToken?.symbol || "ETH"}/USD`],
                    true
                  )}
                </p>
              </div>
            )}

            {checkList[0].checked && (
              <div>
                <p className="muted">Leverage:</p>
                <p className="price">{formatAmount(leverage, 30, 2, true)}x</p>
              </div>
            )}
          </div>
        </div>
        <div className="referral-code-container">
          <img className="logo" src={naviLogo} alt="Navi Logo" />
          <div className="referral-code">
            <div className="referral-code-info">
              {success && code ? (
                <>
                  <p className="label">Referral code</p>
                  <p className="code">{code}</p>
                </>
              ) : (
                <>
                  <p className="label">Trade now on</p>
                  <p className="code">navigator.exchange</p>
                </>
              )}
            </div>
            <div>
              <QRCodeSVG
                size={isMobile ? 36 : 56}
                value={success && code ? `${homeURL}/#/?ref=${code}` : `${homeURL}`}
              />
            </div>
          </div>
        </div>
      </div>

      {!uploadedImageInfo && !uploadedImageError && (
        <div className="image-overlay-wrapper">
          <div className="image-overlay">
            <SpinningLoader />
            <p className="loading-text">
              <span>Generating shareable image...</span>
            </p>
          </div>
        </div>
      )}
    </div>
  );
}

export default PositionShareClosedV1;
