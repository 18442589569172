import React, { useRef, useEffect } from "react";
import cx from "classnames";
import { motion, AnimatePresence } from "framer-motion";
import styled from "styled-components";

import { MdClose } from "react-icons/md";

import "./Modal.css";
import useLockBodyScroll, { TOUCH_MOVE_CONTAINER_CLASS_NAME } from "lib/useLockBodyScroll";
import { useThemeContext } from "contexts/ThemeProvider";

export default function Modal(props) {
  const { lightThemeClassName } = useThemeContext();
  const {
    isVisible,
    setIsVisible,
    className,
    zIndex,
    onAfterOpen,
    disableBodyScrollLock,
    allowContentTouchMove,
    isBottomMobal,
  } = props;

  const modalRef = useRef(null);

  useLockBodyScroll(modalRef, isVisible, {
    disableLock: disableBodyScrollLock,
    allowTouchMove: allowContentTouchMove,
  });

  useEffect(() => {
    function close(e) {
      if (e.keyCode === 27 && setIsVisible) {
        setIsVisible(false);
      }
    }
    window.addEventListener("keydown", close);
    return () => window.removeEventListener("keydown", close);
  }, [setIsVisible]);

  useEffect(() => {
    if (typeof onAfterOpen === "function") onAfterOpen();
  }, [onAfterOpen]);

  const fadeVariants = {
    hidden: { opacity: 0 },
    visible: { opacity: 1 },
  };

  return (
    <AnimatePresence>
      {isVisible && (
        <motion.div
          className={cx("Modal", className, lightThemeClassName)}
          style={{ zIndex }}
          initial="hidden"
          animate="visible"
          exit="hidden"
          variants={fadeVariants}
          transition={{ duration: 0.2 }}
        >
          <div
            className="Modal-backdrop"
            style={{
              overflow: isVisible ? "hidden" : "visible",
              position: "fixed",
            }}
            onClick={() => setIsVisible && setIsVisible(false)}
          ></div>
          <div className={`Modal-content ${isBottomMobal ? "Modal-bottom" : ""} modal-content-v1`}>
            <div className="Modal-title-bar">
              <div className="Modal-title">{props.label}</div>
              {setIsVisible && (
                <div className="Modal-close-button" onClick={() => setIsVisible && setIsVisible(false)}>
                  <svg
                    className="Modal-close-icon"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clip-path="url(#clip0_19499_16612)">
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M18.364 5.63593C17.9734 5.24541 17.3403 5.24541 16.9497 5.63593L12 10.5857L7.05025 5.63593C6.65973 5.24541 6.02656 5.24541 5.63604 5.63593C5.24552 6.02646 5.24552 6.65962 5.63604 7.05015L10.5858 11.9999L5.63604 16.9496C5.24552 17.3402 5.24551 17.9733 5.63604 18.3639C6.02656 18.7544 6.65973 18.7544 7.05025 18.3639L12 13.4141L16.9497 18.3639C17.3403 18.7544 17.9734 18.7544 18.364 18.3639C18.7545 17.9733 18.7545 17.3402 18.364 16.9496L13.4142 11.9999L18.364 7.05015C18.7545 6.65962 18.7545 6.02646 18.364 5.63593Z"
                        fill="white"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_19499_16612">
                        <rect width="24" height="24" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                  {/* <MdClose fontSize={24}  /> */}
                </div>
              )}
            </div>
            <div className="divider-line-top" />
            <div>
              <div className={cx("Modal-body", TOUCH_MOVE_CONTAINER_CLASS_NAME)} ref={modalRef}>
                {props.children}
              </div>
            </div>
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
}

export const StyledAnimatePresence = styled(AnimatePresence)`
  .divider {
    margin-bottom: 0;
  }

  .Modal-body {
    overflow-y: auto;
    max-height: 80vh;
    -webkit-overflow-scrolling: touch;
  }
`;
