import {
  ARBITRUM,
  ARBITRUM_TESTNET,
  AVALANCHE,
  MAINNET,
  TESTNET,
  FANTOM,
  MUMBAI,
  OP,
  BASE,
  FANTOM_TESTNET,
  SONIC_TESTNET,
} from "./chains";

export const XNSLPT_EXCLUDED_ACCOUNTS = [
  "0x330eef6b9b1ea6edd620c825c9919dc8b611d5d5",
  "0xd9b1c23411adbb984b1c4be515fafc47a12898b2",
  "0xa633158288520807f91ccc98aa58e0ea43acb400",
  "0xffd0a93b4362052a336a7b22494f1b77018dd34b",
];

const CONTRACTS = {
  [MAINNET]: {
    // bsc mainnet
    Treasury: "0xa44E7252a0C137748F523F112644042E5987FfC7",
    BUSD: "0xe9e7cea3dedca5984780bafc599bd69add087d56",
    NSLPT: "0x99e92123eB77Bc8f999316f622e5222498438784",
    Vault: "0xc73A8DcAc88498FD4b4B1b2AaA37b0a2614Ff67B",
    Router: "0xD46B23D042E976F8666F554E928e0Dc7478a8E1f",
    Reader: "0x087A618fD25c92B61254DBe37b09E5E8065FeaE7",
    AmmFactory: "0xBCfCcbde45cE874adCB698cC183deBcF17952812",
    AmmFactoryV2: "0xcA143Ce32Fe78f1f7019d7d551a6402fC5350c73",
    OrderBook: "0x1111111111111111111111111111111111111111",
    OrderBookReader: "0x1111111111111111111111111111111111111111",
    NaviMigrator: "0xDEF2af818514c1Ca1A9bBe2a4D45E28f260063f9",
    USDN: "0x85E76cbf4893c1fbcB34dCF1239A91CE2A4CF5a7",
    NATIVE_TOKEN: "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
    XNSLPT: "0xe304ff0983922787Fd84BC9170CD21bF78B16B10",
    NSLPT_USDN_PAIR: "0xa41e57459f09a126F358E118b693789d088eA8A0",
    XNSLPT_USDN_PAIR: "0x0b622208fc0691C2486A3AE6B7C875b4A174b317",
    NSLPT_USDN_FARM: "0x3E8B08876c791dC880ADC8f965A02e53Bb9C0422",
    XNSLPT_USDN_FARM: "0x68D7ee2A16AB7c0Ee1D670BECd144166d2Ae0759",
    USDN_YIELD_TRACKER: "0x0EF0Cf825B8e9F89A43FfD392664131cFB4cfA89",
    XNSLPT_YIELD_TRACKER: "0x82A012A9b3003b18B6bCd6052cbbef7Fa4892e80",
    NSLPT_USDN_FARM_TRACKER_XNSLPT: "0x08FAb024BEfcb6068847726b2eccEAd18b6c23Cd",
    NSLPT_USDN_FARM_TRACKER_NATIVE: "0xd8E26637B34B2487Cad1f91808878a391134C5c2",
    XNSLPT_USDN_FARM_TRACKER_XNSLPT: "0x026A02F7F26C1AFccb9Cba7C4df3Dc810F4e92e8",
    XNSLPT_USDN_FARM_TRACKER_NATIVE: "0x22458CEbD14a9679b2880147d08CA1ce5aa40E84",
    AUTO: "0xa184088a740c695E156F91f5cC086a06bb78b827",
    AUTO_USDN_PAIR: "0x0523FD5C53ea5419B4DAF656BC1b157dDFE3ce50",
    AUTO_USDN_FARM: "0xE6958298328D02051769282628a3b4178D0F3A47",
    AUTO_USDN_FARM_TRACKER_XNSLPT: "0x093b8be41c1A30704De84a9521632f9a139c08bd",
    AUTO_USDN_FARM_TRACKER_NATIVE: "0x23ed48E5dce3acC7704d0ce275B7b9a0e346b63A",
    NSLPT_NAVI_IOU: "0x47052469970C2484729875CC9E2dd2683fcE71fb",
    XNSLPT_NAVI_IOU: "0xeB3733DFe3b68C9d26898De2493A3Bb59FDb4A7B",
    NSLPT_USDN_NAVI_IOU: "0x481312655F81b5e249780A6a49735335BF6Ca7f4",
    XNSLPT_USDN_NAVI_IOU: "0x8095F1A92526C304623483018aA28cC6E62EB1e1",
    Faucet: "0x81f3112bD4737d2bB0E4eC55c0dfeB8284573B6a",
  },
  [TESTNET]: {
    // bsc testnet
    Vault: "0x1B183979a5cd95FAF392c8002dbF0D5A1C687D9a",
    Router: "0x10800f683aa564534497a5b67F45bE3556a955AB",
    Reader: "0x98D4742F1B6a821bae672Cd8721283b91996E454",
    AmmFactory: "0x6725f303b657a9451d8ba641348b6761a6cc7a17",
    AmmFactoryV2: "0x1111111111111111111111111111111111111111",
    OrderBook: "0x9afD7B4f0b58d65F6b2978D3581383a06b2ac4e9",
    OrderBookReader: "0x0713562970D1A802Fa3FeB1D15F9809943982Ea9",
    NaviMigrator: "0xDEF2af818514c1Ca1A9bBe2a4D45E28f260063f9",
    USDN: "0x2D549bdBf810523fe9cd660cC35fE05f0FcAa028",
    NSLPT: "0xedba0360a44f885ed390fad01aa34d00d2532817",
    NATIVE_TOKEN: "0x612777Eea37a44F7a95E3B101C39e1E2695fa6C2",
    XNSLPT: "0x28cba798eca1a3128ffd1b734afb93870f22e613",
    NSLPT_USDN_PAIR: "0xe0b0a315746f51932de033ab27223d85114c6b85",
    XNSLPT_USDN_PAIR: "0x0108de1eea192ce8448080c3d90a1560cf643fa0",
    NSLPT_USDN_FARM: "0xbe3cB06CE03cA692b77902040479572Ba8D01b0B",
    XNSLPT_USDN_FARM: "0x138E92195D4B99CE3618092D3F9FA830d9A69B4b",
    USDN_YIELD_TRACKER: "0x62B49Bc3bF252a5DB26D88ccc7E61119e3179B4f",
    XNSLPT_YIELD_TRACKER: "0x5F235A582e0993eE9466FeEb8F7B4682993a57d0",
    NSLPT_USDN_FARM_TRACKER_XNSLPT: "0x4f8EE3aE1152422cbCaFACd4e3041ba2D859913C",
    NSLPT_USDN_FARM_TRACKER_NATIVE: "0xd691B26E544Fe370f39A776964c991363aF72e56",
    XNSLPT_USDN_FARM_TRACKER_XNSLPT: "0xfd5617CFB082Ba9bcD62d654603972AE312bC695",
    XNSLPT_USDN_FARM_TRACKER_NATIVE: "0x0354387DD85b7D8aaD1611B3D167A384d6AE0c28",
    NSLPT_NAVI_IOU: "0x47052469970C2484729875CC9E2dd2683fcE71fb",
    XNSLPT_NAVI_IOU: "0xeB3733DFe3b68C9d26898De2493A3Bb59FDb4A7B",
    NSLPT_USDN_NAVI_IOU: "0x481312655F81b5e249780A6a49735335BF6Ca7f4",
    XNSLPT_USDN_NAVI_IOU: "0x8095F1A92526C304623483018aA28cC6E62EB1e1",
    PositionRouter: "0x0000000000000000000000000000000000000000",
    PositionManager: "0x0000000000000000000000000000000000000000",

    // UniswapNaviEthPool: "0x80A9ae39310abf666A87C743d6ebBD0E8C42158E",
    ReferralStorage: "0x0000000000000000000000000000000000000000",
    ReferralReader: "0x0000000000000000000000000000000000000000",
    VaultReader: "0x0000000000000000000000000000000000000000",
    Faucet: "0x81f3112bD4737d2bB0E4eC55c0dfeB8284573B6a",
  },
  [ARBITRUM_TESTNET]: {
    // arbitrum testnet
    Vault: "0xBc9BC47A7aB63db1E0030dC7B60DDcDe29CF4Ffb",
    Router: "0xe0d4662cdfa2d71477A7DF367d5541421FAC2547",
    VaultReader: "0xFc371E380262536c819D12B9569106bf032cC41c",
    Reader: "0x2E093c70E3A7E4919611d2555dFd8D697d2fC0a1",
    NlpManager: "0xD875d99E09118d2Be80579b9d23E83469077b498",
    RewardRouter: "0x0000000000000000000000000000000000000000",
    RewardReader: "0x0000000000000000000000000000000000000000",
    NATIVE_TOKEN: "0xB47e6A5f8b33b3F17603C83a0535A9dcD7E32681",
    NLP: "0xb4f81Fa74e06b5f762A104e47276BA9b2929cb27",
    NAVI: "0x0000000000000000000000000000000000000000",
    ES_NAVI: "0x0000000000000000000000000000000000000000",
    BN_NAVI: "0x0000000000000000000000000000000000000000",
    USDN: "0xBCDCaF67193Bf5C57be08623278fCB69f4cA9e68",
    ES_NAVI_IOU: "0x0000000000000000000000000000000000000000",
    StakedNaviTracker: "0x0000000000000000000000000000000000000000",
    BonusNaviTracker: "0x0000000000000000000000000000000000000000",
    FeeNaviTracker: "0x0000000000000000000000000000000000000000",
    StakedNlpTracker: "0x0000000000000000000000000000000000000000",
    FeeNlpTracker: "0x0000000000000000000000000000000000000000",

    StakedNaviDistributor: "0x0000000000000000000000000000000000000000",
    StakedNlpDistributor: "0x0000000000000000000000000000000000000000",

    NaviVester: "0x0000000000000000000000000000000000000000",
    NlpVester: "0x0000000000000000000000000000000000000000",

    OrderBook: "0xebD147E5136879520dDaDf1cA8FBa48050EFf016",
    OrderBookReader: "0xC492c8d82DC576Ad870707bb40EDb63E2026111E",

    PositionRouter: "0xB4bB78cd12B097603e2b55D2556c09C17a5815F8",
    PositionManager: "0x168aDa266c2f10C1F37973B213d6178551030e44",

    // UniswapNaviEthPool: "0x80A9ae39310abf666A87C743d6ebBD0E8C42158E",
    ReferralStorage: "0x0000000000000000000000000000000000000000",
    ReferralReader: "0x0000000000000000000000000000000000000000",
    Faucet: "0x81f3112bD4737d2bB0E4eC55c0dfeB8284573B6a",
    MicroSwapRouter: "0x0000000000000000000000000000000000000000",
  },
  [ARBITRUM]: {
    // arbitrum mainnet
    VaultNAVI: "0xe0Ab0b3d621A38137514D2359D9Ca6c3c4610Ca5",
    VaultNLP: "0x3f83F08c51522F142dD8056c1468bFAcec619e3e",
    VaultNAVI_WFTM: "???",
    VaultNAVI_WETH: "???",
    Vault: "0x304951d7172bCAdA54ccAC1E4674862b3d5b3d5b",
    Router: "0x01e9B35785eF3f7Ef2677c371442976bd550f320",
    VaultReader: "0x7B26207457A9F8fF4fd21A7A0434066935f1D8E7",
    Reader: "0x04f23404553fcc388Ec73110A0206Dd2E76a6d95",
    NlpManager: "0x65DCb38637b526305Be55F14B24a4Ab2bD177780",
    RewardRouter: "0x766257B7cE3a5E3Ad5876200Ab1671D31289BC75",
    RewardReader: "0xe149164D8eca659E8912DbDEC35E3f7E71Fb5789",
    NATIVE_TOKEN: "0x82aF49447D8a07e3bd95BD0d56f35241523fBab1",
    NLP: "0x41cD8CaFc24A771031B9eB9C57cFC94D86045eB6",
    NAVI: "0xA6D7D0e650aa40FFa42d845A354c12c2bc0aB15f",
    ES_NAVI: "0x68d1CA32Aee9a73534429D8376743Bf222ff1870",
    BN_NAVI: "0x2D270f66feE6Ac9e27Ff6551AF5a8cFB5C8a7493",
    USDN: "0x9032aeD8C1F2139E04C1AD6D9F75bdF1D6e5CF5c",
    ES_NAVI_IOU: "0x0000000000000000000000000000000000000000",
    StakedNaviTracker: "0x52cC60893d3Bd8508baAB835620CbF9ddfA0A13C",
    BonusNaviTracker: "0xCEBC8A8b66De9B3f4393966EaB86Fc9DE26B2529",
    FeeNaviTracker: "0xA30b1C3c8EDE3841dE05F9CdD4D0e097aC4C6D92",
    StakedNlpTracker: "0xad96D9736484e35985773bdf7dBa5750dC830042",
    FeeNlpTracker: "0xa0207802B0953b17421bf9Cb10B3ACf5823496ab",
    TraderJoeNaviAvaxPool: "0x0c91a070f862666bbcce281346be45766d874d98",
    StakedNaviDistributor: "0xa1a65D3639A1EFbFB18C82003330a4b1FB620C5a",
    StakedNlpDistributor: "0xfDC9B5be032216315bBe8c06b1c4F563D1689b85",

    NaviVester: "0xb471865C97a271E1572EcCA838b971E710498e4c",
    NlpVester: "0x3C05Ad1Ba92906dB8b9F3dB03c4C4D5119cE4110",
    NaviVesterV1: "0x0000000000000000000000000000000000000000",
    NlpVesterV1: "0x0000000000000000000000000000000000000000",

    OrderBook: "0x08Dfcfb0bD6e7eAF828f60021C77d273DE56b1C5",
    OrderExecutor: "0x0000000000000000000000000000000000000000",
    OrderBookReader: "0x727dB8FA7861340d49d13ea78321D0C9a1a79cd5",

    PositionRouter: "0x3B2732c1e5A248BBDD0315E9e8845c64f5A6FaF5",
    PositionManager: "0x198634D01A8E1646faE8676904343c33b1d2C6b9",

    UniswapNaviEthPool: "0x694d94AcafA4494Ab532c5ac7b89aB243d91dd52",
    ReferralStorage: "0xC2C5968e16ec9fABc39e27f9AbFC07C8Cfba6F16",
    ReferralReader: "0xFfB69477FeE0DAEB64E7dE89B57846aFa990e99C",
    NFTClub: "0x95f1bb125c5c531384f46a7c20d5c88013a4c40b",
    NavigatorClubSale: "0x0000000000000000000000000000000000000000",
    NavigatorClubStaking: "0xe092037623667d0d9d3e0a07ea9c607bdfa56155",
    NavigatorClubStakingV2: "0x0000000000000000000000000000000000000000",
    Multicall: "0xcA11bde05977b3631167028862bE2a173976CA11",
    Bridge: "0xA6D7D0e650aa40FFa42d845A354c12c2bc0aB15f",
    NAVIMigration: "0x0000000000000000000000000000000000000000",
    MigrateVester: "0x0000000000000000000000000000000000000000",
    BridgeNFT: "0x8BB0f8C2815924F195c1a86D6fe21fafac6F8c45",
    NFTSaleV2: "0x4f1B6191fD7EE88375E28d87F738bb5772f0C877",
    NFTClubV2: "0xec866016ce430654d823f682491462a2be39014c",
    Faucet: "0x81f3112bD4737d2bB0E4eC55c0dfeB8284573B6a",

    VaultNslp: "0x0000000000000000000000000000000000000000",
    OrderVaultNslp: "0x0000000000000000000000000000000000000000",
    ReaderNslp: "0x0000000000000000000000000000000000000000",
    NSUSD: "0x0000000000000000000000000000000000000000",
    "USDC.e": "0x0000000000000000000000000000000000000000",
    PriceManager: "0x0000000000000000000000000000000000000000",
    SettingsManager: "0x0000000000000000000000000000000000000000",
    FireBirdRouter: "0x6131B5fae19EA4f9D964eAc0408E4408b66337b5",
    MicroSwapRouter: "0x0000000000000000000000000000000000000000",
    NSLP: "0x0000000000000000000000000000000000000000",
    NAVIFarm: "0x0000000000000000000000000000000000000000",
    vNAVI: "0x0000000000000000000000000000000000000000",
    PoolRewardersVNAVI: "0x0000000000000000000000000000000000000000",
    StakeHelper: "0x0000000000000000000000000000000000000000",
  },
  [AVALANCHE]: {
    // avalanche
    Vault: "0x9ab2De34A33fB459b538c43f251eB825645e8595",
    Router: "0x5F719c2F1095F7B9fc68a68e35B51194f4b6abe8",
    VaultReader: "0x66eC8fc33A26feAEAe156afA3Cb46923651F6f0D",
    Reader: "0x2eFEE1950ededC65De687b40Fd30a7B5f4544aBd",
    NlpManager: "0xe1ae4d4b06A5Fe1fc288f6B4CD72f9F8323B107F",
    RewardRouter: "0x82147C5A7E850eA4E28155DF107F2590fD4ba327",
    RewardReader: "0x04Fc11Bd28763872d143637a7c768bD96E44c1b6",
    NATIVE_TOKEN: "0xB31f66AA3C1e785363F0875A1B74E27b85FD66c7",
    NLP: "0x01234181085565ed162a948b6a5e88758CD7c7b8",
    NAVI: "0x62edc0692BD897D2295872a9FFCac5425011c661",
    ES_NAVI: "0xFf1489227BbAAC61a9209A08929E4c2a526DdD17",
    BN_NAVI: "0x8087a341D32D445d9aC8aCc9c14F5781E04A26d2",
    USDN: "0xc0253c3cC6aa5Ab407b5795a04c28fB063273894",
    ES_NAVI_IOU: "0x6260101218eC4cCfFF1b778936C6f2400f95A954", // placeholder address

    StakedNaviTracker: "0x2bD10f8E93B3669b6d42E74eEedC65dd1B0a1342",
    BonusNaviTracker: "0x908C4D94D34924765f1eDc22A1DD098397c59dD4",
    FeeNaviTracker: "0x4d268a7d4C16ceB5a606c173Bd974984343fea13",
    StakedNlpTracker: "0x9e295B5B976a184B14aD8cd72413aD846C299660",
    FeeNlpTracker: "0xd2D1162512F927a7e282Ef43a362659E4F2a728F",

    StakedNaviDistributor: "0xfc5A1A6EB076a2C7aD06eD22C90d7E710E35ad0a",
    StakedNlpDistributor: "0xDd593Cf40734199afc9207eBe9ffF23dA4Bf7720",

    NaviVester: "0x472361d3cA5F49c8E633FB50385BfaD1e018b445",
    NlpVester: "0x62331A7Bd1dfB3A7642B7db50B5509E57CA3154A",

    OrderBook: "0x4296e307f108B2f583FF2F7B7270ee7831574Ae5",
    OrderExecutor: "0x4296e307f108B2f583FF2F7B7270ee7831574Ae5",
    OrderBookReader: "0xccFE3E576f8145403d3ce8f3c2f6519Dae40683B",

    PositionRouter: "0xffF6D276Bc37c61A23f06410Dce4A400f66420f8",
    PositionManager: "0xA21B83E579f4315951bA658654c371520BDcB866",

    TraderJoeNaviAvaxPool: "0x0c91a070f862666bbcce281346be45766d874d98",
    ReferralStorage: "0x827ed045002ecdabeb6e2b0d1604cf5fc3d322f8",
    ReferralReader: "0x505Ce16D3017be7D76a7C2631C0590E71A975083",
    Faucet: "0x81f3112bD4737d2bB0E4eC55c0dfeB8284573B6a",
    MicroSwapRouter: "0x0000000000000000000000000000000000000000",
  },
  [FANTOM]: {
    Vault: "0x7e9E9d925846B110B025041cfdF9942f2C401F4F",
    VaultNAVI: "0x6b357844C40EA317bf9a214a93FFf72C658DDd03",
    VaultNLP: "0x29C7b5dC2F24DD26D3962cbCD75A7122bd1706D8",
    VaultNAVI_WFTM_OLD: "0x0E3fE9D47c8db3ae68092c942D759BdCDde7eb3A",
    VaultNAVI_WFTM: "0x6448600794C29c0B78710c2b12FF3728C9c91c71",
    VaultNAVI_WETH: "???",
    Router: "0x6b4E5cbCED6E907Ab8525d83Dd3Cd9Ec6BDb62C4",
    VaultReader: "0x67B8a4A528A0a697491988B12EFbE865f9f20E18",
    Reader: "0xb5546535a3181d24F1e0cea032c696a92407bd4F",
    NlpManager: "0xca057751eDa4FB5BD23766C165555AD12A042202",
    RewardRouter: "0x0ccB273CA9842855636A6aC15bea105Ca05b20c4",
    RewardReader: "0x53418e5D25d1E4Ff176C28866A12b2fC546c357A",
    NATIVE_TOKEN: "0x21be370D5312f44cB42ce377BC9b8a0cEF1A4C83", //
    NLP: "0xAd41EC9dbCd9B4bD59d5EEF3E2be2cB8AAfc5e7e",
    NAVI: "0x01e77288b38b416F972428d562454fb329350bAc",
    ES_NAVI: "0xe41c6c006De9147FC4c84b20cDFBFC679667343F",
    BN_NAVI: "0xc7CcA5b6EDC3e2ca3Bae2CCdf6bc4eCA943B7598",
    USDN: "0xd60aeF040e04a1F6b5f33078F18DD580B9A3f3C7",
    ES_NAVI_IOU: "0x0000000000000000000000000000000000000000", //
    StakedNaviTracker: "0x2301d1AF7f00FfF41F8ecb250eee399972d23530",
    BonusNaviTracker: "0x2a6da5A53aFD235Ffb0A3569f0AeA841E6Fdc8F2",
    FeeNaviTracker: "0xC52Be971c10f2Ef8d34ED7C5E997dda1705cc292",
    StakedNlpTracker: "0xC35208A29EF948395db0F7e58A9C3293a6f42EB5",
    FeeNlpTracker: "0x11fe19BaBaA706D7B45f54e9F0231b7BA1DB9E4A",

    StakedNaviDistributor: "0x42E0fBa31503DD5c223006eD46bb1Cf25Bf1cd3a",
    StakedNlpDistributor: "0xe91e9279d89f508E8070801df13754304b86EbCE",

    NaviVester: "0x8eF5634F5C6Ef625302Ab6e4C424Dbe6B59496C0",
    NlpVester: "0xDdeaC5Dc3A5352cCa9dD13C3067Cbbdbe2A43700",
    NaviVesterV1: "0xa1a65D3639A1EFbFB18C82003330a4b1FB620C5a",
    NlpVesterV1: "0x2A3E489F713ab6F652aF930555b5bb3422711ac1",

    OrderBook: "0xf564737Ba16695c4a2733Ba9EdDf215D60d9a557",
    OrderExecutor: "0x0000000000000000000000000000000000000000", //
    OrderBookReader: "0xDf933efE492E54D0e7b99b6FD80A37906Ad0E98e",

    PositionRouter: "0x1cA552b930723f5d9CEDD56DBa53F3545215c7a3",
    PositionManager: "0xf342404561D8Ee0364F519aBCd7F18353D20ff5c",

    TraderJoeNaviAvaxPool: "0xdc935ffe9f9c972b7b304e0b7e61eb774037e394",
    UniswapNaviEthPool: "0x2A6538a456650Cd454Dcd8f0B4665183DBa0bb27", //
    ReferralStorage: "0xd1FeA2883728330079bB6A6F1c6D332fCf764bAa",
    ReferralReader: "0x9e82f29a0f30584D9A92e93371234B807047c273",
    NFTClub: "0x573235B8e2708C39DD2Fa9Ec2d880121f4F83cA9",
    NavigatorClubSale: "0x6983249cFBE49eBD2c7fD37d392d69F1aEbE2d11",
    Multicall: "0xb828C456600857abd4ed6C32FAcc607bD0464F4F",
    NavigatorClubStaking: "0xD8e6AF2bE7A928F075a02a32eb65F2B7DfB696ad",
    NavigatorClubStakingV2: "0x7B25fA4AC6871CcdC2f29451ee0fa6eA01399968",
    LiquidityLocker: "0xcd6Fc0f32Eee391Ec4685c6c2E5c20A19960A804",
    Bridge: "0x4EE33bF422690B38552ec2A39dd942043C944ae8",
    NAVIMigration: "0x0000000000000000000000000000000000000000",
    MigrateVester: "0x789eB395b830842c2E091c0B200C69D8c5Bb3D5a",
    BridgeNFT: "0xA6D7D0e650aa40FFa42d845A354c12c2bc0aB15f",
    NFTClubV2: "0x4964795d3F320C4BbfdAA0686a7629Cf92A4B266",
    NFTSaleV2: "0xf93a3F5488836b69735895c1C7843D5AF20CD8DE",
    Faucet: "0x81f3112bD4737d2bB0E4eC55c0dfeB8284573B6a",

    VaultNslp: "0x6b181AAcbe3aBEa94e6C4261ca07B70eDE531a4B",
    OrderVaultNslp: "0x4B9C8Ccab3D807d86Eaf0a06829a0E6454fb9B5b",
    ReaderNslp: "0xC7c06ee5ee166Ee04D24EB09A7bB0b8DA0eD796C",
    NSUSD: "0x7Ed09cC525622A7eBbDd15A94139d09b884979f5",
    "USDC.e": "0x28a92dde19D9989F39A49905d7C9C2FAc7799bDf",
    PriceManager: "0x6adB51FD771D52d7A2fbB69C232fb8380c1C4c2B",
    SettingsManager: "0x0BDf2C6b7Aeec5eE0Cfbb80055630e5dfc6C314A",
    NSLP: "0x25162a2D9B5D359e8A98394947195080997a6EC4",
    NAVIFarm: "0x31F42F25C5E73F746f5197011c0430dC72C965Ff",
    vNAVI: "0xc25C7538caBC16A2916b304f086338FC4DCDc0C6",
    PoolRewardersVNAVI: "0x751408Fc1e3bB663B51AC5976c459d52f33a41cA",
    FireBirdRouter: "0x6131B5fae19EA4f9D964eAc0408E4408b66337b5",
    MicroSwapRouter: "0xc9147959baB2cEEAC49692E03f8b132FDd62196c",
    StakeHelper: "0x7C841C5E304aEd36339f54B54edC04f89Ef4ab0f",
  },
  [SONIC_TESTNET]: {
    Vault: "0xe9263682E837eFabb145f8C632B9d2c518D90652",
    VaultNAVI: "0xf64F9d588401702030e920dF059042dC440b3faD",
    VaultNLP: "0xcc1B084E5076cE095C835E31316cA1F75eE49C57",
    VaultNAVI_WFTM: "0x0000000000000000000000000000000000000000",
    VaultNAVI_WETH: "0x0000000000000000000000000000000000000000",
    Router: "0xC62E220F3676a22Cab87D2D18c4338079A96C1ac",
    VaultReader: "0x2a73cebd3B07c2cC4B1949478dC670b581EdfAeA",
    Reader: "0x026440626B4eAF9A2f4B1F57A971E8F86a542C25",
    NlpManager: "0x1aff48aB87315cb805abeA9Fb0B2B2F9280F1992",
    RewardRouter: "0x808e3dc8a8D8E70d102619603006fF93e5EeDF4b",
    RewardReader: "0x6c45a24C90E5735F58E7Bc6d3142ab85E4962e26",
    NATIVE_TOKEN: "0x039e2fB66102314Ce7b64Ce5Ce3E5183bc94aD38", //
    NLP: "0x489e0A78fCb4841729C00638eD9cE306973A88FE",
    NAVI: "0x6881B80ea7C858E4aEEf63893e18a8A36f3682f3",
    ES_NAVI: "0x63c10405d939E03032436f3fea80d8fcc5E4c68f",
    BN_NAVI: "0x10B4147ea7E6AF618F22A894161194209D76c3e9",
    USDN: "0xffFa8Ef2e8B076FB588e6DB7C10bEb34184f7495",
    ES_NAVI_IOU: "0x0000000000000000000000000000000000000000", //
    StakedNaviTracker: "0xEF8770E9506a8D1aAE3D599327a39Cf14B6B3dc4",
    BonusNaviTracker: "0x155F37470a79bA62aBAe2Ac1ec62D77218eb938a",
    FeeNaviTracker: "0xc5BE9eD36Cbb282a923Aa160fa62b9372f035A5b",
    StakedNlpTracker: "0xDF456C5137c562F7ba7aCe9364198e80D6553aC3",
    FeeNlpTracker: "0x40933500d88760B8B5e6Ee5910c2dDA7ee8041D0",

    StakedNslpTracker: "0x1606679B0A15cD0FB8aE91Ea4aD2A69D3f101950",
    FeeNslpTracker: "0xCf79FA2403418eE9ae4b7980e64B7F97517D06b4",

    StakedNaviDistributor: "0xa483f261EeBedcD1777c9591503934B3519AFF78",
    StakedNlpDistributor: "0x1c347c3476Eebf2C8A12Ed0De86d2Efb1F07c8f8",
    StakedNslpDistributor: "0x07B75aD8f11053684C26377f8DCb00d2bF5E1b46",

    NaviVester: "0xCd2d21b4449346984bf7a3Fd7b2D2d2FE3D78E37",
    NlpVester: "0x2a9fe0f79B42F73C03B3146C8c4c6F7f04e7DDaE",
    NslpVester: "0x995E5E3eFeD5042A391e9CcE41725044044EE86B",

    NaviVesterV1: "0x0000000000000000000000000000000000000000",
    NlpVesterV1: "0x0000000000000000000000000000000000000000",

    OrderBook: "0xf6F9C8e2aF4Abe11F012a81B1f4f3b077824653a",
    OrderExecutor: "0x0000000000000000000000000000000000000000", //
    OrderBookReader: "0x932394589E9c8484e005Ce5E104d4F6eA7D3d467",

    PositionRouter: "0x91c62ae93ccfFdBE0B1Be841433E46E033514C8D",
    PositionManager: "0x74de5577272E86224B4FabD439d701A1F993b341",

    TraderJoeNaviAvaxPool: "0x25f21f51b3d6322e165b80c9fde31104cb82df04",
    UniswapNaviEthPool: "0x0000000000000000000000000000000000000000", //
    ReferralStorage: "0x4C89A74a2f1CcCD99d3661ee34934F04509325D4",
    ReferralReader: "0xF0168Da5adfbBaE45e652736d593476523d2AB7d",
    NFTClub: "0x4CC5D6f3F79f3fBe8Fd02866d7D5dE9F24aE53fA",
    NavigatorClubSale: "0x0000000000000000000000000000000000000000",
    Multicall: "0xcA11bde05977b3631167028862bE2a173976CA11",
    NavigatorClubStaking: "0xac7cc9018B26f8Df2F96be95d8561041e42D6caf",
    NavigatorClubStakingV2: "0x0000000000000000000000000000000000000000",
    LiquidityLocker: "0x0000000000000000000000000000000000000000",
    Bridge: "0x0000000000000000000000000000000000000000",
    NAVIMigration: "0x0000000000000000000000000000000000000000",
    MigrateVester: "0x0000000000000000000000000000000000000000",
    BridgeNFT: "0x0000000000000000000000000000000000000000",
    Faucet: "0x0000000000000000000000000000000000000000",

    VaultNslp: "0x41cD8CaFc24A771031B9eB9C57cFC94D86045eB6",
    OrderVaultNslp: "0x65DCb38637b526305Be55F14B24a4Ab2bD177780",
    ReaderNslp: "0xf75D3c6f20FB65cc0B8f84687e5cBAC3D7f4221e",
    NSUSD: "0x0d8393CEa30df4fAFA7f00f333A62DeE451935C1",
    "USDC.e": "0x29219dd400f2Bf60E5a23d13Be72B486D4038894",
    PriceManager: "0x01e9B35785eF3f7Ef2677c371442976bd550f320",
    SettingsManager: "0x7b9e962dd8AeD0Db9A1D8a2D7A962ad8b871Ce4F",
    NSLP: "0x641Ff5D17178C3B9498133eBC264Bb4170A04668",
    NAVIFarm: "0x0000000000000000000000000000000000000000",
    vNAVI: "0x0000000000000000000000000000000000000000",
    PoolRewardersVNAVI: "0x0000000000000000000000000000000000000000",
    FireBirdRouter: "0x6131B5fae19EA4f9D964eAc0408E4408b66337b5",
    MicroSwapRouter: "0x097DF2e7a99633EFEa9560E1B6873AE9DB6669EB",
    StakeHelper: "0x56368023e6321fF56a8166c355f6FA92F1232d26",
    MigrateSign: "0x0000000000000000000000000000000000000000",
    NaviLocker: "0xD451ffD204906C1A725c65eFE570Ad8a6e2B08Bd",
    PositionVault: "0x3B2732c1e5A248BBDD0315E9e8845c64f5A6FaF5",
  },
  [FANTOM_TESTNET]: {
    Vault: "0x2d1203CF1610701AA9c265f06E47d9ed90A5cbb1",
    VaultNAVI: "0x0000000000000000000000000000000000000000",
    VaultNLP: "0x0000000000000000000000000000000000000000",
    VaultNAVI_WFTM: "0x0000000000000000000000000000000000000000",
    VaultNAVI_WETH: "0x0000000000000000000000000000000000000000",
    Router: "0x25854f67162aBaE9E3Ae3547d9049a0C342F302D",
    VaultReader: "0x12886824826F10aC355ddDc2FFF9C29b85bC9989",
    Reader: "0x43B60c142989DC0B39881A86EE0f87b0370c0D8A",
    NlpManager: "0x7f9cFB2d4c9688961784d939E1fb9c4912e58613",
    RewardRouter: "0x2ad81305C5C762CC834e761695788c3F24b33231",
    RewardReader: "0x6e021ab849326413a7E100C18d83A9a58715cA2E",
    NATIVE_TOKEN: "0xDba382b2e41C30C5939F898359C490bd94dD8e95", //
    NLP: "0x82BAD76B56B0117C832270c9f7e394F3c99f239e",
    NAVI: "0x9c6bDb36Bf7cc6504b9941F609226Ee16Ae0deF8",
    ES_NAVI: "0xCc8C865D2Cd41DC7CdD81cff12f117C7C40C4544",
    BN_NAVI: "0x8DC095eB70D63EEf34233aa61Ae067cc13F776C9",
    USDN: "0x7437Fd6D38F2773BEeed672e0d05A6ADB91bDA0A",
    ES_NAVI_IOU: "0x0000000000000000000000000000000000000000", //
    StakedNaviTracker: "0xcC3757e68237B43CABd46590a588285bD47a41Da",
    BonusNaviTracker: "0x433d3b24e80E7664B2c1FC9DE5a9982829bF8d47",
    FeeNaviTracker: "0x6c247198a23A21F3E65dD880e557822Db0305E08",
    StakedNlpTracker: "0x18240f4393B2360DfdDd50156900E4318319C724",
    FeeNlpTracker: "0x462c9B81505a934a3b01C604dE53CC0F994B89C7",

    StakedNslpTracker: "0x73bf8e0102C4152b5e4f82BB84bAba117A023dEA",
    FeeNslpTracker: "0x3F99088D22b2b128f7B63e57ED9ec1b893627A26",

    StakedNaviDistributor: "0x41F54bB58BC27f96C5205bb3D3aE45273ab36f33",
    StakedNlpDistributor: "0xa17f2654Cd7145ae933A288bcad818fd8B1515bb",
    StakedNslpDistributor: "0x16E2525DEbCeAb7E6B3334154507213168c7482a",

    NaviVester: "0x4C2C74366aA04f1F7e44e4764987a8778bABf005",
    NlpVester: "0x22470BFB5e38c6e2e179Df471F963086Ba71C72B",
    NslpVester: "0xcDf4202d0B349Bf05c5B634aCeD3e7D5f0F0ECa5",

    NaviVesterV1: "0x0000000000000000000000000000000000000000",
    NlpVesterV1: "0x0000000000000000000000000000000000000000",

    OrderBook: "0x63d6C268f65C50C365e7068222526C33F48fde34",
    OrderExecutor: "0x0000000000000000000000000000000000000000", //
    OrderBookReader: "0x9544649441D25FBDff15A431B4861c93852631cc",

    PositionRouter: "0x3129eEf57dbdc147B20fDAa18Af596e8B321533D",
    PositionManager: "0xC984d5321Ed8Efd4b0F16cA8991a95784253133b",

    TraderJoeNaviAvaxPool: "0x0000000000000000000000000000000000000000",
    UniswapNaviEthPool: "0x0000000000000000000000000000000000000000", //
    ReferralStorage: "0x5626dEf116f63E211060E33B9e617bbaf64c2131",
    ReferralReader: "0x402344D30C850dbcdDbcac6E3b190e9A67FA446f",
    NFTClub: "0x0000000000000000000000000000000000000000",
    NavigatorClubSale: "0x0000000000000000000000000000000000000000",
    Multicall: "0xcA11bde05977b3631167028862bE2a173976CA11",
    NavigatorClubStaking: "0x0000000000000000000000000000000000000000",
    NavigatorClubStakingV2: "0x0000000000000000000000000000000000000000",
    LiquidityLocker: "0x0000000000000000000000000000000000000000",
    Bridge: "0x0000000000000000000000000000000000000000",
    NAVIMigration: "0x0000000000000000000000000000000000000000",
    MigrateVester: "0x0000000000000000000000000000000000000000",
    BridgeNFT: "0x0000000000000000000000000000000000000000",
    Faucet: "0x0000000000000000000000000000000000000000",

    VaultNslp: "0xC292DF547e5B6ac39E1509Cba209ddC4073e8A60",
    OrderVaultNslp: "0x7581431cc29e901AcBa8EdBD9E7abea0Dd3c32a0",
    ReaderNslp: "0x9Dbf485B20565218D4267C6fefeB0ecBfBaAA035",
    NSUSD: "0x88f033DEDf66a1d2feB4b0062FFd2707FC8F117a",
    USDC: "0x598F5089Ed518c7C9fB639295fe39285810d72e3",
    PriceManager: "0x3694eFD4c3CDA0BBCFE669AAB126CD45Af702a22",
    SettingsManager: "0xc9DeCe8eE2424425e9992EE770433032918a179f",
    NSLP: "0xD16D1FA08636764CfB5264403a268BAD913C07f7",
    NAVIFarm: "0x0000000000000000000000000000000000000000",
    vNAVI: "0x0000000000000000000000000000000000000000",
    PoolRewardersVNAVI: "0x0000000000000000000000000000000000000000",
    FireBirdRouter: "0x6131B5fae19EA4f9D964eAc0408E4408b66337b5",
    MicroSwapRouter: "0xc9147959baB2cEEAC49692E03f8b132FDd62196c",
    StakeHelper: "0x0000000000000000000000000000000000000000",
    PositionVault: "0x00aBC1FfeE6D420574dbeCa25eA019Fc0A5fd76f",
    MigrateSign: "0xbC65CF2883e92a694C95f4dF2c28692a46E8fA60",
    NaviLocker: "0x40Bd060b187683A18c2F9674eC14aa3158b0E31A"
  },
  [OP]: {
    Vault: "0xA6D7D0e650aa40FFa42d845A354c12c2bc0aB15f",
    VaultNAVI: "???",
    VaultNLP: "???",
    VaultNAVI_WFTM: "???",
    VaultNAVI_WETH: "0x27F59c92e26EA651370A4f18ab6DA5B7C3458586",
    Router: "0x68d1CA32Aee9a73534429D8376743Bf222ff1870",
    VaultReader: "0xc592b3ADd24ea30c030978e5E7F5d02E123b4263",
    Reader: "0x4C4d81aA29fBb7Fe90172ad70a72F00Fb1ee46c6",
    NlpManager: "0x9032aeD8C1F2139E04C1AD6D9F75bdF1D6e5CF5c",
    RewardRouter: "0xD451ffD204906C1A725c65eFE570Ad8a6e2B08Bd",
    RewardReader: "0x766257B7cE3a5E3Ad5876200Ab1671D31289BC75",
    NATIVE_TOKEN: "0x4200000000000000000000000000000000000006",
    NLP: "0xCaB2C0A41556149330F4223C9b76d93C610DAfE6",
    NAVI: "0x385719545Ef34d457A88e723504544A53F0Ad9BC",
    ES_NAVI: "0x0d8393CEa30df4fAFA7f00f333A62DeE451935C1",
    BN_NAVI: "0x641Ff5D17178C3B9498133eBC264Bb4170A04668",
    USDN: "0x01e77288b38b416F972428d562454fb329350bAc",
    ES_NAVI_IOU: "0x0000000000000000000000000000000000000000", // placeholder address

    StakedNaviTracker: "0x04f23404553fcc388Ec73110A0206Dd2E76a6d95",
    BonusNaviTracker: "0xe149164D8eca659E8912DbDEC35E3f7E71Fb5789",
    FeeNaviTracker: "0x7B26207457A9F8fF4fd21A7A0434066935f1D8E7",
    StakedNlpTracker: "0xC2C5968e16ec9fABc39e27f9AbFC07C8Cfba6F16",
    FeeNlpTracker: "0xFfB69477FeE0DAEB64E7dE89B57846aFa990e99C",

    StakedNaviDistributor: "0x52cC60893d3Bd8508baAB835620CbF9ddfA0A13C",
    StakedNlpDistributor: "0xad96D9736484e35985773bdf7dBa5750dC830042",

    NaviVester: "0x4F697DeDF2b3862CA43493c33589F42CFEF66026",
    NlpVester: "0xA25d432ae720e57e85778905935A9A0B9284114A",
    NaviVesterV1: "0x2A3E489F713ab6F652aF930555b5bb3422711ac1",
    NlpVesterV1: "0xb54AbE3FEC8e1aE64620185A1E111Aa0c3a81542",
    OrderBook: "0x41cD8CaFc24A771031B9eB9C57cFC94D86045eB6",
    OrderExecutor: "0x0000000000000000000000000000000000000000",
    OrderBookReader: "0xB2a477C6BA5E96f6dECbCEd836cB7d3d32ef9ecD",

    PositionRouter: "0xC5129208cb1DC2b3c916011c9D94632e602B9811",
    PositionManager: "0x2D270f66feE6Ac9e27Ff6551AF5a8cFB5C8a7493",

    TraderJoeNaviAvaxPool: "0x104D0312f95A1B3056435d19668f2272ab1e7dF2",
    ReferralStorage: "0x727dB8FA7861340d49d13ea78321D0C9a1a79cd5",
    ReferralReader: "0x28cfD8e1E83beC8EE879d21634e7DC3D93102CED",

    UniswapNaviEthPool: "0x5d2D9deE001D95Dc6B203d74AA651b6D864a619A", //
    NFTClub: "0x04AA94F9e529645B2Cf65027Ef45FeD47a3Cff66",
    NavigatorClubSale: "0x3C05Ad1Ba92906dB8b9F3dB03c4C4D5119cE4110",
    Multicall: "0xeAa6877139d436Dc6d1f75F3aF15B74662617B2C",
    NavigatorClubStaking: "0xd94452957bC47bc59985013BC087AD5a55f251aC",
    NavigatorClubStakingV2: "0x0000000000000000000000000000000000000000",
    LiquidityLocker: "0x0000000000000000000000000000000000000000",
    Bridge: "0x385719545Ef34d457A88e723504544A53F0Ad9BC",
    NAVIMigration: "0x8082B086A8cD851A9690a03070e82737f37A548A",
    MigrateVester: "0x511A4CEf565FB8094fb71E7Ce735bC578cB57B86",
    BridgeNFT: "0x57f67286B6D1cE0623766022bCc2fAfE80B32c9a",
    NFTSaleV2: "0x4f1B6191fD7EE88375E28d87F738bb5772f0C877",
    NFTClubV2: "0xec866016ce430654d823f682491462a2be39014c",
    Faucet: "0x81f3112bD4737d2bB0E4eC55c0dfeB8284573B6a",

    VaultNslp: "0x22F1b6e775B53a39269f2bd9880CB846E7dD252F",
    OrderVaultNslp: "0xBDa9DeC273c63E8254d80258555D502580B1EA9C",
    ReaderNslp: "0x008efA72Ae42E7fd33AF75bB527582949e74edeB",
    NSUSD: "0x19c3bF74Bb805d80CD0BC06CC9FDd7A14c312467",
    "USDC.e": "0x7F5c764cBc14f9669B88837ca1490cCa17c31607",
    PriceManager: "0x74fEbEC784112032937A73D34a130A401D7B35Cd",
    SettingsManager: "0x56368023e6321fF56a8166c355f6FA92F1232d26",
    FireBirdRouter: "0x6131B5fae19EA4f9D964eAc0408E4408b66337b5",
    StakeHelper: "0x39980bE7182f9A910B1De42db83C9C2a3A8CbF4D",
    NSLP: "0xBA4DA42B991CA2dE2D1Cdd73A3F1aB0bf51a3908",
    NAVIFarm: "0xC40b5191ADc69155dF994875457672b5c5861CD0",
    vNAVI: "0xe208010f555Fa94622e80e279FB1593D9C474a16",
    PoolRewardersVNAVI: "0x77E4DFFeAF7823E2Ab13953B67E36FEE0d49b533",
    MicroSwapRouter: "0x0000000000000000000000000000000000000000",
  },
  [BASE]: {
    Vault: "0xA6D7D0e650aa40FFa42d845A354c12c2bc0aB15f",
    VaultNAVI: "???",
    VaultNLP: "???",
    VaultNAVI_WFTM: "???",
    VaultNAVI_WETH: "???",
    Router: "0x65DCb38637b526305Be55F14B24a4Ab2bD177780",
    VaultReader: "0x04f23404553fcc388Ec73110A0206Dd2E76a6d95",
    Reader: "0x28cfD8e1E83beC8EE879d21634e7DC3D93102CED",
    NlpManager: "0x2D270f66feE6Ac9e27Ff6551AF5a8cFB5C8a7493",
    RewardRouter: "0xB2a477C6BA5E96f6dECbCEd836cB7d3d32ef9ecD",
    RewardReader: "0x727dB8FA7861340d49d13ea78321D0C9a1a79cd5",
    NATIVE_TOKEN: "0x4200000000000000000000000000000000000006", //
    NLP: "0x304951d7172bCAdA54ccAC1E4674862b3d5b3d5b",
    NAVI: "0x0d8393CEa30df4fAFA7f00f333A62DeE451935C1",
    ES_NAVI: "0x9032aeD8C1F2139E04C1AD6D9F75bdF1D6e5CF5c",
    BN_NAVI: "0x41cD8CaFc24A771031B9eB9C57cFC94D86045eB6",
    USDN: "0x641Ff5D17178C3B9498133eBC264Bb4170A04668",
    ES_NAVI_IOU: "0x0000000000000000000000000000000000000000", // placeholder address

    StakedNaviTracker: "0xFfB69477FeE0DAEB64E7dE89B57846aFa990e99C",
    BonusNaviTracker: "0xC2C5968e16ec9fABc39e27f9AbFC07C8Cfba6F16",
    FeeNaviTracker: "0x52cC60893d3Bd8508baAB835620CbF9ddfA0A13C",
    StakedNlpTracker: "0xA30b1C3c8EDE3841dE05F9CdD4D0e097aC4C6D92",
    FeeNlpTracker: "0xCEBC8A8b66De9B3f4393966EaB86Fc9DE26B2529",

    StakedNaviDistributor: "0xa0207802B0953b17421bf9Cb10B3ACf5823496ab",
    StakedNlpDistributor: "0xb54AbE3FEC8e1aE64620185A1E111Aa0c3a81542",

    NaviVesterV1: "0x0000000000000000000000000000000000000000",
    NlpVesterV1: "0x0000000000000000000000000000000000000000",

    NaviVester: "0xfDC9B5be032216315bBe8c06b1c4F563D1689b85",
    NlpVester: "0xB280D03909Cc18640621955Cb3bC30948DF2Fa9a",
    OrderBook: "0xC5129208cb1DC2b3c916011c9D94632e602B9811",
    OrderExecutor: "0x0000000000000000000000000000000000000000",
    OrderBookReader: "0xc592b3ADd24ea30c030978e5E7F5d02E123b4263",

    PositionRouter: "0x01e9B35785eF3f7Ef2677c371442976bd550f320",
    PositionManager: "0x08Dfcfb0bD6e7eAF828f60021C77d273DE56b1C5",

    TraderJoeNaviAvaxPool: "0x104D0312f95A1B3056435d19668f2272ab1e7dF2", //TODO
    ReferralStorage: "0x7B26207457A9F8fF4fd21A7A0434066935f1D8E7",
    ReferralReader: "0xe149164D8eca659E8912DbDEC35E3f7E71Fb5789",

    UniswapNaviEthPool: "0x5d2D9deE001D95Dc6B203d74AA651b6D864a619A", //TODO
    Multicall: "0xcA11bde05977b3631167028862bE2a173976CA11",
    NFTClub: "0x0000000000000000000000000000000000000000",
    NavigatorClubSale: "0x0000000000000000000000000000000000000000",
    NavigatorClubStaking: "0x0000000000000000000000000000000000000000",
    NFTSaleV2: "0x0000000000000000000000000000000000000000",
    NFTClubV2: "0x0000000000000000000000000000000000000000",
    NavigatorClubStakingV2: "0x0000000000000000000000000000000000000000",
    BridgeNFT: "0x0000000000000000000000000000000000000000",
    MigrateVester: "0x0000000000000000000000000000000000000000",
    Bridge: "0x0d8393CEa30df4fAFA7f00f333A62DeE451935C1",

    VaultNslp: "0xEDFFF5d0C68cFBd44FA12659Fd9AD55F04748874",
    OrderVaultNslp: "0xca1F8B9A0ec30687824512a5dE83bcD1C867696C",
    ReaderNslp: "0x18F929a6FB125bb8e60E04CB37Ad2dFdb02c7b03",
    PriceManager: "0x796aEa62b76fd0916aCD0FBCddb3A38253555E56",
    SettingsManager: "0xF5599A5B7d330DD194DaD3Ce4095C1F942106A9B",
    "USDC.e": "0x0000000000000000000000000000000000000000",
    NSUSD: "0x0000000000000000000000000000000000000000",
    FireBirdRouter: "0x6131B5fae19EA4f9D964eAc0408E4408b66337b5",
    MicroSwapRouter: "0x0000000000000000000000000000000000000000",
    NSLP: "0x0000000000000000000000000000000000000000",
    NAVIFarm: "0x0000000000000000000000000000000000000000",
    vNAVI: "0x0000000000000000000000000000000000000000",
    PoolRewardersVNAVI: "0x0000000000000000000000000000000000000000",
    StakeHelper: "0x0000000000000000000000000000000000000000",
  },
  [MUMBAI]: {
    Vault: "0xA6D7D0e650aa40FFa42d845A354c12c2bc0aB15f",
    Router: "0x41cD8CaFc24A771031B9eB9C57cFC94D86045eB6",
    VaultReader: "0x766257B7cE3a5E3Ad5876200Ab1671D31289BC75",
    Reader: "0xB2a477C6BA5E96f6dECbCEd836cB7d3d32ef9ecD",
    NlpManager: "0x304951d7172bCAdA54ccAC1E4674862b3d5b3d5b",
    RewardRouter: "0x7b9e962dd8AeD0Db9A1D8a2D7A962ad8b871Ce4F",
    RewardReader: "0x4C4d81aA29fBb7Fe90172ad70a72F00Fb1ee46c6",
    NATIVE_TOKEN: "0x21be370D5312f44cB42ce377BC9b8a0cEF1A4C83", //
    NLP: "0x0ce61aAF89500e4f007884fBbf62642618dEf5dd",
    NAVI: "0x01e77288b38b416F972428d562454fb329350bAc",
    ES_NAVI: "0xe41c6c006De9147FC4c84b20cDFBFC679667343F",
    BN_NAVI: "0x0d8393CEa30df4fAFA7f00f333A62DeE451935C1",
    USDN: "0xCaB2C0A41556149330F4223C9b76d93C610DAfE6",
    ES_NAVI_IOU: "0x0000000000000000000000000000000000000000", //
    StakedNaviTracker: "0x727dB8FA7861340d49d13ea78321D0C9a1a79cd5",
    BonusNaviTracker: "0x04f23404553fcc388Ec73110A0206Dd2E76a6d95",
    FeeNaviTracker: "0xe149164D8eca659E8912DbDEC35E3f7E71Fb5789",
    StakedNlpTracker: "0xFfB69477FeE0DAEB64E7dE89B57846aFa990e99C",
    FeeNlpTracker: "0x7B26207457A9F8fF4fd21A7A0434066935f1D8E7",

    StakedNaviDistributor: "0xC2C5968e16ec9fABc39e27f9AbFC07C8Cfba6F16",
    StakedNlpDistributor: "0xa0207802B0953b17421bf9Cb10B3ACf5823496ab",

    NaviVester: "0xa1a65D3639A1EFbFB18C82003330a4b1FB620C5a",
    NlpVester: "0x2A3E489F713ab6F652aF930555b5bb3422711ac1",

    OrderBook: "0x9032aeD8C1F2139E04C1AD6D9F75bdF1D6e5CF5c",
    OrderExecutor: "0x0000000000000000000000000000000000000000", //
    OrderBookReader: "0x2Aef1BC2f13118009DF8663705F736F77EFaFD5c",

    PositionRouter: "0x2D270f66feE6Ac9e27Ff6551AF5a8cFB5C8a7493",
    PositionManager: "0x68d1CA32Aee9a73534429D8376743Bf222ff1870",

    UniswapNaviEthPool: "0x855B2EeDA3ffFEabf03b6DbF2609904da2247Cc8", //
    ReferralStorage: "0x28cfD8e1E83beC8EE879d21634e7DC3D93102CED",
    ReferralReader: "0xc592b3ADd24ea30c030978e5E7F5d02E123b4263",
    NFTClub: "0x9e9f0901fde8914f13b2c965aa1538dc557b06a6",
    NavigatorClubSale: "0x90B8dA33F39F3C2Bf7456b312afE44CfDAc09eEe",
    Multicall: "0x08411ADd0b5AA8ee47563b146743C13b3556c9Cc",
    NavigatorClubStaking: "0x46bd9B7F2a353A2A5d1Ad92440c0437419113A7b",

    BridgeNFT: "0x57f67286B6D1cE0623766022bCc2fAfE80B32c9a",
    NFTSaleV2: "0x34Cb8892c04c240ae0342f1632565D4EC305f678",
    NFTClubV2: "0xec866016ce430654d823f682491462a2be39014c",
    Faucet: "0x81f3112bD4737d2bB0E4eC55c0dfeB8284573B6a",
  },
};

export function getContract(chainId: number, name: string): string {
  if (!CONTRACTS[chainId]) {
    throw new Error(`Unknown chainId ${chainId}`);
  }

  if (!CONTRACTS[chainId][name]) {
    throw new Error(`Unknown contract "${name}" for chainId ${chainId}`);
  }

  return CONTRACTS[chainId][name];
}
