import React, { useState, useCallback, useEffect, useMemo } from "react";
import { BigNumber, ethers } from "ethers";
import cx from "classnames";
import { Trans, t } from "@lingui/macro";
import { BsArrowRight } from "react-icons/bs";

import { MARKET, STOP, importImage } from "lib/legacy";
import { ARBITRUM, getChainName, getConstant, IS_NETWORK_DISABLED } from "config/chains";
import { createDecreaseOrder, useHasOutdatedUi } from "domain/legacy";
import { getContract } from "config/contracts";
import PositionRouter from "abis/PositionRouter.json";
import Checkbox from "../Checkbox/Checkbox";
import Tab from "../Tab/Tab";
import Modal from "../Modal/Modal";
import ExchangeInfoRow from "./ExchangeInfoRow";
import Tooltip from "../Tooltip/Tooltip";
import "./PositionSeller.css";
import { formatAmount, limitDecimals, parseValue } from "lib/numbers";
import moment from "moment";
import RowsDropdown from "./RowsDropdown";
import PositionHistory from "./PositionHistory";
import usePositionActivities from "hooks/usePositionActivities";
import { THEME_KEY } from "config/localStorage";

import ClockIcon from "../Icon/Clock";
import styled from "styled-components";
const dateFormat = "YYYY-MM-DD";
const timeFormat = "HH:mm:ss";

export default function PositionClosed(props) {
  const { isVisible, position, setIsVisible, chainId, marketTokensInfo } = props;
  // console.log(position.positionNetValue.prefix, "--position.positionNetValue.prefix--");
  const [isOpenPositionHistory, setIsOpenPositionHistory] = useState(false);
  const rPnl = BigNumber.from(position.realisedPnl || 0);
  const currentMarket = marketTokensInfo?.find((x) => x.id === position.tokenId);
  const { data: positionActivities } = usePositionActivities(chainId, position.posId);
  // const theme = window.localStorage.getItem(`"${THEME_KEY}"`);
  return (
    <Wrap className="PositionEditor">
      {position && (
        <Modal
          className={`PositionSeller-modal  confirmation-modal details-position-modal`}
          isVisible={isVisible}
          setIsVisible={setIsVisible}
          label={"Closed Position"}
          allowContentTouchMove
        >
          <div className="order-title-line order-title-line-details">
            <div className="position-info-container">
              <div className="position-info">
                <img src={importImage("ic_" + position?.symbol?.toLowerCase() + "_24.svg")} alt="" />
                <div className="title">{position.symbol}/USD</div>
                <div className="position-id">#{position?.posId}</div>
                <div className="postion-history-btn" onClick={() => setIsOpenPositionHistory(true)}>
                  <ClockIcon />
                </div>
              </div>
              <div className="position-tags">
                <div className={`side ${position?.isLong ? "side-long" : "side-short"}`}>
                  {position?.isLong ? "LONG" : "SHORT"}
                </div>
                <div className="side">
                  {position?.positionType === "Market Order" ? "Market" : position?.positionType}
                </div>
                <div className="leverage-box">{formatAmount(parseValue(position.leverage, 30), 30, 2, true)}X</div>
              </div>
            </div>
          </div>
          <div className="content-container" style={{ marginBottom: 24 }}>
            <div className="gray-divider" />
            <div className="order-details-container">
              {/* <div className="title">Position Details</div> */}
              <div className="square-container">
                <ExchangeInfoRow label={`Realized PnL`}>
                  <div
                    className={cx({
                      positive: rPnl.gt(0),
                      negative: rPnl.lt(0),
                    })}
                    // style={{ color: position.positionNetValue.prefix == "+" ? "#90FB75" : "#F6475D" }}
                  >
                    {position.rPnLPrifix}${formatAmount(position.realisedPnl, 30, 2, true).replace("-", "")}
                  </div>
                </ExchangeInfoRow>
                <ExchangeInfoRow label={`ROI`}>
                  <div
                    className={cx({
                      positive: !position.positionNetValue.prefix.includes("-"),
                      negative: position.positionNetValue.prefix.includes("-"),
                    })}
                  >
                    <span>
                      {position.positionNetValue.prefix}
                      {limitDecimals(Number(position.totalROI) / 1000, 2).replace("-", "")}%
                    </span>
                  </div>
                </ExchangeInfoRow>
                <ExchangeInfoRow label={`Pos.Size`}>${formatAmount(position.totalSize, 30, 2, true)}</ExchangeInfoRow>
                <ExchangeInfoRow label={`Pos.Qty`}>
                  {formatAmount(position.quantity, 30, 2, true)} {position.symbol}
                </ExchangeInfoRow>
                {/* <ExchangeInfoRow label={`Leverage`}>
                  <div className="position-list-leve">
                    {formatAmount(parseValue(position.leverage, 30), 30, 2, true)}X
                  </div>
                </ExchangeInfoRow> */}
                <ExchangeInfoRow label={`Collateral`}>
                  ${formatAmount(position.collateral, 30, 2, true)}
                </ExchangeInfoRow>
                <ExchangeInfoRow label={`Mark Price`}>
                  ${formatAmount(position.markPrice, 30, currentMarket?.decimals, true)}
                </ExchangeInfoRow>
                <ExchangeInfoRow label={`Avg. Entry Price`}>
                  ${formatAmount(position.averagePrice, 30, currentMarket?.decimals, true)}
                </ExchangeInfoRow>
                <ExchangeInfoRow label={`Date/Time Opened`}>
                  {moment(new Date(position.createdAt * 1000)).format(dateFormat)}&nbsp;
                  {moment(new Date(position.createdAt * 1000)).format(timeFormat)}
                </ExchangeInfoRow>
                <ExchangeInfoRow label={`Duration`}>{position.duration}</ExchangeInfoRow>
              </div>

              <div className="gray-divider" style={{ margin: 0 }} />

              <RowsDropdown
                handler={
                  <ExchangeInfoRow
                    label={<div className="title">Fees</div>}
                    isPositive={position.paidFees.gte(0)}
                  ></ExchangeInfoRow>
                }
              >
                <div className="square-container square-fee-container">
                  <ExchangeInfoRow label={`Paid`}>
                    {position.paidFees.lt(0) ? "-" : ""}${formatAmount(position.paidFees, 30, 4, true).replace("-", "")}
                  </ExchangeInfoRow>
                  <ExchangeInfoRow label={`Position`}>
                    ${formatAmount(position.positionFee, 30, 4, true)}
                  </ExchangeInfoRow>
                  <ExchangeInfoRow label={`Funding`}>
                    {BigNumber.from(position.fundingFee).gte(0) ? "" : "-"}$
                    {formatAmount(BigNumber.from(position.fundingFee), 30, 4, true).replace("-", "")}
                  </ExchangeInfoRow>
                  <ExchangeInfoRow label={`Borrow`}>${formatAmount(position.borrowFee, 30, 4, true)}</ExchangeInfoRow>
                  {/* <ExchangeInfoRow label={`Fee Discount (--%)`}>$--</ExchangeInfoRow> */}
                </div>
              </RowsDropdown>
            </div>
          </div>
          <PositionHistory
            isVisible={isOpenPositionHistory}
            setIsVisible={setIsOpenPositionHistory}
            position={position}
            rows={positionActivities}
            chainId={chainId}
            optionalHeader={
              <div className="order-title-line order-title-line-details">
                <div className="position-info-container">
                  <div className="position-info">
                    <img src={importImage("ic_" + position?.symbol?.toLowerCase() + "_24.svg")} alt="" />
                    <div className="title">{position.symbol}</div>
                    <div className="position-id">#{position?.posId}</div>
                  </div>
                  <div className="position-tags">
                    <div className={`side ${position?.isLong ? "side-long" : "side-short"}`}>
                      {position?.isLong ? "LONG" : "SHORT"}
                    </div>
                    <div className="side">
                      {position?.positionType === "Market Order" ? "Market" : position?.positionType}
                    </div>
                  </div>
                </div>
              </div>
            }
          />
        </Modal>
      )}
    </Wrap>
  );
}
const Wrap = styled.div`
  .content-container {
    @media screen and (max-width: 700px) {
      padding-left: 16px !important;
      padding-right: 16px !important;
    }
  }
`;
