import { ARBITRUM, SONIC_TESTNET } from "config/chains";

const NAVI_VAULT = "NAVI vault";
const NLP_VAULT = "NLP vault";
const NAVI_WFTM_OLD_VAULT = "NAVI_WFTM_OLD vault";
const NAVI_WFTM_VAULT = "NAVI_WFTM vault";
const NAVI_WETH_VAULT = "NAVI_WETH vault";

const FANTOM = 250;
const OP = 10;

const CHAINS = {
  [SONIC_TESTNET]: {
    strategy: {
      [NAVI_VAULT]: {
        link: "https://sonicscan.org/address/0x3ff3d7Adf25DB76e555Ae30dE5102B5a48Ab3483",
        text: "NAVI is staked to earn SONIC, esNAVI, and MPs. SONIC reward is compounded to more NAVI, while esNAVI and MPs are staked to acquire more SONIC rewards, compounded back for more NAVI and MPs. All of this works to boost APR optimally.",
      },
      [NLP_VAULT]: {
        link: "https://sonicscan.org/address/0xFf482116110afB8CF0D3b7664cF0e4858A1F4991",
        text: "NLP is staked to earn SONIC and esNAVI. The claimed fees from esNAVI staked and SONIC then are used to mint more NLP to earn more fees. All of this works to boost APR optimally. A 2-hour cooldown will begin each time the vault auto-compounds. You can only withdraw after the cooldown has ended.",
      },
    },
    token: {
      [NAVI_VAULT]: {
        link: "https://sonicscan.org/address/0x6881B80ea7C858E4aEEf63893e18a8A36f3682f3",
        text: "NAVI is the utility and governance token of Navigator Exchange Spot and Perpetual DEX. Stake NAVI to earn esNAVI, MPs, and 20% of platform fees in SONIC.",
      },
      [NLP_VAULT]: {
        link: "https://sonicscan.org/address/0x489e0A78fCb4841729C00638eD9cE306973A88FE",
        text: "NLP is the liquidity provider token. Accrues 50% of the platform's generated fees.",
      },
    },
  },
  [OP]: {
    strategy: {
      [NAVI_WETH_VAULT]: {
        link: "https://optimistic.etherscan.io/address/0xc1d4e4743dde709831e1cb485dcdbd44def893b0",
        text: "The vault deposits the user's NAVI-WETH LP in a Velodrome farm to earn VELO. The earned token is then swapped to acquire more of the same LP token. To complete the compounding cycle, the new NAVI-WETH LP is added to the farm, ready for the next earning event.",
      },
    },
    token: {
      [NAVI_WETH_VAULT]: {
        link: [
          "https://optimistic.etherscan.io/address/0x385719545Ef34d457A88e723504544A53F0Ad9BC",
          "https://optimistic.etherscan.io/token/0x4200000000000000000000000000000000000006",
        ],
        text: [
          "NAVI is the utility and governance token of Navigator Exchange Spot and Perpetual DEX. Stake NAVI to earn esNAVI, MPs, and 30% of platform fees in ETH.",
          "WETH is an ERC-20 token on Ethereum pegged to the price of Ether (ETH).",
        ],
      },
    },
  },
  [ARBITRUM]: {
    strategy: {
      [NAVI_VAULT]: {
        link: "https://arbiscan.io/address/0xc55E86aF2F11e912a48e47f82a51eA782e082ed2",
        text: "NAVI is staked to earn FTM, esNAVI, and MPs. FTM reward is compounded to more NAVI, while esNAVI and MPs are staked to acquire more FTM rewards, compounded back for more NAVI and MPs. All of this works to boost APR optimally.",
      },
      [NLP_VAULT]: {
        link: "https://arbiscan.io/address/0x703Dc0F50fEB31F040F92158A2dd571DB1A472B2",
        text: "NLP is staked to earn FTM and esNAVI. The claimed fees from esNAVI staked and FTM then are used to mint more NLP to earn more fees. All of this works to boost APR optimally.",
      },
    },
    token: {
      [NAVI_VAULT]: {
        link: "https://arbiscan.io/token/0xA6D7D0e650aa40FFa42d845A354c12c2bc0aB15f",
        text: "NAVI is the utility and governance token of Navigator Exchange Spot and Perpetual DEX. Stake NAVI to earn esNAVI, MPs, and 30% of platform fees in FTM.",
      },
      [NLP_VAULT]: {
        link: "https://arbiscan.io/address/0x421b4c1Db159936834D5DcA1aF35DBD5443420D1",
        text: "NLP is the liquidity provider token. Accrues 60% of the platform's generated fees.",
      },
    },
  },
};

export default CHAINS;
