import { ImageNavi, ImageNLP, ImageNSLP } from "assets";
import Tooltip from "components/Tooltip/Tooltip";
import { SONIC_TESTNET } from "config/chains";
import { useNaviPrice } from "domain/legacy";
import { motion } from "framer-motion";
import icToken from "img/ic_s_24.svg";
import { itemVariants, listVariants } from "lib/anim";
import { formatAmount, formatKeyAmount } from "lib/numbers";
import styled from "styled-components";
import icSonic from "../../assets/images/ic-sonic.svg";
import { contentVariants } from "./Banner";

const Tokens = ({ processedData }) => {
  const { naviPrice } = useNaviPrice(SONIC_TESTNET, { arbitrum: undefined }, false);
  const listToken = [
    {
      name: "NAVI",
      price: formatAmount(naviPrice, 30, 2, true),
      img: ImageNavi,
      desc: "Navigator Exchange governance token",
      aprSonic: formatKeyAmount(processedData, "naviAprTotalWithBoost", 2, 2, true),
      href: "https://app.openocean.finance/swap/sonic/USDC.e/NAVI_0x6881B80ea7C858E4aEEf63893e18a8A36f3682f3",
      linkReadMore: "https://docs.navigator.exchange/tokenomics",
    },
    {
      name: "Crypto Pool - NLP",
      price: formatAmount(processedData.nlpPrice, 30, 2, true),
      img: ImageNLP,
      desc: "Crypto index pool (S, WS, ETH, USDC.e...)",
      aprSonic: formatKeyAmount(processedData, "nlpAprTotal", 2, 2, true),
      href: "https://app.navigator.exchange/#/earn/nlp",
      linkReadMore: "https://docs.navigator.exchange/earn/nlp",
      isDisable: false,
    },
    {
      name: "Stable Pool - NSLP",
      price: formatAmount(processedData.nslpPrice, 30, 2, true),
      img: ImageNSLP,
      desc: "Stable index pool (USDC.e, scUSD, USDT)",
      aprSonic: formatKeyAmount(processedData, "nslpAprTotal", 2, 2, true),
      href: "https://app.navigator.exchange/#/earn/nslp",
      linkReadMore: "https://docs.navigator.exchange/earn/nslp",
      isDisable: false,
    },
  ];
  return (
    <Wrapper>
      <div className="container wrapper">
        <motion.div variants={contentVariants} initial="hidden" whileInView="visible" className="title">
          Ecosystem tokens
        </motion.div>
        <motion.div variants={listVariants} initial="hidden" whileInView="visible" className="wrap-box">
          {listToken.map((item, idx) => (
            <motion.div variants={itemVariants} className="box-item" key={idx}>
              <div className="box-info">
                <img className="icon" src={item.img} alt="" />
                <div>
                  <div className="name">{item.name}</div>
                  <div className="price">
                    <span className="sub-price">Price:</span> ${item.price}
                  </div>
                </div>
              </div>
              <div className="desc">{item.desc}</div>
              <div className="box-apr">
                <div className="apr-item">
                  <img className="img-apr" src={icToken} alt="" />
                  <div className="apr-percent">
                    <span className="sub-apr">APR</span>
                    {item.aprSonic ? (
                      <span> {item.aprSonic}%</span>
                    ) : (
                      // <Tooltip
                      //   position={`${"center-bottom"}`}
                      //   className="tooltip-apr"
                      //   handle={
                      //     <h5 className="positive">
                      //       {item.aprSonic}% <img className="sonic-icon-tooltip" src={icSonic} alt="sonic" />
                      //     </h5>
                      //   }
                      //   renderContent={() => {
                      //     return (
                      //       <TooltipWrap>
                      //         <div>
                      //           <span className="muted">You Get Additional:</span>
                      //           <b>
                      //             <img src={icSonic} alt="sonic" /> 5x Sonic <span className="muted">Points</span>{" "}
                      //           </b>
                      //         </div>
                      //         <a
                      //           href="https://blog.soniclabs.com/sonic-points-simplified-how-to-qualify-for-200-million-s-airdrop/"
                      //           target="_blank"
                      //           rel="noreferrer"
                      //         >
                      //           Sonic Details{" "}
                      //           <svg
                      //             width="20"
                      //             height="20"
                      //             viewBox="0 0 20 20"
                      //             fill="none"
                      //             xmlns="http://www.w3.org/2000/svg"
                      //           >
                      //             <path
                      //               fill-rule="evenodd"
                      //               clip-rule="evenodd"
                      //               d="M8.33333 2.5C7.8731 2.5 7.5 2.8731 7.5 3.33333C7.5 3.79357 7.8731 4.16667 8.33333 4.16667H14.6548L2.74408 16.0774C2.41864 16.4028 2.41864 16.9305 2.74408 17.2559C3.06951 17.5814 3.59715 17.5814 3.92259 17.2559L15.8333 5.34518V10.8333C15.8333 11.2936 16.2064 11.6667 16.6667 11.6667C17.1269 11.6667 17.5 11.2936 17.5 10.8333V2.5H8.33333Z"
                      //               fill="#90FB75"
                      //             />
                      //           </svg>
                      //         </a>
                      //       </TooltipWrap>
                      //     );
                      //   }}
                      // />
                      <div className="skeleton-box" style={{ width: "80px", height: "19.6px" }} />
                    )}
                  </div>
                </div>
              </div>
              <div className="group-btn">
                <button
                  onClick={() => (window.location.href = item.href)}
                  className="default-btn btn-network"
                  disabled={item.isDisable}
                >
                  Buy
                </button>
                {/* <Link to="/buy_mmy" className="default-btn btn-network">

                </Link> */}
                <button
                  onClick={() => window.open(item.linkReadMore, "_blank")}
                  className="btn-outline-landing btn-network"
                >
                  Read More
                </button>
              </div>
            </motion.div>
          ))}
        </motion.div>
      </div>
    </Wrapper>
  );
};

export default Tokens;
const TooltipWrap = styled.div`
  font-style: normal;
  font-weight: 500;
  line-height: 140%;

  > div:first-child {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 12px;
  }
  b {
    color: #fff;
    display: flex;
    gap: 4px;
    align-items: center;
    .muted {
      font-weight: 700;
    }
    font-weight: 700 !important;
  }
  a {
    font-style: normal;
    font-weight: 700 !important;
    line-height: 140%;
    text-decoration: none;
    display: flex;
    align-items: center;
    color: #90fb75;
  }
`;
const Wrapper = styled.div`
  .sonic-icon-tooltip {
    position: relative;
    top: -1px;
  }
  .tooltip-apr {
    .Tooltip-popup {
      @media screen and (max-width: 700px) {
        width: 300px !important;
      }
    }
  }
  .wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 36px;
  }
  .title {
    color: #fff;
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%; /* 50.4px */
    @media screen and (max-width: 768px) {
      font-size: 28px;
    }
  }
  .box-item {
    display: flex;
    padding: 24px;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    flex: 1 0 0;
    border-radius: 16px;
    background: #0c0f0c;
    width: 100%;
  }
  .wrap-box {
    display: flex;
    align-items: flex-start;
    gap: 24px;
    align-self: stretch;
    @media screen and (max-width: 1024px) {
      flex-direction: column;
    }
  }
  .box-info {
    display: flex;
    align-items: center;
    gap: 12px;
  }
  .icon {
    width: 40px;
    height: 40px;
    flex-shrink: 0;
  }
  .name {
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%; /* 22.4px */
  }
  .price {
    color: var(--Primary, #90fb75);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    margin-top: 4px;
  }
  .sub-price {
    color: var(--Text-Text_Secondary, #828899);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%; /* 19.6px */
  }
  .desc {
    color: var(--Text-Text_Secondary, #828899);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 22.4px */
  }
  .box-apr {
    display: flex;
    align-items: flex-start;
    align-self: stretch;
    border-radius: 16px;
    background: #161a16;
  }
  .apr-item {
    display: flex;
    padding: 24px 12px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: var(--Base-Unit-XS-8, 8px);
    flex: 1 0 0;
    align-self: stretch;
    h5 {
      margin-bottom: 0;
    }
  }
  /* .apr-item:first-child {
    border-right: 1px solid var(--Border, rgba(255, 255, 255, 0.1));
  } */
  .img-apr {
    display: flex;
    width: 24px;
    height: 24px;
    justify-content: center;
    align-items: center;
  }
  .apr-percent {
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--Primary, #90fb75);
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%; /* 19.6px */
    gap: 4px;
    h5.positive {
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 140%;
    }
  }
  .sub-apr {
    color: var(--Text-Text_Secondary, #828899);
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%; /* 19.6px */
  }
  .group-btn {
    display: flex;
    align-items: flex-start;
    gap: 12px;
    align-self: stretch;
  }
  .btn-network {
    flex: 1;
  }
`;
