import React from "react";
import cx from "classnames";
import styled from "styled-components";
export default function TabContainer({
  tab50,
  TABS,
  setActiveTab,
  activeTab,
  fullWidth,
  rightComponent,
  item1,
  item2,
}) {
  if (item1 && item2)
    return (
      <Container
        className={cx("tab-container", {
          fullWidth,
        })}
      >
        <div className="tabs">
          {item1}
          {item2}
        </div>{" "}
        <div className="tab-line"></div>
      </Container>
    );
  return (
    <Container
      className={cx("tab-container", {
        fullWidth,
      })}
    >
      <div className="container-box">
        <div className="tabs">
          {TABS.map((tab) => {
            return (
              <div
                className={cx("tab", {
                  active: activeTab.key === tab.key,
                  tab50,
                })}
                onClick={() => setActiveTab(tab)}
                key={tab.key}
              >
                {tab.label}{" "}
                {tab.hasOwnProperty("count") ? (
                  <Styledcount
                    className={cx("", {
                      styleActive: activeTab.key === tab.key,
                    })}
                  >
                    {tab.count}
                  </Styledcount>
                ) : null}
              </div>
            );
          })}
        </div>
        {rightComponent}
      </div>
      <div className="tab-line"></div>
    </Container>
  );
}
const Container = styled.div`
  .container-box {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  display: flex;
  position: relative;
  flex-direction: column;
  &.fullWidth {
    width: 100%;
  }
  .tab-line {
    width: 100%;
    height: 1px;
    background: var(--Border, rgba(55, 63, 92, 0.5));
  }

  .tabs {
    display: flex;
    z-index: 1;
    flex: 1;
  }

  @media screen and (max-width: 700px) {
    overflow: auto;
  }
  .tab {
    text-decoration: none;
    display: flex;
    padding: 16px 24px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    border-radius: 100px;
    color: #828899;
    font-size: 14px;
    font-weight: 700;
    line-height: 140%; /* 19.6px */
    text-wrap: nowrap;
    white-space: nowrap;
    cursor: pointer;
    border: 1px solid transparent;
    &.tab50 {
      width: 50%;
    }
    &:hover {
      color: #fff;
    }

    &.active {
      border-radius: 16px 16px 0px 0px;
      border-bottom: 2px solid var(--Primary, #90fb75);
      background: var(--Nature-2, #1a1d26);
      pointer-events: none;
      color: #90fb75;
      @media screen and (max-width: 700px) {
        position: relative;
        top: 1px;
      }
    }
  }
  .tab-small {
    padding: 16px 24px;
    max-height: 52px;
  }
`;
const Styledcount = styled.div`
  border-radius: 8px;
  border: 1px solid #41454d;
  background: var(--Nature-2, #1a1d26);
  color: #828899;
  height: 20px;
  padding: 0 6px;
  font-size: 10px;

  &.styleActive {
    color: #fff;
  }
`;
