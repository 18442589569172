import React from "react";

import { formatAmount, parseValue } from "lib/numbers";
import { DataTable, DateAndTime, PositionHead, TableRow } from "../../helper/table";

import icView from "img/ic-view-arrow.svg";
import icViewLight from "img/ic-view-arrowLight.svg";
import { useThemeContext } from "contexts/ThemeProvider";
import { TYPE_MAP_TYPE } from "..";
import { getExplorerUrl } from "config/chains";
import { formatHistory } from "../../helper";
import styled from "styled-components";
import { TOKEN_SHOWS_DECIMALS } from "config/tokens";
const TradeHistories = ({ histories, chainId, assets, isEmpty }) => {
  const { lightThemeClassName, isLightTheme } = useThemeContext();

  const formattedPositions = histories.map((history) => {
    return formatHistory(history, assets);
  });

  return (
    <StyledDataTable $noIndex $autoHeight $noHeading={isEmpty} className={lightThemeClassName}>
      <div className="head">
        <div className="col-name">Position</div>
        <div className="col-name">Date/Time</div>
        <div className="col-name">Type</div>
        <div
          className="col-name"
          style={{
            minWidth: 400,
          }}
        >
          Action
        </div>
        <div className="col-name">Size Delta</div>
      </div>
      <div className={`body ${formattedPositions?.length === 1 ? "body-single-row" : ""}`}>
        {formattedPositions.map((history, i) => {
          const asset = assets[history.indexToken.id] || {};
          const price = parseValue(history.price, 30);
          const symbol = `${history.type === "V1" ? history.indexToken.symbol + "/USD" : history.symbol}`;
          const isV2 = history.type === "V2";
          return (
            <TableRow key={history.key || i} href={`${getExplorerUrl(chainId)}tx/${history.txhash}`}>
              <div d-label="Position" className="col-body">
                <PositionHead noFlag position={history} isV2={isV2} asset={isV2 ? asset : undefined} hideLeverage />
              </div>
              <div d-label="Date/Time" className="col-body">
                <div className="col-body-content">
                  <DateAndTime unix={history.timestamp} />
                </div>
              </div>
              <div d-label="Type" className="col-body">
                {/* {isV2 ? (
                  <>{history.positionType}</>
                ) : (
                  <>
                    {history.action === "DECREASE"
                      ? "Decrease position"
                      : history.action === "INCREASE"
                      ? "Increase position "
                      : "Liquidation"}
                  </>
                )} */}
                {history.positionType}
              </div>
              <div
                d-label="Action"
                className="col-body col-min-width"
                style={{
                  minWidth: 400,
                }}
              >
                {isV2 ? (
                  <>
                    {history.action === "ADD_TRAILING_STOP" ? (
                      history.trailingStopAll ? (
                        "Close"
                      ) : (
                        "Decrease"
                      )
                    ) : (
                      <span>
                        {history.action === "EDIT_COLLATERAL"
                          ? history.isPlus
                            ? "Deposited"
                            : "Withdrew"
                          : TYPE_MAP_TYPE[history.action]}{" "}
                        <span className="muted">(Price ${formatAmount(price, 30, asset.decimals, true)})</span>
                      </span>
                    )}
                  </>
                ) : (
                  <>
                    {history.action === "CLOSE"
                      ? "Decreased position"
                      : history.action === "OPEN"
                      ? "Increased position"
                      : history.action === "DEPOSIT"
                      ? "Deposited"
                      : history.action === "INCREASE"
                      ? "Increased position"
                      : history.action === "WITHDRAW"
                      ? "Withdrew"
                      : history.action === "DECREASE"
                      ? "Decreased position"
                      : "Liquidated"}{" "}
                    <span className="muted">
                      (Price ${formatAmount(price, 30, TOKEN_SHOWS_DECIMALS[symbol] || 2, true)})
                    </span>
                  </>
                )}
              </div>
              <div d-label="Size Delta" className="col-body">
                {!["DEPOSIT", "WITHDRAW"].includes(history.action) && (
                  <>
                    <div> ${formatAmount(history.size, 30, 2, true)}</div>
                    <div className="muted fz-12 bold">
                      {formatAmount(history.volume, 30, 4, true)} {asset.tokenSymbol || history.indexToken.symbol}
                    </div>
                  </>
                )}
              </div>
              <img className="icon-view" src={isLightTheme ? icViewLight : icView} alt="view_ic" />
            </TableRow>
          );
        })}
      </div>
    </StyledDataTable>
  );
};

const StyledDataTable = styled(DataTable)`
  .muted.fz-12 {
    font-size: 12px;
    font-weight: 700 !important;
  }
  .body .body-row {
    background: #1a1d26;
    padding: 10px 16px;
    .position-head img {
      width: 32px;
      height: 32px;
    }
  }
  @media screen and (max-width: 1023px) {
    .body {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 24px;
      &.body-single-row {
        grid-template-columns: 1fr;
      }
      .body-row {
        border-radius: 16px;
        background: #12141a;
        padding: 0 0 16px 0;
        .col-body {
          padding: 0 24px;
          display: flex;
          align-items: center;
          gap: 4px;
        }
        .link-underline {
          display: none;
        }
      }
    }
  }
  @media screen and (max-width: 700px) {
    .body {
      display: grid;
      grid-template-columns: 1fr;
      gap: 16px;
      .body-row {
        .col-body {
          padding: 0 16px;
        }
        .col-time {
          padding: 12px 16px !important;
        }
      }
    }
  }

  .col-body {
    font-weight: 500;
  }
`;

export default TradeHistories;
