import { useCallback, useEffect, useState } from "react";

import { useWeb3React } from "@web3-react/core";
import { max } from "lodash";
import { Bank } from "hooks/vault/types";
import useVaultInfo from "hooks/vault/useVaultInfo";
import { BankVaultsNew, listTokens } from "config/vaults";
import multicall from "domain/multicall";
import { getProvider } from "lib/rpc";
import { useChainIdWithDefault } from "lib/chains";
import { getContract } from "config/contracts";
import { Contract } from "ethers";

import Multicall from "abis/Multicall.json";
import VaultsCRO from "abis/VaultsCRO.json";
import VaultAuto from "abis/VaultAuto.json";
import { formatEther, formatUnits } from "ethers/lib/utils";
import { useVaultContext } from "pages/Vault1/VaultProvider";

interface BankInfoMap {
  [key: string]: any;
}

const useAllStakedValueVault = (banks: Bank[]) => {
  const { account } = useWeb3React();
  const chainId = useChainIdWithDefault();
  const { vaultInfo } = useVaultContext();

  const [stakedValues, setValues] = useState({});

  const fetchTotalLockedValue = useCallback(async () => {
    const provider = getProvider(undefined, chainId);

    const multicallAddress = getContract(chainId, "Multicall");

    let stakedData: any[] = [];
    if (!vaultInfo || vaultInfo?.length < 1) return;
    if (account) {
      const calls = [] as any;
      let index = 0;
      banks.forEach((bank) => {
        let callsData: any[] = [];
        let stakedWantTokens = {
          // address: currentConfig.contractAddresses?.[bank.rewardPoolName],
          address: BankVaultsNew.find((x) => x.chainId === bank.chainId)?.address,
          name: "stakedWantTokens",
          params: [bank.pid, account],
        };
        let userInfo = {
          // address: currentConfig.contractAddresses?.[bank.rewardPoolName],
          address: BankVaultsNew.find((x) => x.chainId === bank.chainId)?.address,
          name: "userInfo",
          params: [bank.pid, account],
        };
        let pending = {
          address: BankVaultsNew.find((x) => x.chainId === bank.chainId)?.address,
          name: "pendingReward",
          params: [bank.pid, bank.rewardId, account],
        };
        callsData.push(stakedWantTokens);
        callsData.push(userInfo);
        if (bank.chainId === 25 && bank.rewardPoolName !== "BankVaultCandidate") callsData.push(pending);
        else callsData.push(stakedWantTokens);
        var find = calls.findIndex((x) => x.chainId === bank.chainId);
        if (find !== -1) {
          calls[find].call.push(stakedWantTokens);
          calls[find].call.push(userInfo);
          if (bank.chainId === 25 && bank.rewardPoolName !== "BankVaultCandidate") calls[find].call.push(pending);
          else calls[find].call.push(stakedWantTokens);
          calls[find].indexes.push(index);
        } else {
          calls.push({
            chainId: bank.chainId, // bank chain id
            call: callsData,
            indexes: [index], // index of bank in vault.json
          });
        }
        index++;
      });

      let statkeDataChain: any[] = [];
      for (let i = 0; i < calls.length; i++) {
        const MultiCallContract = new Contract(multicallAddress, Multicall.abi, provider);
        let result = await multicall(
          MultiCallContract,
          calls[i].chainId === 146 ? VaultsCRO.abi : VaultAuto.abi,
          calls[i].call
        ).catch(() => []);
        statkeDataChain.push(result);
      }

      for (let i = 0; i < banks.length; i++) {
        let findChainIndex = calls.findIndex((x) => x.chainId === banks[i].chainId);
        if (findChainIndex !== -1) {
          let findIndex = calls[findChainIndex].indexes.findIndex((x) => x === i);
          if (findIndex !== -1) {
            stakedData.push(statkeDataChain[findChainIndex][getValueStakeData(findIndex)[0]]);
            stakedData.push(statkeDataChain[findChainIndex][getValueStakeData(findIndex)[1]]);
            stakedData.push(statkeDataChain[findChainIndex][getValueStakeData(findIndex)[2]]);
          }
        }
      }
    }

    const result: BankInfoMap = {};

    try {
      let info: BankInfoMap = {};
      vaultInfo.forEach((item: any) => {
        info[item.name] = item;
      });
      banks.forEach((item, idx) => {
        const decimals = listTokens[item?.depositTokenName] ? listTokens[item?.depositTokenName][1] || 18 : 18;

        const i = idx * 3;

        const stakedInfo = stakedData[i] ? stakedData[i] : "0";
        // const earningInfo = "0";
        const earningInfo = stakedData[i + 2] ? stakedData[i + 2] : "0";
        //   ? stakedData[i + 1][0].toString()
        //   : "0";
        const depositedInfo = stakedData[i + 1] ? max([0, stakedData[i + 1].totalDeposit]) : "0";
        const withdrawInfo = stakedData[i + 1] ? stakedData[i + 1].totalWithdraw : "0";

        const unfrozenDepositInfo = "0"; //stakedData[i+2] ?  stakedData[i+2][0].toString() : '0';
        const rewardPerSecond = "0"; //stakedData[i + 2] ? stakedData[i + 2][0].toString() : '0';
        // const pendingHarvest = allPendingHarvest[idx] || BigNumber.from(0);

        result[item.contract] = {
          ...info[item.contract],
          staked: stakedInfo ? +formatUnits(stakedInfo.toString() || "0", decimals) : 0,
          totalDeposited: depositedInfo ? +formatUnits(depositedInfo.toString() || "0", decimals) : 0,
          totalWithdraw: withdrawInfo ? +formatUnits(withdrawInfo.toString() || "0", decimals) : 0,
          earning: earningInfo ? +formatEther(earningInfo[0]) : 0,
          unfrozenTime: unfrozenDepositInfo,
          rewardPerSecond: rewardPerSecond,
        };
      });
    } catch (e) {
      console.log(e);
    }

    setValues(result);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account, banks.length, vaultInfo?.length, chainId]);

  useEffect(() => {
    if (banks && banks.length) {
      fetchTotalLockedValue().catch((err) => console.error(err.stack));

      const refreshBalance = setInterval(fetchTotalLockedValue, 30000);
      return () => clearInterval(refreshBalance);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account, banks.length, vaultInfo?.length]);

  return stakedValues;
};
const getValueStakeData = (indexMap: number) => {
  let start = 3 * indexMap || 0;
  let end = start + 1;
  let pending = start + 2;
  return [start, end, pending];
};
export default useAllStakedValueVault;
