import GSNAKETokenIcon from "img/icons/GSNAKE.png";
import SNAKETokenIcon from "img/icons/SNAKE.png";
import STokenIcon from "img/icons/S.png";
import USDCTokenIcon from "img/ic_usdc.e_24.svg";
import NaviIcon from "img/navi.svg";
import NlpIcon from "img/nlp.svg";
import COLORS from "img/icons/COLORS.svg";
const NOT_ALLOWED_KEYS = ["-", "e", "+"];

type GetButtonTextParams = {
  title: string;
  inprocess?: string | boolean;
  error?: string;
};

export function getButtonText(options: GetButtonTextParams) {
  const { inprocess, error, title } = options;
  if (error) return error || "Error";
  if (inprocess) return inprocess || "Loading...";
  return title;
}

export function preventSpecialCharacters(e: React.KeyboardEvent<HTMLInputElement>) {
  if (NOT_ALLOWED_KEYS.includes(e.key)) e.preventDefault();
}

export function isIphoneDevice() {
  return ["iPhone"].includes(navigator.platform);
}
export function secondsToDhms(lockTime: number, isShort = false) {
  lockTime = Number(lockTime);

  var d = Math.floor(lockTime / (3600 * 24));
  var h = Math.floor((lockTime % (3600 * 24)) / 3600);
  var m = Math.floor((lockTime % 3600) / 60);
  var s = Math.floor(lockTime % 60);

  var dDisplay = d > 0 ? d + (!isShort ? (d === 1 ? " Day " : " Days") : "d") : "";
  var hDisplay = h > 0 ? h + (!isShort ? (h === 1 ? " Hour " : " Hours ") : "h") : "";
  var mDisplay = m > 0 ? m + (!isShort ? (m === 1 ? " Minute " : " Minutes ") : "m") : "";
  var sDisplay = s > 0 ? s + (!isShort ? (s === 1 ? " Second" : " Seconds") : "s") : "";

  return (
    dDisplay +
    (hDisplay !== "" ? " " + hDisplay : hDisplay) +
    (mDisplay !== "" ? " " + mDisplay : mDisplay) +
    (sDisplay !== "" ? " " + sDisplay : sDisplay)
  );
}
export const iconsToken: any = {
  fS: "https://www.fingerofgod.app/static/media/Token_fS.b1bbcea335ed2d24e7a307d90e4428f8.svg",
  FOG: "https://www.fingerofgod.app/static/media/Token_FOG.5d03068188d32261be730f92f4df6bbe.svg",
  GSNAKE: GSNAKETokenIcon,
  SNAKE: SNAKETokenIcon,
  BLUE: "https://www.colors.finance/static/media/Token_BLUE.2fc9a1266e3184ca3dcdb7ce9b549c8c.svg",
  GREEN: "https://www.colors.finance/static/media/Token_GREEN.7eea83998c50cc0c5273373669bfa41a.svg",
  RED: "https://www.colors.finance/static/media/Token_RED.5d55dbecd9be517747cd573c36d04e29.svg",
  WS: STokenIcon,
  S: STokenIcon,
  "WS-LP": STokenIcon,
  FIVE: "https://dd.dexscreener.com/ds-data/tokens/sonic/0xb0695ce12c56aae40894235e2d1888d0b62dd110.png?size=lg&key=42429c",
  FRTN: "https://cdn-prod.ebisusbay.com/files/dex/images/tokens/frtn.webp",
  COLORS,
  SNS: "https://dd.dexscreener.com/ds-data/tokens/sonic/0x7b0a41f0c17474e41a0c36c0bf33b9aed06ee9f5.png?size=lg&key=25e20c",
  LUMOS:
    "https://dd.dexscreener.com/ds-data/tokens/sonic/0xc0da8629599b364eb0a8921a477fb80440a68bd4.png?size=lg&key=a2e7d1",
  "EURC.e": "https://sonicscan.org/token/images/eurc_32.png",
  "EURC.E": "https://sonicscan.org/token/images/eurc_32.png",
  "USDC.e": USDCTokenIcon,
  "USDC.E": USDCTokenIcon,
  NAVI: NaviIcon,
  NLP: NlpIcon,
};
