import React, { useEffect, useState, useRef, useCallback, useMemo, useContext } from "react";
import cx from "classnames";
import styled from "styled-components";
import SettingsManager from "abis/SettingsManager.json";
import { createChart } from "krasulya-lightweight-charts";
import { getTokenSymbolFromString } from "domain/tokens";

import { USD_DECIMALS, SWAP, INCREASE, CHART_PERIODS } from "lib/legacy";
import { useChartPrices } from "domain/pricesV2";
import ChartTokenSelector from "./ChartTokenSelector";
import { useLocalStorageSerializeKey } from "lib/localStorage";
import { getTokenInfo } from "domain/tokens/utils";
import { usePrevious } from "lib/usePrevious";
import { formatAmount, limitDecimals, numberWithCommas, parseValue } from "lib/numbers";
import { getToken, getTokens } from "config/tokens";
import { formatDateTime } from "lib/dates";
import { Trans } from "@lingui/macro";
import { availableNetworksForChart } from "components/TVChartContainer/constants";
import TVChartContainer from "components/TVChartContainer/TVChartContainer";
import { useMediaQuery } from "react-responsive";
import { formatNumber } from "lib/numbers";
// import Tooltip from "components/Tooltip/Tooltip";
import { Tooltip } from "antd";
import { useWeb3React } from "@web3-react/core";
import { getContract } from "config/contracts";
import { BigNumber } from "ethers";
import { useMedia } from "react-use";
import { StatsContext } from "contexts/StatsProvider";
import TVChartContainerV2 from "components/TVChartContainer/TVChartContainerV2";
import icStar from "img/trade/star.svg";
import useMarketAssetsV1 from "hooks/useMarketAssetsV1";
import { useThemeContext } from "contexts/ThemeProvider";
import infoIcon from "img/icons/ic-info.svg";
import ic_ArrowDown from "img/trade/arrow-red.svg";
import ic_ArrowUp from "img/trade/arrow-up-green.svg";
import { contractFetcher } from "lib/contracts";
import useSWR from "swr";
const PRICE_LINE_TEXT_WIDTH = 15;

const timezoneOffset = -new Date().getTimezoneOffset() * 60;

export function getChartToken(swapOption, fromToken, toToken, chainId) {
  if (!fromToken || !toToken) {
    return;
  }

  if (swapOption !== SWAP) {
    return toToken;
  }

  if (fromToken.isUsdg && toToken.isUsdg) {
    return getTokens(chainId).find((t) => t.isStable);
  }
  if (fromToken.isUsdg) {
    return toToken;
  }
  if (toToken.isUsdg) {
    return fromToken;
  }

  if (fromToken.isStable && toToken.isStable) {
    return toToken;
  }
  if (fromToken.isStable) {
    return toToken;
  }
  if (toToken.isStable) {
    return fromToken;
  }

  return toToken;
}

const DEFAULT_PERIOD = "4h";

const getSeriesOptions = () => ({
  // https://github.com/tradingview/lightweight-charts/blob/master/docs/area-series.md
  lineColor: "#5472cc",
  topColor: "rgba(49, 69, 131, 0.4)",
  bottomColor: "rgba(42, 64, 103, 0.0)",
  lineWidth: 2,
  priceLineColor: "#3a3e5e",
  downColor: "#fa3c58",
  wickDownColor: "#fa3c58",
  upColor: "#0ecc83",
  wickUpColor: "#0ecc83",
  borderVisible: false,
});

const getChartOptions = (width, height) => ({
  width,
  height,
  layout: {
    backgroundColor: "rgba(255, 255, 255, 0)",
    textColor: "#ccc",
    fontFamily: "Relative",
  },
  localization: {
    // https://github.com/tradingview/lightweight-charts/blob/master/docs/customization.md#time-format
    timeFormatter: (businessDayOrTimestamp) => {
      return formatDateTime(businessDayOrTimestamp - timezoneOffset);
    },
  },
  grid: {
    vertLines: {
      visible: true,
      color: "rgba(35, 38, 59, 1)",
      style: 2,
    },
    horzLines: {
      visible: true,
      color: "rgba(35, 38, 59, 1)",
      style: 2,
    },
  },
  // https://github.com/tradingview/lightweight-charts/blob/master/docs/time-scale.md#time-scale
  timeScale: {
    rightOffset: 5,
    borderVisible: false,
    barSpacing: 5,
    timeVisible: true,
    fixLeftEdge: true,
  },
  // https://github.com/tradingview/lightweight-charts/blob/master/docs/customization.md#price-axis
  priceScale: {
    borderVisible: false,
  },
  crosshair: {
    horzLine: {
      color: "#aaa",
    },
    vertLine: {
      color: "#aaa",
    },
    mode: 0,
  },
});

export default function ExchangeTVChart(props) {
  const {
    isProChart,
    chartToken,
    setChartToken,
    swapOption,
    fromTokenAddress,
    toTokenAddress,
    infoTokens,
    chainId,
    positions,
    savedShouldShowPositionLines,
    orders,
    setToTokenAddress,
    marketPrices,
    marketAsssetList,
    defaultChartToken,
    setDefaultChartToken,
    marketAsssets,
    setTradeVersionState,
    onSelectSymbolToken,
  } = props;
  const { library } = useWeb3React();
  const theme = useThemeContext();
  const [currentChart, setCurrentChart] = useState();
  const [currentSeries, setCurrentSeries] = useState();
  const [tradeVersion, setTradeVersion] = useLocalStorageSerializeKey(["trade-version"], "V2");
  const VersionTab = ["V2", "V1"];
  let [period, setPeriod] = useLocalStorageSerializeKey([chainId, "Chart-period"], DEFAULT_PERIOD);
  if (!(period in CHART_PERIODS)) {
    period = DEFAULT_PERIOD;
  }
  const assetListV1 = useMarketAssetsV1();
  const mergeList = useMemo(() => {
    const list = [];
    if (!assetListV1 || !marketAsssetList) return [];
    else {
      var notV2 = assetListV1.filter((x) => !marketAsssetList.find((y) => y.symbol === x.symbol));
      for (let i = 0; i < notV2.length; i++) {
        list.push({
          ...notV2[i],
          isV1: true,
          isV2: false,
        });
      }
      for (let i = 0; i < marketAsssetList.length; i++) {
        var find = assetListV1.find((x) => x.symbol === marketAsssetList[i].symbol);
        list.push({
          ...marketAsssetList[i],
          isV1: !!find,
          isV2: true,
        });
      }
    }
    return list;
  }, [assetListV1, marketAsssetList]);
  // console.log("??????", mergeList);
  const currentMarketPrices = marketAsssetList?.find((x) => x.symbol === defaultChartToken);
  const currentAveragePrice = currentMarketPrices
    ? BigNumber.from(currentMarketPrices?.askPrice || 0)
        .div(parseValue(1, 12))
        .add(BigNumber.from(currentMarketPrices?.bidPrice || 0).div(parseValue(1, 12)))
        .div(2)
    : null;
  // console.log("????currentMarketPrices", {defaultChartToken, marketAsssetList, currentMarketPrices});
  const [hoveredCandlestick, setHoveredCandlestick] = useState();

  // useEffect(() => {
  //   setChartToken(getTokenSymbolFromString(defaultChartToken));
  // }, [chainId, defaultChartToken, setChartToken]);

  const symbol = chartToken ? chartToken : undefined;
  const marketName = chartToken ? symbol + "_USD" : undefined;
  const previousMarketName = usePrevious(marketName);
  // const { volume24H } = useContext(StatsContext);
  // console.log("????", volume24H);
  // console.log("/????", { marketAsssets, defaultChartToken, orders });

  const currentOrders = useMemo(() => {
    if (swapOption === SWAP || !chartToken) {
      return [];
    }

    return (
      orders
        .filter((o) => marketAsssets?.[o.tokenId]?.symbol === defaultChartToken)
        // .filter((order) => {
        //   // if (order.type === SWAP) {
        //   //   // we can't show non-stable to non-stable swap orders with existing charts
        //   //   // so to avoid users confusion we'll show only long/short orders
        //   //   return false;
        //   // }

        //   // const indexToken = getToken(chainId, order.indexToken);
        //   return (
        //     order.indexToken === chartToken.address || (chartToken.isNative && indexToken.isWrapped)
        //   );
        // })
        .map((order) => {
          const color = "#3a3e5e";
          if (order.type === "TRIGGER") {
            const title = `#${order.posId} Trig. ${marketAsssets?.[order.tokenId]?.tokenSymbol}`;

            return {
              price: parseFloat(
                formatAmount(order.trigger.price, USD_DECIMALS, marketAsssets?.[order.tokenId]?.decimals || 2)
              ),
              color,
              title: title.padEnd(PRICE_LINE_TEXT_WIDTH, " "),
            };
          } else if (order.type === "PENDDING") {
            if (order.positionType === "Limit Order") {
              const title = `#${order.posId} Limit ${marketAsssets?.[order.tokenId]?.tokenSymbol}`;
              return {
                price: parseFloat(
                  formatAmount(order.lmtPrice, USD_DECIMALS, marketAsssets?.[order.tokenId]?.decimals || 2)
                ),
                color,
                title: title.padEnd(PRICE_LINE_TEXT_WIDTH, " "),
              };
            }
            const title = `#${order.posId} Stop ${marketAsssets?.[order.tokenId]?.tokenSymbol}`;
            return {
              price: parseFloat(
                formatAmount(order.stpPrice, USD_DECIMALS, marketAsssets?.[order.tokenId]?.decimals || 2)
              ),
              color,
              title: title.padEnd(PRICE_LINE_TEXT_WIDTH, " "),
            };
          } else {
            const title = `#${order.posId} Trail. ${marketAsssets?.[order.tokenId]?.tokenSymbol}`;
            const drawPrice = formatAmount(
              order.stopPrice || 0,
              30,
              marketAsssets?.[order.tokenId]?.decimals || 2,
              false
            );
            return {
              price: parseFloat(drawPrice),
              color,
              title: title.padEnd(PRICE_LINE_TEXT_WIDTH, " "),
            };
          }
        })
    );
  }, [swapOption, chartToken, orders, marketAsssets]);
  // console.log("????", defaultChartToken);

  const currentPositions = useMemo(() => {
    if (!positions || !chartToken) {
      return [];
    }

    return positions
      .filter((p) => marketAsssets?.[p.tokenId]?.symbol === defaultChartToken)
      .map((position) => {
        const longOrShortText = position.isLong ? `Long` : `Short`;
        if (position.positionType !== "Trailing Stop")
          return {
            open: {
              price: parseFloat(position.averagePrice),
              title: `#${position.posId} Open ${marketAsssets?.[position.tokenId]?.tokenSymbol} ${longOrShortText}`,
            },
            liquidation: {
              price: parseFloat(position.liquidationPrice),
              title: `#${position.posId} Liq. ${marketAsssets?.[position.tokenId]?.tokenSymbol} ${longOrShortText}`,
            },
          };
        else {
          const markPrice = currentAveragePrice;
          const type = position.stepType;
          const stepAmount = position.stepAmount;
          const deltaPrice = markPrice
            ? type == 1
              ? markPrice.mul(parseValue(stepAmount, 18)).div(parseValue(100, 18))
              : parseValue(stepAmount, 18)
            : BigNumber.from(0);
          const trailingPrice = markPrice
            ? position.isLong
              ? markPrice.sub(deltaPrice || 0)
              : markPrice.add(deltaPrice || 0)
            : BigNumber.from(0);
          const drawPrice = formatAmount(trailingPrice, 18, 2, false);

          return {
            open: {
              price: parseFloat(position.averagePrice),
              title: `#${position.posId} Open ${marketAsssets?.[position.tokenId]?.tokenSymbol} ${longOrShortText}`,
            },
            liquidation: {
              price: parseFloat(position.liquidationPrice),
              title: `#${position.posId} Liq. ${marketAsssets?.[position.tokenId]?.tokenSymbol} ${longOrShortText}`,
            },
            // trailingStop: {
            //   price: parseFloat(drawPrice),
            //   title: `TStop. ${marketAsssets?.[position.tokenId]?.tokenSymbol} ${longOrShortText}`,
            // },
          };
        }
      });
  }, [chartToken, currentAveragePrice, marketAsssets, positions]);

  const chartLines = useMemo(() => {
    const lines = [];
    if (currentOrders.length > 0) {
      lines.push(...currentOrders);
    }

    if (currentPositions.length > 0) {
      currentPositions.forEach((position) => {
        lines.push(position.open);
        lines.push(position.liquidation);
        if (position.trailingStop) lines.push(position.trailingStop);
      });
    }

    return lines;
  }, [currentOrders, currentPositions]);

  const ref = useRef(null);
  const chartRef = useRef(null);

  // console.log(">>>>>>>currentAveragePrice", chainId, getTokenSymbolFromString(defaultChartToken), false, period, currentAveragePrice);
  const [priceData, updatePriceData] = useChartPrices(
    chainId,
    getTokenSymbolFromString(defaultChartToken),
    false,
    period,
    currentAveragePrice
  );

  const isMobile = useMedia("(max-width: 767px)");
  const isPad = useMedia("(max-width: 1250px)");
  // const { data: borrowRateLong } = useSWR(
  //   chartToken?.id && [`Exchange:borrowRateLong:${active}`, chainId, SettingsManagerAddress, "getBorrowRate"],
  //   {
  //     fetcher: contractFetcher(library, SettingsManager, [chartToken?.id, true]),
  //   }
  // );
  // const { data: borrowRateShort } = useSWR(
  //   chartToken?.id && [`Exchange:borrowRateShort:${active}`, chainId, SettingsManagerAddress, "getBorrowRate"],
  //   {
  //     fetcher: contractFetcher(library, SettingsManager, [chartToken?.id, false]),
  //   }
  // );
  // const { data: fundingRate } = useSWR(
  //   chartToken?.id && [`Exchange:fundingRate:${active}`, chainId, SettingsManagerAddress, "getFundingRate"],
  //   {
  //     fetcher: contractFetcher(library, SettingsManager, [chartToken?.id]),
  //   }
  // );
  // console.log("?????", {borrowRateLong, borrowRateShort, fundingRate});
  const settingsManagerAddress = getContract(chainId, "SettingsManager");
  const { data: openInterestPerAssetPerSideLong } = useSWR(
    currentMarketPrices?.id && [
      "ExChange:openInterestPerAssetPerSideLong",
      chainId,
      settingsManagerAddress,
      "openInterestPerAssetPerSide",
      currentMarketPrices?.id,
      true,
    ],
    {
      fetcher: contractFetcher(library, SettingsManager),
    }
  );
  const { data: maxOpenInterestPerAssetLong } = useSWR(
    currentMarketPrices?.id && [
      "ExChange:maxOpenInterestPerAssetLong",
      chainId,
      settingsManagerAddress,
      "maxOpenInterestPerAssetPerSide",
      currentMarketPrices?.id,
      true,
    ],
    {
      fetcher: contractFetcher(library, SettingsManager),
    }
  );
  const { data: openInterestPerAssetPerSideShort } = useSWR(
    currentMarketPrices?.id && [
      "ExChange:openInterestPerAssetPerSideShort",
      chainId,
      settingsManagerAddress,
      "openInterestPerAssetPerSide",
      currentMarketPrices?.id,
      false,
    ],
    {
      fetcher: contractFetcher(library, SettingsManager),
    }
  );
  const { data: maxOpenInterestPerAssetShort } = useSWR(
    currentMarketPrices?.id && [
      "ExChange:maxOpenInterestPerAssetLong",
      chainId,
      settingsManagerAddress,
      "maxOpenInterestPerAssetPerSide",
      currentMarketPrices?.id,
      false,
    ],
    {
      fetcher: contractFetcher(library, SettingsManager),
    }
  );
  const [chartInited, setChartInited] = useState(false);
  useEffect(() => {
    if (marketName !== previousMarketName) {
      setChartInited(false);
    }
  }, [marketName, previousMarketName]);
  const onSelectSymbolTokenChart = (token) => {
    onSelectSymbolToken && onSelectSymbolToken(token);
  };
  const scaleChart = useCallback(() => {
    const from = Date.now() / 1000 - (7 * 24 * CHART_PERIODS[period]) / 2 + timezoneOffset;
    const to = Date.now() / 1000 + timezoneOffset;
    currentChart.timeScale().setVisibleRange({ from, to });
  }, [currentChart, period]);
  const isPadScreen = useMediaQuery({ query: "(max-width: 1110px)" });
  const onCrosshairMove = useCallback(
    (evt) => {
      if (!evt.time) {
        setHoveredCandlestick(null);
        return;
      }

      for (const point of evt.seriesPrices.values()) {
        setHoveredCandlestick((hoveredCandlestick) => {
          if (hoveredCandlestick && hoveredCandlestick.time === evt.time) {
            // rerender optimisations
            return hoveredCandlestick;
          }
          return {
            time: evt.time,
            ...point,
          };
        });
        break;
      }
    },
    [setHoveredCandlestick]
  );

  useEffect(() => {
    if (isProChart || !chartRef?.current || !ref.current || !priceData || !priceData.length || currentChart) {
      return;
    }

    const chart = createChart(
      chartRef.current,
      getChartOptions(chartRef.current.offsetWidth, chartRef.current.offsetHeight)
    );

    chart.subscribeCrosshairMove(onCrosshairMove);

    const series = chart.addCandlestickSeries(getSeriesOptions());
    series.applyOptions({
      priceFormat: {
        type: "price",
        precision: 2,
        minMove: 0.01,
      },
    });
    setCurrentChart(chart);
    setCurrentSeries(series);
  }, [isProChart, ref, priceData, currentChart, onCrosshairMove]);

  useEffect(() => {
    const interval = setInterval(() => {
      updatePriceData(undefined, true);
    }, 60 * 1000);
    return () => clearInterval(interval);
  }, [updatePriceData]);

  useEffect(() => {
    if (!currentChart) {
      return;
    }
    const resizeChart = () => {
      currentChart.resize(chartRef.current.offsetWidth, chartRef.current.offsetHeight);
    };
    window.addEventListener("resize", resizeChart);
    return () => window.removeEventListener("resize", resizeChart);
  }, [currentChart]);

  useEffect(() => {
    if (currentSeries && priceData && priceData.length) {
      currentSeries.setData(priceData);

      if (!chartInited) {
        scaleChart();
        setChartInited(true);
      }
    }
  }, [priceData, currentSeries, chartInited, scaleChart]);

  useEffect(() => {
    const lines = [];
    if (currentSeries && savedShouldShowPositionLines) {
      if (currentOrders && currentOrders.length > 0) {
        currentOrders.forEach((order) => {
          const indexToken = getToken(chainId, order.indexToken);
          let tokenSymbol;
          if (indexToken && indexToken.symbol) {
            tokenSymbol = indexToken.isWrapped ? indexToken.baseSymbol : indexToken.symbol;
          }
          const title = `${order.type === INCREASE ? `Inc.` : `Dec.`} ${tokenSymbol} ${
            order.isLong ? `Long` : `Short`
          }`;
          const color = "#3a3e5e";
          lines.push(
            currentSeries.createPriceLine({
              price: parseFloat(formatAmount(order.triggerPrice, USD_DECIMALS, 3)),
              color,
              title: title.padEnd(PRICE_LINE_TEXT_WIDTH, " "),
            })
          );
        });
      }
      if (positions && positions.length > 0) {
        const color = "#3a3e5e";
        positions.forEach((position) => {
          const longOrShortText = position.isLong ? `Long` : `Short`;
          lines.push(
            currentSeries.createPriceLine({
              price: parseFloat(position.averagePrice),
              color,
              title: `#${position.posId} Open ${
                marketAsssets?.[position.tokenId]?.tokenSymbol
              } ${longOrShortText}`.padEnd(PRICE_LINE_TEXT_WIDTH, " "),
            })
          );

          // const liquidationPrice = getLiquidationPrice(position);
          lines.push(
            currentSeries.createPriceLine({
              price: parseFloat(position.liquidationPrice),
              color,
              title: `#${position.posId} Liq. ${
                marketAsssets?.[position.tokenId]?.tokenSymbol
              } ${longOrShortText}`.padEnd(PRICE_LINE_TEXT_WIDTH, " "),
            })
          );

          // lines.push(
          //   currentSeries.createPriceLine({
          //     price: parseFloat(position.liquidationPrice),
          //     color,
          //     title: `TStop. ${
          //       marketAsssets?.[position.tokenId]?.tokenSymbol
          //     } ${longOrShortText}`.padEnd(PRICE_LINE_TEXT_WIDTH, " "),
          //   }),
          // );
        });
      }
    }
    return () => {
      lines.forEach((line) => currentSeries.removePriceLine(line));
    };
  }, [currentOrders, positions, currentSeries, chainId, savedShouldShowPositionLines, marketAsssets]);

  useEffect(() => {
    const hash = window.location.hash;
    const queryParams = new URLSearchParams(hash.split("?")[1]);
    const symbolParam = queryParams.get("symbol");

    if (symbolParam) {
      setDefaultChartToken(symbolParam);
      queryParams.delete("symbol");
      const newHash = `#/trade?${queryParams.toString()}`;
      window.history.replaceState(null, "", newHash);
    }
  }, []);

  let high;
  let low;
  let deltaPrice;
  let delta;
  let deltaPercentage;
  let deltaPercentageStr;
  const now = parseInt(Date.now() / 1000);
  const timeThreshold = now - 24 * 60 * 60;

  if (priceData) {
    for (let i = priceData.length - 1; i > 0; i--) {
      const price = priceData[i];
      if (price.time < timeThreshold) {
        break;
      }
      if (!low) {
        low = price.low;
      }
      if (!high) {
        high = price.high;
      }

      if (price.high > high) {
        high = price.high;
      }
      if (price.low < low) {
        low = price.low;
      }

      deltaPrice = price.open;
    }
  }

  if (deltaPrice && currentAveragePrice) {
    const average = parseFloat(formatAmount(currentAveragePrice, USD_DECIMALS, 2));
    delta = average - deltaPrice;
    deltaPercentage = (delta * 100) / average;
    if (deltaPercentage > 0) {
      deltaPercentageStr = `+${deltaPercentage.toFixed(2)}%`;
    } else {
      deltaPercentageStr = `${deltaPercentage.toFixed(2)}%`;
    }
    if (deltaPercentage === 0) {
      deltaPercentageStr = "0.00";
    }
  }

  if (!chartToken) {
    return null;
  }

  const onSelectToken = (token) => {
    // const tmp = getTokenInfo(infoTokens, token.address);
    // setChartToken(tmp);
    // setToTokenAddress(swapOption, token.address);
  };

  return (
    <div
      style={{
        height: isProChart ? 70 : "49.6rem",
      }}
      className="ExchangeChart tv"
      ref={ref}
    >
      <div className="ExchangeChart-top App-box App-box-border">
        <div className="ExchangeChart-top-inner">
          <div className="ExchangeChart-title-container">
            <div className="ExchangeChart-title">
              <ChartTokenSelector
                chainId={chainId}
                selectedToken={chartToken}
                swapOption={swapOption}
                infoTokens={infoTokens}
                onSelectToken={onSelectToken}
                setChartToken={setChartToken}
                className="chart-token-selector"
                marketAsssetList={mergeList}
                defaultChartToken={defaultChartToken}
                setDefaultChartToken={setDefaultChartToken}
                theme={theme}
                onSelectSymbolToken={onSelectSymbolTokenChart}
              />
            </div>
          </div>
          <div className="exchange-chart-info-container exchange-chart-info-container-final">
            <div className="info-main-price">
              <div className="ExchangeChart-additional-info">
                <div className="last-price">
                  {!currentMarketPrices && <div className="skeleton-box" style={{ width: "60px" }} />}
                  {currentMarketPrices?.askPrice &&
                    `$${formatAmount(currentMarketPrices.askPrice, USD_DECIMALS, currentMarketPrices?.decimals, true)}`}
                </div>
                <div
                  style={{
                    fontWeight: 500,
                  }}
                  className={cx("changed-24h", {
                    positive: currentMarketPrices?.price24HChanged > 0,
                    negative: currentMarketPrices?.price24HChanged < 0,
                  })}
                >
                  {currentMarketPrices?.price24HChanged == 0 ? null : (
                    <img src={currentMarketPrices?.price24HChanged < 0 ? ic_ArrowDown : ic_ArrowUp} alt="up arrow" />
                  )}
                  {currentMarketPrices ? (
                    limitDecimals(currentMarketPrices.price24HChanged || 0, 2)
                      ?.replace("-", "")
                      .replace("+", "") + "%"
                  ) : (
                    <div className="skeleton-box" style={{ width: "60px" }} />
                  )}
                </div>
              </div>
            </div>

            <div className="ExchangeChart-additional-info">
              <div className="ExchangeChart-info-label">
                <span>24h High</span>
              </div>
              <div
                style={{
                  fontWeight: 700,
                }}
              >
                {!high && <div className="skeleton-box" style={{ width: "60px" }} />}
                {high && "$" + numberWithCommas(high.toFixed(currentMarketPrices?.decimals))}
              </div>
            </div>
            <div className="ExchangeChart-additional-info">
              <div className="ExchangeChart-info-label">
                <span>24h Low</span>
              </div>
              <div
                style={{
                  fontWeight: 700,
                }}
              >
                {!low && <div className="skeleton-box" style={{ width: "60px" }} />}
                {low && "$" + numberWithCommas(low.toFixed(currentMarketPrices?.decimals))}
              </div>
            </div>

            <>
              <div className="ExchangeChart-additional-info">
                <div className="ExchangeChart-info-label">
                  <Tooltip
                    placement={isPad ? "topRight" : "top"}
                    autoAdjustOverflow={isPad ? true : false}
                    overlayClassName={` ${theme?.isLightTheme ? "custom-popup-antd-tooltip-light" : ""}`}
                    className="custom-antd-tooltip"
                    title={
                      <span>
                        Total notional value on {getTokenSymbolFromString(defaultChartToken)} positions / max open
                        interest
                      </span>
                    }
                  >
                    Open Interest <img src={infoIcon} alt="info icon" />
                  </Tooltip>
                </div>
                <StyledTooltip>
                  <>
                    {!currentMarketPrices ||
                    !openInterestPerAssetPerSideLong ||
                    !maxOpenInterestPerAssetLong ||
                    !openInterestPerAssetPerSideShort ||
                    !maxOpenInterestPerAssetShort ? (
                      <div className="skeleton-box" style={{ width: "60px" }} />
                    ) : (
                      <Flex>
                        <div>
                          <span style={{ color: "#828899", marginRight: "4px" }} className="orr">
                            (L)
                          </span>
                          <span>
                            {formatNumber(formatAmount(openInterestPerAssetPerSideLong, 30, 6, false), 0)}/
                            {formatNumber(formatAmount(maxOpenInterestPerAssetLong, 30, 6, false), 0)}
                          </span>
                        </div>
                        <div>
                          <span style={{ color: "#828899", marginRight: "4px" }} className="orr">
                            (S)
                          </span>
                          <span>
                            {formatNumber(formatAmount(openInterestPerAssetPerSideShort, 30, 6, false), 0)}/
                            {formatNumber(formatAmount(maxOpenInterestPerAssetShort, 30, 6, false), 0)}
                          </span>
                        </div>
                      </Flex>
                    )}
                  </>
                </StyledTooltip>
              </div>

              <div className="ExchangeChart-additional-info">
                <div className="ExchangeChart-info-label">
                  <Tooltip
                    placement={isPad ? "topRight" : "top"}
                    autoAdjustOverflow={isPad ? true : false}
                    overlayClassName={`custom-popup-antd-tooltip ${
                      theme?.isLightTheme ? "custom-popup-antd-tooltip-light" : ""
                    }`}
                    className="custom-antd-tooltip"
                    title={<div>Borrow rate is determined per asset by using each asset's historical volatility</div>}
                  >
                    Borrow Rate <img src={infoIcon} alt="info icon" />
                  </Tooltip>
                </div>
                <StyledTooltip>
                  <>
                    {!currentMarketPrices?.borrowRates ? (
                      <div className="skeleton-box" style={{ width: "60px" }} />
                    ) : (
                      <Flex>
                        <div>
                          <span style={{ color: "#828899", marginRight: "4px" }} className="orr">
                            (L){" "}
                          </span>
                          <span>
                            {formatAmount(
                              currentMarketPrices?.borrowRates?.find((rate) => rate.isLong === true)?.value || 0,
                              4,
                              4,
                              true
                            )}
                            %
                          </span>
                        </div>
                        <div>
                          <span style={{ color: "#828899", marginRight: "4px" }} className="orr">
                            (S){" "}
                          </span>
                          <span>
                            {formatAmount(
                              currentMarketPrices?.borrowRates.find((rate) => rate.isLong === false)?.value || 0,
                              4,
                              4,
                              true
                            )}
                            %
                          </span>
                        </div>
                      </Flex>
                    )}
                  </>
                </StyledTooltip>
              </div>
              <div className="ExchangeChart-additional-info">
                <div className="ExchangeChart-info-label">
                  <Tooltip
                    placement={isPad ? "topRight" : "top"}
                    overlayClassName={`custom-popup-antd-tooltip ${
                      theme?.isLightTheme ? "custom-popup-antd-tooltip-light" : ""
                    }`}
                    autoAdjustOverflow={isPad ? true : false}
                    className={`custom-antd-tooltip`}
                    title={
                      currentMarketPrices?.fundingRate.includes("-")
                        ? "Negative value means that shorts are paying longs"
                        : "Positive value means that longs are paying shorts"
                    }
                  >
                    Funding Rate <img src={infoIcon} alt="info icon" />
                  </Tooltip>
                </div>
                <StyledTooltip>
                  <span
                    className={`${
                      !currentMarketPrices?.fundingRate.includes("-")
                        ? currentMarketPrices?.fundingRate == 0
                          ? ""
                          : "positive"
                        : "negative"
                    }`}
                  >
                    {!currentMarketPrices?.fundingRate ? (
                      <div className="skeleton-box" style={{ width: "60px" }} />
                    ) : (
                      <div>
                        {currentMarketPrices?.fundingRate == 0 ? null : (
                          <img
                            src={currentMarketPrices?.fundingRate.includes("-") ? ic_ArrowDown : ic_ArrowUp}
                            alt="up arrow"
                          />
                        )}{" "}
                        {`${formatAmount(currentMarketPrices?.fundingRate, 13, 4, true)
                          ?.replace("-", "")
                          ?.replace("+", "")}%/H`}
                      </div>
                    )}
                  </span>
                </StyledTooltip>
              </div>
              <div className="ExchangeChart-additional-info">
                <div className="ExchangeChart-info-label">
                  <Tooltip
                    placement={isPad ? "topRight" : "topRight"}
                    overlayClassName={`custom-popup-antd-tooltip ${
                      theme?.isLightTheme ? "custom-popup-antd-tooltip-light" : ""
                    }`}
                    autoAdjustOverflow={isPad ? true : false}
                    className={`custom-antd-tooltip`}
                    title={"The 24h volume represents each asset's total trading volume within the past 24 hours"}
                  >
                    Volume 24h <img src={infoIcon} alt="info icon" />
                  </Tooltip>
                </div>
                {currentMarketPrices?.volume24h || currentMarketPrices?.volume24h === 0 ? (
                  <div>
                    {currentMarketPrices?.volume24h || currentMarketPrices?.volume24h === 0
                      ? currentMarketPrices?.volume24h === 0
                        ? "$0"
                        : "$" + formatNumber(currentMarketPrices?.volume24h, 0)
                      : "$--"}
                  </div>
                ) : (
                  <div className="skeleton-box" style={{ width: "60px" }} />
                )}
              </div>
            </>
          </div>
        </div>
        {/* <div className="trade-version">
          <div className="trade-version-container">
            {VersionTab.map((item) => (
              <Link key={item} to={`${item === "V2" ? "/trade" : "/v1"}`}>
                <div className={`version-tab ${item === "V2" ? "version-tab-active" : ""}`}>
                  {item}
                  {item === "V2" && <img className="star" alt="" src={icStar} />}
                </div>
              </Link>
            ))}
          </div>
        </div> */}
      </div>

      {!isPadScreen && (
        <div className="ExchangeChart-bottom App-box App-box-border">
          {availableNetworksForChart.includes(chainId) && chartToken && chainId ? (
            <TVChartContainerV2
              chartLines={chartLines}
              savedShouldShowPositionLines={savedShouldShowPositionLines}
              // symbol={chartToken.symbol}
              symbol={getTokenSymbolFromString(defaultChartToken)}
              chainId={chainId}
              onSelectToken={onSelectToken}
              period={period}
              setPeriod={setPeriod}
              marketPrices={marketPrices}
              theme={theme}
            />
          ) : (
            <p className="ExchangeChart-error">Sorry, chart is not supported on this network yet.</p>
          )}
        </div>
      )}
    </div>
  );
}

const StyledTooltip = styled.div`
  div.Tooltip-popup:has(.customTooltip) {
    width: fit-content;
  }
`;

export const Flex = styled.div`
  display: flex;
  gap: 4px;
  /* flex-wrap: wrap; */
  div {
    display: flex;
    width: fit-content !important;
  }
  @media screen and (max-width: 1700px) {
    flex-direction: column;
    gap: 4px;
    align-items: flex-start;
    /* flex-wrap: wrap; */
  }
`;
