import { useWeb3React } from "@web3-react/core";
import SEO from "components/Common/SEO";
import Footer from "components/Footer/Footer";
import AddAffiliateCode, { AffiliateCodeForm } from "components/Referrals/AddAffiliateCode";
import AffiliatesStats from "components/Referrals/AffiliatesStats";
import JoinReferralCode, { ReferralCodeForm } from "components/Referrals/JoinReferralCode";
import TradersStats from "components/Referrals/TradersStats";
import {
  deserializeSampleStats,
  getReferralCodeTradeUrl,
  getTierIdDisplay,
  getUSDValue,
  isRecentReferralCodeNotExpired,
  tierDiscountInfo,
} from "components/Referrals/referralsHelper";
import { REFERRALS_SELECTED_TAB_KEY } from "config/localStorage";
import {
  registerReferralCode,
  useCodeOwner,
  useReferralsData,
  useReferrerTier,
  useUserReferralCode,
  useUserReferralCodeV2,
} from "domain/referrals";
import { BigNumber, ethers } from "ethers";
import { useChainId } from "lib/chains";
import { USD_DECIMALS, getPageTitle } from "lib/legacy";
import { useLocalStorageSerializeKey } from "lib/localStorage";
import { useMemo, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { useCopyToClipboard, useLocalStorage } from "react-use";
import "./Referrals.css";

import { IconCopy, IconEdit, ImageReferral } from "assets";
import Modal from "components/Modal/Modal";
import TabContainer from "components/Tabs";
import { SUPPORTED_V2_CHAINS } from "config/chains";
import { useThemeContext } from "contexts/ThemeProvider";
import useUserStats from "hooks/useUserStats";
import { helperToast } from "lib/helperToast";
import { bigNumberify, formatAmount, parseValue } from "lib/numbers";
import styled, { css } from "styled-components";
import { parseNumberOrDefault } from "utils/analyticsV3";
import MyRefCodes from "./MyRefCodes";
import PayoutTab from "./PayoutTab";
import "./Referrals.scss";

const AsTabList = [
  {
    label: "As Trader",
    key: "trader",
  },
  {
    label: "As Referrer",
    key: "referrer",
  },
];

const tableTabList = [
  {
    label: "Payout Activities",
    key: "payout",
  },
  {
    label: "My Referral Codes",
    key: "referral",
  },
];

const TRADERS = "Traders";
const AFFILIATES = "Affiliates";
const TAB_OPTIONS = [TRADERS, AFFILIATES];
const PAYOUT_TABS = ["Payout activities", "My referral codes"];
function Referrals({ connectWallet, setPendingTxns, pendingTxns }) {
  const { active, account: walletAccount, library } = useWeb3React();
  const { account: queryAccount } = useParams();
  let account;
  if (queryAccount && ethers.utils.isAddress(queryAccount)) {
    account = ethers.utils.getAddress(queryAccount);
  } else {
    account = walletAccount;
  }
  const theme = useThemeContext();
  const [, copyToClipboard] = useCopyToClipboard();
  const [currentPayoutTab, setCurrentPayoutTab] = useState(PAYOUT_TABS[0]);
  const { chainId } = useChainId();
  const [activeTab, setActiveTab] = useLocalStorage(REFERRALS_SELECTED_TAB_KEY, TRADERS);
  const { data: referralsData, loading } = useReferralsData(chainId, account);
  const [recentlyAddedCodes, setRecentlyAddedCodes] = useLocalStorageSerializeKey([chainId, "REFERRAL", account], [], {
    deserializer: deserializeSampleStats,
  });

  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const editModalRef = useRef(null);

  const [tabAsActive, setTabAsActive] = useState(AsTabList[0]);
  const [tabTableActive, setTabTableActive] = useState(tableTabList[0]);

  const open = () => setIsEditModalOpen(true);
  const close = () => setIsEditModalOpen(false);

  const { userReferralCode, userReferralCodeString } = useUserReferralCode(library, chainId, account);
  const { codeOwner } = useCodeOwner(library, chainId, account, userReferralCode);
  const { referrerTier: traderTier } = useReferrerTier(library, chainId, account);
  function handleCreateReferralCode(referralCode) {
    return registerReferralCode(chainId, referralCode, library, {
      sentMsg: `Referral code submitted!`,
      failMsg: `Referral code creation failed.`,
      pendingTxns,
    });
  }
  const { userReferralStats } = useUserReferralCodeV2(chainId, account);
  const findUniqueReferrer = (v1, v2) => {
    const uniqueStrings = [...new Set([...v1, ...v2])];
    return uniqueStrings.length;
  };
  const getTotalReferrer = useMemo(() => {
    const arrRefV1 = referralsData?.referrerTotalStats
      ? referralsData?.referrerTotalStats?.[0]?.tradedReferrals || []
      : [];
    const arrRefV2 = userReferralStats?.referrals || [];
    const uniqueReferrer = findUniqueReferrer(arrRefV1, arrRefV2);
    return uniqueReferrer;
  }, [referralsData?.referrerTotalStats, userReferralStats]);
  const getTotalRefVolume = () => {
    let data = referralsData?.cumulativeStats?.volume || BigNumber.from(0);
    if (userReferralStats?.volume && SUPPORTED_V2_CHAINS.includes(chainId)) {
      data = data.add(parseValue(userReferralStats?.volume, 30));
    }
    return getUSDValue(data) || "$0.00";
  };
  const getTotalRefEarn = () => {
    let data = referralsData?.referrerRebates || BigNumber.from(0);
    if (userReferralStats?.rebate && SUPPORTED_V2_CHAINS.includes(chainId)) {
      data = data.add(parseValue(userReferralStats?.rebate, 30));
    }
    return getUSDValue(data, 3) || "$0.000";
  };
  const getTotalTraderVolume = () => {
    let data = referralsData?.referralTotalStats?.volume || BigNumber.from(0);
    if (userReferralStats?.referVolumes && userReferralStats?.referVolumes.length > 0) {
      const totalVolume =
        userReferralStats?.referVolumes?.reduce(
          (val, currentValue) => {
            val.volume += parseNumberOrDefault(currentValue.volume, 0);
            return val;
          },
          { volume: 0 }
        ).volume || 0;
      data = data.add(parseValue(totalVolume, 30));
    }
    return getUSDValue(data) || "$0.00";
  };
  const stats = useUserStats(account);

  const allReferralCodes = referralsData?.referrerTotalStats?.map((c) => c.referralCode.trim()) || [];

  const finalAffiliatesTotalStats = useMemo(
    () =>
      recentlyAddedCodes?.filter(isRecentReferralCodeNotExpired).reduce((acc, cv) => {
        if (!allReferralCodes.includes(cv.referralCode)) {
          acc = acc.concat(cv);
        }
        return acc;
      }, referralsData?.referrerTotalStats || []),
    [allReferralCodes, referralsData?.referrerTotalStats, recentlyAddedCodes]
  );
  const totalEarnRefCode = useMemo(() => {
    if (!finalAffiliatesTotalStats || finalAffiliatesTotalStats.length === 0) {
      return 0;
    }

    return finalAffiliatesTotalStats.reduce((sum, transaction) => {
      let referrerRebate = bigNumberify(0);
      const firstCode = referralsData?.codes?.[0];
      const isLast = firstCode && firstCode === transaction.referralCode && SUPPORTED_V2_CHAINS.includes(chainId);

      if (transaction && transaction.totalRebateUsd && transaction.discountUsd) {
        referrerRebate = transaction.totalRebateUsd.sub(transaction.discountUsd);
      }

      let earn;
      if (isLast) {
        earn = Number(userReferralStats?.amount || 0) + Number(formatAmount(referrerRebate, USD_DECIMALS, 6, false));
      } else {
        earn = Number(formatAmount(referrerRebate, USD_DECIMALS, 6, false));
      }

      return sum + earn;
    }, 0);
  }, [finalAffiliatesTotalStats, referralsData, userReferralStats, chainId]);

  const totalRewardsEarned = useMemo(() => {
    return (
      +formatAmount(referralsData?.referralTotalStats?.discountUsd, USD_DECIMALS, 4, true, "0.00") + totalEarnRefCode
    );
  }, [totalEarnRefCode, referralsData?.referralTotalStats?.discountUsd]);

  function renderAffiliatesTab() {
    const isReferralCodeAvailable =
      referralsData?.codes?.length > 0 || recentlyAddedCodes?.filter(isRecentReferralCodeNotExpired).length > 0;

    if (loading) return <div className="skeleton-box" style={{ height: "30px", marginTop: "20px" }} />;

    return (
      <div className="container-ref">
        <div className="content-ref">
          <AddAffiliateCode
            isReferralCodeAvailable={isReferralCodeAvailable}
            referralsData={referralsData}
            handleCreateReferralCode={handleCreateReferralCode}
            active={active}
            connectWallet={connectWallet}
            recentlyAddedCodes={recentlyAddedCodes}
            setRecentlyAddedCodes={setRecentlyAddedCodes}
          />
          <AffiliatesStats
            referralsData={referralsData}
            handleCreateReferralCode={handleCreateReferralCode}
            setRecentlyAddedCodes={setRecentlyAddedCodes}
            recentlyAddedCodes={recentlyAddedCodes}
            chainId={chainId}
          />
        </div>
      </div>
    );

    // if (loading) return <Loader />;
    // if (isReferralCodeAvailable) {
    //   return (
    //     <AffiliatesStats
    //       referralsData={referralsData}
    //       handleCreateReferralCode={handleCreateReferralCode}
    //       setRecentlyAddedCodes={setRecentlyAddedCodes}
    //       recentlyAddedCodes={recentlyAddedCodes}
    //       chainId={chainId}
    //     />
    //   );
    // } else {
    //   return (
    //     <AddAffiliateCode
    //       handleCreateReferralCode={handleCreateReferralCode}
    //       active={active}
    //       connectWallet={connectWallet}
    //       recentlyAddedCodes={recentlyAddedCodes}
    //       setRecentlyAddedCodes={setRecentlyAddedCodes}
    //     />
    //   );
    // }
  }

  function renderTradersTab() {
    if (loading) return <div className="skeleton-box" style={{ height: "30px", marginTop: "20px" }} />;

    return (
      <div className="container-ref">
        <div className="content-ref">
          <JoinReferralCode
            userReferralCodeString={userReferralCodeString}
            connectWallet={connectWallet}
            active={active}
            setPendingTxns={setPendingTxns}
            pendingTxns={pendingTxns}
          />
          <TradersStats
            userReferralCodeString={userReferralCodeString}
            chainId={chainId}
            referralsData={referralsData}
            setPendingTxns={setPendingTxns}
            pendingTxns={pendingTxns}
            traderTier={traderTier}
          />
        </div>
      </div>
    );
  }
  const TAB_OPTION_LABELS = { [TRADERS]: `Traders`, [AFFILIATES]: `Affiliates` };

  // return (
  //   <SEO title={getPageTitle("Referrals")}>
  //     <Modal
  //       className="Connect-wallet-modal"
  //       isVisible={isEditModalOpen}
  //       setIsVisible={close}
  //       label={userReferralCodeString ? `Edit Referral Code` : "Create Referral Code"}
  //       onAfterOpen={() => editModalRef.current?.focus()}
  //     >
  //       <div className="edit-referral-modal">
  //         <ReferralCodeForm
  //           userReferralCodeString={userReferralCodeString}
  //           setPendingTxns={setPendingTxns}
  //           pendingTxns={pendingTxns}
  //           type={userReferralCodeString ? "edit" : "join"}
  //           callAfterSuccess={() => setIsEditModalOpen(false)}
  //         />
  //       </div>
  //     </Modal>
  //     <Modal
  //       isVisible={isVisible}
  //       setIsVisible={() => setIsVisible(false)}
  //       label="Create Referral Code"
  //       allowContentTouchMove
  //     >
  //       <div className="card-action">
  //         <div className="card-action">
  //           {active ? (
  //             <AffiliateCodeForm
  //               handleCreateReferralCode={handleCreateReferralCode}
  //               recentlyAddedCodes={recentlyAddedCodes}
  //               setRecentlyAddedCodes={setRecentlyAddedCodes}
  //             />
  //           ) : (
  //             <button className="App-cta Exchange-swap-button default-btn" type="submit" onClick={connectWallet}>
  //               <span>Connect Wallet</span>
  //             </button>
  //           )}
  //         </div>
  //       </div>
  //     </Modal>
  //     <div className="default-container page-layout Referrals">
  //       <div className="top-bg-container">
  //         <div
  //           style={{
  //             backgroundImage: `url(${header_ref})`,
  //           }}
  //           className="section-title-block"
  //         >
  //           <div className="section-title-content">
  //             <div className="left">
  //               <div className="Page-title">
  //                 <span>Referrals</span>
  //               </div>
  //               <div className="Page-description">
  //                 <div>
  //                   Get fee discounts and earn rebates through the Navigator referral program.
  //                   <br />
  //                   For more information, please read the{" "}
  //                   <ExternalLink href="https://docs.navigator.exchange/social-features/referrals">
  //                     referral program details
  //                   </ExternalLink>
  //                   .
  //                 </div>
  //               </div>
  //               <div className="more-info-container">
  //                 <div className="earn">
  //                   Refer your friends and <span>earn rewards</span>
  //                 </div>
  //                 <div className="ref-code-container">
  //                   <div className="ref-content">
  //                     https://app.navigator.exchange/?ref=
  //                     {referralsData?.codes && referralsData?.codes?.length > 0 ? referralsData.codes[0] : ""}
  //                     <div className="coppy-container">
  //                       <img
  //                         alt=""
  //                         src={icCoppy}
  //                         onClick={() => {
  //                           copyToClipboard(
  //                             getReferralCodeTradeUrl(
  //                               referralsData?.codes && referralsData?.codes?.length > 0 ? referralsData.codes[0] : ""
  //                             )
  //                           );
  //                           helperToast.success("Referral link copied to your clipboard");
  //                         }}
  //                       />
  //                     </div>
  //                   </div>
  //                 </div>
  //               </div>
  //             </div>

  //             <img className="right" src={ref_header_cash} alt="ref_header_cash" />
  //           </div>
  //         </div>
  //       </div>

  //       {/* <div className="referral-tab-container">
  //         <div className="container">
  //           <Tab
  //             options={TAB_OPTIONS}
  //             optionLabels={TAB_OPTION_LABELS}
  //             option={activeTab}
  //             setOption={setActiveTab}
  //             onChange={setActiveTab}
  //           />
  //         </div>
  //       </div>
  //       {activeTab === AFFILIATES ? renderAffiliatesTab() : renderTradersTab()} */}
  //       <div className="ref-stats-container">
  //         <div className="ref-as-container">
  //           <div className="ref-as-content">
  //             <div className="title">
  //               <img alt="" src={theme?.isLightTheme ? icTraderLight : icTrader} /> As Trader
  //             </div>
  //             <div className="ref-as-line">
  //               <div className="line-title">Your Active Referral Code</div>
  //               {userReferralCodeString ? (
  //                 <div className="line-value">
  //                   {userReferralCodeString} <EditIcon onClick={open} />
  //                 </div>
  //               ) : (
  //                 <div className="line-value">No Code</div>
  //               )}
  //             </div>
  //             <div className="ref-as-line">
  //               <div className="line-title">Your Total Trading Volume</div>
  //               <div className="line-value">{getTotalTraderVolume()}</div>
  //             </div>
  //             <div className="ref-as-line">
  //               <div className="line-title">Trade V1 Discount</div>
  //               <div className="line-value">
  //                 {traderTier ? `${tierDiscountInfo[traderTier]}%` : "0%"}{" "}
  //                 <span className="tier">{traderTier ? `(Tier ${getTierIdDisplay(traderTier)})` : "(Tier 0)"}</span>
  //               </div>
  //             </div>
  //             {SUPPORTED_V2_CHAINS.includes(chainId) && (
  //               <div className="ref-as-line">
  //                 <div className="line-title">Trade V2 Discount</div>
  //                 <div className="line-value">0%</div>
  //               </div>
  //             )}

  //             <div className="ref-as-line">
  //               <div className="line-title">Total Earned</div>
  //               <div className="line-value green-value">
  //                 {referralsData?.referralTotalStats?.discountUsd
  //                   ? getUSDValue(referralsData?.referralTotalStats?.discountUsd, 3)
  //                   : "$0.000"}
  //               </div>
  //             </div>
  //             {active ? (
  //               <button className={userReferralCodeString ? "default-btn" : "border-btn"} onClick={open}>
  //                 {userReferralCodeString ? "Edit code" : "Enter referral code "}
  //               </button>
  //             ) : (
  //               <button className="App-cta Exchange-swap-button default-btn" type="submit" onClick={connectWallet}>
  //                 <span>Connect Wallet</span>
  //               </button>
  //             )}

  //             <div className="ref-desc">
  //               Enter a referral code to receive up to a 5% discount on trading fees. The discount rebates will be
  //               airdropped weekly to your wallet address.
  //             </div>
  //           </div>

  //           <div className="ref-as-content">
  //             <div className="title">
  //               <img alt="" src={theme?.isLightTheme ? icRefLight : icRef} /> As Referrer
  //             </div>
  //             <div className="ref-as-line">
  //               <div className="line-title">Total Traders Referred</div>
  //               <div className="line-value">{getTotalReferrer()}</div>
  //             </div>
  //             <div className="ref-as-line">
  //               <div className="line-title">Total Trading Volume</div>
  //               <div className="line-value">{getTotalRefVolume()}</div>
  //             </div>
  //             <div className="ref-as-line">
  //               <div className="line-title">Your V1 Fee Share</div>
  //               <div className="line-value">
  //                 {traderTier ? `${tierDiscountInfo[traderTier]}%` : "0%"}{" "}
  //                 <span className="tier">{traderTier ? `(Tier ${getTierIdDisplay(traderTier)})` : "(Tier 0)"}</span>
  //               </div>
  //             </div>
  //             {SUPPORTED_V2_CHAINS.includes(chainId) && (
  //               <div className="ref-as-line">
  //                 <div className="line-title">Your V2 Fee Share</div>
  //                 <div className="line-value">5%</div>
  //               </div>
  //             )}

  //             <div className="ref-as-line">
  //               <div className="line-title">Total Earned</div>
  //               <div className="line-value green-value">{getTotalRefEarn()}</div>
  //             </div>
  //             {active ? (
  //               <button className="border-btn" onClick={() => setIsVisible(true)}>
  //                 Create new code
  //               </button>
  //             ) : (
  //               <button className="App-cta Exchange-swap-button default-btn" type="submit" onClick={connectWallet}>
  //                 <span>Connect Wallet</span>
  //               </button>
  //             )}
  //           </div>
  //         </div>
  //         <div className="right-content-container">
  //           <div className="payout-container">
  //             <div className="payout-tab-container">
  //               {PAYOUT_TABS.map((item) => (
  //                 <div
  //                   key={item}
  //                   onClick={() => setCurrentPayoutTab(item)}
  //                   className={`payout-tab ${item === currentPayoutTab ? "payout-tab-active" : ""}`}
  //                 >
  //                   {item}
  //                 </div>
  //               ))}
  //             </div>
  //             {active && currentPayoutTab === PAYOUT_TABS[1] && (
  //               <button className="default-btn" onClick={() => setIsVisible(true)}>
  //                 Create Code
  //               </button>
  //             )}
  //           </div>
  //           <div className="main-content-container">
  //             {currentPayoutTab === PAYOUT_TABS[0] ? (
  //               <PayoutTab />
  //             ) : (
  //               <MyRefCodes
  //                 userReferralStats={userReferralStats}
  //                 referrerTotalStats={referralsData?.referrerTotalStats}
  //                 firstCode={referralsData?.codes?.[0]}
  //                 recentlyAddedCodes={recentlyAddedCodes}
  //                 chainId={chainId}
  //               />
  //             )}
  //           </div>
  //         </div>
  //       </div>
  //     </div>
  //     <Footer />
  //   </SEO>
  // );
  // console.log("traderTier", traderTier);
  return (
    <SEO title={getPageTitle("Referrals")}>
      <Modal
        className="Connect-wallet-modal"
        isVisible={isEditModalOpen}
        setIsVisible={close}
        label={userReferralCodeString ? `Edit Referral Code` : "Create Referral Code"}
        onAfterOpen={() => editModalRef.current?.focus()}
      >
        <div className="edit-referral-modal">
          <ReferralCodeForm
            userReferralCodeString={userReferralCodeString}
            setPendingTxns={setPendingTxns}
            pendingTxns={pendingTxns}
            type={userReferralCodeString ? "edit" : "join"}
            callAfterSuccess={() => setIsEditModalOpen(false)}
          />
        </div>
      </Modal>
      <Modal
        isVisible={isVisible}
        setIsVisible={() => setIsVisible(false)}
        label="Create Referral Code"
        allowContentTouchMove
      >
        <div className="card-action">
          <div className="card-action">
            {active ? (
              <AffiliateCodeForm
                handleCreateReferralCode={handleCreateReferralCode}
                recentlyAddedCodes={recentlyAddedCodes}
                setRecentlyAddedCodes={setRecentlyAddedCodes}
                setIsVisible={setIsVisible}
              />
            ) : (
              <button className="App-cta Exchange-swap-button default-btn" type="submit" onClick={connectWallet}>
                <span>Connect Wallet</span>
              </button>
            )}
          </div>
        </div>
      </Modal>
      <Wrapper className="ref-stats-container">
        <div className="head">
          <div className="img-wrap-small">
            <img src={ImageReferral} alt="" />
          </div>
          <div className="head-left">
            <div>
              <div className="title">Referrals</div>
              <div className="desc">
                Get fee discounts and earn rebates through the Navigator referral program.
                <br /> For more information, please read the{" "}
                <a
                  target="_blank"
                  href="https://docs.navigator.exchange/social-features/referrals"
                  className="link-desc"
                  rel="noreferrer"
                >
                  referral program details
                </a>
                .
              </div>
            </div>
            <div className="content-head-left">
              <div className="content-head-first">
                <div className="content-head-first-title">Refer your friends and earn rewards</div>

                <div className="link-ref">
                  <div>
                    {" "}
                    https://app.navigator.exchange/?ref=
                    {referralsData?.codes && referralsData?.codes?.length > 0 ? referralsData.codes[0] : ""}
                  </div>
                  <div
                    className="icon-copy"
                    onClick={() => {
                      copyToClipboard(
                        getReferralCodeTradeUrl(
                          referralsData?.codes && referralsData?.codes?.length > 0 ? referralsData.codes[0] : ""
                        )
                      );
                      helperToast.success("Referral link copied to your clipboard");
                    }}
                  >
                    <IconCopy />
                  </div>
                </div>
              </div>

              <div className="content-head-last">
                <div className="item-content">
                  <div className="item-content-title">Traders Referred</div>
                  <div className="item-content-value">{getTotalReferrer}</div>
                </div>
                <div className="item-content">
                  <div className="item-content-title">Rewards Earned</div>
                  <div className="item-content-value">${Number(totalRewardsEarned).toFixed(4)}</div>
                </div>
              </div>
            </div>
          </div>
          <div className="img-wrap">
            <img className="img-ref" src={ImageReferral} alt="" />
          </div>
        </div>

        <div className="body">
          <div className="body-left">
            {/* <div
              style={{
                width: "100%",
              }}
              className="tab-wrapper"
            >
              {AsTabList.map((item, idx) => (
                <TabItem
                  style={{
                    flex: "1 0 0",
                  }}
                  onClick={() => setTabAsActive(item.value)}
                  isActive={item.value === tabAsActive}
                  key={idx}
                >
                  {item.title}
                </TabItem>
              ))}
            </div> */}
            <TabContainer
              tab50={true}
              fullWidth={true}
              TABS={AsTabList}
              setActiveTab={setTabAsActive}
              activeTab={tabAsActive}
            />
            <div className="body-left-container">
              {tabAsActive.key === "trader" && (
                <div
                  style={{
                    width: "100%",
                  }}
                >
                  {!userReferralCodeString ? (
                    <div className="wrapper-content-tab">
                      <div className="trader-null-text">
                        Enter a referral code to receive up to a 5% discount on trading fees. The discount rebates will
                        be airdropped weekly to your wallet address.
                      </div>
                      {account ? (
                        <button onClick={open} className="default-btn">
                          Enter ref. code
                        </button>
                      ) : (
                        <button className="default-btn" type="submit" onClick={connectWallet}>
                          <span>Connect Wallet</span>
                        </button>
                      )}
                    </div>
                  ) : (
                    <div className="wrapper-content-tab">
                      <div className="box-trader">
                        <div className="item-trader">
                          <div className="item-trader-title">Your Active Referral Code</div>
                          <div className="item-trader-value">
                            {userReferralCodeString ? <div>{userReferralCodeString}</div> : <div>No Code</div>}
                            <div
                              style={{
                                height: "16px",
                                cursor: "pointer",
                              }}
                              onClick={open}
                            >
                              <IconEdit />
                            </div>
                          </div>
                        </div>
                        <div className="item-trader">
                          <div className="item-trader-title">Your Total Trading Volume</div>
                          <div className="item-trader-value">{getTotalTraderVolume()}</div>
                        </div>
                        <div className="item-trader">
                          <div className="item-trader-title">Crypto Pool Discount</div>
                          <div className="item-trader-value" style={{ gap: "4px" }}>
                            {" "}
                            {traderTier ? `${tierDiscountInfo[traderTier]}%` : "0%"}
                            <span className="item-trader-value-tier">
                              {traderTier ? `(Tier ${getTierIdDisplay(traderTier)})` : "(Tier 0)"}
                            </span>
                          </div>
                        </div>
                        <div className="item-trader">
                          <div className="item-trader-title">Stable Pool Discount</div>
                          <div className="item-trader-value" style={{ gap: "4px" }}>
                            {"0%"}
                            <span className="item-trader-value-tier">
                              {traderTier ? `(Tier ${getTierIdDisplay(traderTier)})` : "(Tier 0)"}
                            </span>
                          </div>
                        </div>
                        <div className="item-trader">
                          <div className="item-trader-title">Total Earned</div>
                          <div className="item-trader-value green-value">
                            {referralsData?.referralTotalStats?.discountUsd
                              ? getUSDValue(referralsData?.referralTotalStats?.discountUsd, 4)
                              : "$0.0000"}
                          </div>
                        </div>
                      </div>
                      <button onClick={open} className="btn-fill">
                        Edit Ref. Code
                      </button>
                      <div className="desc-trader-edit">
                        Enter a referral code to receive up to a 5% discount on trading fees. The discount rebates will
                        be airdropped weekly to your wallet address.
                      </div>
                    </div>
                  )}
                </div>
              )}

              {tabAsActive.key === "referrer" && (
                <div
                  style={{
                    width: "100%",
                  }}
                >
                  {!referralsData?.codes || referralsData?.codes?.length === 0 ? (
                    <div className="wrapper-content-tab">
                      <div className="trader-null-text">
                        You don't have any referral code yet. Create a referral code to receive fee sharing now.
                      </div>
                      {account ? (
                        <button onClick={() => setIsVisible(true)} className="default-btn">
                          Create code
                        </button>
                      ) : (
                        <button className="default-btn" type="submit" onClick={connectWallet}>
                          <span>Connect Wallet</span>
                        </button>
                      )}
                    </div>
                  ) : (
                    <div className="wrapper-content-tab">
                      <div className="box-trader">
                        <div className="item-trader">
                          <div className="item-trader-title">Total Traders Referred</div>
                          <div className="item-trader-value">{getTotalReferrer}</div>
                        </div>
                        <div className="item-trader">
                          <div className="item-trader-title">Total Trading Volume</div>
                          <div className="item-trader-value">{getTotalRefVolume()}</div>
                        </div>
                        <div className="item-trader">
                          <div className="item-trader-title">Your Fee Share</div>
                          <div className="item-trader-value" style={{ gap: "4px" }}>
                            {" "}
                            {traderTier ? `${getTotalReferrer > 0 ? tierDiscountInfo[traderTier] : "0"}%` : "0%"}
                            <span className="item-trader-value-tier">
                              {traderTier
                                ? `(Tier ${getTierIdDisplay(getTotalReferrer > 0 ? traderTier : null)})`
                                : "(Tier 0)"}
                            </span>
                          </div>
                        </div>
                        <div className="item-trader">
                          <div className="item-trader-title">Total Earned</div>
                          <div className="item-trader-value green-value">
                            ${formatAmount(parseValue(totalEarnRefCode, 30), 30, 4, true)}
                          </div>
                        </div>
                      </div>
                      <button onClick={() => setIsVisible(true)} className="btn-fill">
                        Create Code
                      </button>
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
          <div className="body-right">
            <div className="head-table">
              {/* <div className="tab-wrapper">
                {tableTabList.map((item, idx) => (
                  <TabItem
                    onClick={() => setTabTableActive(item.value)}
                    isActive={item.value === tabTableActive}
                    key={idx}
                  >
                    {item.title}
                  </TabItem>
                ))}
              </div> */}
              <TabContainer
                fullWidth={true}
                TABS={tableTabList}
                setActiveTab={setTabTableActive}
                activeTab={tabTableActive}
                rightComponent={
                  tabTableActive.key === "referral" && (
                    <div className="button-container">
                      <button className="btn-fill btn-create-table" onClick={() => setIsVisible(true)}>
                        Create Code
                      </button>
                    </div>
                  )
                }
              />{" "}
              {tabTableActive.key === "referral" && (
                <button
                  className="btn-fill btn-create-table btn-create-table-mobile"
                  onClick={() => setIsVisible(true)}
                >
                  Create Code
                </button>
              )}
            </div>

            <div className="main-content-container">
              {tabTableActive.key === "payout" ? (
                <PayoutTab />
              ) : (
                <MyRefCodes
                  userReferralStats={userReferralStats}
                  referrerTotalStats={referralsData?.referrerTotalStats}
                  firstCode={referralsData?.codes?.[0]}
                  recentlyAddedCodes={recentlyAddedCodes}
                  chainId={chainId}
                  totalReferer={getTotalReferrer}
                />
              )}
            </div>
          </div>
        </div>
      </Wrapper>
      <Footer />
    </SEO>
  );
}

export default Referrals;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  .btn-create-table-mobile {
    display: none;
  }
  @media screen and (max-width: 700px) {
    .btn-create-table-mobile {
      display: block;
      margin-left: 16px;
      margin-right: 16px;
    }
  }
  .button-container {
    margin-right: 24px;
    @media screen and (max-width: 700px) {
      .btn-create-table {
        display: none;
      }
    }
  }
  .default-btn {
    width: 100%;
  }
  .btn-outline {
    width: 100%;
  }
  .head {
    display: flex;
    /* align-items: center; */
    gap: 24px;
    width: 100%;
    position: relative;
  }
  .title {
    color: #fff;
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
    @media screen and (max-width: 700px) {
      font-size: 24px;
    }
  }
  .img-wrap {
    display: flex;
    justify-content: flex-end;
    @media screen and (max-width: 1282px) {
      display: none;
    }
  }
  .img-wrap-small {
    display: none;
    @media screen and (max-width: 1282px) {
      display: block;
      position: absolute;
      right: 0;
      top: 12px;
      width: 254px;
      img {
        max-width: 100%;
        height: 252px;
      }
    }
    @media screen and (max-width: 768px) {
      top: 17px;
    }
    @media screen and (max-width: 700px) {
      display: none;
    }
  }
  .desc {
    color: var(--Text-Text_Secondary, #828899);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    margin-top: 8px;
    max-width: calc(100% - 278px);
    @media screen and (max-width: 991px) {
      br {
        display: none;
      }
    }
    @media screen and (max-width: 700px) {
      max-width: 100%;
    }
  }
  .link-desc {
    color: var(--Text-Text_Secondary, #828899);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    text-decoration-line: underline;
    cursor: pointer;
  }
  .head-left {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 40px;
    flex: 1 0 0;
    > div {
      width: 100%;
    }
    @media screen and (max-width: 991px) {
      gap: 24px;
    }
    @media screen and (max-width: 700px) {
      gap: 40px;
    }
  }
  .content-head-left {
    display: flex;
    align-items: center;
    gap: 24px;
    align-self: stretch;
    @media screen and (max-width: 1280px) {
      flex-direction: column;
      align-items: flex-start;
    }
    @media screen and (max-width: 700px) {
      gap: 16px;
    }
  }

  .img-ref {
    height: auto;
    max-width: 100%;

    @media screen and (max-width: 1282px) {
      display: none;
    }
  }

  .content-head-first {
    max-height: 126px;
    width: 424px;
    padding: 24px;
    border-radius: 16px;
    background: #12141a;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    /* gap: 8px; */
    @media screen and (max-width: 1280px) {
      width: calc(100vw - 326px);
    }
    @media screen and (max-width: 700px) {
      width: 100%;
      padding: 24px 16px;
      gap: 0;
    }
  }
  .content-head-first-title {
    color: white;

    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%; /* 19.6px */
  }
  .link-ref {
    margin-top: 8px;
    display: flex;
    padding: 12px 16px;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    border-radius: var(--Border-Radius-Medium, 12px);
    border: 2px solid var(--Primary, #90fb75);
    background: var(--Nature-1, #12141a);
  }
  .icon-copy {
    cursor: pointer;
    width: 24px;
    height: 24px;
  }
  .content-head-last {
    display: flex;
    padding: 24px;
    align-items: center;
    gap: 36px;
    flex: 1 0 0;
    border-radius: 16px;
    background: var(--Nature-1, #12141a);
    width: 100%;
    color: #fff;
    @media screen and (max-width: 1282px) {
      width: calc(100vw - 48px);
    }
    @media screen and (max-width: 700px) {
      width: 100%;
      padding: 24px 16px;
    }
  }
  .item-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    flex: 1 0 0;
  }
  .item-content-title {
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    color: var(--Primary, #90fb75);
  }
  .item-content-value {
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%; /* 36px */
    display: flex;
    height: 48px;
    align-items: center;
    gap: 8px;
  }
  .body {
    margin-top: 24px;
    display: inline-flex;
    align-items: flex-start;
    gap: 24px;
    @media screen and (max-width: 1220px) {
      flex-direction: column;
    }
    @media screen and (max-width: 700px) {
      margin-top: 16px;
    }
  }
  .body-left {
    position: relative;
    width: 424px;
    display: flex;
    padding: 0;
    flex-direction: column;
    align-items: flex-start;
    height: fit-content;
    gap: 16px;
    align-self: stretch;
    border-radius: 16px;
    background: var(--Nature-1, #12141a);
    &-container {
      padding: 0 24px 24px 24px;
      width: 100%;
      @media screen and (max-width: 700px) {
        padding: 0 16px 16px 16px;
      }
    }
    @media screen and (max-width: 1220px) {
      width: 100%;
    }
  }
  .tab-wrapper {
    display: flex;

    align-items: center;
    align-self: stretch;

    transition: all 0.4s;
    width: fit-content;
    position: absolute;
    top: -24px;
    left: -24px;
    top: 0;
    left: 0;
    @media screen and (max-width: 700px) {
      width: 100%;
    }
  }
  .wrapper-content-tab {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
    width: 100%;
  }
  .trader-null-text {
    display: flex;
    padding: 24px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;
    align-self: stretch;
    border-radius: 16px;
    border: 1px solid var(--Border, rgba(55, 63, 92, 0.5));
    color: var(--Text-Text_Secondary, #828899);
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%; /* 19.6px */
    text-align: center;
  }
  .item-trader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    width: 100%;
  }
  .item-trader-title {
    color: var(--Text-Text_Secondary, #828899);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%; /* 19.6px */
  }
  .item-trader-value {
    display: flex;
    align-items: center;
    gap: 8px;
    color: #fff;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%; /* 19.6px */
  }
  .box-trader {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
    align-self: stretch;
    margin: 8px 0;
  }
  .item-trader-value-tier {
    color: var(--Text-Text_Secondary, #828899);
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
  }
  .desc-trader-edit {
    color: var(--Text-Text_Secondary, #828899);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%; /* 19.6px */
    align-self: stretch;
    margin-top: 8px;
  }
  .body-right {
    flex: 1 1 0;
    /* padding: 24px; */
    border-radius: 16px;
    background: var(--Nature-1, #12141a);
    @media screen and (max-width: 1220px) {
      width: 100%;
    }
    @media screen and (max-width: 700px) {
      /* padding: 24px 16px; */
    }
    .main-content-container {
      padding: 24px;
      @media screen and (max-width: 700px) {
        padding: 16px;
      }
    }
  }
  .head-table {
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media screen and (max-width: 700px) {
      flex-direction: column;
      gap: 16px;
    }
  }
  .btn-create-table {
    background: var(--Primary, #90fb75);
    color: var(--Text-Text_Button, #000);
    border: none;
    &:hover {
      background: var(--primary-hover);
    }
  }
`;

const TabItem = styled.div`
  display: flex;
  padding: 12px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%; /* 19.6px */
  cursor: pointer;
  border: 2px solid transparent;
  white-space: nowrap;
  @media screen and (max-width: 768px) {
    flex: 1 1 0;
  }
  ${({ isActive }) =>
    isActive
      ? css`
          border-radius: var(--Border-Radius-Medium, 100px);

          background: var(--Nature-3, #262933);
          color: #90fb75;
        `
      : css`
          color: #828899;
          &:hover {
            border-radius: var(--Border-Radius-Medium, 100px);

            background: #28292c;
          }
        `}
`;
