import { IconVault, ImageIconBigVault, ImageIconSmallVault, ImageNavi, ImageNLP, ImageNSLP, ImageNAVIWS } from "assets";
import React from "react";
import { useMedia } from "react-use";
import styled from "styled-components";
import { motion } from "framer-motion";
import { contentVariants, descVariant } from "./Banner";
import { itemVariants, listVariants } from "lib/anim";
import { formatKeyAmount, formatNumber } from "lib/numbers";
import { caculateAPYDaily } from "lib/helper";
import useBanks from "hooks/vault/useBanks";
import useAllStakedValueVault from "hooks/vault/useAllStakedValueVault";
import { iconsToken } from "utils/helpers";

interface BankInfoMap {
  [key: string]: any;
}

const VaultLanding = ({ processedData }) => {
  const banks = useBanks();

  const stakedValue: BankInfoMap = useAllStakedValueVault(
    banks.filter((x) => x.type === "vault" || x.type === "vaultCandidate")
  );

  const pools = banks
    .filter((x) => x.type === "vault" || x.type === "vaultCandidate")
    .map((bank) => {
      const tokenPrice = stakedValue[bank.contract]?.tokenPrice || 1;
      const staked = stakedValue[bank.contract]?.staked || 0;
      const totalDeposited = stakedValue[bank.contract]?.totalDeposited || 0;
      const totalWithdraw = stakedValue[bank.contract]?.totalWithdraw || 0;
      const earning = stakedValue[bank.contract]?.earning || 0;
      // const tvlFarm = stakedValue[bank.contract]?.tvl || 0;
      // const pendingHarvest = stakedValue[bank.contract]?.pendingHarvest || 0;

      // totalTvlFarm += tvlFarm;

      // totalEarning += earning;
      // totalPendingHarvest += pendingHarvest;
      // if (staked * tokenPrice >= 0.1) {
      // vaultActive += 1;
      // totalDeposit += staked * tokenPrice;
      // }

      return {
        ...bank,
        tvl: stakedValue[bank.contract]?.tvl || 0,
        incentiveApr: stakedValue[bank.contract]?.incentiveApr || 0,
        apr: stakedValue[bank.contract]?.apyDaily * 365 || 0,
        apy: stakedValue[bank.contract]?.apy || 0,
        apyDaily: stakedValue[bank.contract]?.apyDaily || 0,
        aprDaily: stakedValue[bank.contract]?.aprDaily || 0,
        allocPoint: stakedValue[bank.contract]?.allocPoint || 0,
        staked,
        totalDeposited,
        totalWithdraw,
        earning,
        unfrozenTime: stakedValue[bank.contract]?.unfrozenTime,
        rewardPerSecond: stakedValue[bank.contract]?.rewardPerSecond,
        lpBalance: stakedValue[bank.contract]?.lpBalance || 0,
        tokenPrice,
      };
    });

  const best2pools = pools.sort((a, b) => b.apy - a.apy).slice(0, 2);

  const caculateAPYHourly = (apr, time) => {
    const aprTimeFrame = (apr / 2920) * time;
    const A = 1 + aprTimeFrame / (time * 100);
    return (Math.pow(A, time) - 1) * 100;
  };
  const naviAPYcalc =
    Number(
      String(caculateAPYHourly(Number(formatKeyAmount(processedData, "naviAprForNativeToken", 2, 2, true)), 2920))
    ) + Number(String(caculateAPYHourly(Number(formatKeyAmount(processedData, "naviAprForEsNavi", 2, 2, true)), 2920)));
  const naviAPY = naviAPYcalc ? formatNumber(Number(naviAPYcalc), 2) : "--";

  const nlpAPYcalc =
    Number(
      String(caculateAPYHourly(Number(formatKeyAmount(processedData, "nlpAprForNativeToken", 2, 2, true)), 2920))
    ) + Number(String(caculateAPYHourly(Number(formatKeyAmount(processedData, "nlpAprForEsNavi", 2, 2, true)), 2920)));
  const nlpAPY = nlpAPYcalc ? formatNumber(Number(nlpAPYcalc), 2) : "--";

  const dailyNaviCalc =
    Number(String(caculateAPYDaily(Number(formatKeyAmount(processedData, "naviAprForNativeToken", 2, 2, true)), 1))) +
    Number(String(caculateAPYDaily(Number(formatKeyAmount(processedData, "naviAprForEsNavi", 2, 2, true)), 1)));
  const dailyNavi = dailyNaviCalc ? formatNumber(Number(dailyNaviCalc), 2) : "--";
  const dailyNlpCalc =
    Number(String(caculateAPYDaily(Number(formatKeyAmount(processedData, "nlpAprForNativeToken", 2, 2, true)), 1))) +
    Number(String(caculateAPYDaily(Number(formatKeyAmount(processedData, "nlpAprForEsNavi", 2, 2, true)), 1)));
  const dailyNlp = dailyNlpCalc ? formatNumber(Number(dailyNlpCalc), 2) : "--";

  const tableData = [
    {
      name: "NAVI",
      apy: naviAPY ? naviAPY : "-",
      daily: dailyNavi,
      img: ImageNavi,
      disable: false,
      link: "https://app.navigator.exchange/#/vault/detail/native/navi",
    },
    {
      name: "NLP",
      apy: nlpAPY ? nlpAPY : "-",
      daily: dailyNlp,
      img: ImageNLP,
      disable: false,
      link: "https://app.navigator.exchange/#/vault/detail/native/nlp",
    },
    // {
    //   name: "NSLP",
    //   apy: "--",
    //   daily: "--",
    //   img: ImageNSLP,
    //   disable: true,
    // },
    // {
    //   name: "NAVI-WS LP",
    //   apy: "--",
    //   daily: "--",
    //   img: ImageNAVIWS,
    //   disable: true,
    // },
  ];
  const isMobile = useMedia("(max-width: 768px)");

  return (
    <Wrapper>
      <img className="left-small" src={ImageIconSmallVault} alt="" />
      <img className="left-big" src={ImageIconBigVault} alt="" />
      <img className="right-small" src={ImageIconSmallVault} alt="" />
      <img className="right-big" src={ImageIconBigVault} alt="" />
      <div className="wrapper container">
        <div className="left-vault">
          <motion.div initial="hidden" whileInView="visible" variants={contentVariants} className="title-left">
            <div>Maximize Your Yield</div>
            <div className="title-left-sub">
              With{" "}
              <div className="vault-box">
                <IconVault size={isMobile ? "32" : "49"} /> Vault
              </div>
            </div>
          </motion.div>
          <motion.div initial="hidden" whileInView="visible" variants={descVariant}>
            Our cutting-edge auto-compound Yield Farming strategy, designed to empower investors to earn rewards
            effortlessly.
          </motion.div>
        </div>
        <motion.div variants={listVariants} initial="hidden" whileInView="visible" className="right-vault">
          {tableData.map((item, idx) => (
            <motion.div variants={itemVariants} className="box-item" key={idx}>
              <div className="item-left">
                <div>
                  <img className="img-coin" src={item.img} alt="" />
                </div>

                <div className="wrap-value">
                  {" "}
                  <div className="name-coin">{item.name}</div>
                  <div className="boxes">
                    <div className="box-value">
                      <div className="sub-title">APY</div>
                      {item.apy}%
                    </div>

                    <div className="box-value">
                      <div className="sub-title">Daily</div>
                      {item.daily}%
                    </div>
                  </div>
                </div>
              </div>
              {item.disable ? (
                <button disabled={true} className="border-btn btn-earn">
                  Coming Soon
                </button>
              ) : (
                <a href={item.link} className="border-btn btn-earn" rel="noreferrer">
                  Earn Now
                </a>
              )}
            </motion.div>
          ))}
          {best2pools.map((item, idx) => (
            <motion.div variants={itemVariants} className="box-item" key={idx}>
              <div className="item-left">
                <div className="panel-header__logos">
                  {/* <img className="img-coin" src={item.img} alt="" /> */}
                  {item.depositTokens.length && (
                    <span className="panel-header__logo-left">
                      <img src={iconsToken[item.depositTokens[0]]} alt="" />
                    </span>
                  )}
                  {item.depositTokens.length === 2 && (
                    <span className="panel-header__logo-right">
                      <img src={iconsToken[item.depositTokens[1]]} alt="" />
                    </span>
                  )}
                </div>

                <div className="wrap-value">
                  {" "}
                  <div className="name-coin">{item.name}</div>
                  <div className="boxes">
                    <div className="box-value">
                      <div className="sub-title">APY</div>
                      {formatNumber(item.apy)}%
                    </div>

                    <div className="box-value">
                      <div className="sub-title">Daily</div>
                      {formatNumber(item.apyDaily, 4)}%
                    </div>
                  </div>
                </div>
              </div>
              <a
                href={`https://app.navigator.exchange/#/vault/detail/${item.pid}-146`}
                className="border-btn btn-earn"
                rel="noreferrer"
              >
                Earn Now
              </a>
            </motion.div>
          ))}
        </motion.div>
      </div>
    </Wrapper>
  );
};

export default VaultLanding;

const Wrapper = styled.div`
  .wrapper.container {
    padding-left: 24px;
    padding-right: 24px;
    max-width: 1368px;
    @media screen and (max-width: 1200px) {
      flex-direction: column;
      padding: 48px 24px;
    }
    @media screen and (max-width: 700px) {
      padding: 48px 16px;
      gap: 16px;
    }
  }

  .panel-header__logos {
    display: flex;
    align-items: center;
    align-self: flex-start;

    .panel-header__logo-left {
      img {
        width: 32px;
        height: 32px;
      }
    }

    .panel-header__logo-right {
      margin-left: -8px;

      img {
        width: 32px;
        height: 32px;
      }
    }
  }

  /* @media screen and (max-width: 768px) {
    padding: 20px 0;
  } */
  position: relative;
  background: #0c0f0c;
  .left-small {
    position: absolute;
    left: 11%;
    top: 13%;
  }
  .left-big {
    position: absolute;
    left: 3%;
    bottom: 25%;
  }
  .right-small {
    position: absolute;
    right: 6%;
    top: 47%;
    height: 36px;
    width: 36px;
  }
  .right-big {
    position: absolute;
    right: 11%;
    bottom: 9%;
    width: 118px;
    height: 91px;
    z-index: 5;
  }
  .wrapper {
    display: flex;

    gap: 24px;

    padding: 120px 0px;
    @media screen and (max-width: 1024px) {
      grid-template-columns: repeat(1, minmax(0, 1fr));
      row-gap: 24px;
      column-gap: 0px;
      padding: 50px 0px;
    }
  }
  .left-vault {
    max-width: 524px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    gap: 16px;
    color: var(--Text-Text_Secondary, #828899);
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%; /* 22.4px */

    @media screen and (min-width: 701px) and (max-width: 1200px) {
      max-width: 100%;
    }
    @media screen and (max-width: 700px) {
      width: 100%;
    }
  }
  .title-left {
    color: var(--Primary, #90fb75);
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%; /* 67.2px */
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    @media screen and (min-width: 701px) and (max-width: 1200px) {
      flex-direction: row;
      white-space: nowrap;
      font-size: 36px;
      .vault-box {
        font-size: 36px;
      }
    }
    @media screen and (max-width: 700px) {
      .vault-box {
        font-size: 24px;
      }
      font-size: 24px;

      width: 100%;
      gap: 12px;
    }
  }
  .title-left-sub {
    display: flex;
    align-items: flex-start;
    gap: 16px;
  }
  .vault-box {
    display: flex;
    padding: 0px 16px;
    align-items: center;
    gap: var(--Base-Unit-XS-8, 8px);
    background: var(--Primary, #90fb75);
    color: var(--Black, #000);
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%; /* 67.2px */
    /* @media screen and (max-width: 768px) {
      font-size: 32px;
    } */
  }
  .right-vault {
    flex: 1;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 16px;
    position: relative;
    z-index: 10;
    @media screen and (max-width: 700px) {
      grid-template-columns: 1fr;
    }
  }
  table {
    width: 100%;
  }
  .name-coin {
    color: #fff;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%; /* 22.4px */
  }
  .box-info {
    display: flex;
    align-items: center;
    gap: 12px;
    width: 100%;
    flex: 1 0 0;
    @media screen and (max-width: 768px) {
      gap: 6px;
    }
  }
  .sub-title {
    color: var(--Text-Text_Secondary, #828899);
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%; /* 22.4px */
    /* @media screen and (max-width: 768px) {
      font-size: 14px;
    } */
  }
  .box-value {
    /* width: 100%; */

    display: flex;

    gap: 4px;

    color: var(--Primary, #90fb75);
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%; /* 22.4px */
    /* @media screen and (max-width: 768px) {
      font-size: 14px;
    } */
  }
  .box-item {
    height: fit-content;
    flex-direction: column;
    display: flex;
    padding: 24px;
    align-items: center;
    gap: 16px;
    align-self: stretch;
    border-radius: 24px;
    background: #161a16;
    @media screen and (max-width: 700px) {
      padding: 16px;
    }
  }
  .btn-earn {
    width: 100%;
    /* @media screen and (max-width: 768px) {

    } */
  }
  .item-left {
    display: flex;
    align-items: center;
    gap: 12px;
    width: 100%;
    .boxes {
      display: flex;
      gap: 16px;
    }
    /* @media screen and (max-width: 768px) {
      flex-direction: column;
      width: 100%;
    } */
  }
  .wrap-value {
    display: flex;
    flex-direction: column;
    gap: 8px;

    @media screen and (max-width: 768px) {
      width: 100%;
    }
  }
  .img-coin {
    width: 48px;
    height: 48px;

    /* @media screen and (max-width: 768px) {
      width: 30px;
      height: 30px;
    } */
  }
`;
