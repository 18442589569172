/* eslint-disable no-fallthrough */
import styled from "styled-components";
import cx from "classnames";

import { useThemeContext } from "contexts/ThemeProvider";

import useBackHistory from "hooks/useBackHistory";
import Avatar from "components/Avatar";
import { hashString, importImage } from "lib/legacy";
import { DataTable, SkeletonLoadingTable, TableRow } from "../helper/table";

import useMergedPositionInfo from "hooks/useMergedPositionInfo";
import { Link, useParams } from "react-router-dom";
import { formatAmount, formatAmountInDollar, parseValue } from "lib/numbers";
import moment from "moment";
import { BigNumber } from "ethers";
import { useMediaQuery } from "react-responsive";
import LinkSquareIcon from "components/Icon/LinkSquareIcon";
import { TYPE_MAP_TYPE } from ".";
import { getExplorerUrl } from "config/chains";
import ExternalLink from "components/ExternalLink/ExternalLink";
import NoItemFound from "pages/Analytics/components/NoItemFound";
import Tooltip from "components/Tooltip/Tooltip";
import BackIcon from "components/Icon/BackIcon";
import StatsTooltipRow from "../../../components/StatsTooltip/StatsTooltipRow";
import { useMemo, useState } from "react";
import PaginationV2 from "../../AnalyticsV3/components/PaginationV2";

// const renderDeltaCollateral = (action) => {
//   let prefix = "";

//   //Get prefix for Collateral Delta
//   switch (action.actionType) {
//     case "EDIT_COLLATERAL":
//       if (action.isPlus) {
//         prefix = "+";
//       } else {
//         prefix = "-";
//       }
//       break;

//     case "OPEN_POSITION":
//     case "ADD_POSITION":
//       prefix = "+";
//       break;

//     case "DECREASE_POSITION":
//     case "CLOSE_POSITION":
//     case "LIQUIDATE_POSITION":
//     case "ADD_TRAILING_STOP":
//       prefix = "-";
//       break;

//     default:
//       prefix = "";
//   }

//   //Get number for Collateral Delta
//   switch (action.actionType) {
//     case "OPEN_POSITION":
//     case "DECREASE_POSITION":
//     case "CLOSE_POSITION":
//     case "ADD_POSITION":
//     case "LIQUIDATE_POSITION":
//       return prefix + formatAmountInDollar(action.collateral, 30, 2, true);

//     case "EDIT_COLLATERAL":
//       return prefix + formatAmountInDollar(action.amount, 30, 2, true);

//     default:
//       return null;
//   }
// };

// const renderDeltaSize = (action) => {
//   let prefix = "";

//   //Get prefix for Size Delta
//   switch (action.actionType) {
//     case "OPEN_POSITION":
//     case "ADD_POSITION":
//       prefix = "+";
//       break;

//     case "DECREASE_POSITION":
//     case "CLOSE_POSITION":
//     case "LIQUIDATE_POSITION":
//     case "ADD_TRAILING_STOP":
//       prefix = "-";
//       break;

//     case "EDIT_COLLATERAL":
//     default:
//       prefix = "";
//   }

//   //Get number for Size Delta
//   switch (action.actionType) {
//     case "OPEN_POSITION":
//     case "DECREASE_POSITION":
//     case "CLOSE_POSITION":
//     case "ADD_POSITION":
//     case "LIQUIDATE_POSITION":
//       return prefix + formatAmountInDollar(action.tradeVolume, 30, 2, true);

//     case "EDIT_COLLATERAL":
//     default:
//       return null;
//   }
// };

const PAGE_SIZE = 10;

const PositionDetail = ({ chainId, isValidChain }) => {
  const isSmallDevice = useMediaQuery({ query: "(max-width: 1023px)" });
  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });
  const [page, setPage] = useState(1);
  const { lightThemeClassName, isLightTheme } = useThemeContext();
  // const { assets } = useMarketTokensInfo();
  const { posId } = useParams();
  const { data: positionInfo, isLoading: isPositionInfoLoading } = useMergedPositionInfo(chainId, posId);

  const { symbol, decimals } = positionInfo?.indexToken || {};
  const back = useBackHistory("/analytic-v2/top-positions");

  const indexSymbol = symbol?.split("/")[0];

  const tokenImage = positionInfo?.indexToken ? importImage(`ic_${indexSymbol.toLowerCase()}_40.svg`) : undefined;

  const pagination = useMemo(() => {
    return positionInfo?.activities?.filter((_activity, index) => {
      return (page - 1) * PAGE_SIZE <= index && index <= page * PAGE_SIZE - 1;
    });
  }, [page, JSON.stringify(positionInfo?.activities)]);

  const isV2 = positionInfo?.type === "V2";

  const stringId =
    positionInfo.openTimestamp &&
    `${positionInfo.openTimestamp.toString()}-${positionInfo.key}-${positionInfo.openTimestamp.toString()}`;
  const posIdHash = isV2 ? undefined : hashString(stringId);

  const renderSign = (type, action) => {
    if (action === "LIQUIDATE_POSITION" || action === "LIQUIDATE") {
      return "-";
    }
    if (type === "Collateral") {
      if (
        action === "DEPOSIT" ||
        action === "INCREASE" ||
        action === "OPEN" ||
        action === "OPEN_POSITION" ||
        action === "ADD_POSITION"
      ) {
        return `+`;
      }
      if (
        action === "WITHDRAW" ||
        action === "DECREASE" ||
        action === "CLOSE" ||
        action === "CLOSE_POSITION" ||
        action === "DECREASE_POSITION"
      ) {
        return `-`;
      }
    }
    if (type === "Size") {
      if (action === "INCREASE" || action === "OPEN" || action === "OPEN_POSITION" || action === "ADD_POSITION") {
        return `+`;
      }
      if (
        action === "DECREASE" ||
        action === "CLOSE" ||
        action === "CLOSE_POSITION" ||
        action === "DECREASE_POSITION"
      ) {
        return `-`;
      }
    }
  };

  return (
    <Wrapper className={lightThemeClassName}>
      {isValidChain && (
        <>
          <div className="breadcrumb">
            <div className="back-btn" onClick={back}>
              {/* <img src={isLightTheme ? left_arrowLight : left_arrow} alt="left_arrow" /> */}
              <BackIcon fill={isLightTheme && "#0D1A16"} />
            </div>
            {isPositionInfoLoading ? (
              <SkeletonLoadingPositionInfo />
            ) : (
              <>
                <div className="position">
                  {tokenImage ? <img src={tokenImage} alt="tokenImage" /> : <div className="skeleton-box token-img" />}
                  <div className="position-detail">
                    <div className="position-detail-top">
                      {symbol?.includes("/") ? symbol : symbol + "/USD"}
                      <span className="posId">
                        {!isPositionInfoLoading ? `#${posIdHash || posId}` : <div className="skeleton-box" />}
                      </span>
                    </div>
                    <div className="position-detail-bottom">
                      <span className={cx({ positive: positionInfo.isLong })}>
                        {positionInfo.isLong ? "LONG" : "SHORT"}
                      </span>{" "}
                      <div className="leverage">
                        {Number(positionInfo.leverage) ? Number(positionInfo.leverage).toFixed(2) : "--"}X
                      </div>
                      {/* <div className="position-detail-price">
                        Entry Price:{" "}
                        <span>
                          {formatAmountInDollar(positionInfo.averagePrice, 30, decimals || 2, true, {
                            autoDisplayDecimals: true,
                          })}
                        </span>
                      </div>
                      <svg xmlns="http://www.w3.org/2000/svg" width="2" height="12" viewBox="0 0 2 12" fill="none">
                        <path d="M1 0.5V11.5" stroke="white" />
                      </svg>
                      <div className="position-detail-price">
                        {positionInfo.isClosed && (
                          <>
                            Exit Price:{" "}
                            <span>
                              {formatAmountInDollar(data?.rows?.[0]?.markPrice, 30, decimals || 2, true, {
                                autoDisplayDecimals: true,
                              })}
                            </span>
                          </>
                        )}
                        {!positionInfo.isClosed && (
                          <>
                            Mark Price:{" "}
                            <span>
                              {formatAmountInDollar(marketPrice, 30, decimals || 2, true, {
                                autoDisplayDecimals: true,
                              })}
                            </span>
                          </>
                        )}
                      </div> */}
                    </div>
                  </div>
                </div>
                <div className="wallet">
                  {/* Wallet:{" "} */}
                  {/* {positionInfo.account ? (
                    <Avatar address={positionInfo.account} size="24px" chainId={chainId} />
                  ) : (
                    <span className="skeleton-box wallet-img" />
                  )}{" "} */}
                  {positionInfo.account ? (
                    <Link to={`/analytics/trader/${positionInfo.account}`} className="link-account">
                      {isMobile
                        ? positionInfo.account?.slice(0, 6) +
                          "..." +
                          positionInfo.account?.slice(positionInfo.account.length - 4, positionInfo.account.length)
                        : positionInfo.account}

                      <LinkSquareIcon />
                    </Link>
                  ) : (
                    <span>
                      <div className="skeleton-box" />
                    </span>
                  )}
                </div>
              </>
            )}
          </div>
          <div className="summary">
            <div className="sumary-item">
              Entry Price
              <div>
                {formatAmountInDollar(positionInfo.entryPrice, 30, decimals || 2, true, {
                  autoDisplayDecimals: true,
                })}
              </div>
            </div>
            {/* {positionInfo.isClosed && (
              <div className="sumary-item">
                Exit Price
                <div>
                  {formatAmountInDollar(
                    parseValue(positionInfo?.activities?.[0]?.marketPrice, 30),
                    30,
                    decimals || 2,
                    true,
                    {
                      autoDisplayDecimals: true,
                    }
                  )}
                </div>
              </div>
            )} */}
            <div className="sumary-item">
              {positionInfo.isClosed ? "Exit Price" : "Mark Price"}
              <div>
                {formatAmountInDollar(
                  positionInfo.isClosed
                    ? parseValue(positionInfo?.activities?.[0]?.price, 30)
                    : positionInfo.marketPrice,
                  30,
                  decimals || 2,
                  true,
                  {
                    autoDisplayDecimals: true,
                  }
                )}
              </div>
            </div>
            <div className="sumary-item">
              Position Size
              <div>
                {!isPositionInfoLoading ? (
                  formatAmountInDollar(positionInfo.size, 30, 2, true)
                ) : (
                  <div className="skeleton-box" />
                )}
              </div>
            </div>
            <div className="sumary-item">
              Collateral Value
              <div>
                {!isPositionInfoLoading ? (
                  formatAmountInDollar(positionInfo.collateral, 30, 2, true)
                ) : (
                  <div className="skeleton-box" />
                )}
              </div>
            </div>
            <div className="sumary-item">
              <Tooltip
                handle={`PnL`}
                position={isMobile ? "left-top" : "center-top"}
                // className="fitnowrap"
                renderContent={() => {
                  return (
                    <>
                      <span>
                        {positionInfo.isClosed
                          ? "Realized PnL after all paid fees"
                          : positionInfo.isV2
                          ? "Unrealized PnL after accrued funding, borrow and position fee"
                          : "Unrealized PnL after accrued borrow and position fee"}
                      </span>
                      <br />
                      <br />

                      {!positionInfo.isClosed && (
                        <StatsTooltipRow
                          label={`PnL without fees`}
                          showDollar={false}
                          value={
                            positionInfo.isClosed
                              ? (positionInfo.netPnl?.lt(0) ? "-$" : "$") +
                                formatAmount(positionInfo.netPnl?.abs(), 30, 2, true)
                              : (positionInfo.netPnl?.lt(0) ? "-$" : "$") +
                                formatAmount(positionInfo.netPnl?.abs(), 30, 2, true)
                          }
                        />
                      )}

                      {!positionInfo.isClosed && (
                        <>
                          <>
                            {positionInfo.isV2 ? (
                              <StatsTooltipRow
                                label={`Accrued funding fee`}
                                showDollar={false}
                                value={
                                  (positionInfo.accruedFundingFee?.lt(0) ? "-$" : "$") +
                                  formatAmount(positionInfo.accruedFundingFee?.abs(), 30, 2, true)
                                }
                              />
                            ) : (
                              <StatsTooltipRow
                                label={`Accrued position fee`}
                                showDollar={false}
                                value={
                                  (positionInfo.accruedPositionFee?.lt(0) ? "-$" : "$") +
                                  formatAmount(positionInfo.accruedPositionFee?.abs(), 30, 2, true)
                                }
                              />
                            )}
                            {positionInfo.isV2 ? (
                              <StatsTooltipRow
                                label={`Accrued borrow fee`}
                                showDollar={false}
                                value={
                                  (positionInfo.accruedBorrowFee?.lt(0) ? "-$" : "$") +
                                  formatAmount(positionInfo.accruedBorrowFee?.abs(), 30, 2, true)
                                }
                              />
                            ) : (
                              <StatsTooltipRow
                                label={`Accrued borrow fee`}
                                showDollar={false}
                                value={
                                  (positionInfo.accruedFundingFee?.lt(0) ? "-$" : "$") +
                                  formatAmount(positionInfo.accruedFundingFee?.abs(), 30, 2, true)
                                }
                              />
                            )}
                          </>
                        </>
                      )}

                      {positionInfo.isV2 && positionInfo.isClosed && (
                        <>
                          <StatsTooltipRow
                            label={`Paid funding fee`}
                            showDollar={false}
                            value={
                              (positionInfo?.paidFundingFee?.lt(0) ? "-$" : "$") +
                              formatAmount(positionInfo?.paidFundingFee?.abs(), 30, 2, true)
                            }
                          />
                          <StatsTooltipRow
                            label={`Paid borrow fee`}
                            showDollar={false}
                            value={
                              (positionInfo?.paidBorrowFee?.lt(0) ? "-$" : "$") +
                              formatAmount(positionInfo?.paidBorrowFee?.abs(), 30, 2, true)
                            }
                          />
                        </>
                      )}

                      {positionInfo.isV2 && (
                        <>
                          <StatsTooltipRow
                            label={positionInfo.isClosed ? `Paid position fee` : `Accrued position fee`}
                            showDollar={false}
                            value={
                              (positionInfo?.paidPositionFee?.lt(0) ? "-$" : "$") +
                              formatAmount(positionInfo?.paidPositionFee?.abs(), 30, 2, true)
                            }
                          />
                        </>
                      )}

                      {!positionInfo.isV2 && positionInfo.isClosed && (
                        <>
                          <StatsTooltipRow
                            label={`Paid borrow fee`}
                            showDollar={false}
                            value={
                              (positionInfo?.accruedFundingFee?.lt(0) ? "-$" : "$") +
                              formatAmount(positionInfo?.accruedFundingFee?.abs(), 30, 2, true)
                            }
                          />
                          <StatsTooltipRow
                            label={`Paid position fee`}
                            showDollar={false}
                            value={
                              (positionInfo?.accruedPositionFee?.lt(0) ? "-$" : "$") +
                              formatAmount(positionInfo?.accruedPositionFee?.abs(), 30, 2, true)
                            }
                          />
                        </>
                      )}
                    </>
                  );
                }}
              />
              <div
                className={cx({
                  positive: positionInfo.pnlAfterFee && BigNumber.from(positionInfo.pnlAfterFee).gt(0),
                  negative: positionInfo.pnlAfterFee && BigNumber.from(positionInfo.pnlAfterFee).lt(0),
                })}
              >
                {!isPositionInfoLoading ? (
                  formatAmountInDollar(positionInfo.pnlAfterFee, 30, 2, true, {
                    showPlus: true,
                  })
                ) : (
                  <div className="skeleton-box" />
                )}
              </div>
            </div>
            {/* <div className="sumary-item">
              Paid Fees
              <div>
                {!isPositionInfoLoading ? (
                  formatAmountInDollar(positionInfo.fee, 30, 2, true)
                ) : (
                  <div className="skeleton-box" />
                )}
              </div>
            </div> */}
            {!positionInfo.isClosed && (
              <div className="sumary-item">
                Liquidation Price
                <div className="yellow">
                  {!isPositionInfoLoading ? (
                    formatAmountInDollar(positionInfo.liquidPrice, 30, decimals || 2, true, {
                      autoDisplayDecimals: true,
                    })
                  ) : (
                    <div className="skeleton-box" />
                  )}
                </div>
              </div>
            )}
          </div>
          <DataTable $noIndex $rowHeightSkeletonMobile="146.38px" className={`table-analytics ${lightThemeClassName}`}>
            <div className="head">
              <div className="col-name">Time</div>
              <div className="col-name">Action</div>
              <div className="col-name">Collateral Delta</div>
              <div className="col-name">Size Delta</div>
              <div className="col-name">Paid Fee</div>
              <div className="col-name">Executed Price</div>
            </div>
            <div className={`body ${pagination?.length === 1 ? "body-single-row" : ""}`}>
              {!isPositionInfoLoading &&
                pagination?.map((row, i) => {
                  // const positionFee = BigNumber.from(row.positionFee);
                  // const fundingFee = BigNumber.from(row.fundingFee);
                  // const borrowFee = BigNumber.from(row.borrowFee);
                  // const paidFees = positionFee.add(fundingFee).add(borrowFee);

                  const hasPaidFee = !["EDIT_COLLATERAL", "LIQUIDATE"].includes(row.action);
                  const withdrawOrDeposit = ["DEPOSIT", "WITHDRAW"].includes(row.action);
                  return (
                    <TableRow1 key={i} noScaleOnHover>
                      <div d-label="Time" className="col-body col-time" style={{ color: "#828899" }}>
                        {moment(row?.timestamp * 1000).format("YYYY-MM-DD HH:mm:ss")}
                      </div>
                      <div d-label="Action" className="col-body">
                        {row.action === "ADD_TRAILING_STOP"
                          ? row.trailingStopAll
                            ? "Close"
                            : "Decrease"
                          : TYPE_MAP_TYPE[row.action]}
                      </div>
                      <div
                        d-label="Collateral Delta"
                        className={cx("col-body", {
                          // "col-hide-mobile": renderDeltaCollateral(row) === null,
                        })}
                      >
                        {withdrawOrDeposit ? (
                          <>
                            {positionInfo.isV2 ? (
                              <>
                                {renderSign("Collateral", row.action)}
                                {formatAmountInDollar(parseValue(row.amount, 30), 30, 2, true, {
                                  autoDisplayDecimals: true,
                                })}
                              </>
                            ) : (
                              <>
                                {renderSign("Collateral", row.action)}
                                {formatAmountInDollar(parseValue(row.collateral, 30), 30, 2, true, {
                                  autoDisplayDecimals: true,
                                })}
                              </>
                            )}
                          </>
                        ) : (
                          <>
                            {renderSign("Collateral", row.action)}
                            {formatAmountInDollar(parseValue(row.collateral, 30), 30, 2, true, {
                              autoDisplayDecimals: true,
                            })}
                          </>
                        )}

                        {/* {renderDeltaCollateral(row)} */}
                      </div>
                      <div
                        d-label="Size Delta"
                        className={cx("col-body", {
                          // "col-hide-mobile": renderDeltaSize(row) === null,
                        })}
                      >
                        {renderSign("Size", row.action)}
                        {row.action.includes("DEPOSIT") || row.action.includes("WITHDRAW")
                          ? ""
                          : formatAmountInDollar(parseValue(row.size, 30), 30, 2, true, {
                              autoDisplayDecimals: true,
                            })}
                      </div>
                      <div
                        d-label="Paid Fee"
                        className={cx("col-body", {
                          "col-hide-mobile": !hasPaidFee,
                        })}
                      >
                        {renderSign("Paid", row.action)}
                        {row.action.includes("DEPOSIT") || row.action.includes("WITHDRAW")
                          ? ""
                          : hasPaidFee && formatAmountInDollar(parseValue(row.fee, 30), 30, 2, true)}
                      </div>
                      <div d-label="Executed Price" className="col-body">
                        {formatAmountInDollar(parseValue(row.price, 30), 30, 2, true, {
                          autoDisplayDecimals: true,
                        })}
                      </div>
                      {isSmallDevice && (
                        <ExternalLink
                          d-label=""
                          className="col-body col-link"
                          href={`${getExplorerUrl(chainId)}tx/${row.txhash}`}
                        >
                          <LinkSquareIcon />
                        </ExternalLink>
                      )}
                      <ExternalLink className="icon-last col-link" href={`${getExplorerUrl(chainId)}tx/${row.txhash}`}>
                        <LinkSquareIcon />
                      </ExternalLink>
                    </TableRow1>
                  );
                })}
            </div>
            {!isPositionInfoLoading && positionInfo?.activities?.length > 0 && (
              <PaginationV2
                className="pagination-analytics"
                currentPage={page}
                setCurrentPage={setPage}
                rowsPerPage={PAGE_SIZE}
                rows={positionInfo?.activities?.length}
              />
            )}
            {isPositionInfoLoading && <SkeletonLoadingTable noHeading />}
          </DataTable>
        </>
      )}
      {!isValidChain && <NoItemFound />}
    </Wrapper>
  );
};

function SkeletonLoadingPositionInfo() {
  return (
    <>
      <div className="position skeleton">
        <div className="skeleton-box token-img" />
        <div className="position-detail">
          <div className="position-detail-top">
            <div className="skeleton-box" />
          </div>
          <div className="position-detail-bottom">
            <div className="skeleton-box" />
          </div>
        </div>
      </div>
      <div className="wallet skeleton">
        <div className="skeleton-box wallet-img" />
        <span>
          <div className="skeleton-box" />
        </span>
      </div>
    </>
  );
}

const TableRow1 = styled(TableRow)`
  @media screen and (max-width: 1023px) {
    &.body-row .icon-last {
      display: block !important;

      top: 12px;
    }
  }
`;

const Wrapper = styled.div`
  width: 100%;
  margin: 0 auto 284px;

  .link-account {
    svg {
      margin-left: 8px;
      position: relative;
      top: 3px;
      &:hover {
        path {
          fill: #90fb75;
        }
      }
    }
  }
  .table-analytics {
    padding: 24px;
    border-radius: 16px;
    background: var(--Nature-1, #12141a);
  }
  @media screen and (max-width: 700px) {
    margin-bottom: 0;
    .breadcrumb {
      margin-top: 24px;
    }
  }
  .body {
    .body-row {
      background: #1a1d26;
    }
  }

  @media screen and (max-width: 1023px) {
    .body {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 24px;
      &.body-single-row {
        grid-template-columns: 1fr;
      }
      .body-row {
        border-radius: 16px;
        background: #12141a;
        padding: 0 0 16px 0;
        .col-body {
          padding: 0 24px;
        }
        .link-underline {
          display: none;
        }
        .col-time {
          border-bottom: 1px solid var(--Border, rgba(55, 63, 92, 0.5));
          padding: 12px 24px !important;
          font-weight: 700;
          color: #fff !important;
          &::before {
            content: none;
          }
        }
      }
    }
  }
  @media screen and (max-width: 768px) {
    .table-analytics {
      padding: 0;
      background: none;
    }
  }
  @media screen and (max-width: 700px) {
    .body {
      display: grid;
      grid-template-columns: 1fr;
      gap: 16px;
      .body-row {
        .col-body {
          padding: 0 16px;
        }
        .col-time {
          padding: 12px 16px !important;
        }
      }
    }
  }

  .breadcrumb {
    display: flex;
    align-items: center;
    gap: 16px;
    margin-bottom: 24px;

    .back-btn {
      display: flex;
      padding: 8px;
      align-items: center;
      gap: 8px;

      border-radius: 1000px;
      border: 1px solid var(--Border, rgba(55, 63, 92, 0.5));
      background: var(--Nature-1, #12141a);
      cursor: pointer;

      &:hover {
        path {
          fill: #90fb75;
        }
      }
      @media screen and (max-width: 700px) {
        width: 24px;
        height: 24px;
        padding: 0;
      }
    }

    .position {
      flex: 1;
      display: flex;
      align-items: center;
      gap: var(--Base-Unit-XS-8, 8px);
      flex: 1 0 0;

      & > img {
        width: 48px;
        height: 48px;
      }

      & > .skeleton-box.token-img {
        width: 48px;
        height: 48px;
        border-radius: 999px;
      }

      .position-detail {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: stretch;
        gap: 4px;

        .position-detail-top {
          display: flex;
          align-items: baseline;
          gap: var(--Border_radius, 4px);

          font-size: 16px;
          font-weight: 700;
          line-height: 140%; /* 22.4px */

          .posId {
            color: var(--Text-Text_Secondary, #828899);
            font-size: 14px;
            font-weight: 500;
            line-height: 140%; /* 19.6px */
          }
        }

        .position-detail-bottom {
          display: flex;
          align-items: center;
          gap: var(--Border_radius, 4px);

          span {
            color: #f6475d;
            font-size: 12px;
            font-weight: 700;
            line-height: 140%; /* 16.8px */

            &.isLong {
              color: var(--Primary, #90fb75);
            }
          }

          .leverage {
            display: flex;
            padding: 0px 4px;
            justify-content: flex-end;
            align-items: center;
            gap: 10px;

            border-radius: 6px;
            background: var(--Leverage-Background, rgba(255, 199, 0, 0.2));

            color: var(--Leverage-Text, #ffc700);

            font-size: 12px;
            font-weight: 700;
            line-height: 140%; /* 16.8px */
            height: 17px;
          }

          .position-detail-price {
            color: #828899;
            font-size: 14px;
            font-weight: 500;
            line-height: 140%; /* 19.6px */

            span {
              color: #fff;
            }
          }
        }
      }

      &.skeleton {
        .skeleton-box.token-img {
          width: 48px;
          height: 48px;
          border-radius: 999px;
        }

        .position-detail {
          width: 500px;

          .position-detail-bottom {
            margin-top: 4px;
          }

          .skeleton-box {
            height: 19.7px;
          }
        }
      }
    }

    .wallet {
      color: #828899;
      font-size: 14px;
      font-weight: 500;
      line-height: 140%; /* 19.6px */
      display: flex;
      align-items: center;
      gap: var(--Base-Unit-XS-8, 8px);

      a {
        color: #fff;
        font-size: 14px;
        font-weight: 500;
        line-height: 140%; /* 19.6px */
        text-decoration: none;
      }

      & > .skeleton-box.wallet-img {
        width: 24px;
        height: 24px;
        border-radius: 50%;
        flex-shrink: 0;
      }

      span {
        width: 326px;
        display: flex;

        @media screen and (max-width: 767px) {
          width: 175px;
        }
      }

      &.skeleton {
        & > .skeleton-box.wallet-img {
          width: 24px;
          height: 24px;
          border-radius: 50%;
        }

        span {
          width: 326px;
          display: flex;
        }
      }
    }
  }

  .summary {
    display: flex;
    padding: 16px 24px;
    align-items: center;
    gap: 48px;
    align-self: stretch;
    margin-bottom: 24px;

    border-radius: 16px;
    background: var(--Nature-1, #12141a);

    .sumary-item {
      color: #828899;
      font-size: 14px;
      font-weight: 500;
      line-height: 140%; /* 19.6px */

      width: 100%;

      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 8px;
      align-self: stretch;

      & > div {
        color: #fff;
        font-size: 14px;
        font-weight: 700;
        line-height: 140%; /* 19.6px */

        &.green {
          color: #03f5ae;
        }

        &.yellow {
          color: #ffb200;
        }
      }

      &:has(.skeleton-box) {
        .skeleton-box {
          width: 50px;
          display: flex;
        }
      }
    }
  }

  .pagination-analytics {
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;

    .ant-select {
      & > .ant-select-selector {
        border-radius: 4px;
      }
    }

    .btn.btn-primary {
      border-radius: var(--Border_radius, 4px);
      border: 1px solid var(--Border, rgba(55, 63, 92, 0.5));
      width: 24px;
      height: 24px;
    }

    .btn-label {
      margin: 0;
      padding: 0;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  @media screen and (max-width: 1023px) {
    margin-top: 0;

    .breadcrumb {
      margin-top: 48px;
      .wallet {
        width: 100%;
        display: flex;
        justify-content: flex-end;
      }
    }

    .summary {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 24px;
      .sumary-item {
        flex-direction: column;
        align-items: flex-start;
      }
    }
  }

  @media screen and (max-width: 767px) {
    .summary {
      grid-template-columns: repeat(2, 1fr);
    }
    .breadcrumb {
      margin-bottom: 16px;
      gap: 8px;

      .position {
        & > img {
          width: 32px;
          height: 32px;
        }

        & > .skeleton-box.token-img {
          width: 24px;
          height: 24px;
          border-radius: 999px;
        }

        &.skeleton {
          .skeleton-box.token-img {
            width: 24px;
            height: 24px;
          }

          .position-detail {
            width: 200px;

            .position-detail-bottom {
              margin-top: 4px;
            }

            .skeleton-box {
              height: 19.7px;
            }
          }
        }
      }

      .wallet {
        &.skeleton {
          span {
            width: 175px;
          }
        }
      }
    }

    .summary {
      flex-direction: column;
      align-items: stretch;
      gap: 12px;
      margin-bottom: 16px;
      padding-left: 16px;
      padding-right: 16px;

      .sumary-item {
        /* flex-direction: row;
        align-items: center;
        justify-content: space-between; */
      }
    }
  }

  &.theme--light {
    .breadcrumb {
      border-bottom-color: rgba(13, 26, 22, 0.7);

      .back-btn {
        background: var(--Nature-2, #f2f5f7);
      }

      .position {
        .position-detail-top {
          color: #0d1a16;

          .posId {
            color: var(--Text-Text_Secondary, rgba(13, 26, 22, 0.7));
          }
        }

        .position-detail-bottom {
          svg {
            path {
              stroke: #0d1a16;
            }
          }

          .position-detail-price {
            color: var(--Text-Text_Secondary, rgba(13, 26, 22, 0.7));

            span {
              color: #0d1a16;
            }
          }
        }
      }

      .wallet {
        a {
          color: #0d1a16;
        }
      }
    }

    .summary {
      .sumary-item {
        .Tooltip-handle {
          color: #0d1a16b2;
        }

        div {
          color: #0d1a16;

          &.yellow {
            color: var(--Functional-Orange, #d49007);
          }
        }
      }
    }
  }
`;

export default PositionDetail;
