function Icon({ ...props }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.size ?? "49"}
      height={props.size ?? "49"}
      fill="none"
      viewBox="0 0 49 49"
    >
      <path
        fill="#000"
        d="M9 8.5c-2.48 0-4.5 2.02-4.5 4.5v23c0 2.48 2.02 4.5 4.5 4.5h1.543a2.5 2.5 0 004.91 0h18.09a2.5 2.5 0 004.91 0H40c2.48 0 4.5-2.02 4.5-4.5V13c0-2.48-2.02-4.5-4.5-4.5H9zm5.992 7.992c.64 0 1.282.243 1.77.73l1.691 1.692a6.296 6.296 0 014.094 0l1.691-1.691a2.505 2.505 0 013.54 0 2.505 2.505 0 010 3.539l-1.665 1.66c.244.658.387 1.356.387 2.078 0 .722-.145 1.418-.387 2.074l1.664 1.664a2.5 2.5 0 01-3.535 3.535l-1.664-1.66a5.975 5.975 0 01-2.078.387 5.991 5.991 0 01-2.074-.387l-1.664 1.664a2.491 2.491 0 01-1.77.73 2.491 2.491 0 01-1.77-.73 2.505 2.505 0 010-3.539l1.665-1.66A5.967 5.967 0 0114.5 24.5c0-.722.143-1.42.387-2.078l-1.664-1.66a2.503 2.503 0 010-3.54 2.496 2.496 0 011.77-.73zM37 18.5a2.5 2.5 0 012.5 2.5v7a2.5 2.5 0 01-5 0v-7a2.5 2.5 0 012.5-2.5z"
      ></path>
    </svg>
  );
}

export default Icon;
