import { ImageAssets, ImageFeatures, ImagePools, ImageProtocol, ImageSecured } from "assets";
import React from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import { itemVariants, listVariants, titleVariants } from "lib/anim";

const contentFirst = [
  {
    title: "CEX-like Trading Features",
    value:
      "Navigator empowers you with exclusive position management tools, including TP/SL order, collateral adjustment, leverage modification, position size increase, and trailing stop.",
    img: ImageFeatures,
  },
  {
    title: "High-Leverage Perpetuals, All Assets",
    value:
      "Navigator lets you trade crypto, forex, and commodities. Take advantage of up to 100x leverage, to maximize your trading opportunities.",
    img: ImageAssets,
  },
];
const contentSecond = [
  {
    title: "Double Trading Liquidity Pools",
    value:
      "Trading orders on Navigator are executed across two specialized liquidity pools: one for blue-chip assets and another exclusively for stablecoins.",
    img: ImagePools,
  },
  {
    title: "SAFU, Transparency and Secured",
    value:
      "Navigator uses Pyth to protect against fraudulent trading and manipulation. Your funds are self-custodied. All smart contracts are open-source, transparent and audited.",
    img: ImageSecured,
  },
];
const Protocol = () => {
  return (
    <Wrapper>
      <motion.div initial="hidden" whileInView="visible" variants={titleVariants} className="title">
        Protocol strengths
      </motion.div>
      <div className="container wrapper">
        <motion.div initial="hidden" whileInView="visible" variants={listVariants}>
          {contentFirst.map((item, idx) => (
            <div key={idx}>
              <motion.div variants={itemVariants} className="item-protocol protocol-left">
                <div className="box-title ">
                  <div className="title-protocol">{item.title}</div>
                  <div>{item.value}</div>
                </div>
                <img className="img-protocol" src={item.img} alt="" />
              </motion.div>
              {idx === 0 && <div className="line-left" />}
            </div>
          ))}
        </motion.div>
        <div className="mid-protocol">
          <div className="line-top" />
          <img src={ImageProtocol} alt="" />
          <div className="line-bottom" />
        </div>
        <motion.div initial="hidden" whileInView="visible" variants={listVariants}>
          {contentSecond.map((item, idx) => (
            <div key={idx}>
              <motion.div variants={itemVariants} className="item-protocol">
                <img className="img-protocol" src={item.img} alt="" />

                <div className="box-title title-right">
                  <div className="title-protocol">{item.title}</div>
                  <div className="desc-right">{item.value}</div>
                </div>
              </motion.div>
              {idx === 0 && <div className="line-right" />}
            </div>
          ))}
        </motion.div>
      </div>
    </Wrapper>
  );
};

export default Protocol;

const Wrapper = styled.div`
  .title {
    color: Text/Text_Primary;
    text-align: center;
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%; /* 50.4px */
    margin-bottom: 24px;
    @media screen and (max-width: 768px) {
      font-size: 28px;
    }
  }
  .wrapper {
    display: flex;
    align-items: center;
    align-self: stretch;
    @media screen and (max-width: 1024px) {
      flex-direction: column;
    }
  }
  .wrapper > *:not(:first-child) {
    margin-left: -2px;
  }
  .mid-protocol {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: stretch;
    @media screen and (max-width: 1024px) {
      display: none;
    }
  }
  .mid-protocol > *:not(:first-child) {
    margin-top: -2px;
  }
  .line-top {
    width: 2px;
    flex: 1 0 0;
    background: linear-gradient(180deg, rgba(85, 149, 69, 0) 0%, #90fb75 100%);
  }
  .line-bottom {
    width: 2px;
    flex: 1 0 0;
    background: linear-gradient(180deg, #90fb75 0%, rgba(85, 149, 69, 0) 100%);
  }
  .item-protocol {
    display: flex;
    padding: 48px 0px;
    align-items: center;
    gap: 24px;
    flex: 1 0 0;
    align-self: stretch;
    @media screen and (max-width: 1024px) {
      flex-direction: column;
      gap: 12px;
      padding: 24px 0px;
    }
  }
  .img-protocol {
    @media screen and (max-width: 768px) {
      width: 25%;
    }
  }
  .protocol-left {
    @media screen and (max-width: 1024px) {
      flex-direction: column-reverse;
    }
  }
  .box-title {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    gap: 16px;
    flex: 1 0 0;
    color: #fff;
    text-align: right;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%; /* 22.4px */
    @media screen and (max-width: 1024px) {
      align-items: center;
      text-align: center;
      font-size: 14px;
    }
  }
  .title-right {
    align-items: flex-start;
    @media screen and (max-width: 1024px) {
      align-items: center;
    }
  }
  .desc-right {
    text-align: left;
    @media screen and (max-width: 1024px) {
      text-align: center;
    }
  }
  .title-protocol {
    color: var(--Primary, #90fb75);
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%; /* 22.4px */
  }
  .line-left {
    height: 2px;
    align-self: stretch;
    background: linear-gradient(270deg, #90fb75 0%, rgba(85, 149, 69, 0) 100%);
    @media screen and (max-width: 1024px) {
      display: none;
    }
  }
  .line-right {
    height: 2px;
    align-self: stretch;
    background: linear-gradient(270deg, rgba(85, 149, 69, 0) 0%, #90fb75 100%);
    @media screen and (max-width: 1024px) {
      display: none;
    }
  }
`;
