import { SONIC_TESTNET } from "config/chains";
import vaults from "config/vaults.json";
import nativeVaults from "config/native_vaults.json";
import sonicTokens from "config/tokens_146.json";

export const listTokens = [
  {
    chainId: SONIC_TESTNET,
    tokens: sonicTokens,
  },
];

export const BankVaultsNew = [
  {
    chainId: 146,
    address: "0x8Ae21A4f5b99f18De502FCeb3174C74229ab326C",
  },
];

export const bankDefinitions = Object.assign({}, vaults);
export const nativeBankDefinitions = Object.assign({}, nativeVaults);
